import React from 'react';

const MonitorDashboardActiveSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="monitor-dashboard">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.4142 2.58579C22.0391 2.21071 21.5304 2 21 2H3C1.89 2 1 2.89 1 4V16C1 17.1 1.89 18 3 18H10V20H8V22H16V20H14V18H21C21.5304 18 22.0391 17.7893 22.4142 17.4142C22.7893 17.0391 23 16.5304 23 16V4C23 3.46957 22.7893 2.96086 22.4142 2.58579ZM14 6H5V11H14V6ZM19 6H15V8H19V6ZM19 14V9H15V14H19ZM9 12H5V14H9V12ZM14 12H10V14H14V12Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default MonitorDashboardActiveSvg;
