import {
  IOptionsWithMaterials,
  IArrayFromBackend,
  IArrayOrderListFromBackend,
  IOptions,
  IParametersOfAddTariffs,
  IResponseDetailsTariff,
} from './type';
import { fetchGet } from '../../../common/proxy-1C-fetch-auth';
import { changeFieldOfSelector, convertForSelectOptions, nameOfParameters } from './const';
import dayjs from 'dayjs';
import React from 'react';

export const getListOrganization = async (setMainParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>) => {
  try {
    const responseOrganization: IArrayFromBackend[] = await fetchGet(`/admin/companies/organizations`, {});
    changeFieldOfSelector({
      setState: setMainParametersInfo,
      nameObject: nameOfParameters.ORGANIZER,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseOrganization),
      clearValue: false,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListCompany = async (
  currentRole: string,
  setMainParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
) => {
  try {
    const role = currentRole === 'shipment' ? 'supplier' : currentRole;
    const responseListCompany: IArrayFromBackend[] = await fetchGet(`/admin/companies/list`, { type: role });
    changeFieldOfSelector({
      setState: setMainParametersInfo,
      nameObject: nameOfParameters.COUNTERPARTY,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListCompany, true),
      clearValue: true,
    });
    return responseListCompany;
  } catch (e) {
    console.log(e);
  }
};
export const getListQuarryCarrier = async (
  carrierParametersInfo: IParametersOfAddTariffs[],
  setCarrierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
  materialTypeId?: number,
) => {
  try {
    const responseListQuarry: IArrayFromBackend[] = await fetchGet(`/admin/quarries/list`, {
      materialTypeId: materialTypeId || Number(carrierParametersInfo[0].value),
    });
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.QUARRY,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListQuarry),
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListQuarrySupplier = async (
  mainParametersInfo: IParametersOfAddTariffs[],
  setSupplierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
  companyId?: number,
) => {
  try {
    const responseListQuarry: IArrayFromBackend[] = await fetchGet(`/admin/quarries/list`, {
      companyId: companyId || Number(mainParametersInfo[2].value),
    });
    changeFieldOfSelector({
      setState: setSupplierParametersInfo,
      nameObject: nameOfParameters.QUARRY,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListQuarry),
      clearValue: true,
    });
    changeFieldOfSelector({
      setState: setSupplierParametersInfo,
      nameObject: nameOfParameters.MATERIAL,
      fieldOfObject: 'options',
      valueField: null,
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListOrderShipment = async (
  mainParametersInfo: IParametersOfAddTariffs[],
  setShipmentParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
) => {
  try {
    const responseListOrder: IArrayOrderListFromBackend[] = await fetchGet(`/admin/orders/list`, {
      status: 'Активные',
      withMaterials: true,
    });
    const formattedResponse: IOptionsWithMaterials[] = responseListOrder.map(response => ({
      inn: response?.client?.inn,
      value: response.id.toString(),
      label: `${response.id} от ${response.date}: ${response?.client?.title} ${response?.client?.inn}`,
      materials: response?.materials.map(item => {
        return {
          value: item.id.toString(),
          label: item.material,
        };
      }),
    }));
    changeFieldOfSelector({
      setState: setShipmentParametersInfo,
      nameObject: nameOfParameters.ORDER,
      fieldOfObject: 'orderList',
      valueField: formattedResponse,
      clearValue: false,
    });
    changeFieldOfSelector({
      setState: setShipmentParametersInfo,
      nameObject: nameOfParameters.ORDER,
      fieldOfObject: 'options',
      valueField: formattedResponse.map(item => {
        return {
          value: item.value,
          label: item.label,
        };
      }),
      clearValue: true,
    });

    //Зануляем материал опции и значение, когда делаем перезапрос поле (заказ)
    changeFieldOfSelector({
      setState: setShipmentParametersInfo,
      nameObject: nameOfParameters.MATERIAL,
      fieldOfObject: 'options',
      valueField: null,
      clearValue: true,
    });
    return formattedResponse;
  } catch (e) {
    console.log(e);
  }
};
export const getListContracts = async (
  mainParametersInfo: IParametersOfAddTariffs[],
  setMainParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
  clientId?: number,
  organizationId?: number,
) => {
  try {
    const responseListContracts: IArrayFromBackend[] = await fetchGet(`/admin/contracts/list`, {
      companyId: clientId || Number(mainParametersInfo[2].value),
      organizationId: organizationId || Number(mainParametersInfo[0].value),
    });
    changeFieldOfSelector({
      setState: setMainParametersInfo,
      nameObject: nameOfParameters.CONTRACT,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListContracts),
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListMaterialsCarrier = async (setCarrierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>) => {
  try {
    const responseListMaterialsCarrier: IArrayFromBackend[] = await fetchGet(`/admin/materials/types`, {});
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.TYPE_MATERIAL,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListMaterialsCarrier),
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListSemitrailers = async (setCarrierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>) => {
  try {
    const responseListMaterialsCarrier: IArrayFromBackend[] = await fetchGet(`/admin/cars/semitrailers`, {});
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.SEMITRAILER,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListMaterialsCarrier),
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListObjects = async (setCarrierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>) => {
  try {
    const responseListObjects: IArrayFromBackend[] = await fetchGet(`/admin/objects/list`, {});
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.OBJECT,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListObjects),
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getListMaterialsByQuarry = async (
  supplierParametersInfo: IParametersOfAddTariffs[],
  setSupplierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
  quarryId?: number,
) => {
  try {
    const responseListMaterialsByQuarry: IArrayFromBackend[] = await fetchGet(`/admin/quarries/list/materials`, {
      quarryId: quarryId || Number(supplierParametersInfo[0].value),
    });
    changeFieldOfSelector({
      setState: setSupplierParametersInfo,
      nameObject: nameOfParameters.MATERIAL,
      fieldOfObject: 'options',
      valueField: convertForSelectOptions(responseListMaterialsByQuarry),
      clearValue: true,
    });
  } catch (e) {
    console.log(e);
  }
};
export const getMainValue = async (
  responseDetailsTariff: IResponseDetailsTariff,
  mainParametersInfo: IParametersOfAddTariffs[],
  setMainParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
  currentRole,
) => {
  await getListOrganization(setMainParametersInfo).then(() => {
    changeFieldOfSelector({
      setState: setMainParametersInfo,
      nameObject: nameOfParameters.ORGANIZER,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.organization?.id),
      clearValue: false,
    });
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.ROLE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.company?.type),
    clearValue: false,
  });
  await getListCompany(currentRole, setMainParametersInfo).then(() => {
    changeFieldOfSelector({
      setState: setMainParametersInfo,
      nameObject: nameOfParameters.COUNTERPARTY,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.company?.id),
      clearValue: false,
    });
  });
  await getListContracts(
    mainParametersInfo,
    setMainParametersInfo,
    responseDetailsTariff.company?.id,
    responseDetailsTariff.organization?.id,
  ).then(() => {
    changeFieldOfSelector({
      setState: setMainParametersInfo,
      nameObject: nameOfParameters.CONTRACT,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.contract?.id),
      clearValue: false,
    });
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.DATE_FROM,
    fieldOfObject: 'date',
    valueField: dayjs(responseDetailsTariff?.dateFrom),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.DATE_TO,
    fieldOfObject: 'date',
    valueField: responseDetailsTariff.dateTo ? dayjs(responseDetailsTariff.dateTo) : null,
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.UNIT,
    fieldOfObject: 'value',
    valueField: responseDetailsTariff?.unit,
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.EXTRA_CHARGE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff?.margin),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setMainParametersInfo,
    nameObject: nameOfParameters.PRICE_REQUEST,
    fieldOfObject: 'check',
    valueField: responseDetailsTariff?.priceOnRequest,
    clearValue: false,
  });
};
export const getCarrierValue = async (
  responseDetailsTariff: IResponseDetailsTariff,
  setCarrierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
  mainParametersInfo: IParametersOfAddTariffs[],
  carrierParametersInfo: IParametersOfAddTariffs[],
) => {
  await getListMaterialsCarrier(setCarrierParametersInfo).then(() => {
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.TYPE_MATERIAL,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.tariffDetails.materialType?.id),
      clearValue: false,
    });
  });
  await getListSemitrailers(setCarrierParametersInfo).then(() => {
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.SEMITRAILER,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.tariffDetails.semitrailer?.id),
      clearValue: false,
    });
  });
  await getListQuarryCarrier(
    carrierParametersInfo,
    setCarrierParametersInfo,
    responseDetailsTariff.tariffDetails.materialType?.id,
  ).then(() => {
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.QUARRY,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.tariffDetails.quarry?.id),
      clearValue: false,
    });
  });
  changeFieldOfSelector({
    setState: setCarrierParametersInfo,
    nameObject: nameOfParameters.RANGE_INPUT,
    fieldOfObject: 'valueFrom',
    valueField: String(responseDetailsTariff.tariffDetails?.distanceFrom),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setCarrierParametersInfo,
    nameObject: nameOfParameters.RANGE_INPUT,
    fieldOfObject: 'valueTo',
    valueField: String(responseDetailsTariff.tariffDetails?.distanceTo),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setCarrierParametersInfo,
    nameObject: nameOfParameters.COST_PRICE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.tariffDetails?.cost),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setCarrierParametersInfo,
    nameObject: nameOfParameters.EXTRA_CHARGE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.tariffDetails?.margin),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setCarrierParametersInfo,
    nameObject: nameOfParameters.MINIMAL_PRICE_CARRIER,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.tariffDetails?.minPrice),
    clearValue: false,
  });
  await getListObjects(setCarrierParametersInfo).then(() => {
    changeFieldOfSelector({
      setState: setCarrierParametersInfo,
      nameObject: nameOfParameters.OBJECT,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.tariffDetails.object?.id),
      clearValue: false,
    });
  });
  changeFieldOfSelector({
    setState: setCarrierParametersInfo,
    nameObject: nameOfParameters.PRICE_REQUEST_CARRIER,
    fieldOfObject: 'check',
    valueField: responseDetailsTariff.tariffDetails?.priceOnRequest,
    clearValue: false,
  });
};
export const getSupplierValue = async (
  responseDetailsTariff: IResponseDetailsTariff,
  mainParametersInfo: IParametersOfAddTariffs[],
  supplierParametersInfo: IParametersOfAddTariffs[],
  setSupplierParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
) => {
  await getListQuarrySupplier(mainParametersInfo, setSupplierParametersInfo, responseDetailsTariff.company.id).then(
    () => {
      changeFieldOfSelector({
        setState: setSupplierParametersInfo,
        nameObject: nameOfParameters.QUARRY,
        fieldOfObject: 'value',
        valueField: String(responseDetailsTariff.tariffDetails.quarry?.id),
        clearValue: false,
      });
    },
  );
  await getListMaterialsByQuarry(
    supplierParametersInfo,
    setSupplierParametersInfo,
    responseDetailsTariff.tariffDetails.quarry.id,
  ).then(() => {
    changeFieldOfSelector({
      setState: setSupplierParametersInfo,
      nameObject: nameOfParameters.MATERIAL,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.tariffDetails.material?.id),
      clearValue: false,
    });
  });
  changeFieldOfSelector({
    setState: setSupplierParametersInfo,
    nameObject: nameOfParameters.VOLUME,
    fieldOfObject: 'value',
    valueField: !!responseDetailsTariff.tariffDetails?.volume
      ? String(responseDetailsTariff.tariffDetails?.volume)
      : '',
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setSupplierParametersInfo,
    nameObject: nameOfParameters.COST_PRICE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.tariffDetails?.cost),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setSupplierParametersInfo,
    nameObject: nameOfParameters.EXTRA_CHARGE,
    fieldOfObject: 'value',
    valueField: String(responseDetailsTariff.tariffDetails?.margin),
    clearValue: false,
  });
  changeFieldOfSelector({
    setState: setSupplierParametersInfo,
    nameObject: nameOfParameters.PRICE_REQUEST_SUPPLIER,
    fieldOfObject: 'check',
    valueField: responseDetailsTariff.tariffDetails?.priceOnRequest,
    clearValue: false,
  });
};
export const getShipmentValue = async (
  responseDetailsTariff: IResponseDetailsTariff,
  mainParametersInfo: IParametersOfAddTariffs[],
  shipmentParametersInfo: IParametersOfAddTariffs[],
  setShipmentParametersInfo: React.Dispatch<IParametersOfAddTariffs[]>,
) => {
  await getListOrderShipment(mainParametersInfo, setShipmentParametersInfo).then(data => {
    const materialType: IOptions[] = data.find(
      item => item.value === String(responseDetailsTariff.tariffDetails.order.id),
    ).materials; //Ищем опции материала из выбранного заказа

    changeFieldOfSelector({
      setState: setShipmentParametersInfo,
      nameObject: nameOfParameters.ORDER,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.tariffDetails.order.id),
      clearValue: false,
    });
    changeFieldOfSelector({
      setState: setShipmentParametersInfo,
      nameObject: nameOfParameters.MATERIAL,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff?.tariffDetails?.material?.id),
      clearValue: false,
    });
    changeFieldOfSelector({
      setState: setShipmentParametersInfo,
      nameObject: nameOfParameters.MATERIAL,
      fieldOfObject: 'options',
      valueField: materialType,
      clearValue: false,
    });
    changeFieldOfSelector({
      setState: setShipmentParametersInfo,
      nameObject: nameOfParameters.VOLUME,
      fieldOfObject: 'value',
      valueField: !!responseDetailsTariff.tariffDetails?.volume
        ? String(responseDetailsTariff.tariffDetails?.volume)
        : '',
      clearValue: false,
    });
    changeFieldOfSelector({
      setState: setShipmentParametersInfo,
      nameObject: nameOfParameters.COST_PRICE,
      fieldOfObject: 'value',
      valueField: String(responseDetailsTariff.tariffDetails?.cost),
      clearValue: false,
    });
    changeFieldOfSelector({
      setState: setShipmentParametersInfo,
      nameObject: nameOfParameters.PRICE_REQUEST_SHIPMENT,
      fieldOfObject: 'check',
      valueField: responseDetailsTariff.tariffDetails?.priceOnRequest,
      clearValue: false,
    });
  });
};
