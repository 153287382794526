import React from 'react';

export const ActualPriceSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332ZM9.06283 7.39567C9.06283 6.8779 9.48256 6.45817 10.0003 6.45817C10.5181 6.45817 10.9378 6.8779 10.9378 7.39567C10.9378 7.64987 10.8375 7.87937 10.6729 8.04881C10.6251 8.09801 10.5706 8.15237 10.512 8.21083L10.5119 8.21098L10.5118 8.21103L10.5118 8.21106L10.5118 8.21106L10.5118 8.21108C10.3173 8.40514 10.0778 8.64426 9.88713 8.88918C9.6295 9.2202 9.37533 9.66218 9.37533 10.2082V10.8332C9.37533 11.1783 9.65515 11.4582 10.0003 11.4582C10.3455 11.4582 10.6253 11.1783 10.6253 10.8332V10.2082C10.6253 10.0638 10.6911 9.89144 10.8736 9.65692C11.0118 9.47928 11.1694 9.32185 11.3493 9.14214L11.3493 9.14212L11.3494 9.14203C11.4193 9.07216 11.4926 8.99891 11.5695 8.91981C11.9515 8.52649 12.1878 7.98804 12.1878 7.39567C12.1878 6.18755 11.2084 5.20817 10.0003 5.20817C8.7922 5.20817 7.81283 6.18755 7.81283 7.39567C7.81283 7.74085 8.09265 8.02067 8.43783 8.02067C8.783 8.02067 9.06283 7.74085 9.06283 7.39567ZM10.8337 13.3332C10.8337 13.7934 10.4606 14.1665 10.0003 14.1665C9.54009 14.1665 9.16699 13.7934 9.16699 13.3332C9.16699 12.8729 9.54009 12.4998 10.0003 12.4998C10.4606 12.4998 10.8337 12.8729 10.8337 13.3332Z"
        fill="var(--color-orange400)"
      />
    </svg>
  );
};
