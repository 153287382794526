import React from 'react';

export const GSMLineSvg = ({ width = '160px' }: { width?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 161 160" fill="none">
      <g clipPath="url(#clip0_27891_86225)">
        <path
          d="M97.3054 6.27587V33.7624H96.3175V6.27587C96.3175 -2.17648 89.4349 -9.05911 80.9825 -9.05911H60.1151V-10.047H80.9935C89.9837 -10.047 97.3054 -2.72533 97.3054 6.27587Z"
          fill="url(#paint0_linear_27891_86225)"
        />
        <path
          d="M94.9997 6.27587V33.7624H94.0118V6.27587C94.0118 -0.903133 88.172 -6.74295 80.9929 -6.74295H60.1255V-7.73088H80.9929C88.7098 -7.74186 94.9997 -1.45198 94.9997 6.27587Z"
          fill="url(#paint1_linear_27891_86225)"
        />
        <path
          d="M92.6944 6.27587V33.7624H91.7064V6.27587C91.7064 0.370209 86.8985 -4.43776 80.9928 -4.43776H60.1254V-5.4257H80.9928C87.4363 -5.4257 92.6944 -0.178646 92.6944 6.27587Z"
          fill="url(#paint2_linear_27891_86225)"
        />
        <path
          d="M90.3898 6.27586V33.7624H89.4018V6.27586C89.4018 1.64354 85.6257 -2.13258 80.9934 -2.13258H60.126V-3.12052H80.9934C86.1746 -3.12052 90.3898 1.09468 90.3898 6.27586Z"
          fill="url(#paint3_linear_27891_86225)"
        />
        <path
          d="M88.0733 6.27586V33.7624H87.0854V6.27586C87.0854 2.91688 84.3521 0.172604 80.9822 0.172604H60.1147V-0.815334H80.9931C84.901 -0.815334 88.0733 2.36803 88.0733 6.27586Z"
          fill="url(#paint4_linear_27891_86225)"
        />
        <path
          d="M85.768 6.27586V33.7624H84.7801V6.27586C84.7801 4.19022 83.0786 2.48877 80.993 2.48877H60.1256V1.50084H80.993C83.6275 1.48986 85.768 3.64137 85.768 6.27586Z"
          fill="url(#paint5_linear_27891_86225)"
        />
        <path
          d="M64.8019 34.7512C56.3496 34.7512 49.4669 41.6339 49.4669 50.0862C49.4669 58.5386 56.3386 65.4212 64.8019 65.4212H105.088C107.722 65.4212 109.863 67.5617 109.863 70.1962C109.863 72.8307 107.722 74.9822 105.088 74.9822V73.9943C107.173 73.9943 108.875 72.2928 108.875 70.2072C108.875 68.1215 107.173 66.4201 105.088 66.4201H64.8019C55.8007 66.4201 48.479 59.0984 48.479 50.0972C48.479 41.096 55.8007 33.7743 64.8019 33.7743H102.113V34.7622H64.8019V34.7512Z"
          fill="url(#paint6_linear_27891_86225)"
        />
        <path
          d="M64.8025 37.0679C57.6235 37.0679 51.7838 42.9077 51.7838 50.0867C51.7838 57.2657 57.6235 63.1055 64.8025 63.1055H105.088C108.996 63.1055 112.18 66.2889 112.18 70.1967C112.18 74.1046 108.996 77.2879 105.088 77.2879L105.088 76.3C108.447 76.3 111.192 73.5667 111.192 70.1967C111.192 66.8377 108.458 64.0935 105.088 64.0935H64.8025C57.0747 64.0935 50.7958 57.8036 50.7958 50.0867C50.7958 42.3589 57.0857 36.08 64.8025 36.08H102.114V37.0679H64.8025Z"
          fill="url(#paint7_linear_27891_86225)"
        />
        <path
          d="M64.8027 39.3733C58.897 39.3733 54.0891 44.1812 54.0891 50.0869C54.0891 55.9926 58.897 60.8005 64.8027 60.8005H105.089C110.27 60.8005 114.485 65.0157 114.485 70.1969C114.485 75.3781 110.27 79.5933 105.089 79.5933L105.089 78.6053C109.721 78.6053 113.497 74.8292 113.497 70.1969C113.497 65.5646 109.721 61.7885 105.089 61.7885H64.8027C58.3482 61.7885 53.1012 56.5414 53.1012 50.0869C53.1012 43.6324 58.3482 38.3853 64.8027 38.3853H102.114V39.3733H64.8027Z"
          fill="url(#paint8_linear_27891_86225)"
        />
        <path
          d="M64.8021 41.6782C60.1698 41.6782 56.3937 45.4544 56.3937 50.0867C56.3937 54.719 60.1698 58.4951 64.8021 58.4951H105.088C111.542 58.4951 116.79 63.7422 116.79 70.1967C116.79 76.6512 111.542 81.8982 105.088 81.8982V80.9103C110.994 80.9103 115.802 76.1023 115.802 70.1967C115.802 64.291 110.994 59.4831 105.088 59.4831H64.8021C59.621 59.4831 55.4058 55.2679 55.4058 50.0867C55.4058 44.9055 59.621 40.6903 64.8021 40.6903H102.113V41.6782H64.8021Z"
          fill="url(#paint9_linear_27891_86225)"
        />
        <path
          d="M64.8034 43.984C61.4444 43.984 58.7002 46.7173 58.7002 50.0872C58.7002 53.4462 61.4334 56.1905 64.8034 56.1905H105.089C112.817 56.1905 119.096 62.4803 119.096 70.1972C119.096 77.9251 112.806 84.2039 105.089 84.2039V83.216C112.268 83.216 118.108 77.3762 118.108 70.1972C118.108 63.0182 112.268 57.1784 105.089 57.1784H64.8034C60.8956 57.1784 57.7122 53.995 57.7122 50.0872C57.7122 46.1794 60.8956 42.996 64.8034 42.996H102.114V43.984H64.8034Z"
          fill="url(#paint10_linear_27891_86225)"
        />
        <path
          d="M64.8026 46.2993C62.7169 46.2993 61.0155 48.0007 61.0155 50.0864C61.0155 52.172 62.7169 53.8735 64.8026 53.8735H105.088C114.09 53.8735 121.411 61.1952 121.411 70.1964C121.411 79.1976 114.09 86.5193 105.088 86.5193V85.5313C113.541 85.5313 120.423 78.6487 120.423 70.1964C120.423 61.744 113.541 54.8614 105.088 54.8614H64.8026C62.1681 54.8614 60.0275 52.7209 60.0275 50.0864C60.0275 47.4519 62.1681 45.3004 64.8026 45.3004H102.114V46.2883H64.8026V46.2993Z"
          fill="url(#paint11_linear_27891_86225)"
        />
        <path
          d="M92.5299 -21.318C92.5299 -12.8657 99.4126 -5.98307 107.865 -5.98307H142.511V-4.99513H107.865C98.8637 -4.99513 91.542 -12.3168 91.542 -21.318C91.542 -21.812 91.5639 -30.2021 91.6078 -30.6851H92.5957C92.5518 -30.2021 92.5299 -21.812 92.5299 -21.318Z"
          fill="url(#paint12_linear_27891_86225)"
        />
        <path
          d="M94.8351 -21.3187C94.8351 -14.1397 100.675 -8.29986 107.854 -8.29986H142.511V-7.31193H107.865C100.137 -7.31193 93.8582 -13.6018 93.8582 -21.3187C93.8582 -21.8126 93.88 -30.2027 93.9349 -30.6857H94.9338C94.868 -30.2027 94.8351 -21.8126 94.8351 -21.3187Z"
          fill="url(#paint13_linear_27891_86225)"
        />
        <path
          d="M97.1403 -21.3192C97.1403 -15.4135 101.948 -10.6056 107.854 -10.6056H142.511V-9.61764H107.865C101.41 -9.61764 96.1633 -14.8647 96.1633 -21.3192C96.1633 -21.8132 96.1962 -30.2033 96.262 -30.6863H97.261C97.1841 -30.2033 97.1403 -21.8241 97.1403 -21.3192Z"
          fill="url(#paint14_linear_27891_86225)"
        />
        <path
          d="M99.4455 -21.3194C99.4455 -16.687 103.222 -12.9109 107.854 -12.9109H142.511V-11.923H107.865C102.684 -11.923 98.4685 -16.1382 98.4685 -21.3194C98.4685 -21.8133 98.5123 -30.2034 98.5892 -30.6864H99.5991C99.5003 -30.2144 99.4455 -21.8243 99.4455 -21.3194Z"
          fill="url(#paint15_linear_27891_86225)"
        />
        <path
          d="M101.762 -21.318C101.762 -17.9591 104.495 -15.2148 107.865 -15.2148H142.511V-14.2269H107.865C103.957 -14.2269 100.774 -17.4102 100.774 -21.318C100.774 -21.823 100.829 -30.2131 100.927 -30.6851H101.948C101.827 -30.2131 101.762 -21.823 101.762 -21.318Z"
          fill="url(#paint16_linear_27891_86225)"
        />
        <path
          d="M104.067 -21.319C104.067 -19.2334 105.768 -17.532 107.854 -17.532H142.511V-16.544H107.865C105.23 -16.544 103.09 -18.6845 103.09 -21.319C103.09 -21.835 103.178 -30.2251 103.32 -30.6861H104.374C104.177 -30.2361 104.067 -21.8459 104.067 -21.319Z"
          fill="url(#paint17_linear_27891_86225)"
        />
        <path
          d="M60.1261 -33.7906V11.6756C60.1261 13.7612 61.8276 15.4627 63.9132 15.4627C65.9989 15.4627 67.7003 13.7612 67.7003 11.6756V2.4878H68.6882V11.6756C68.6882 14.3101 66.5477 16.4506 63.9022 16.4506C61.2677 16.4506 59.1272 14.3101 59.1272 11.6756V-33.7906H60.1261Z"
          fill="url(#paint18_linear_27891_86225)"
        />
        <path
          d="M50.8944 -31.8045V11.6756C50.8944 18.8546 56.7342 24.6944 63.9132 24.6944C71.0922 24.6944 76.932 18.8546 76.932 11.6756V2.45487H77.92V11.6756C77.92 19.4035 71.6301 25.6823 63.9132 25.6823C56.1854 25.6823 49.9065 19.3925 49.9065 11.6756V-31.8045H50.8944Z"
          fill="url(#paint19_linear_27891_86225)"
        />
        <path
          d="M48.5778 -31.8991V11.6751C48.5778 20.1275 55.4604 27.0101 63.9127 27.0101C72.3651 27.0101 79.2477 20.1275 79.2477 11.6751V2.48734H80.2357V11.6751C80.2357 20.6763 72.9139 27.998 63.9127 27.998C54.9115 27.998 47.5898 20.6763 47.5898 11.6751V-31.8991H48.5778Z"
          fill="url(#paint20_linear_27891_86225)"
        />
        <path
          d="M57.8099 11.6755C57.8099 15.0345 60.5432 17.7787 63.9131 17.7787C67.2721 17.7787 70.0164 15.0455 70.0164 11.6755V2.48769H71.0043V11.6755C71.0043 15.5833 67.8209 18.7667 63.9131 18.7667C60.0053 18.7667 56.8219 15.5833 56.8219 11.6755V-33.8852H57.7989V11.6755H57.8099Z"
          fill="url(#paint21_linear_27891_86225)"
        />
        <path
          d="M55.5045 11.6753C55.5045 16.3076 59.2806 20.0837 63.9129 20.0837C68.5452 20.0837 72.3213 16.3076 72.3213 11.6753V2.48749H73.3093V11.6753C73.3093 16.8565 69.0941 21.0717 63.9129 21.0717C58.7317 21.0717 54.5165 16.8565 54.5165 11.6753V-31.7099H55.5045V11.6753Z"
          fill="url(#paint22_linear_27891_86225)"
        />
        <path
          d="M53.1998 11.6758C53.1998 17.5815 58.0078 22.3894 63.9134 22.3894C69.8191 22.3894 74.627 17.5815 74.627 11.6758V2.48802H75.615V11.6758C75.615 18.1303 70.368 23.3774 63.9134 23.3774C57.4589 23.3774 52.2119 18.1303 52.2119 11.6758V-32.1828H53.1998V11.6758Z"
          fill="url(#paint23_linear_27891_86225)"
        />
        <path
          d="M42.1839 -43.5391V-13.8022C42.1839 -11.1677 44.3244 -9.02718 46.9589 -9.02718H47.7273V-10.0151H46.9589C44.8733 -10.0151 43.1718 -11.7166 43.1718 -13.8022V-43.5391C43.1718 -45.3723 42.8645 -47.1286 42.3046 -48.7642H41.2618C41.8546 -47.1286 42.1839 -45.3723 42.1839 -43.5391Z"
          fill="url(#paint24_linear_27891_86225)"
        />
        <path
          d="M39.8668 -43.5388V-13.8019C39.8668 -9.89409 43.0501 -6.71075 46.9579 -6.71075H47.7263V-7.69868H46.9579C43.599 -7.69868 40.8547 -10.432 40.8547 -13.8019V-43.5388C40.8547 -45.383 40.4924 -47.1503 39.8338 -48.7639H38.7581C39.4716 -47.1612 39.8668 -45.3939 39.8668 -43.5388Z"
          fill="url(#paint25_linear_27891_86225)"
        />
        <path
          d="M37.5633 -43.5394V-13.8025C37.5633 -8.62133 41.7785 -4.40614 46.9597 -4.40614H47.7281V-5.39408H46.9597C42.3273 -5.39408 38.5512 -9.17018 38.5512 -13.8025V-43.5394C38.5512 -45.4165 38.1012 -47.1948 37.3108 -48.7645H36.1912C37.0693 -47.2167 37.5633 -45.4274 37.5633 -43.5394Z"
          fill="url(#paint26_linear_27891_86225)"
        />
        <path
          d="M35.2572 -43.5389V-13.802C35.2572 -7.34746 40.5042 -2.10043 46.9587 -2.10043H47.7271V-3.08836H46.9587C41.0531 -3.08836 36.2451 -7.89632 36.2451 -13.802V-43.5389C36.2451 -45.4708 35.6523 -47.2711 34.6534 -48.7639H33.424C34.5656 -47.3259 35.2572 -45.5147 35.2572 -43.5389Z"
          fill="url(#paint27_linear_27891_86225)"
        />
        <path
          d="M32.9519 -43.5398V-13.8029C32.9519 -6.07507 39.2417 0.203812 46.9586 0.203812H47.727V-0.784126H46.9586C39.7796 -0.784126 33.9398 -6.62392 33.9398 -13.8029V-43.5398C33.9398 -45.6145 33.0397 -47.4696 31.6236 -48.7649H29.9771C31.7444 -47.7001 32.9519 -45.7572 32.9519 -43.5398Z"
          fill="url(#paint28_linear_27891_86225)"
        />
        <path
          d="M30.6358 -43.5381V-13.8012C30.6358 -4.79998 37.9575 2.52172 46.9587 2.52172H47.7271V1.53378H46.9587C38.5064 1.53378 31.6237 -5.34883 31.6237 -13.8012V-43.5381C31.6237 -46.1396 29.5271 -48.2692 26.9365 -48.3131V-47.3251C28.9892 -47.2703 30.6358 -45.5908 30.6358 -43.5381Z"
          fill="url(#paint29_linear_27891_86225)"
        />
        <path
          d="M133.437 106.313H135.402C136.39 106.313 136.927 106.313 137.235 106.346V106.313C139.869 106.313 142.021 108.454 142.021 111.088V124.678C142.021 127.313 139.88 129.464 137.235 129.464C134.6 129.464 132.46 127.324 132.46 124.678V94.6118C132.46 91.9773 134.6 89.8258 137.235 89.8258H174.621V90.8138H137.235C135.149 90.8138 133.448 92.5152 133.448 94.6008V124.678C133.448 126.764 135.149 128.465 137.235 128.465C139.32 128.465 141.022 126.764 141.022 124.678V111.099C141.022 109.014 139.32 107.312 137.235 107.312H136.905C136.005 107.312 133.448 107.312 133.448 107.312"
          fill="url(#paint30_linear_27891_86225)"
        />
        <path
          d="M131.132 94.612V124.678C131.132 128.037 133.865 130.781 137.235 130.781C140.605 130.781 143.338 128.048 143.338 124.678V111.1C143.338 107.741 140.605 104.996 137.235 104.996H136.905C136.005 104.996 133.579 104.996 133.579 104.996H133.448V104.008H135.413C136.401 104.008 136.938 104.008 137.246 104.041V104.008C141.154 104.008 144.337 107.192 144.337 111.1V124.678C144.337 128.586 141.154 131.769 137.246 131.769C133.338 131.769 130.155 128.586 130.155 124.678V94.612C130.155 90.7041 133.338 87.5208 137.246 87.5208H174.621V88.5087H137.235C133.865 88.5197 131.132 91.253 131.132 94.612Z"
          fill="url(#paint31_linear_27891_86225)"
        />
        <path
          d="M128.826 94.6107V124.677C128.826 129.309 132.602 133.085 137.235 133.085C141.867 133.085 145.643 129.309 145.643 124.677V111.098C145.643 106.466 141.867 102.69 137.235 102.69H136.905C135.994 102.69 133.514 102.69 133.514 102.69H133.448V101.702H135.38C136.39 101.702 136.938 101.702 137.246 101.735V101.702C142.427 101.702 146.642 105.917 146.642 111.098V124.677C146.642 129.858 142.427 134.073 137.246 134.073C132.065 134.073 127.849 129.858 127.849 124.677V94.6107C127.849 89.4295 132.065 85.2143 137.246 85.2143H174.621V86.2022H137.235C132.591 86.2022 128.826 89.9783 128.826 94.6107Z"
          fill="url(#paint32_linear_27891_86225)"
        />
        <path
          d="M126.522 94.6116V124.678C126.522 130.583 131.329 135.391 137.235 135.391C143.141 135.391 147.949 130.583 147.949 124.678V111.099C147.949 105.193 143.141 100.385 137.235 100.385H136.906C135.995 100.385 133.525 100.385 133.525 100.385H133.448V99.3976L135.38 99.3866C136.39 99.3866 136.939 99.3866 137.246 99.4195V99.3866C143.701 99.3866 148.948 104.634 148.948 111.088V124.678C148.948 131.132 143.701 136.379 137.246 136.379C130.792 136.379 125.545 131.132 125.545 124.678V94.6116C125.545 88.1571 130.792 82.91 137.246 82.91H174.622V83.8979H137.235C131.318 83.8979 126.522 88.7059 126.522 94.6116Z"
          fill="url(#paint33_linear_27891_86225)"
        />
        <path
          d="M124.205 94.611V124.677C124.205 131.856 130.045 137.696 137.224 137.696C144.403 137.696 150.254 131.856 150.254 124.677V111.099C150.254 103.92 144.414 98.0797 137.235 98.0797H136.905C135.994 98.0797 133.514 98.0797 133.514 98.0797H133.448V97.0918H135.38C136.39 97.0918 136.938 97.0918 137.246 97.1247V97.0918C144.974 97.0918 151.252 103.382 151.252 111.099V124.677C151.252 132.405 144.963 138.684 137.246 138.684C129.529 138.684 123.239 132.394 123.239 124.677V94.611C123.239 86.8831 129.529 80.6042 137.246 80.6042H174.621V81.5922H137.235C130.056 81.5922 124.205 87.432 124.205 94.611Z"
          fill="url(#paint34_linear_27891_86225)"
        />
        <path
          d="M121.9 94.6109V124.677C121.9 133.129 128.782 140.012 137.235 140.012C145.687 140.012 152.559 133.129 152.559 124.677V111.098C152.559 102.646 145.676 95.7635 137.224 95.7635H136.894C135.961 95.7635 133.437 95.7745 133.437 95.7745V94.7865L136.62 94.7755C136.894 94.7755 137.081 94.7755 137.224 94.7865V94.7755C146.225 94.7755 153.547 102.097 153.547 111.098V124.677C153.547 133.678 146.225 141 137.224 141C128.223 141 120.901 133.678 120.901 124.677V94.6109C120.901 85.6097 128.223 78.288 137.224 78.288H174.621V79.2759H137.235C128.782 79.2869 121.9 86.1585 121.9 94.6109Z"
          fill="url(#paint35_linear_27891_86225)"
        />
        <path
          d="M134.759 15.9501V56.5652C134.759 58.6509 136.46 60.3523 138.546 60.3523C140.632 60.3523 142.333 58.6509 142.333 56.5652V27.5842H143.321V56.5762C143.321 59.2107 141.18 61.3512 138.535 61.3512C135.9 61.3512 133.76 59.2107 133.76 56.5762V15.9611C133.76 7.50872 126.877 0.626087 118.425 0.626087C109.973 0.626087 103.09 7.50872 103.09 15.9611V54.0734H102.102V15.9611C102.102 6.95987 109.424 -0.361855 118.425 -0.361855C127.437 -0.372833 134.759 6.94889 134.759 15.9501Z"
          fill="url(#paint36_linear_27891_86225)"
        />
        <path
          d="M144.649 26.733V56.555C144.649 59.925 141.905 62.6583 138.546 62.6583C135.176 62.6583 132.443 59.914 132.443 56.555V15.9399C132.443 8.22302 126.164 1.93316 118.436 1.93316C110.719 1.93316 104.429 8.21204 104.429 15.9399V54.0523H105.088C105.143 54.0523 105.198 54.055 105.253 54.0578C105.307 54.0605 105.362 54.0633 105.417 54.0633V15.9399C105.417 8.76089 111.257 2.92109 118.436 2.92109C125.615 2.92109 131.455 8.76089 131.455 15.9399V56.555C131.455 60.4629 134.638 63.6462 138.546 63.6462C142.454 63.6462 145.637 60.4629 145.637 56.555V26.4493L144.649 26.733Z"
          fill="url(#paint37_linear_27891_86225)"
        />
        <path
          d="M146.954 25.5035V56.5652C146.954 61.1975 143.178 64.9736 138.546 64.9736C133.914 64.9736 130.138 61.1975 130.138 56.5652V15.95C130.138 9.49552 124.891 4.24847 118.436 4.24847C111.982 4.24847 106.734 9.49552 106.734 15.95V54.1392C107.064 54.1722 107.393 54.2161 107.722 54.271V15.95C107.722 10.0444 112.53 5.23641 118.436 5.23641C124.342 5.23641 129.15 10.0444 129.15 15.95V56.5652C129.15 61.7464 133.365 65.9616 138.546 65.9616C143.727 65.9616 147.942 61.7464 147.942 56.5652V24.936L146.954 25.5035Z"
          fill="url(#paint38_linear_27891_86225)"
        />
        <path
          d="M149.26 23.8957V56.5649C149.26 62.4705 144.452 67.2785 138.546 67.2785C132.64 67.2785 127.832 62.4705 127.832 56.5649V15.9497C127.832 10.7685 123.617 6.55334 118.436 6.55334C113.255 6.55334 109.04 10.7685 109.04 15.9497H109.051V54.5341C109.38 54.6219 109.709 54.7207 110.039 54.8195V15.9387C110.039 11.3064 113.815 7.5303 118.447 7.5303C123.079 7.5303 126.855 11.3064 126.855 15.9387V56.5539C126.855 63.0084 132.102 68.2554 138.557 68.2554C145.012 68.2554 150.259 63.0084 150.259 56.5539V23.139L149.26 23.8957Z"
          fill="url(#paint39_linear_27891_86225)"
        />
        <path
          d="M151.565 21.2475V56.5659C151.565 63.7449 145.725 69.5847 138.546 69.5847C131.367 69.5847 125.527 63.7449 125.527 56.5659V15.9507C125.527 12.0429 122.344 8.85952 118.436 8.85952C114.528 8.85952 111.345 12.0429 111.345 15.9507V55.3145C111.674 55.4462 112.003 55.5999 112.333 55.7645V15.9507C112.333 12.5808 115.077 9.84747 118.436 9.84747C121.806 9.84747 124.539 12.5917 124.539 15.9507V56.5659C124.539 64.2828 130.818 70.5726 138.546 70.5726C146.263 70.5726 152.553 64.2937 152.553 56.5659V19.9234L151.565 21.2475Z"
          fill="url(#paint40_linear_27891_86225)"
        />
        <path
          d="M153.892 16.5186V56.5643C153.892 65.0166 147.009 71.8993 138.557 71.8993C130.105 71.8993 123.222 65.0166 123.222 56.5643V15.9492C123.222 13.3037 121.071 11.1632 118.436 11.1632C115.802 11.1632 113.661 13.3147 113.661 15.9492H113.672V56.4984C114.012 56.707 114.342 56.9375 114.66 57.168V15.9492C114.66 13.8635 116.361 12.1621 118.447 12.1621C120.533 12.1621 122.234 13.8635 122.234 15.9492V56.5643C122.234 65.5655 129.556 72.8872 138.557 72.8872C147.558 72.8872 154.88 65.5655 154.88 56.5643V12.0734L153.892 16.5186Z"
          fill="url(#paint41_linear_27891_86225)"
        />
        <path
          d="M147.947 -27.3666V12.1709C147.947 17.3521 143.732 21.5673 138.551 21.5673H134.471V20.5793H138.551C143.183 20.5793 146.959 16.8032 146.959 12.1709V-27.3666H147.947Z"
          fill="url(#paint42_linear_27891_86225)"
        />
        <path
          d="M149.265 -27.3666V12.1714C149.265 18.0771 144.457 22.885 138.552 22.885H134.472V23.873H138.552C145.006 23.873 150.253 18.6259 150.253 12.1714V-27.3666H149.265Z"
          fill="url(#paint43_linear_27891_86225)"
        />
        <path
          d="M151.571 -27.3666V12.1705C151.571 19.3495 145.731 25.1893 138.552 25.1893H134.472V26.1772H138.552C146.269 26.1772 152.559 19.8983 152.559 12.1705V-27.3666H151.571Z"
          fill="url(#paint44_linear_27891_86225)"
        />
        <path
          d="M153.887 -27.3666V12.1722C153.887 20.6245 147.004 27.5072 138.552 27.5072H134.472V28.4951H138.552C147.553 28.4951 154.875 21.1734 154.875 12.1722V-27.3666H153.887Z"
          fill="url(#paint45_linear_27891_86225)"
        />
        <path
          d="M142.339 -27.3666V12.1712C142.339 14.2568 140.637 15.9583 138.552 15.9583H134.472V16.9462H138.552C141.186 16.9462 143.327 14.8057 143.327 12.1712V-27.3666H142.339Z"
          fill="url(#paint46_linear_27891_86225)"
        />
        <path
          d="M144.656 -27.3666V12.1715C144.656 15.5414 141.911 18.2747 138.552 18.2747H134.473V19.2626H138.552C142.46 19.2626 145.644 16.0793 145.644 12.1715V-27.3666H144.656Z"
          fill="url(#paint47_linear_27891_86225)"
        />
        <path
          d="M100.246 74.9845C91.7828 74.9845 84.8911 81.8762 84.8911 90.3396C84.8911 98.8031 91.7718 105.695 100.246 105.695H121.018V106.695H100.246C91.2332 106.695 83.9019 99.3637 83.9019 90.3506C83.9019 81.3376 91.2332 74.0062 100.246 74.0062H105.095V74.9955H100.246V74.9845Z"
          fill="url(#paint48_linear_27891_86225)"
        />
        <path
          d="M100.247 77.3042C93.0584 77.3042 87.211 83.1517 87.211 90.3402C87.211 97.5286 93.0584 103.376 100.247 103.376H121.019C121.019 103.376 121.019 100.452 121.019 104.365V103.376C121.019 100.013 121.019 104.365 121.019 104.365H100.247C92.5089 104.365 86.2217 98.0672 86.2217 90.3402C86.2217 82.6021 92.5199 76.315 100.247 76.315H105.095V77.3042H100.247Z"
          fill="url(#paint49_linear_27891_86225)"
        />
        <path
          d="M100.247 79.6126C94.3336 79.6126 89.5193 84.4269 89.5193 90.3403C89.5193 96.2538 94.3336 101.068 100.247 101.068H121.019V102.057H100.247C93.784 102.057 88.5301 96.8034 88.5301 90.3403C88.5301 83.8773 93.784 78.6234 100.247 78.6234H105.096V79.6126H100.247Z"
          fill="url(#paint50_linear_27891_86225)"
        />
        <path
          d="M100.246 81.9206C95.6081 81.9206 91.827 85.7017 91.827 90.3401C91.827 94.9786 95.6081 98.7596 100.246 98.7596H121.018V99.7489H100.246C95.0585 99.7489 90.8377 95.5281 90.8377 90.3401C90.8377 85.1521 95.0585 80.9314 100.246 80.9314H105.095V81.9206H100.246Z"
          fill="url(#paint51_linear_27891_86225)"
        />
        <path
          d="M100.248 84.2294C96.8844 84.2294 94.1365 86.9662 94.1365 90.3407C94.1365 93.7041 96.8734 96.452 100.248 96.452H121.019C121.019 104.19 121.019 97.4412 121.019 97.4412H100.248C96.3348 97.4412 93.1472 94.2536 93.1472 90.3407C93.1472 86.4277 96.3348 83.2401 100.248 83.2401H105.096V84.2294H100.248Z"
          fill="url(#paint52_linear_27891_86225)"
        />
        <path
          d="M100.247 86.5477C98.1585 86.5477 96.4548 88.2514 96.4548 90.3398C96.4548 92.4282 98.1585 94.1319 100.247 94.1319H121.019V95.1212C121.019 103.585 121.019 94.9763 121.019 95.1212L100.247 95.1211C97.609 95.1211 95.4656 92.9778 95.4656 90.3398C95.4656 87.7018 97.609 85.5475 100.247 85.5475H105.095V86.5367H100.247V86.5477Z"
          fill="url(#paint53_linear_27891_86225)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_27891_86225"
          x1="90.5325"
          y1="106.695"
          x2="122"
          y2="82.8725"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBF1FF" />
          <stop offset="1" stopColor="#CDD3E6" />
        </linearGradient>
        <clipPath id="clip0_27891_86225">
          <path d="M0 0H149.219C155.682 0 160.922 5.23981 160.922 11.7034V183.354H0V0Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
