import { createdPropertyName, ICalculationAddProperties } from '../AdminCalculationSupplies/type';
import { Dayjs } from 'dayjs';
export interface ICreateOrderProperties extends Omit<ICalculationAddProperties, 'name'> {
  name?: orderPropertyName;
}

export enum orderPropertyName {
  anyQuarryCheckbox = 'anyQuarryCheckbox',
  clientName = 'clientName',
  clientPhone = 'clientPhone',
  semitrailerTitle = 'semitrailerTitle',
  workScheduleTimeLeft = 'workScheduleTimeLeft',
  workScheduleTimeRight = 'workScheduleTimeRight',
  materialTitle = 'materialTitle',
  workSchedule = 'workSchedule',
  typeSchedule = 'typeSchedule',
  clientPricePerUnit = 'clientPricePerUnit',
  volume = 'volume',
  margin = 'margin',
  clientTitle = 'clientTitle',
  clientContract = 'clientContract',
  addToOrder = 'addToOrder',
  paymentProcess = 'paymentProcess',
  object = 'object',
  address = 'address',
  coordinates = 'coordinates',
  comment = 'comment',
  volumeCheckbox = 'volumeCheckbox',
  nomenclatureCheckbox = 'nomenclatureCheckbox',
  specificationCheckbox = 'specificationCheckbox',
}

export type TCreatedCalculationObject = {
  [key in createdPropertyName]: ICalculationAddProperties;
};
export interface IMainFilters {
  searchString: string;
  sort: IOrderSortBox;
}
export interface ISubFilters {
  dateFrom: string | Dayjs;
  dateTo: string | Dayjs;
  managerId: string;
  materialTypeId: string;
  volumeFrom: number;
  volumeTo: number;
  status: string;
}
export interface IOrderSortBox {
  value: string;
  label: string;
}
export interface IGetListOrdersRequestData {
  sort: string;
  limit: number;
  offset: number;
  dateFrom?: string;
  dateTo?: string;
  managerId?: number;
  materialTypeId?: number;
  volumeFrom?: number;
  volumeTo?: number;
  status?: string;
  searchString?: string;
}
export interface IGetListTableOrders {
  meta: {
    arg: IGetListOrdersRequestData;
    requestId: string;
    requestStatus: string;
  };
  payload: {
    count: number;
    rows: IRowsGetListTableOrders[];
    type: string;
  };
}
export interface IRowsGetListTableOrders {
  id: number;
  createdAt: string;
  client: {
    id: number;
    title: string;
    inn: string;
  };
  materials: {
    id: number;
    title: string;
  }[];
  totalPrice: number;
  manager: {
    id: number;
    title: string;
  };
  status: {
    id: number;
    title: string;
  };
  volume: {
    volume: number;
    unit: string;
  }[];
}
export interface IConvertGetListTableOrders {
  id: number;
  createdAt: string;
  client: {
    company: string;
    inn: string;
  };
  materialAndVolume: {
    material: string;
    volume: string;
  };
  totalPrice: string;
  manager: string;
  status: string;
}

export interface IMaterial {
  id: number;
  material: string;
  deliveryCalculationId: number;
  unit: string;
  plannedMargin: number;
  pricePerUnit: number;
  volume: {
    fact: number;
    plan: number;
  };
  plannedMarginWithId: {
    margin: number;
    id: number;
  };
  dataForShowMore: {
    id: number;
    deliveryCalculationId: number;
    unit: string;
  };
}

export interface IDataOrderId {
  id: number;
  client: {
    id: number;
    title: string;
    inn: string;
    hasIdentificatorEdo: boolean;
  };
  contract: {
    id: number;
    title: string;
  };
  manager: {
    id: number;
    title: string;
  };
  status: {
    id: number;
    title: string;
  };
  workSchedulesGraph: {
    from: string;
    to: string;
    aroundTheClock: boolean;
  };
  semitrailer: {
    id: number;
    title: string;
  };
  contactPerson: {
    id: number;
    person: string;
    phone: string;
  };
  address: {
    id: number;
    address: string;
    latitude: number;
    longitude: number;
  };
  objectTitle: string;
  acceptance: string;
  paymentMethod: string;
  comment: string | null;
  specificationClientForm: boolean;
  requestSum: number;
  orderSum: number;
  materials: IMaterial[];
  specificationPublishDate: string;
  specificationReceived: boolean;
  specificationSignedDate: string;
  files: any;
}

interface ContactPerson {
  person: string;
  phone: string;
}

interface Time {
  workingHoursType: string;
  from: string;
  to: string;
}

export interface IOrderCreateRequestDTO {
  clientId: number;
  acceptance: string;
  contractId: number;
  deliveryCalculationId: number;
  title: string;
  comment: string;
  orderId: number;
  paymentMethod: string;
  pricePerUnit: number;
  volume: number;
  plannedMargin: number;
  nomenclatureCanImproved: boolean;
  volumeMustNotExceeded: boolean;
  specificationClientForm: boolean;
  anyQuarry: boolean;
  contactPerson: ContactPerson[];
  time: Time;
  objectTitle: string;
}
export interface IDataOrderIdInnConvertForDetail extends IDataOrderId {
  inn: string;
}
export interface IDataInfoTable {
  name: string;
  label: string;
  value: string;
}
export interface IChangeableData {
  manager: string;
  status: string;
  comment: string;
  specificationReceived: boolean;
}

export interface IRequestDataPatchOrderId {
  status: string;
  comment: string;
  managerId: number;
  specificationReceived: boolean;
}

export interface ISpecificationRequest {
  provider: string;
  identificator: string;
}

export interface IInformationEDO {
  identificator: string[];
  provider: string;
}
