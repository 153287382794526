import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import styles from './OptionField.module.scss';
import SelectLk from '../../../../../components/newUI/SelectLk/SelectLk';
import { InputText } from '../../../../../components/UI/inputs/InputText';
import TextArea from 'antd/es/input/TextArea';
import {
  addPropertyName,
  createdPropertyName,
  ICalculationAddProperties,
} from 'src/Pages/AdminPages/AdminCalculationSupplies/type';
import { MyPhoneInput } from 'src/components/UI/inputs/MyPhoneInput';
import CustomAddressInput from 'src/components/newUI/CustomAddressInput/CustomAddressInput';
import { RadioBoxCircle } from 'src/components/UI/checkBox/RadioBoxCircle';
import CustomDatePicker from 'src/components/newUI/CustomDatePicker/CustomDatePicker';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import {
  getAddressFromCoordinates,
  patchDetail,
  selectChangedCoordinates,
} from 'src/Pages/AdminPages/AdminCalculationSupplies/AdminCalculationSuppliesSlice';
import dayjs from 'dayjs';
import useDebounce from 'src/CustomHooks/useDebounce';
import { CheckBoxCustom } from 'src/components/UI/checkBox/CheckBoxCustom';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';
import { MapSvg } from 'src/Pages/AdminPages/AdminCalculationSupplies/components/MapSvg';

export const OptionField = ({
  properties,
  changeValue,
  setOpenMap = () => {},
  calculationId,
}: {
  properties: ICalculationAddProperties[];
  changeValue: ({ name, value }: { name: string; value: any }) => void;
  setOpenMap?: Dispatch<SetStateAction<boolean>>;
  calculationId: string;
}) => {
  const dispatch = useAppDispatch();
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const lat = properties.find(item => item.name === addPropertyName.latitude)?.value as string;
  const lon = properties.find(item => item.name === addPropertyName.longitude)?.value as string;
  const isChangedCoordinates = useAppSelector(selectChangedCoordinates);
  const debouncedLat = useDebounce(lat, 1000);
  const debouncedLon = useDebounce(lon, 1000);
  const materialTypeValue = String(properties.find(item => item.name === addPropertyName.materialTypeId)?.value);
  const isGravel = materialTypeValue === '6';
  useEffect(() => {
    if (debouncedLat && debouncedLon && isChangedCoordinates) {
      dispatch(getAddressFromCoordinates({ lat: debouncedLat, lon: debouncedLon }));
    }
  }, [debouncedLat, debouncedLon]);

  const handleValidateAndChangeInputField = useCallback(
    (value: string, name: string, fieldMask: string) => {
      if (fieldMask) {
        (new RegExp(fieldMask.slice(1, -1)).test(value) || !value) && changeValue({ name, value });
        return;
      }
      changeValue({ name, value });
    },
    [changeValue],
  );

  const handleChangeValue = useCallback(
    (value: any, name: string) => {
      changeValue({ name, value });
    },
    [changeValue],
  );

  const findValueManager = (infoField: ICalculationAddProperties) => {
    return infoField.properties.find(item => item.label === infoField.value)?.value || infoField.value;
  };
  return (
    <div>
      <div className={styles.materialFieldsContainer}>
        {properties?.map((infoField: ICalculationAddProperties) => {
          const isGravelKind = isGravel && infoField?.name === addPropertyName.materialKindId;
          return (
            <div key={infoField?.id} className={styles.fieldContainer}>
              {infoField.fieldType === 'select' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <p className={styles.materialTypeTitle}>
                    {infoField.title}
                    {infoField.isRequired && !isGravelKind && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                  </p>
                  <SelectLk
                    disabled={
                      (infoField?.isDisabled || !infoField?.properties?.length) &&
                      infoField?.name !== createdPropertyName.managerId
                    }
                    isSearch={infoField.isSearch}
                    value={
                      infoField.name === createdPropertyName.managerId
                        ? findValueManager(infoField)
                        : (infoField.value as string)
                    }
                    heightSelectWindow={'195px'}
                    options={infoField?.properties || []}
                    placeholder={isGravelKind ? '' : infoField?.placeholder || ''}
                    setValue={value => {
                      handleChangeValue(value, infoField?.name || infoField?.title);
                      infoField?.name === createdPropertyName.managerId &&
                        value &&
                        dispatch(
                          patchDetail({
                            data: { managerId: Number(value) },
                            id: Number(calculationId),
                          }),
                        );
                    }}
                    isClearable={infoField?.name !== addPropertyName.unit}
                  />
                </div>
              )}
              {infoField.fieldType === 'input' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <div className={styles.addressTitle}>
                    <p className={styles.materialTypeTitle}>
                      {infoField.title}
                      {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                    </p>
                    {infoField.name === 'address' && (
                      <ButtonSimple text="Показать на карте" svg={<MapSvg />} onClick={() => setOpenMap(true)} />
                    )}
                  </div>
                  <InputText
                    isLabel={true}
                    error={false}
                    type={''}
                    value={infoField.value}
                    textError={''}
                    placeholder={infoField?.placeholder || ''}
                    setValue={value => {
                      handleValidateAndChangeInputField(
                        value,
                        infoField?.name || infoField?.title,
                        infoField.fieldMask,
                      );
                    }}
                    disabled={infoField.isDisabled}
                    helpText={''}
                  />
                </div>
              )}
              {infoField.fieldType === 'textArea' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <p className={styles.materialTypeTitle}>
                    {infoField.title}
                    {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                  </p>
                  <TextArea
                    value={infoField.value as string}
                    className={styles.descriptionMaterialField}
                    maxLength={300}
                    placeholder={infoField?.placeholder || ''}
                    onChange={e => {
                      handleChangeValue(e.target.value, infoField?.name || infoField?.title);
                    }}
                    style={{ resize: 'none' }}
                    disabled={infoField.isDisabled}
                    rows={3}
                  />
                </div>
              )}
              {infoField.fieldType === 'phone' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <p className={styles.materialTypeTitle}>
                    {infoField.title}
                    {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                  </p>
                  <MyPhoneInput
                    value={infoField.value}
                    placeholder={infoField?.placeholder}
                    disabled={infoField.isDisabled}
                    onChange={value => {
                      handleChangeValue(value, infoField?.name || infoField?.title);
                    }}
                    isActive={undefined}
                    btnHelp={undefined}
                    setIsActive={undefined}
                    isError={false}
                    setIsError={setPhoneError}
                    isFunction={undefined}
                    isLabel={false}
                  />
                </div>
              )}
              {infoField.fieldType === 'address' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <div className={styles.addressTitle}>
                    <p className={styles.materialTypeTitle}>
                      {infoField.title}
                      {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                    </p>
                    <ButtonSimple text="Показать на карте" svg={<MapSvg />} onClick={() => setOpenMap(true)} />
                  </div>
                  <CustomAddressInput
                    setValueAddress={value => {
                      handleChangeValue(value, infoField?.name || infoField?.title);
                    }}
                    valueAddress={infoField?.value}
                    placeholder={infoField?.placeholder}
                    disabled={infoField.isDisabled}
                  />
                </div>
              )}
              {infoField.fieldType === 'radio' && typeof infoField?.value !== 'string' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <p className={styles.materialTypeTitle}>
                    {infoField.title}
                    {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                  </p>
                  <div className={styles.option}>
                    {infoField?.properties?.length &&
                      infoField?.properties.map((radioItem, index) => {
                        return (
                          <RadioBoxCircle
                            key={infoField?.id + '_' + index}
                            id={`${index}`}
                            value={radioItem.label}
                            name={'optionFieldRadio'}
                            check={typeof infoField?.value === 'object' && infoField?.value?.label === radioItem?.label}
                            onInput={event => {
                              if (infoField.isDisabled) return;
                              const value = event.target.value;
                              handleChangeValue({ value, label: value }, infoField?.name || infoField?.title);
                            }}
                            isSmall={true}
                            disabled={infoField.isDisabled}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
              {infoField.fieldType === 'checkbox' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <CheckBoxCustom
                    error={false}
                    children={infoField.title}
                    check={infoField?.value}
                    pick={() => {
                      handleChangeValue(!infoField?.value, infoField?.name || infoField?.title);
                      dispatch(patchDetail({ data: { callback: !infoField?.value }, id: Number(calculationId) }));
                    }}
                    name={'option-checkbox'}
                    id={'option-checkbox'}
                    disabled={infoField.isDisabled}
                    size={infoField.size}
                  />
                </div>
              )}
              {infoField.fieldType === 'dateRange' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <p className={styles.materialTypeTitle}>
                    {infoField.title}
                    {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                  </p>
                  <div className={styles.datePickerContainer}>
                    <CustomDatePicker
                      value={infoField?.value ? dayjs(infoField?.value as string, 'DD.MM.YYYY') : null}
                      setValue={date => {
                        handleChangeValue(date?.format('DD.MM.YYYY').toString(), infoField?.name || infoField?.title);
                      }}
                      height="48px"
                      width={infoField.width}
                      paddingRight="16px"
                      disabled={infoField.isDisabled}
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
