import React from 'react';
import { ImageContainer } from '../../../components/ImageContainer';
import { ButtonClose } from '../../../components/UI/buttons/buttonClose';
import { removeImage, removeImageFormData } from '../../../common/removeImage';
interface IProps {
  setImages;
  images;
  openPopupFunc;
  disabled?;
  formData?: boolean;
  setShowImages?;
  showImages?;
}
const UploadImageContainer = ({
  setImages,
  images,
  openPopupFunc,
  disabled = false,
  formData,
  setShowImages,
  showImages,
}: IProps) => {
  const arrayImages = formData ? showImages : images;
  return (
    <>
      {arrayImages.map(({ id, value, name, size, isSize, date, isDate, type }, index) => (
        <div className="image-container" key={id}>
          <ImageContainer
            id={id}
            value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
            name={name}
            size={size}
            isSize={isSize}
            date={date}
            isDate={isDate}
            type={type}
            openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupFunc(value)}
          >
            <ButtonClose
              id={id}
              onClick={({ target }) =>
                !disabled && formData
                  ? removeImageFormData(index, showImages, setShowImages, images, setImages)
                  : removeImage(target, images, setImages)
              }
              hidden=""
            />
          </ImageContainer>
        </div>
      ))}
    </>
  );
};

export default UploadImageContainer;
