import React from 'react';
import { formPriceOnlyNumber } from 'src/common/form-price.helper';
import styles from './AdminCalculationSupplies.module.scss';
import CheckBoxCalculation from './components/CheckBoxCalculation/CheckBoxCalculation';
import { formatDate } from './utils';
import {
  addPropertyName,
  calculationDetailsInfoTitles,
  createdPropertyName,
  ICalculationAddProperties,
  propertySize,
  typeOfValue,
} from './type';
import cx from 'classnames';
import { validationPhoneNumberFunction } from 'src/common/validation-phone-number.function';
import CustomTooltip from 'src/components/newUI/CustomTooltip/CustomTooltip';
import { ActualPriceSvg } from './components/ActualPriceSvg';

export const initStateAdminCalculationFilters = {
  manager: '',
  material: '',
  checkCallback: false,
};
const volumeProperties = [
  { value: 'м³', label: 'м³' },
  { value: 'тн', label: 'тн' },
];

export const scheduleProperties = [
  { value: 'Круглосуточная', label: 'Круглосуточная' },
  { value: 'Дневная', label: 'Дневная' },
];
export const typeScheduleProperties = [
  { value: 'Доверенность', label: 'Доверенность' },
  { value: 'Печать', label: 'Печать' },
];
export const dataTableCalculations = [
  {
    title: 'id / Заказ',
    dataIndex: 'idOrder',
    width: 60,
    key: 'idOrder',
    render: text => (
      <div className={cx(styles.columnAndBorder, styles.firstColumnContainer)}>
        <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '60px' }}>
          {text.id && (
            <p className={styles.tableText} style={{ width: '60px' }}>
              {text.id}
            </p>
          )}
          {text.order && (
            <p className={cx(styles.tableText, styles.secondaryText)} style={{ width: '79px' }}>
              {'№ ' + text.order}
            </p>
          )}
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Дата и время',
    dataIndex: 'createdAt',
    width: 119,
    key: 'createdAt',
    render: text => (
      <div className={styles.columnAndBorder}>
        <p className={styles.tableText} style={{ width: '119px' }}>
          {formatDate(text)}
        </p>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Клиент/Объект',
    dataIndex: 'clientAndObject',
    width: 199,
    key: 'clientAndObject',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '199px' }}>
          <p className={styles.tableText} style={{ width: '199px' }}>
            {text.client}
          </p>
          <p className={cx(styles.tableText, styles.secondaryText)} style={{ width: '199px' }}>
            {text.object}
          </p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Материал',
    dataIndex: 'materialAndQuarry',
    width: 193,
    key: 'materialAndQuarry',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '185px' }}>
          <p className={styles.tableText} style={{ width: '185px' }}>
            {text.material}
          </p>
          <p className={cx(styles.tableText, styles.secondaryText)} style={{ width: '185px' }}>
            {text.quarry}
          </p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Стоимость',
    dataIndex: 'priceAndUnit',
    width: 98,
    key: 'priceAndUnit',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '98px' }}>
          <p className={styles.tableText}>
            {formPriceOnlyNumber(Number(text.price), 'RUB', 'ru')} ₽/{text.unit}
          </p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Менеджер',
    dataIndex: 'manager',
    width: 185,
    key: 'manager',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '185px' }}>
          <p className={cx(styles.tableText, styles.secondaryText)}>{text ? text?.fullName : 'Нет данных'}</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Обратный звонок',
    dataIndex: 'callbackClientAndPhone',
    width: 195,
    key: 'callbackClientAndPhone',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.callBackContainer} style={{ width: '214px' }}>
          <div style={{ width: '20px' }}>
            <CheckBoxCalculation check={text.callback} />
          </div>
          <div className={cx(styles.columnContainer, styles.twoRows)}>
            <p className={styles.tableText}>{text.phone ? validationPhoneNumberFunction(text.phone) : ''}</p>
            <p className={cx(styles.tableText, styles.secondaryText)} style={{ width: '196px' }}>
              {text.client}
            </p>
          </div>
        </div>
      </div>
    ),
  },
];

const startDate = new Date();

export const initCalculationAddFields: ICalculationAddProperties[] = [
  {
    id: 1,
    name: addPropertyName.managerId,
    title: 'Менеджер',
    isRequired: false,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Выберите из списка',
    width: '412px',
    isDisabled: false,
    typeOfValue: typeOfValue.NUMBER,
  },
  {
    id: 2,
    name: addPropertyName.callback,
    title: 'Требуется обратный звонок по расчету',
    isRequired: false,
    fieldType: 'checkbox',
    value: false,
    placeholder: '',
    width: '332px',
    isDisabled: false,
    size: propertySize.MEDIUM,
    typeOfValue: typeOfValue.BOOLEAN,
  },
  {
    id: 3,
    name: addPropertyName.materialTypeId,
    title: 'Тип материала',
    isRequired: true,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Выберите из списка',
    width: '412px',
    isDisabled: false,
    typeOfValue: typeOfValue.NUMBER,
  },
  {
    id: 4,
    name: addPropertyName.materialKindId,
    title: 'Вид материала',
    isRequired: true,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Выберите из списка',
    width: '412px',
    isDisabled: true,
    typeOfValue: typeOfValue.NUMBER,
  },
  {
    id: 5,
    name: addPropertyName.quarryId,
    title: 'Карьер',
    isRequired: false,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Все карьеры',
    width: '412px',
    isDisabled: true,
    typeOfValue: typeOfValue.NUMBER,
    isSearch: true,
  },
  {
    fieldMask: '',
    id: 6,
    name: addPropertyName.volume,
    title: 'Объем материала',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: '0',
    width: '280px',
    isDisabled: false,
    typeOfValue: typeOfValue.NUMBER,
  },
  {
    id: 7,
    name: addPropertyName.unit,
    title: 'Ед.изм',
    isRequired: true,
    fieldType: 'select',
    value: volumeProperties[0].value,
    properties: volumeProperties,
    width: '100px',
    isDisabled: false,
    typeOfValue: typeOfValue.STRING,
  },
  {
    fieldMask: '',
    id: 8,
    name: addPropertyName.volumePerDay,
    title: 'Суточный объем',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: 'Пример: 10 000 м³',
    width: '412px',
    isDisabled: false,
    typeOfValue: typeOfValue.NUMBER,
  },
  {
    id: 9,
    name: addPropertyName.startDelivery,
    title: 'Период поставок',
    isRequired: false,
    fieldType: 'dateRange',
    value: new Date(startDate.setDate(startDate.getDate() + 1)).toLocaleDateString(),
    placeholder: '',
    width: '190px',
    isDisabled: false,
    typeOfValue: typeOfValue.STRING,
  },
  {
    id: 10,
    name: addPropertyName.endDelivery,
    title: '',
    isRequired: false,
    fieldType: 'dateRange',
    value: '',
    placeholder: '',
    width: '190px',
    isDisabled: false,
    typeOfValue: typeOfValue.STRING,
  },
  {
    id: 11,
    name: addPropertyName.clientId,
    title: 'Покупатель',
    isRequired: false,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Выберите из списка',
    width: '412px',
    isDisabled: false,
    typeOfValue: typeOfValue.NUMBER,
    isSearch: true,
  },
  {
    id: 12,
    name: addPropertyName.clientContractId,
    title: 'Договор',
    isRequired: false,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Выберите из списка',
    width: '412px',
    isDisabled: true,
    typeOfValue: typeOfValue.NUMBER,
  },
  {
    id: 13,
    name: addPropertyName.objectId,
    title: 'Объект',
    isRequired: false,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Выберите из списка',
    width: '412px',
    isDisabled: true,
    typeOfValue: typeOfValue.NUMBER,
    isSearch: true,
  },
  {
    id: 14,
    name: addPropertyName.address,
    title: 'Адрес',
    isRequired: true,
    fieldType: 'address',
    value: null,
    placeholder: 'Введите адрес',
    width: '412px',
    isDisabled: false,
    typeOfValue: typeOfValue.STRING,
  },
  {
    id: 15,
    name: addPropertyName.latitude,
    title: 'Широта',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: 'Широта',
    width: '190px',
    isDisabled: false,
    typeOfValue: typeOfValue.NUMBER,
  },
  {
    id: 16,
    name: addPropertyName.longitude,
    title: 'Долгота',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: 'Долгота',
    width: '190px',
    isDisabled: false,
    typeOfValue: typeOfValue.NUMBER,
  },
  {
    id: 17,
    name: addPropertyName.semitrailerId,
    title: 'Максимальный размер кузова',
    isRequired: true,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Выберите из списка',
    width: '249px',
    isDisabled: false,
    typeOfValue: typeOfValue.NUMBER,
  },
  {
    id: 18,
    name: addPropertyName.workSchedule,
    title: 'График приемки',
    isRequired: true,
    fieldType: 'radio',
    value: { value: '', label: '' },
    properties: scheduleProperties,
    width: '133px',
    isDisabled: false,
    typeOfValue: typeOfValue.STRING,
  },
  {
    id: 19,
    name: addPropertyName.clientName,
    title: 'ФИО',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: 'Введите ФИО',
    width: '412px',
    isDisabled: false,
    typeOfValue: typeOfValue.STRING,
  },
  {
    id: 20,
    name: addPropertyName.clientPhone,
    title: 'Телефон',
    isRequired: false,
    fieldType: 'phone',
    value: '',
    placeholder: '+7 (_ _ _) _ _ _  _ _  _ _',
    width: '412px',
    isDisabled: false,
    typeOfValue: typeOfValue.STRING,
  },
  {
    id: 21,
    name: addPropertyName.comment,
    title: 'Комментарий',
    isRequired: false,
    fieldType: 'textArea',
    value: '',
    placeholder: 'Комментарий к расчету',
    width: '1302px',
    isDisabled: false,
    typeOfValue: typeOfValue.STRING,
  },
];
export const initCalculationCreatedFields: ICalculationAddProperties[] = [
  {
    id: 1,
    name: createdPropertyName.managerId,
    title: 'Менеджер',
    isRequired: false,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Выберите из списка',
    width: '412px',
    isDisabled: false,
  },
  {
    id: 2,
    name: createdPropertyName.callback,
    title: 'Требуется обратный звонок по расчету',
    isRequired: false,
    fieldType: 'checkbox',
    value: false,
    placeholder: '',
    width: '332px',
    isDisabled: false,
    size: propertySize.MEDIUM,
  },
  {
    id: 3,
    name: createdPropertyName.materialTypeId,
    title: 'Тип материала',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: true,
  },
  {
    id: 4,
    name: createdPropertyName.materialKindId,
    title: 'Вид материала',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: true,
  },
  {
    id: 5,
    name: createdPropertyName.quarryId,
    title: 'Карьер',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: true,
  },
  {
    fieldMask: '',
    id: 6,
    name: createdPropertyName.volume,
    title: 'Объем материала',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '280px',
    isDisabled: true,
  },
  {
    id: 7,
    name: createdPropertyName.unit,
    title: 'Ед.изм',
    isRequired: true,
    fieldType: 'input',
    value: '',
    width: '100px',
    isDisabled: true,
  },
  {
    fieldMask: '',
    id: 8,
    name: createdPropertyName.volumePerDay,
    title: 'Суточный объем',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: true,
  },
  {
    id: 9,
    name: createdPropertyName.startDelivery,
    title: 'Период поставок',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '190px',
    isDisabled: true,
  },
  {
    id: 10,
    name: createdPropertyName.endDelivery,
    title: '',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '190px',
    isDisabled: true,
  },
  {
    id: 11,
    name: createdPropertyName.clientId,
    title: 'Покупатель',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: true,
  },
  {
    id: 12,
    name: createdPropertyName.clientContractId,
    title: 'Договор',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: true,
  },
  {
    id: 13,
    name: createdPropertyName.objectId,
    title: 'Объект',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: true,
  },
  {
    id: 14,
    name: createdPropertyName.address,
    title: 'Адрес',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: true,
  },
  {
    id: 15,
    name: createdPropertyName.latitude,
    title: 'Широта',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '190px',
    isDisabled: true,
  },
  {
    id: 16,
    name: createdPropertyName.longitude,
    title: 'Долгота',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '190px',
    isDisabled: true,
  },
  {
    id: 17,
    name: createdPropertyName.semitrailerId,
    title: 'Максимальный размер кузова',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '249px',
    isDisabled: true,
  },
  {
    id: 18,
    name: createdPropertyName.workSchedule,
    title: 'График приемки',
    isRequired: true,
    fieldType: 'radio',
    value: { value: '', label: '' },
    properties: scheduleProperties,
    width: '133px',
    isDisabled: true,
  },
  {
    id: 19,
    name: createdPropertyName.clientName,
    title: 'ФИО',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: true,
  },
  {
    id: 20,
    name: createdPropertyName.clientPhone,
    title: 'Телефон',
    isRequired: false,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: true,
  },
  {
    id: 21,
    name: createdPropertyName.comment,
    title: 'Комментарий',
    isRequired: false,
    fieldType: 'textArea',
    value: '',
    placeholder: '',
    width: '1302px',
    isDisabled: true,
  },
];
export const dataTableCalculationVariants = [
  {
    title: 'Юридическое лицо',
    dataIndex: 'supplier',
    width: 180,
    key: 'supplier',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div
          className={cx(styles.columnContainer, styles.twoRows, styles.firstColumnContainer)}
          style={{ width: '180px' }}
        >
          <p className={styles.tableText}>{text?.title || 'Нет данных'}</p>
          <p className={cx(styles.tableText, styles.secondaryText)}>{text?.inn ? 'ИНН: ' + text?.inn : ''}</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Название карьера/расстояние',
    dataIndex: 'quarry',
    width: 300,
    key: 'quarry',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '300px' }}>
          <p className={styles.tableText} style={{ fontWeight: text?.checked ? '700' : '400' }}>
            {text?.title || 'Нет данных'}
          </p>
          <p className={cx(styles.tableText, styles.secondaryText)}>{text?.distance || 'Нет данных'}</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Себестоимость материала',
    dataIndex: 'cost',
    width: 151,
    key: 'cost',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '151px' }}>
          <p className={styles.tableText}>
            {text?.pricePerTon
              ? `${formPriceOnlyNumber(Number(text?.pricePerTon), 'RUB', 'ru')} ₽/${text?.unit}`
              : 'Нет данных'}
          </p>
          {text?.actualPrice && (
            <CustomTooltip
              MobileSideTooltip={'left'}
              SideTooltip={'up'}
              svg={<ActualPriceSvg />}
              positionTooltipBottom={'24px'}
              text={'Цена может быть не актуальна. Свяжитесь с отделом снабжения.'}
              widthTooltip={'260px'}
              widthSvg={'20px'}
            />
          )}
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Перевозчик',
    dataIndex: 'carrier',
    width: 183,
    key: 'carrier',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '135px' }}>
          <p className={styles.tableText}>{text?.title || 'Нет данных'}</p>
          {text?.inn && <p className={cx(styles.tableText, styles.secondaryText)}>{'ИНН: ' + text?.inn}</p>}
        </div>
        {text.semitrailersValue.length ? (
          <div className={styles.tableCarrierContainer} style={{ width: '40px' }}>
            <p>{text.semitrailersValue.reduce((acc, item) => acc + item.value, 0)}</p>
            <CustomTooltip
              MobileSideTooltip={'left'}
              SideTooltip={'up'}
              svg={
                <svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.6663 2.6665H15.833H14.1663H12.4997V7.6665H1.66634V10.1665H2.49967C2.49967 10.8295 2.76307 11.4654 3.23191 11.9343C3.70075 12.4031 4.33663 12.6665 4.99967 12.6665C5.66272 12.6665 6.2986 12.4031 6.76744 11.9343C7.23628 11.4654 7.49967 10.8295 7.49967 10.1665H12.4997C12.4997 10.8295 12.7631 11.4654 13.2319 11.9343C13.7007 12.4031 14.3366 12.6665 14.9997 12.6665C15.6627 12.6665 16.2986 12.4031 16.7674 11.9343C17.2363 11.4654 17.4997 10.8295 17.4997 10.1665H19.1663V5.99984L16.6663 2.6665ZM4.99967 11.4165C4.66815 11.4165 4.35021 11.2848 4.11579 11.0504C3.88137 10.816 3.74967 10.498 3.74967 10.1665C3.74967 9.83498 3.88137 9.51704 4.11579 9.28262C4.35021 9.0482 4.66815 8.9165 4.99967 8.9165C5.33119 8.9165 5.64914 9.0482 5.88356 9.28262C6.11798 9.51704 6.24967 9.83498 6.24967 10.1665C6.24967 10.498 6.11798 10.816 5.88356 11.0504C5.64914 11.2848 5.33119 11.4165 4.99967 11.4165ZM14.9997 11.4165C14.6682 11.4165 14.3502 11.2848 14.1158 11.0504C13.8814 10.816 13.7497 10.498 13.7497 10.1665C13.7497 9.83498 13.8814 9.51704 14.1158 9.28262C14.3502 9.0482 14.6682 8.9165 14.9997 8.9165C15.3312 8.9165 15.6491 9.0482 15.8836 9.28262C16.118 9.51704 16.2497 9.83498 16.2497 10.1665C16.2497 10.498 16.118 10.816 15.8836 11.0504C15.6491 11.2848 15.3312 11.4165 14.9997 11.4165ZM14.1663 5.99984V3.9165H16.2497L17.883 5.99984H14.1663ZM14.9997 1.83317H11.6663V6.83317H2.49967L1.30801 2.6665H0.833008V0.999837H10.833L11.6663 0.166504H14.9997V1.83317Z"
                    fill="#F4753A"
                  />
                </svg>
              }
              positionTooltipBottom={'24px'}
              text={
                <div className={styles.tableText}>
                  {text.semitrailersValue.map(item => (
                    <p key={item.title}>
                      {item?.title} – {item?.value} шт.
                    </p>
                  ))}
                </div>
              }
              widthTooltip={'240px'}
              widthSvg={'20px'}
            />
          </div>
        ) : (
          <></>
        )}

        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: () => <span style={{ whiteSpace: 'wrap' }}>Себестоимость перевозки</span>,
    dataIndex: 'delivery',
    width: 144,
    key: 'delivery',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '144px' }}>
          <p className={styles.tableText}>
            {text?.price ? `${formPriceOnlyNumber(Number(text?.price), 'RUB', 'ru')} ₽/${text?.unit}` : 'Нет данных'}
          </p>
          {text?.actualPrice && (
            <CustomTooltip
              MobileSideTooltip={'left'}
              SideTooltip={'up'}
              svg={<ActualPriceSvg />}
              positionTooltipBottom={'24px'}
              text={'Цена может быть не актуальна. Свяжитесь с отделом снабжения.'}
              widthTooltip={'260px'}
              widthSvg={'20px'}
            />
          )}
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: () => <span style={{ whiteSpace: 'wrap' }}>Себестоимость поставки</span>,
    dataIndex: 'total',
    width: 120,
    key: 'total',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '120px' }}>
          <p className={styles.tableText}>
            {text?.totalCostPerUnit
              ? `${formPriceOnlyNumber(Number(text?.totalCostPerUnit), 'RUB', 'ru')} ₽/${text?.unit}`
              : 'Нет данных'}
          </p>
        </div>
      </div>
    ),
  },
];

export const initCalculationDetailsInfoFields = [
  {
    name: calculationDetailsInfoTitles.createdAt,
    value: '',
    label: '',
    type: typeOfValue.STRING,
  },
  {
    name: calculationDetailsInfoTitles.distance,
    value: '',
    label: 'Длина маршрута',
    type: typeOfValue.NUMBER,
  },
  {
    name: calculationDetailsInfoTitles.deliveryDays,
    value: '',
    label: 'Кол-во дней поставки',
    type: typeOfValue.NUMBER,
  },
  {
    name: calculationDetailsInfoTitles.volume,
    value: '',
    label: 'Кол-во материала',
    type: typeOfValue.NUMBER,
  },
  {
    name: calculationDetailsInfoTitles.flightsPerDay,
    value: '',
    label: 'Кол-во рейсов в день',
    type: typeOfValue.NUMBER,
  },
  {
    name: calculationDetailsInfoTitles.semitrailer,
    value: '',
    label: 'Размер кузова',
    type: typeOfValue.STRING,
  },
  {
    name: calculationDetailsInfoTitles.totalCostPerUnit,
    value: '',
    label: 'Себестоимость',
    isTitle: true,
    type: typeOfValue.NUMBER,
  },
  {
    name: calculationDetailsInfoTitles.margin,
    value: '',
    label: 'Маржа, %',
    type: typeOfValue.NUMBER,
  },
  {
    name: calculationDetailsInfoTitles.clientPricePerUnit,
    value: '',
    label: 'Стоимость для клиента, ₽',
    type: typeOfValue.NUMBER,
  },
  {
    name: calculationDetailsInfoTitles.totalCostWithMargin,
    value: '',
    label: 'Общая стоимость',
    isTitle: true,
    type: typeOfValue.NUMBER,
  },
  {
    name: calculationDetailsInfoTitles.totalMargin,
    value: '',
    label: 'Маржа, ₽',
    type: typeOfValue.NUMBER,
  },
  {
    name: calculationDetailsInfoTitles.managerComment,
    value: '',
    label: 'Примечания к расчету',
    type: typeOfValue.STRING,
  },
];
