import React, { useEffect, useState } from 'react';
import styles from '../CalculationDetailsList/CalculationDetailsList.module.scss';
import { calculationDetailsInfoTitles, ICalculationDetailsInfo } from '../../type';
import CustomInput from 'src/components/newUI/CustomInput/CustomInput';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../../CustomHooks/storeHooks';
import {
  changeCalculationDetailsInfo,
  getDetails,
  handleChangeDetailInfoValue,
  patchDetail,
} from '../../AdminCalculationSuppliesSlice';
import { regForFlakinessWithPoint } from '../../../../../common/number.helper';
import useDebounce from '../../../../../CustomHooks/useDebounce';
interface IProps {
  array: ICalculationDetailsInfo[];
  calculationId: string;
}
const ArrayDataOfCalculationDetails = ({ array, calculationId }: IProps) => {
  const dispatch = useAppDispatch();
  const valueMargin = array?.find(item => item?.name === calculationDetailsInfoTitles?.margin)?.value;
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const marginFieldValue = useDebounce(valueMargin, isFirstRender ? 0 : 2000);
  const { pathname } = useLocation();
  const isCreateOrderPath = pathname.includes('/orders/create');

  useEffect(() => {
    if (!marginFieldValue) return;
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    dispatch(patchDetail({ data: { margin: Number(valueMargin) }, id: Number(calculationId) })).then(() => {
      dispatch(getDetails(Number(calculationId))).then(() => {
        dispatch(changeCalculationDetailsInfo());
      });
    });
  }, [marginFieldValue]);

  const handleChangeMargin = value => {
    if (regForFlakinessWithPoint.test(value) || value === '') {
      dispatch(
        handleChangeDetailInfoValue({
          name: calculationDetailsInfoTitles.margin,
          value: Number(value),
        }),
      );
    }
  };
  return (
    <>
      {array.map((item: ICalculationDetailsInfo) => (
        <div key={item.name} className={styles.stringContainer}>
          <span className={styles.labelText} style={{ fontWeight: item?.isTitle ? '500' : '400' }}>
            {item.label}
          </span>
          <div className={styles.borderBetween} />
          {item.name === calculationDetailsInfoTitles.margin && !isCreateOrderPath ? (
            <CustomInput
              suffix={'%'}
              error={false}
              type={'text'}
              value={item?.value ? String(item.value) : ''}
              placeholder={''}
              setValue={value => handleChangeMargin(value)}
              disabled={false}
              helpText={''}
              width="80px"
              height="40px"
            />
          ) : (
            <span className={styles.valueText} style={{ fontWeight: item?.isTitle ? '500' : '400' }}>
              {`${item.value}${item.name === calculationDetailsInfoTitles.margin ? '%' : ''}`}
            </span>
          )}
        </div>
      ))}
    </>
  );
};

export default ArrayDataOfCalculationDetails;
