// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CarsPage_titleAndSubTitleContainer__MUoyF {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 40px;\n}\n\n.CarsPage_titleAndAddButton__lJglg {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.CarsPage_buttonsContainer__pp5Gv {\n  display: flex;\n  align-items: center;\n  gap: 16px;\n}\n\n@media (max-width: 768px) {\n  .CarsPage_titleAndSubTitleContainer__MUoyF {\n    gap: 4px;\n    margin-bottom: 24px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/CarsPage/CarsPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,mBAAA;AACF;;AACA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAEF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAGF;;AADA;EACE;IACE,QAAA;IACA,mBAAA;EAIF;AACF","sourcesContent":[".titleAndSubTitleContainer{\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n  margin-bottom: 40px;\n}\n.titleAndAddButton{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.buttonsContainer {\n  display: flex;\n  align-items: center;\n  gap:16px\n}\n@media (max-width: 768px) {\n  .titleAndSubTitleContainer{\n    gap: 4px;\n    margin-bottom: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleAndSubTitleContainer": "CarsPage_titleAndSubTitleContainer__MUoyF",
	"titleAndAddButton": "CarsPage_titleAndAddButton__lJglg",
	"buttonsContainer": "CarsPage_buttonsContainer__pp5Gv"
};
export default ___CSS_LOADER_EXPORT___;
