import { Dispatch, SetStateAction } from 'react';
import { Image } from '../../common/image.type';

export const uploadImage = (event: React.ChangeEvent<HTMLInputElement>, setImage: Dispatch<SetStateAction<string>>) => {
  const file = event.target.files?.[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = e => {
      setImage(e.target?.result as string);
    };
    reader.readAsDataURL(file);
  }
};
export const createSendFileFD = (
  file: File[],
  fileBelongsId: string,
  communicationCategory: string,
  category: string,
) => {
  const formData = new FormData();
  file.forEach(image => {
    formData.append('file', image);
  });
  formData.append('fileBelongsId', fileBelongsId);
  formData.append('communicationCategory', communicationCategory);
  formData.append('category', category);
  return formData;
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
