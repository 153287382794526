import styles from './SelectStatus.module.scss';
import { selectStatusEnum } from './type';

export const generateStyleSelectStatus = (text: string) => {
  const activeStyle = text === selectStatusEnum.ACTIVE && styles.activeStatusContainer;
  const newStyle = text === selectStatusEnum.NEW && styles.activeStatusContainer;
  const signatureStyle = text === selectStatusEnum.SIGNATURE && styles.signatureStatusContainer;
  const paymentStyle = text === selectStatusEnum.PAYMENT && styles.paymentStatusContainer;
  const completedStyle = text === selectStatusEnum.COMPLETED && styles.completedStatusContainer;
  const cancelledStyle = text === selectStatusEnum.CANCELLED && styles.cancelledStatusContainer;
  return [styles.statusContainer, activeStyle, signatureStyle, paymentStyle, completedStyle, cancelledStyle, newStyle];
};
