import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction, UnknownAction } from '@reduxjs/toolkit';
import {
  bidTabs,
  IBid,
  IBidFlight,
  IBidMainPageFromBack,
  IBidsListRequest,
  IGetFlightsRequest,
  IInitStateSlicer,
  IMaterialListOptions,
  IPerformer,
  IPostPerformersList,
  ITypeOfTab,
  IValueFieldsCreateAppForBackend,
  IValueFieldsCreateBid,
  IValueFieldsCreateExecutor,
  IValueFieldsCreateExecutorForBack,
  performersStatuses,
  tabs,
} from './types';
import {
  deleteBid,
  deletePerformersListInfo,
  getBidDeliveryRequestsForLogist,
  getBidsCancels,
  getBidsFlightsList,
  getBidsList,
  getBidsMaterialList,
  getBidsTable,
  getCalculateExecutor,
  getDetailsListOrders,
  postBid,
  postNewExecutor,
  postPerformersListInfo,
} from './apiAdminBids';
import { toastError } from 'src/common/toastError.helper';
import { RootState } from 'src/store';
import { convertValueFieldsCreateBidForBack, convertValueFieldsExecutorForBack } from './utils';
import { initStateValueFieldsCreateExecutor } from './constants';
import { toast } from 'react-toastify';
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

function isPendingAction(action: UnknownAction): action is PendingAction {
  return typeof action.type === 'string' && action.type.endsWith('/pending');
}
function isRejectedAction(action: UnknownAction): action is RejectedAction {
  return typeof action.type === 'string' && action.type.endsWith('/rejected');
}
function isFulfilledAction(action: UnknownAction): action is FulfilledAction {
  return typeof action.type === 'string' && action.type.endsWith('/fulfilled');
}

export const getBids = createAsyncThunk(
  'adminBids/getBids',
  async ({ id, data }: { id: number; data: ITypeOfTab }, { rejectWithValue }) => {
    try {
      const response = await getBidsTable(id, data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const addNewExecutor = createAsyncThunk(
  'adminBids/addNewExecutor',
  async (deliveryRequestId: number, { rejectWithValue, getState }) => {
    const state: any = getState(); // Получаем текущее состояние
    const data = convertValueFieldsExecutorForBack(state.adminBids.valueFieldsExecutor, deliveryRequestId);
    try {
      const response = await postNewExecutor(data);
      toast.success('Вы успешно добавили нового исполнителя');
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const getMaterialsBids = createAsyncThunk('adminBids/getMaterials', async (id: number, { rejectWithValue }) => {
  try {
    const response = await getBidsMaterialList(id);
    return response.data;
  } catch (e) {
    const errorResponse = JSON.parse(e.response.request.response);
    toastError(errorResponse);
    return rejectWithValue(e);
  }
});
export const getBidsCancelReasons = createAsyncThunk(
  'adminBids/getBidsCancelReasons',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getBidsCancels();
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const updateAllFieldsCreateBid = createAsyncThunk(
  //@TODO Переделать на редьюсер
  'adminBids/updateAllFieldsCreateBid',
  async (data: IValueFieldsCreateBid, { rejectWithValue }) => {
    try {
      return data; // Здесь можно добавить асинхронную логику, если потребуется
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getDetailsList = createAsyncThunk(
  'adminBids/getDetailsList',
  async (data: { orderId: number }, { rejectWithValue }) => {
    try {
      const response = await getDetailsListOrders(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const deleteCurrentBid = createAsyncThunk(
  'adminBids/deleteCurrentBid',
  async ({ id, data }: { id: number; data: { deliveryRequestsCancelReasonId: number } }, { rejectWithValue }) => {
    try {
      const response = await deleteBid(id, data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const createNewBid = createAsyncThunk('adminBids/createNewBid', async (_, { rejectWithValue, getState }) => {
  const state: any = getState(); // Получаем текущее состояние
  const data: IValueFieldsCreateAppForBackend = convertValueFieldsCreateBidForBack(
    state.adminBids.valueFieldsCreateBid,
  );
  try {
    const response = await postBid(data);
    return response.data;
  } catch (e) {
    const errorResponse = JSON.parse(e.response.request.response);
    toastError(errorResponse);
    return rejectWithValue(e);
  }
});

export const getBidsMainPageList = createAsyncThunk(
  'adminBids/getBidsMainPageList',
  async (data: IBidsListRequest, { rejectWithValue }) => {
    try {
      const response = await getBidsList(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getBidDetailsForLogist = createAsyncThunk(
  'adminBids/getBidDetailsForLogist',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getBidDeliveryRequestsForLogist(id);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getCalculateForExecutor = createAsyncThunk(
  'adminBids/getCalculateForExecutor',
  async (data: IValueFieldsCreateExecutorForBack, { rejectWithValue }) => {
    try {
      const response = await getCalculateExecutor(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const changePerformersList = createAsyncThunk(
  'adminBids/changePerformersList',
  async (data: IPostPerformersList, { rejectWithValue }) => {
    try {
      const response = await postPerformersListInfo(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const rejectPerformersList = createAsyncThunk(
  'adminBids/rejectPerformersList',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await deletePerformersListInfo(id);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getFlights = createAsyncThunk(
  'adminBids/getFlights',
  async (data: IGetFlightsRequest, { rejectWithValue }) => {
    try {
      const response = await getBidsFlightsList(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

const initValueFieldsCreateBid = {
  orderDetailsId: '',
  volumePlan: '',
  semitrailerId: '',
  time: { workingHoursType: '', from: '', to: '' },
  dispatcherName: '',
  dispatcherPhone: '',
  contactPersonName: '',
  contactPersonPhone: '',
  dateFrom: '',
  dateTo: '',
};
const initialState: IInitStateSlicer = {
  bidsTableInfo: [],
  bidsMainPageList: [],
  totalCount: 1,
  performersInfo: null,
  flightsInfo: [],
  activeTab: tabs.active,
  currentBidActiveTab: bidTabs.performers,
  loading: false,
  valueFieldsCreateBid: initValueFieldsCreateBid,
  valueFieldsExecutor: initStateValueFieldsCreateExecutor,
  materialInfo: {
    currentMaterial: null,
    options: [],
  },
};

export const adminBidsSlice = createSlice({
  name: 'adminBidsSlice',
  initialState,
  reducers: {
    changeActiveTab: (state, action: PayloadAction<tabs>) => {
      state.activeTab = action?.payload;
    },
    changeCurrentBidActiveTab: (state, action: PayloadAction<bidTabs>) => {
      state.currentBidActiveTab = action?.payload;
    },
    changeCurrentMaterial: (state, action: PayloadAction<{ currentMaterial: string }>) => {
      state.materialInfo.currentMaterial = action?.payload?.currentMaterial;
    },
    updateFieldCreateBid: <key extends keyof IValueFieldsCreateBid>( //Апдейтим значение у ключа
      state,
      action: PayloadAction<{ field: key; value: IValueFieldsCreateBid[key] }>,
    ) => {
      state.valueFieldsCreateBid[action.payload.field] = action.payload.value;
    },
    updateTimeFieldCreateBid: <key extends keyof IValueFieldsCreateBid['time']>( //Апдейтим объект time
      state,
      action: PayloadAction<{ field: key; value: IValueFieldsCreateBid['time'][key] }>,
    ) => {
      state.valueFieldsCreateBid.time[action.payload.field] = action.payload.value;
    },
    resetFieldsCreateBid: state => {
      state.valueFieldsCreateBid = initValueFieldsCreateBid;
    },
    changePerformersInfoValue: (
      state,
      action: PayloadAction<{ id: number; name: string; value: boolean | number | performersStatuses }>,
    ) => {
      const { id, name, value } = action.payload;
      state.performersInfo.executors.find(executor => executor?.id === id)[name] = value;
    },
    resetBidsData: state => {
      if (!!state.bidsMainPageList.length) state.bidsMainPageList = [];
      state.totalCount = 1;
    },
    updateValueFieldsExecutor: <key extends keyof IValueFieldsCreateExecutor>( //Редьюсер обновления стейта у добавления исполнителя
      state,
      action: PayloadAction<{ field: key; value: IValueFieldsCreateExecutor[key] }>,
    ) => {
      state.valueFieldsExecutor[action.payload.field] = action.payload.value;
    },
    updateAllFieldsCreateExecutor: (state, action: PayloadAction<{ fields: IValueFieldsCreateExecutor }>) => {
      state.valueFieldsExecutor = { ...state.valueFieldsExecutor, ...action.payload.fields };
    },
    resetValueFieldsExecutor: state => {
      state.valueFieldsExecutor = initStateValueFieldsCreateExecutor;
    },
  },
  extraReducers: builder => {
    builder.addCase(getBids.fulfilled, (state, action: PayloadAction<IBid[]>) => {
      state.bidsTableInfo = action?.payload;
    });
    builder.addCase(updateAllFieldsCreateBid.fulfilled, (state, action) => {
      Object.assign(state.valueFieldsCreateBid, action?.payload);
      if (action?.payload?.time) {
        Object.assign(state.valueFieldsCreateBid.time, action.payload.time);
      }
    });
    builder.addCase(getMaterialsBids.fulfilled, (state, action: PayloadAction<IMaterialListOptions[]>) => {
      state.materialInfo.options = action?.payload;
      state.materialInfo.currentMaterial = state.materialInfo.options[0]?.type;
    });
    builder.addCase(getBidsMainPageList.fulfilled, (state, action: IBidMainPageFromBack) => {
      state.bidsMainPageList =
        action.meta.arg.offset > 0 ? [...state.bidsMainPageList, ...action?.payload?.rows] : action?.payload?.rows;
      state.totalCount = action?.payload?.count;
    });
    builder.addCase(getBidDetailsForLogist.fulfilled, (state, action: PayloadAction<IPerformer>) => {
      state.performersInfo = action?.payload;
      state.performersInfo.executors = state.performersInfo.executors.map(item => {
        item.checked = false;
        return item;
      });
    });
    builder.addCase(getFlights.fulfilled, (state, action: PayloadAction<{ count: number; rows: IBidFlight[] }>) => {
      state.flightsInfo = action?.payload?.rows;
    });
    builder.addMatcher(isPendingAction, state => {
      state.loading = true;
    });

    builder.addMatcher(isRejectedAction, state => {
      state.loading = false;
    });

    builder.addMatcher(isFulfilledAction, state => {
      state.loading = false;
    });
  },
});

export const {
  changeActiveTab,
  changeCurrentMaterial,
  updateFieldCreateBid,
  updateTimeFieldCreateBid,
  resetFieldsCreateBid,
  changeCurrentBidActiveTab,
  changePerformersInfoValue,
  resetBidsData,
  updateValueFieldsExecutor,
  resetValueFieldsExecutor,
  updateAllFieldsCreateExecutor,
} = adminBidsSlice.actions;

export const selectActiveTab = (state: RootState) => state?.adminBids.activeTab;
export const selectCurrentBidActiveTab = (state: RootState) => state?.adminBids.currentBidActiveTab;
export const selectMaterialInfo = (state: RootState) => state?.adminBids.materialInfo;
export const selectBidsTableInfo = (state: RootState) => state?.adminBids.bidsTableInfo;
export const selectValueFieldsCreateBid = (state: RootState) => state?.adminBids.valueFieldsCreateBid;
export const selectPerformersInfo = (state: RootState) => state?.adminBids.performersInfo;
export const selectFlightsInfo = (state: RootState) => state?.adminBids.flightsInfo;
export const selectLoadingBids = (state: RootState) => state?.adminBids.loading;
export const selectTotalCount = (state: RootState) => state?.adminBids.totalCount;
export const selectBidsMainPageList = (state: RootState) => state?.adminBids.bidsMainPageList;
export const selectValueFieldsExecutor = (state: RootState) => state?.adminBids.valueFieldsExecutor;
