import React, { useContext, useEffect, useRef, useState } from 'react';
import { Context } from '../../context/context';
import { useMediaQuery } from 'react-responsive';
import styles from './NewAuthSms.module.scss';
import AuthLineSvg from '../../components/UI/svg-icon/AuthLineSvg';
import { InputVerification } from '../../components/UI/inputs/InputVerification';
import { AUTHSUBSERVER } from '../../common/sub-server.constant';
import { localStorageWrite } from '../../common/user/local-storage.helper';
import { toast } from 'react-toastify';
import FooterAuth from './footerAuth/FooterAuth';
import AuthLineMobileSvg from '../../components/UI/svg-icon/AuthLineMobileSvg';
import { fetchPost } from '../../common/proxy-1C-fetch-auth';
import { useNavigate } from 'react-router-dom';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import LogoMobileSvg from '../../components/UI/svg-icon/LogoMobileSvg';
import { clearObject } from '../../common/ClearObject.helper';
import { formatPhoneNumber } from '../../common/formatPhoneNumber.helper';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';
import { getMe } from 'src/common/user/getMe';

interface IProps {
  type: string;
}
const NewAuthSms = ({ type }: IProps) => {
  const { setUser, user } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [codeValue, setCodeValue] = useState<string>('');
  const [errorSending, setErrorSending] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(180);
  const [focus, setFocus] = useState<boolean>(false);
  const refCode = useRef<HTMLInputElement | null>();
  const navigate = useNavigate();

  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  const sendRole = async () => {
    const utmObj = JSON.parse(localStorage.getItem('Utm'));
    const type = localStorage.getItem('RoleType');
    const requestBody = { service: `LK`, role: type?.toUpperCase(), utm: utmObj ? utmObj : undefined };
    const requestOptions: any = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.apiKey}` },
      body: JSON.stringify(clearObject(requestBody)),
    };
    try {
      const response = await fetch(`${AUTHSUBSERVER}/api/auth/setRole`, requestOptions);
      const data = await response.json();
      localStorage.setItem('apiKey', data.access);
      if (data.access) {
        await fetchPost('/users/terms', 'PATCH', { accepted: user.agreement });
      }

      setUser({ ...user, isLogin: true, type });
      localStorageWrite({ ...user });
    } catch (e) {
      console.log(e);
    }
  };

  const sendCode = async () => {
    refCode.current.blur();
    const requestOptions: any = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({ phone: `+${user.phone}`, code: Number(codeValue) }),
    };
    try {
      const response = await fetch(`${AUTHSUBSERVER}/api/auth/login/v2`, requestOptions);
      const data = await response.json();

      if (response.status === 200) {
        localStorage.setItem('apiKey', response.headers.get('Authorization'));
        Object.assign(user, { apiKey: response.headers.get('Authorization') });
        setUser({ ...user });
        localStorageWrite(user);
        await getMe(user, setUser, sendRole);
        return;
      }
      if (response.status === 401) {
        setCodeValue('');
        toast.error('Некорректный код из смс');
        setErrorSending(true);
        return;
      }
      if (data.message === 'Не удалось найти код по данному номеру') {
        navigate('/auth');
        setCodeValue('');
        return;
      }
      setCodeValue('');
      toast.error(data.message);
      setErrorSending(true);
      setCodeValue('');
    } catch (e) {
      setCodeValue('');
      console.log(e);
    }
  };
  const convertVisualOfTimerCount = (): string => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;

    // Добавляем ведущий ноль, если число меньше 10
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    // Возвращаем строку в формате "минуты:секунды"
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const sendPhone = async () => {
    setErrorSending(false);
    setCodeValue('');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone: `+${user.phone}` }),
    };
    try {
      const response = await fetch(`${AUTHSUBSERVER}/api/auth/identificate`, requestOptions);
      if (response.status === 204) {
        setUser({ ...user, isInn: true, isPhone: true });
        localStorageWrite(user);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    refCode?.current?.click();
  }, []);

  useEffect(() => {
    if (codeValue.length === 5) {
      sendCode();
    }
  }, [codeValue]);
  const inputVerificationChange = ({ target: { value } }) => {
    setCodeValue(value.replace(/\D/gu, '').slice(0, 5));
  };

  return (
    <div style={{ height: '100vh' }}>
      {isMobile ? (
        <div style={{ height: '100vh' }}>
          <div className={styles.container}>
            <div className={styles.headerLogo}>
              <div className={styles.headerLogoContent}>
                <LogoMobileSvg />
                <p className={styles.textLogo}>
                  Платформа нерудных
                  <br />
                  материалов
                </p>
              </div>
            </div>
            <div className={styles.phoneContainer}>
              <div className={styles.svgContainer}>
                <AuthLineMobileSvg />
              </div>

              <div className={styles.inputContainer}>
                <h5 className={styles.titleInputContainer}>Введите код</h5>
                <div style={{ zIndex: '20' }}>
                  <p className={styles.descriptionInputContainer}>Мы отправили код подтверждения на номер</p>

                  <div className={styles.containerNumberAndChangeInputContainer}>
                    <div className={styles.numberInputContainer}>
                      {formatPhoneNumber(user?.phone)}
                      <ButtonSimple text={'Изменить'} onClick={() => navigate(-1)} />
                    </div>
                  </div>
                </div>
                <InputVerification
                  placeholder="00000"
                  errorSending={errorSending}
                  setErrorSending={setErrorSending}
                  onInput={inputVerificationChange}
                  value={codeValue}
                  refNew={refCode}
                  focus={focus}
                  setFocus={setFocus}
                  autoFocus={true}
                />
                {errorSending && (
                  <div className={styles.descriptionError}>Вы ввели неверный код. Попробуйте еще раз.</div>
                )}
                <div style={{ marginTop: '40px' }}>
                  {timer > 0 ? (
                    <div className={styles.newCodeCounter}>
                      Получить новый код можно <br />
                      через {convertVisualOfTimerCount()}
                    </div>
                  ) : (
                    <div style={{ marginTop: isMobile ? '16px' : '24px', zIndex: '20', position: 'relative' }}>
                      <ButtonFilled
                        text={'Отправить код повторно'}
                        width={'100%'}
                        onClick={() => sendPhone()}
                        disabled={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        /*компьютерная версия*/
        <div>
          <div className={styles.container}>
            <div className={styles.phoneContainer}>
              <div className={styles.svgContainer}>
                <AuthLineSvg />
              </div>
              <div className={styles.inputContainer}>
                <h5 className={styles.titleInputContainer}>Введите код</h5>
                <div>
                  <p className={styles.descriptionInputContainer}>Мы отправили код подтверждения на номер</p>

                  <div className={styles.containerNumberAndChangeInputContainer}>
                    <div className={styles.numberInputContainer}>
                      {formatPhoneNumber(user?.phone)}
                      <ButtonSimple text={'Изменить'} onClick={() => navigate(-1)} />
                    </div>
                  </div>
                </div>
                <InputVerification
                  placeholder="00000"
                  errorSending={errorSending}
                  setErrorSending={setErrorSending}
                  onInput={inputVerificationChange}
                  value={codeValue}
                  refNew={refCode}
                  focus={focus}
                  setFocus={setFocus}
                  autoFocus={true}
                />
                {errorSending && (
                  <div className={styles.descriptionError}>Вы ввели неверный код. Попробуйте еще раз.</div>
                )}
                <div style={{ marginTop: '20px' }}>
                  {timer > 0 ? (
                    <div className={styles.newCodeCounter}>
                      Получить новый код можно <br />
                      через {convertVisualOfTimerCount()}
                    </div>
                  ) : (
                    <div style={{ marginTop: isMobile ? '16px' : '24px', zIndex: '20', position: 'relative' }}>
                      <ButtonFilled
                        text={'Отправить код повторно'}
                        width={'100%'}
                        onClick={() => sendPhone()}
                        disabled={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <FooterAuth />
        </div>
      )}
    </div>
  );
};

export default NewAuthSms;
