import { fetchAuth } from './fetch/fetch-auth';
import { SUBSERVER } from './sub-server.constant';

export const recordToParams = (data: Record<string, any>) => {
  return Object.entries(data)
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(val => `${key}=${encodeURIComponent(val)}`);
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');
};

export const fetchGet = async (url: string, data: any) =>
  fetchAuth(`${SUBSERVER}/api${url}?${recordToParams({ ...data, time: +new Date() })}`, {
    method: 'get',
    credentials: 'include',
  });

export const fetchPost = async (url: string, method: string, data: any) => {
  return fetchAuth(`${SUBSERVER}/api${url}`, {
    method,
    credentials: 'include',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};
export const fetchPostFile = async (url: string, method: string, data: any) => {
  return fetchAuth(`${SUBSERVER}/api${url}`, {
    method,
    credentials: 'include',
    headers: {
      Accept: 'application/json',
    },
    body: data,
  });
};
