import React, { useEffect, useState } from 'react';
import styles from './CreateNewBid.module.scss';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import {
  createNewBid,
  getBids,
  getDetailsList,
  resetFieldsCreateBid,
  selectActiveTab,
  selectMaterialInfo,
  selectValueFieldsCreateBid,
  updateAllFieldsCreateBid,
  updateFieldCreateBid,
  updateTimeFieldCreateBid,
} from '../../AdminBidsSlice';
import { Modal, TimePicker } from 'antd';
import TitleMain from 'src/components/UI/TitleAndSubtitle/TitleMain';
import CustomRangePicker from 'src/components/newUI/CustomRangePicker/CustomRangePicker';
import dayjs from 'dayjs';
import SelectLk from 'src/components/newUI/SelectLk/SelectLk';
import CustomInput from 'src/components/newUI/CustomInput/CustomInput';
import { RadioBoxCircle } from 'src/components/UI/checkBox/RadioBoxCircle';
import ClockTimeEightOutlineSvg from 'src/components/UI/svg-icon/ClockTimeEightOutlineSvg';
import { MyPhoneInput } from 'src/components/UI/inputs/MyPhoneInput';
import InfoSvg from 'src/components/UI/svg-icon/InfoSvg';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import FrameModalSvg from 'src/components/UI/svg-icon/FrameModalSvg';
import SuccessSvgVer2 from 'src/components/UI/svg-icon/SuccessSvgVer2';
import { getTomorrowLocalDate } from '../../../../../common/date-format.helper';
import { IValueFieldsCreateBid, optionsDataMaterials, optionsDataMaterialsFromBack } from '../../types';
import { getMaxBodySize } from '../../../AdminCalculationSupplies/AdminCalculationSuppliesSlice';
import { IOptions } from '../../../AdminTariffsAddPage/type';
import { unitFormatBackToFront } from '../../../../../common/unit-format.helper';
import { IDataOrderId } from '../../../AdminOrdersPage/type';
import { selectCurrentOrder } from '../../../AdminOrdersPage/OrderSlice';
import { useLocation } from 'react-router-dom';
import { regNumber } from 'src/common/number.helper';
import { formVolume } from '../../../../DispatchingPages/Constans/Constans';
import LoadingSpin from '../../../../../components/newUI/LoadingSpin/LoadingSpin';
import { customTimePickerButton } from '../../constants';
interface IProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<boolean>;
  dataProp?: IValueFieldsCreateBid;
  place?: 'order' | 'bids';
}
const CreateNewBid = ({ openModal, setOpenModal, dataProp, place }: IProps) => {
  const { pathname } = useLocation();
  const [step, setStep] = useState<'create' | 'success'>('create');
  const [successIdApp, setSuccessIdApp] = useState<number>(null); //Хранение созданной заявки
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [openLeftTimer, setOpenLeftTimer] = useState(false); //левый TimePicker открытие
  const [openRightTimer, setOpenRightTimer] = useState(false); //правый TimePicker открытие
  const [loadingModal, setLoadingModal] = useState<boolean>(false); //Загрузка при получении данных
  const [optionsSemitrailers, setOptionsSemitrailers] = useState<IOptions[]>(null); //Опции размера кузова
  const [optionsDataMaterials, setOptionsDataMaterials] = useState<optionsDataMaterials[]>(null); //Материалы с их детализацией
  const [currentMaterial, setCurrentMaterial] = useState<optionsDataMaterials>(null); //Текущий выбранный материал
  const valueField: IValueFieldsCreateBid = useAppSelector(selectValueFieldsCreateBid); //Значение полей в нашей модалке
  const dataOrder: IDataOrderId = useAppSelector(selectCurrentOrder); //вся data заказа
  const materialInfo = useAppSelector(selectMaterialInfo);
  const activeTab = useAppSelector(selectActiveTab); // Статус таб в листе заявок
  const orderId: string = pathname.split('/')[2]; //Айди заказа
  const dispatch = useAppDispatch();
  const disableCreateButton = () =>
    //Валидация кнопки создать (если хоть где то есть пустое значение в state, то будет true
    Object.values(valueField).some((value: string) => {
      //В нашем стейте есть объекты, поэтому в них мы также проверяем значение
      if (typeof value === 'object' && value !== null) {
        return Object.values(value).some(objValue => Boolean(objValue) === false);
      }
      return Boolean(value) === false;
    });
  const disableTimePicker = () => {
    if (valueField.time.workingHoursType !== 'daytime') {
      //Сбрасываем наши таймПикеры в 0, когда выбрали что то кроме дневного графика
      dispatch(updateTimeFieldCreateBid({ field: 'to', value: '00:00' }));
      dispatch(updateTimeFieldCreateBid({ field: 'from', value: '00:00' }));
      return true;
    }
    return false;
  };
  const closeModal = () => {
    setOpenModal(false);
  };
  const afterClose = () => {
    //Действие после закрытия модалки
    dispatch(resetFieldsCreateBid());
    setStep('create'); //Возвращаем первый шаг создания
    setSuccessIdApp(null); //Сбрасываем на всякий случай id новосозданной заявки
    //После закрытия на всякий случай зануляем наши состояние опций и текущего материала
    setOptionsSemitrailers(null);
    setOptionsDataMaterials(null);
    setCurrentMaterial(null);
    place === 'bids' &&
      dispatch(
        getBids({
          id: Number(orderId),
          data: {
            status: activeTab,
            materialType: materialInfo?.currentMaterial,
            materialKind: materialInfo.options.find(option => option?.type === materialInfo?.currentMaterial)?.kind,
          },
        }),
      ); //Обновляем заявки, если мы на листе заявок
  };
  const getMaterialDetails = (optionsDataMaterials: optionsDataMaterials[], orderDetailsId: string) => {
    setCurrentMaterial(optionsDataMaterials?.find(material => material?.value === orderDetailsId)); // Находим текущий материал
  };
  const getFetchData = async () => {
    setLoadingModal(true);
    try {
      dataProp && (await dispatch(updateAllFieldsCreateBid(dataProp))); //Данные, которые мы отдали в dataProp, сетаем в наше состояние
      await dispatch(getDetailsList({ orderId: Number(orderId) })) //Получаем опции материала и данные по нему : ед.измер и остаток объема
        .unwrap()
        .then((data: optionsDataMaterialsFromBack[]) => {
          const convertData = data?.map(item => {
            return {
              value: String(item?.id), //Переделываем под value, label для SelectLk
              label: item?.material,
              unit: item?.unit,
              volumeLeft: item?.volumeLeft,
              pricePerUnit: item?.pricePerUnit,
            };
          });
          setOptionsDataMaterials(convertData);
          // Находим текущий материал, если через пропсы мы передали dataProp?.orderDetailsId
          dataProp?.orderDetailsId && getMaterialDetails(convertData, dataProp.orderDetailsId);
        });
      await dispatch(getMaxBodySize())
        .unwrap()
        .then(data => {
          //Получаем размер кузова
          setOptionsSemitrailers(
            data?.map(item => {
              return {
                value: item?.id,
                label: item?.title,
              };
            }),
          );
        });
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingModal(false);
    }
  };
  useEffect(() => {
    if (openModal) {
      getFetchData();
    }
  }, [openModal]);

  return (
    <Modal
      destroyOnClose={true}
      afterClose={afterClose}
      width={1060}
      zIndex={9500}
      footer={null}
      closeIcon={false}
      open={openModal}
      onCancel={() => setOpenModal(false)}
      centered
    >
      {loadingModal ? (
        <LoadingSpin height={'726.67px'} />
      ) : (
        <>
          {/*JSX разметка при создании*/}
          {step === 'create' && (
            <div className={styles.container}>
              {/*Титульник с описанием*/}
              <div className={styles.titleAndDescriptionContainer}>
                <div className={styles.titleContainer}>
                  <TitleMain title={'Создать новую заявку'} fontSize={'24px'} />
                  <div className={styles.planningContainer}>
                    <p className={styles.planningText}>Планирование на</p>
                    <CustomRangePicker
                      placeholder={[getTomorrowLocalDate(), getTomorrowLocalDate()]}
                      width={'240px'}
                      open={openCalendar}
                      dateRange={[
                        valueField.dateFrom ? dayjs(valueField.dateFrom) : null,
                        valueField.dateTo ? dayjs(valueField.dateTo) : null,
                      ]}
                      setDateRange={value => {
                        //Нужно преобразовывать в строку дату, чтобы не было ошибок в редаксе
                        dispatch(updateFieldCreateBid({ field: 'dateFrom', value: value[0].toISOString() }));
                        dispatch(updateFieldCreateBid({ field: 'dateTo', value: value[1].toISOString() }));
                      }}
                      setOpen={setOpenCalendar}
                    />
                  </div>
                </div>
                <div className={styles.descriptionContainer}>
                  К заказу №{orderId}, {dataOrder?.client?.title}, инн: {dataOrder?.client?.inn};{' '}
                  {dataOrder?.objectTitle}, {dataOrder?.address?.address}
                </div>
              </div>
              <div className={styles.inputsContainer}>
                {/*левый блок инпутов*/}
                <div className={styles.sideInputsContainer}>
                  <div className={styles.titleInputContainer}>
                    <h4>
                      Материал <span className={styles.require}>*</span>
                    </h4>
                    <SelectLk
                      setValue={value => {
                        dispatch(updateFieldCreateBid({ field: 'orderDetailsId', value }));
                        getMaterialDetails(optionsDataMaterials, value);
                      }}
                      value={valueField.orderDetailsId}
                      options={optionsDataMaterials}
                      placeholder={'Щебень гравийный'}
                    />
                  </div>
                  <div className={styles.volumeAndUnitContainer}>
                    <div className={styles.volumeAndUnitInputsContainer}>
                      <div style={{ width: '85%' }} className={styles.titleInputContainer}>
                        <h4>
                          Объем материала <span className={styles.require}>*</span>
                        </h4>
                        <CustomInput
                          clearButton={true}
                          suffix={'тн'}
                          setValue={value => {
                            if ((regNumber.test(value) && value.length <= 6) || value === '') {
                              dispatch(updateFieldCreateBid({ field: 'volumePlan', value }));
                            }
                          }}
                          value={valueField.volumePlan}
                          placeholder={'0 тн'}
                        />
                      </div>
                      <div style={{ width: '15%' }} className={styles.titleInputContainer}>
                        <h4>Ед.изм.</h4>
                        <CustomInput
                          value={unitFormatBackToFront(currentMaterial?.unit)}
                          placeholder={''}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className={styles.remainVolume}>
                      <p className={styles.softText}>Оставшийся объем по материалу: </p>
                      {currentMaterial && ( //Выводим объем, если есть материал
                        <p className={styles.normalText}>
                          {formVolume(currentMaterial?.volumeLeft)} {currentMaterial?.unit}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={styles.titleInputContainer}>
                    <h4>
                      Максимальный размер кузова <span className={styles.require}>*</span>
                    </h4>
                    <SelectLk
                      setValue={value => dispatch(updateFieldCreateBid({ field: 'semitrailerId', value }))}
                      value={valueField.semitrailerId}
                      placeholder={'Выберите из списка'}
                      options={optionsSemitrailers}
                    />
                  </div>
                  <div style={{ gap: '16px' }} className={styles.titleInputContainer}>
                    <h4>
                      График приемки <span className={styles.require}>*</span>
                    </h4>
                    <div className={styles.radioContainer}>
                      <RadioBoxCircle
                        onInput={e =>
                          dispatch(updateTimeFieldCreateBid({ field: 'workingHoursType', value: 'aroundTheClock' }))
                        }
                        isSmall={true}
                        check={valueField.time.workingHoursType === 'aroundTheClock'}
                        name={'optionFieldRadio'}
                        id={'1'}
                        value={'Круглосуточная'}
                      />
                      <RadioBoxCircle
                        onInput={e =>
                          dispatch(updateTimeFieldCreateBid({ field: 'workingHoursType', value: 'daytime' }))
                        }
                        isSmall={true}
                        check={valueField.time.workingHoursType === 'daytime'}
                        name={'optionFieldRadio'}
                        id={'2'}
                        value={'Дневная'}
                      />
                    </div>
                    <div className={styles.datePickers}>
                      <TimePicker
                        suffixIcon={<ClockTimeEightOutlineSvg />}
                        value={valueField.time.from ? dayjs(valueField.time.from, 'HH:mm') : null}
                        onOpenChange={isOpen => {
                          if (!isOpen) {
                            setOpenLeftTimer(false); // Закрываем при клике снаружи
                          }
                        }}
                        style={{ width: '100%', height: '48px' }}
                        showNow={false}
                        needConfirm={false}
                        placeholder="Период времени"
                        format="HH:mm"
                        onChange={(_, value) =>
                          dispatch(updateTimeFieldCreateBid({ field: 'from', value: String(value) }))
                        }
                        disabled={disableTimePicker()}
                        open={openLeftTimer}
                        onClick={() => setOpenLeftTimer(true)}
                        renderExtraFooter={() => customTimePickerButton(true, setOpenLeftTimer, setOpenRightTimer)}
                      />
                      <p>-</p>
                      <TimePicker
                        suffixIcon={<ClockTimeEightOutlineSvg />}
                        value={valueField.time.to ? dayjs(valueField.time.to, 'HH:mm') : null}
                        onOpenChange={isOpen => {
                          if (!isOpen) {
                            setOpenRightTimer(false); // Закрываем при клике снаружи
                          }
                        }}
                        style={{ width: '100%', height: '48px' }}
                        showNow={false}
                        needConfirm={false}
                        placeholder={'Период времени'}
                        format={'HH:mm'}
                        onChange={(_, value) =>
                          dispatch(updateTimeFieldCreateBid({ field: 'to', value: String(value) }))
                        }
                        disabled={disableTimePicker()}
                        open={openRightTimer}
                        onClick={() => setOpenRightTimer(true)}
                        renderExtraFooter={() => customTimePickerButton(false, setOpenLeftTimer, setOpenRightTimer)}
                      />
                    </div>
                  </div>
                </div>
                {/*правый блок инпутов*/}
                <div className={styles.sideInputsContainer}>
                  <div className={styles.titleInputContainer}>
                    <h4>
                      Контакты диспетчера <span className={styles.require}>*</span>
                    </h4>
                    <CustomInput
                      setValue={value => {
                        if (value.length <= 255) {
                          dispatch(updateFieldCreateBid({ field: 'dispatcherName', value }));
                        }
                      }}
                      clearButton={true}
                      value={valueField.dispatcherName}
                      placeholder={'Фамилия Имя Отчество'}
                      disabled={false}
                    />
                    <MyPhoneInput
                      value={valueField.dispatcherPhone}
                      placeholder={'+7 (9 _ _) _ _ _ - _ _ - _ _'}
                      disabled={false}
                      onChange={value => dispatch(updateFieldCreateBid({ field: 'dispatcherPhone', value }))}
                      isActive={undefined}
                      btnHelp={undefined}
                      setIsActive={undefined}
                      isError={false}
                      setIsError={false}
                      isFunction={undefined}
                      isLabel={false}
                      clearButton={true}
                    />
                  </div>
                  <div className={styles.titleInputContainer}>
                    <h4>
                      Контакты на объекте <span className={styles.require}>*</span>
                    </h4>
                    <CustomInput
                      setValue={value => {
                        if (value.length <= 255) {
                          dispatch(updateFieldCreateBid({ field: 'contactPersonName', value }));
                        }
                      }}
                      clearButton={true}
                      value={valueField.contactPersonName}
                      placeholder={'Фамилия Имя Отчество'}
                      disabled={false}
                    />
                    <MyPhoneInput
                      value={valueField.contactPersonPhone}
                      placeholder={'+7 (9 _ _) _ _ _ - _ _ - _ _'}
                      disabled={false}
                      onChange={value => dispatch(updateFieldCreateBid({ field: 'contactPersonPhone', value }))}
                      isActive={undefined}
                      btnHelp={undefined}
                      setIsActive={undefined}
                      isError={false}
                      setIsError={false}
                      isFunction={undefined}
                      isLabel={false}
                      clearButton={true}
                    />
                  </div>
                </div>
              </div>
              {/*инфоКонтейнер*/}
              <div className={styles.infoContainer}>
                <p>Стоимость заявки будет рассчитана автоматически</p>
                <div className={styles.svgContainer}>
                  <InfoSvg color={'#1C274C'} />
                </div>
              </div>
              {/*Кнопки*/}
              <div className={styles.buttonsContainer}>
                <ButtonNotFilled onClick={closeModal} text={'Отмена'} />
                <ButtonFilled
                  onClick={() =>
                    dispatch(createNewBid())
                      .unwrap()
                      .then(data => {
                        setStep('success');
                        setSuccessIdApp(data?.id);
                      })
                  }
                  disabled={disableCreateButton()}
                  text={'Создать'}
                />
              </div>
            </div>
          )}
          {/*JSX разметка при удачном создании*/}
          {step === 'success' && (
            <div className={styles.successContainer}>
              <div className={styles.successContent}>
                <div className={styles.successSvgContainer}>
                  <SuccessSvgVer2 />
                </div>
                <div className={styles.successTextContainer}>
                  <p className={styles.successThickText}>Заявка № {successIdApp} успешно создана</p>
                  <p className={styles.successSoftText}>
                    Они появятся в общем списке заявок к заказу {orderId} после подтверждения
                  </p>
                </div>
                <ButtonFilled width={'100%'} text={'Готово'} onClick={closeModal} />
              </div>
            </div>
          )}
        </>
      )}

      <div className={styles.closeButton}>
        <FrameModalSvg onClick={closeModal} />
      </div>
    </Modal>
  );
};

export default CreateNewBid;
