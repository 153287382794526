// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowMore_container__U0uBv {\n  position: relative;\n}\n\n.ShowMore_svgContainer__sca3J {\n  cursor: pointer;\n}\n\n.ShowMore_menu__P\\+Nc5 {\n  position: absolute;\n  top: 32px;\n  right: -32px;\n  width: 290px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 4px;\n  background: var(--color-white);\n  border: 1px solid var(--color-gray200);\n  border-radius: 8px;\n  box-shadow: var(--shadow-medium);\n  z-index: 10;\n}\n\n.ShowMore_option__7IDzW {\n  padding: 12px;\n  font-family: LiberationSans;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: var(--color-gray800);\n  cursor: pointer;\n}\n\n@media (max-width: 768px) {\n  .ShowMore_menu__P\\+Nc5 {\n    right: 0px;\n    width: 260px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminUsersPage/components/ShowMore/ShowMore.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,QAAA;EACA,8BAAA;EACA,sCAAA;EACA,kBAAA;EACA,gCAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;EACA,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA;EACA,eAAA;AACF;;AAEA;EACE;IACE,UAAA;IACA,YAAA;EACF;AACF","sourcesContent":[".container {\n  position: relative;\n}\n\n.svgContainer {\n  cursor: pointer;\n}\n\n.menu {\n  position: absolute;\n  top: 32px;\n  right: -32px;\n  width: 290px;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 4px;\n  background: var(--color-white);\n  border: 1px solid var(--color-gray200);\n  border-radius: 8px;\n  box-shadow: var(--shadow-medium);\n  z-index: 10;\n}\n\n.option {\n  padding: 12px;\n  font-family: LiberationSans;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  color: var(--color-gray800);\n  cursor: pointer;\n}\n\n@media (max-width: 768px) {\n  .menu {\n    right: 0px;\n    width: 260px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ShowMore_container__U0uBv",
	"svgContainer": "ShowMore_svgContainer__sca3J",
	"menu": "ShowMore_menu__P+Nc5",
	"option": "ShowMore_option__7IDzW"
};
export default ___CSS_LOADER_EXPORT___;
