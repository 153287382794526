import { addPropertyName, propertySize, typeOfValue } from '../../AdminCalculationSupplies/type';
import { ICreateOrderProperties, orderPropertyName } from '../type';
import { scheduleProperties, typeScheduleProperties } from '../../AdminCalculationSupplies/const';
import { PaymentProperties } from '../../../ShowcasesPage/constans';

export const initCalculationCreatedFields: ICreateOrderProperties[] = [
  {
    id: 1,
    name: orderPropertyName.materialTitle,
    title: 'Наименование материала для спецификации клиента',
    isRequired: false,
    fieldType: 'input',
    fieldMask: `^.{0,500}$`,
    value: '',
    placeholder: 'Введите наименование',
    width: '860px',
    isDisabled: false,
  },
  {
    id: 2,
    name: orderPropertyName.clientPricePerUnit,
    title: 'Стоимость поставки за ед',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: '0 ₽',
    width: '418px',
    isDisabled: false,
  },
  {
    id: 3,
    name: orderPropertyName.volume,
    title: 'Объем поставки',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: 'Введите значение',
    width: '418px',
    isDisabled: false,
  },
  {
    id: 4,
    name: orderPropertyName.margin,
    title: 'Плановая маржа, %',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: 'Введите значение',
    width: '418px',
    isDisabled: false,
  },
  {
    id: 5,
    name: orderPropertyName.clientTitle,
    title: 'Покупатель',
    isRequired: true,
    isSearch: true,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Выберите из списка',
    width: '307.5px',
    isDisabled: false,
  },
  {
    fieldMask: '',
    id: 6,
    name: orderPropertyName.clientContract,
    title: 'Договор',
    isRequired: true,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Выберите из списка',
    width: '307.5px',
    isDisabled: true,
  },
  {
    id: 7,
    name: orderPropertyName.addToOrder,
    title: 'Добавить в существующий заказ',
    isRequired: false,
    fieldType: 'select',
    value: '',
    properties: [],
    placeholder: 'Выберите из списка',
    width: '307.5px',
    isDisabled: true,
  },
  {
    fieldMask: '',
    id: 8,
    name: orderPropertyName.paymentProcess,
    title: 'Способ оплаты',
    isRequired: true,
    fieldType: 'select',
    value: '',
    properties: PaymentProperties,
    placeholder: 'Выберите из списка',
    width: '307.5px',
    isDisabled: false,
  },
  {
    id: 9,
    name: orderPropertyName.object,
    title: 'Наименование объекта',
    isRequired: true,
    fieldType: 'input',
    value: 'Объект №300',
    placeholder: '',
    width: '418px',
    isDisabled: false,
  },
  {
    id: 10,
    name: orderPropertyName.address,
    title: 'Адрес',
    isRequired: false,
    fieldType: 'text',
    value: '',
    placeholder: '',
    width: '418px',
    isDisabled: false,
  },
  {
    id: 11,
    name: orderPropertyName.coordinates,
    title: 'Координаты',
    isRequired: false,
    fieldType: 'text',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: false,
  },
  {
    id: 12,
    name: orderPropertyName.workSchedule,
    title: 'График приемки',
    isRequired: true,
    fieldType: 'radio',
    value: { value: '', label: '' },
    properties: scheduleProperties,
    width: '133px',
    isDisabled: false,
    typeOfValue: typeOfValue.STRING,
  },
  {
    id: 13,
    name: orderPropertyName.workScheduleTimeLeft,
    title: '',
    isRequired: false,
    fieldType: 'time',
    value: '',
    width: '133px',
    isDisabled: false,
    typeOfValue: typeOfValue.ANY,
  },
  {
    id: 14,
    name: orderPropertyName.workScheduleTimeRight,
    title: '',
    isRequired: false,
    fieldType: 'time',
    value: '',
    width: '133px',
    isDisabled: false,
    typeOfValue: typeOfValue.ANY,
  },
  {
    id: 15,
    name: orderPropertyName.typeSchedule,
    title: 'Вид приемки',
    isRequired: true,
    fieldType: 'radio',
    value: { value: '', label: '' },
    properties: typeScheduleProperties,
    width: '133px',
    isDisabled: false,
    typeOfValue: typeOfValue.STRING,
  },
  {
    id: 16,
    name: orderPropertyName.semitrailerTitle,
    title: 'Максимальный размер кузова',
    isRequired: false,
    fieldType: 'text',
    value: '',
    placeholder: '',
    width: '412px',
    isDisabled: false,
  },

  {
    id: 17,
    name: orderPropertyName.clientName,
    title: 'ФИО контактного лица на объекте',
    isRequired: true,
    fieldType: 'input',
    value: '',
    placeholder: '',
    width: '639px',
    isDisabled: false,
  },
  {
    id: 18,
    name: orderPropertyName.clientPhone,
    title: 'Телефон контактного лица на объекте',
    isRequired: true,
    fieldType: 'phone',
    value: '',
    placeholder: '',
    width: '639px',
    isDisabled: false,
  },
  {
    id: 19,
    name: orderPropertyName.comment,
    title: 'Комментарий',
    isRequired: false,
    fieldType: 'textArea',
    value: '',
    placeholder: 'Примечания к заказу',
    width: '1302px',
    isDisabled: false,
  },

  {
    id: 20,
    name: orderPropertyName.volumeCheckbox,
    title: 'Нельзя превышать объем поставки',
    isRequired: false,
    fieldType: 'checkbox',
    value: false,
    placeholder: '',
    width: '',
    isDisabled: false,
    size: propertySize.MEDIUM,
  },
  {
    id: 21,
    name: orderPropertyName.nomenclatureCheckbox,
    title: 'Можно улучшать номенклатуру',
    isRequired: false,
    fieldType: 'checkbox',
    value: false,
    placeholder: '',
    width: '',
    isDisabled: false,
    size: propertySize.MEDIUM,
  },
  {
    id: 22,
    name: orderPropertyName.specificationCheckbox,
    title: 'Спецификация по форме клиента',
    isRequired: false,
    fieldType: 'checkbox',
    value: false,
    placeholder: '',
    width: '',
    isDisabled: false,
    size: propertySize.MEDIUM,
  },
  {
    id: 23,
    name: orderPropertyName.anyQuarryCheckbox,
    title: 'Поставка из любого карьера',
    isRequired: false,
    fieldType: 'checkbox',
    value: false,
    placeholder: '',
    width: '',
    isDisabled: false,
    size: propertySize.MEDIUM,
  },
];
