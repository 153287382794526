import { ISelect } from '../DispatchingPages/types';

export interface IEmployees {
  name: string;
  patronymic?: string;
  surname: string;
  profile: {
    email: string;
  };
  fullName?: string;
  id?: number;
  phone: string;
  type?: ISelect | string | any;
}
export interface IRequestGetEmployeesOptions {
  searchString: string | undefined;
  limit: number;
  offset: number;
}

export interface IRequestAddEmployeeOptions {
  name?: string;
  patronymic?: string;
  surname?: string;
  email?: string;
  type?: string;
  phone: string;
}
export interface IRequestEditEmployeesOptions {
  id: number;
  parameters: IRequestAddEmployeeOptions;
}

export enum employeesTypes {
  'accountant' = 'Бухгалтер',
  'manager' = 'Менеджер',
  'admin' = 'Администратор',
}

export enum employeeParameters {
  PHONE = 'phone',
  EMAIL = 'email',
  SURNAME = 'surname',
  NAME = 'name',
  PATRONYMIC = 'patronymic',
  TYPE = 'type',
}
