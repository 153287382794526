import React from 'react';
import styles from './GSMCardMainPage.module.scss';
import { WalletGSMSvg } from 'src/components/UI/svg-icon/WalletGSMSvg';
import { formCardNumber } from 'src/common/formCardNumber.helper';
import { ArrowRightSvg } from 'src/components/UI/svg-icon/ArrowRightSvg';
import { GSMLineSvg } from './GSMLineSvg';
import { useNavigate } from 'react-router-dom';
import { TCardInfo } from 'src/Pages/GSMNewPage/GSMtypes';
import cx from 'classnames';
import { formPrice } from 'src/common/form-price.helper';

export const GSMCardMainPage = ({ cardInfo }: { cardInfo: TCardInfo }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <span className={styles.title}>Карта ГСМ</span>
      <div className={styles.topContainer}>
        <span className={styles.cardNumber}>{formCardNumber(cardInfo?.cardNumber)}</span>
        <div
          className={cx(styles.statusContainer, cardInfo?.status !== 'Активна' ? styles.inactiveStatusContainer : '')}
        >
          {cardInfo?.status}
        </div>
      </div>
      <div className={styles.priceMainContainer}>
        <div className={styles.priceContainer}>
          <div style={{ paddingTop: '4px' }}>
            <WalletGSMSvg />
          </div>
          <span className={styles.price}>{formPrice(Number(cardInfo?.limit?.current) || 0, 'RUB', 'ru')}</span>
        </div>
        <div className={styles.arrowContainer} onClick={() => navigate('/gsm')}>
          <ArrowRightSvg size="24px" />
        </div>
        <div className={styles.svgContainer}>
          <GSMLineSvg />
        </div>
      </div>
    </div>
  );
};
