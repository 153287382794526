import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header/Header';
import { Container } from '../components/Container';
import { useMediaQuery } from 'react-responsive';
import { ButtonClose } from '../components/UI/buttons/buttonClose';
import { Context } from '../context/context';
import NewAsideNavSupplier from '../components/asideNav/NewAsideNavSupplier';
import NewAsideNavSupplierMobile from '../components/asideNav/NewAsideNavSupplierMobile';
import SupportSvg from '../components/UI/svg-icon/SupportSvg';
import FooterLk from '../components/newUI/FooterLk/FooterLk';
import Banner from '../components/Banner/Banner';
import { typeBanner } from '../components/Banner/type';

export const LayoutSupplier = () => {
  const { openDescription, setOpenDescription, helpTitle, helpDescription } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <>
      {!isMobile && <Header />}
      <Banner type={typeBanner.MAIN} />
      {isMobile ? (
        <div id="Page" className="Page">
          <div className={openDescription ? 'Page__wrapper blur' : 'Page__wrapper'}>
            <Outlet />
          </div>
          <NewAsideNavSupplierMobile />
          <div
            onClick={() => setOpenDescription(false)}
            className={openDescription ? 'PopUpHelp active' : 'PopUpHelp'}
          ></div>
          <div className={openDescription ? 'PopUpHelp__content active' : 'PopUpHelp__content'}>
            <ButtonClose
              onClick={() => setOpenDescription(false)}
              hidden={openDescription ? 'active' : 'hidden'}
              style={{ zIndex: '1200', position: 'absolute', top: '6px', right: '20px' }}
            />
            <div className="PopUpHelp__title">{helpTitle}</div>
            <div className="PopUpHelp__description">{helpDescription}</div>
          </div>
          <FooterLk />
        </div>
      ) : (
        <div className="Page">
          <Container>
            <div className={openDescription ? 'Page__wrapper blur' : 'Page__wrapper'}>
              <div className="Aside">
                <NewAsideNavSupplier />
              </div>
              {/*<a href='https://project9474365.tilda.ws/seller'>
                <div className="btnHelpSupport">
                  <SupportSvg/>
                </div>
              </a>*/}
              <div className="content">
                <Outlet />
              </div>
            </div>
          </Container>
          <div
            onClick={() => setOpenDescription(false)}
            className={openDescription ? 'PopUpHelp active' : 'PopUpHelp'}
          ></div>
          <div className={openDescription ? 'PopUpHelp__content active' : 'PopUpHelp__content'}>
            <ButtonClose
              onClick={() => setOpenDescription(false)}
              hidden={openDescription ? 'active' : 'hidden'}
              style={{ zIndex: '1200', position: 'absolute', top: '64px', left: '-112px' }}
            />
            <div className="PopUpHelp__title">{helpTitle}</div>
            <div className="PopUpHelp__description">{helpDescription}</div>
          </div>
          <FooterLk />
        </div>
      )}
    </>
  );
};
