import React from 'react';

const TelegramLogoSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.16486 10.968C3.16486 10.968 11.5681 7.45574 14.4825 6.21897C15.5997 5.72431 19.3884 4.14124 19.3884 4.14124C19.3884 4.14124 21.1371 3.44871 20.9914 5.13064C20.9428 5.82324 20.5542 8.2472 20.1656 10.8691C19.5827 14.5793 18.9513 18.6358 18.9513 18.6358C18.9513 18.6358 18.8541 19.7736 18.0284 19.9715C17.2026 20.1694 15.8425 19.279 15.5997 19.081C15.4054 18.9327 11.9567 16.7065 10.6938 15.6182C10.3537 15.3214 9.96517 14.7278 10.7423 14.0352C12.491 12.4027 14.5796 10.3744 15.8425 9.08824C16.4255 8.49457 17.0083 7.10944 14.5796 8.79137C11.1309 11.2154 7.73078 13.491 7.73078 13.491C7.73078 13.491 6.95358 13.9857 5.49639 13.5404C4.03913 13.0953 2.33905 12.5016 2.33905 12.5016C2.33905 12.5016 1.17336 11.7596 3.16486 10.968Z"
        fill="#9BA1B4"
      />
    </svg>
  );
};

export default TelegramLogoSvg;
