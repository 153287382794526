import { Dayjs } from 'dayjs';
import { IOrderSortBox } from '../AdminOrdersPage/type';

export interface IBid {
  id: number;
  deliveryDate: string;
  carrierCount: number;
  client: {
    id: number;
    title: string;
    inn: string;
  };
  quarry: string;
  distance: number;
  status: {
    id: number;
    title: string;
  };
  material: string;
  volume: {
    factVolume: number;
    planVolume: number;
    unit: string;
  };
}

export interface IMaterialListOptions {
  kind: string;
  type: string;
}

export interface IInitStateSlicer {
  bidsTableInfo: IBid[];
  bidsMainPageList: IBidMainPage[];
  totalCount: number;
  performersInfo: IPerformer;
  flightsInfo: IBidFlight[];
  activeTab: tabs;
  currentBidActiveTab: bidTabs;
  materialInfo: {
    currentMaterial: string;
    options: IMaterialListOptions[];
  };
  valueFieldsCreateBid: IValueFieldsCreateBid;
  valueFieldsExecutor: IValueFieldsCreateExecutor;
  loading: boolean;
}

export enum tabs {
  active = 'Активные',
  completed = 'Завершенные',
  all = 'Все',
}

export enum bidTabs {
  performers = 'Исполнители',
  flights = 'Рейсы',
}

export enum bidsInfoTitles {
  client = 'Клиент',
  inn = 'ИНН',
  paymentMethod = 'Способ оплаты',
  contract = 'Договор',
  objectTitle = 'Объект',
  acceptance = 'Вид приемки',
}

export enum bidsStatuses {
  active = 'Активная',
  new = 'Новая',
  rejected = 'Отменена',
  await = 'Ожидает',
  done = 'Выполнена',
}

export enum performersStatuses {
  new = 'Новый',
  approved = 'Подтвержденный',
  active = 'Активный',
  rejected = 'Отклонен',
  cancelled = 'Отменен',
  await = 'Ожидает',
}

export enum flightsStatuses {
  new = 'Новый',
  approved = 'Подтвержденный',
  active = 'Активный',
  rejected = 'Отклонен',
  cancelled = 'Отменен',
  await = 'Ожидает',
}
export interface ITypeOfTab {
  status: tabs | bidTabs;
  materialType?: string;
  materialKind?: string;
}
export interface IValueFieldsCreateBid {
  orderDetailsId?: string;
  volumePlan?: string;
  semitrailerId?: string;
  time?: { workingHoursType: string; from?: string; to?: string };
  dispatcherName?: string;
  dispatcherPhone?: string;
  contactPersonName?: string;
  contactPersonPhone?: string;
  dateFrom?: string;
  dateTo?: string;
}

export interface IValueFieldsCreateAppForBackend {
  orderDetailsId?: number;
  volumePlan?: number;
  semitrailerId?: number;
  time?: { workingHoursType: string; from?: string; to?: string };
  dispatcherName?: string;
  dispatcherPhone?: string;
  contactPersonName?: string;
  contactPersonPhone?: string;
  dateFrom?: string;
  dateTo?: string;
}

export interface optionsDataMaterials {
  value: string;
  label: string;
  unit: string;
  volumeLeft: number;
  pricePerUnit: number;
}

export interface optionsDataMaterialsFromBack {
  id: number;
  material: string;
  unit: string;
  volumeLeft: number;
  pricePerUnit: number;
}

export interface IExecutor {
  quarry: {
    id: number;
    title: string;
  };
  carrier: {
    id: number;
    title: string;
  };
  id: number;
  margin: number;
  volume: number;
  distance: number;
  factVolume: number;
  requestPercent: number;
  status?: performersStatuses;
  checked?: boolean;
  unit: string;
}
export interface IPerformer {
  id: number;
  unit: string;
  orderVolume: number;
  orderFactVolume: number;
  material: string;
  client: {
    id: number;
    title: string;
    legalAddress: string;
    inn: string;
  };
  executors: IExecutor[];
}

export interface IBidFlight {
  id: number;
  flightName: string;
  volume: number;
  unit: string;
  // status: flightsStatuses; // скрыто, пока бек не определится со статусами
  status: string;
  supplier: {
    id: number;
    inn: string;
  };
  quarry: {
    id: number;
    title: string;
    name: string;
    inn: string;
  };
  carrier: {
    id: number;
    title: string;
    inn: string;
  };
  driver: {
    id: number;
    fullName: string;
  };
  car: {
    id: number;
    stateNumber: string;
  };
}

export interface IGetFlightsRequest {
  deliveryRequestId: number;
}

export interface IMainFiltersBids {
  searchString: string;
  sort: IOrderSortBox;
}
export interface ISubFiltersBids {
  dateFrom: string | Dayjs;
  dateTo: string | Dayjs;
  orderId: string;
  managerId: string;
  materialTypeId: string;
  status: string;
}
export enum infoDetailType {
  NUMBER = 'number',
  ORDER_BID = 'orderAndBid',
  NORMAL = 'normal',
}
export enum infoDetailName {
  ORDER = 'order',
  BID = 'bid',
  DATE_ORDER = 'dateOrder',
  SHIPPER = 'shipper',
  QUARRY = 'quarry',
  SHIPMENT = 'shipment',
  WORK_SCHEDULE = 'workSchedule',
  DISPATCHER = 'dispatcher',
}
export interface IInfoDetail {
  name: infoDetailName;
  label: string;
  value: string;
  type: infoDetailType;
}
export interface IParametersRerouting {
  client: string;
  material: string;
  kindMaterial: string;
}

export interface IBidsListRequest {
  limit: number;
  offset: number;
  sort?: string;
  dateFrom?: string;
  dateTo?: string;
  managerId?: number;
  orderId?: number;
  orderStatus?: string;
  materialTypeId?: number;
  searchString?: string;
}

export interface IBidMainPage {
  id: number;
  dateFrom: string;
  client: {
    id: number;
    title: string;
    inn: string;
  };
  manager: {
    id: number;
    title: string;
  };
  status: {
    id: number;
    title: string;
  };
  volume: number;
  quarry: { id: number; title: string };
  material: string;
  unit: string;
}

export interface IBidMainPageFromBack {
  meta: {
    arg: {
      limit: number;
      offset: number;
    };
  };
  payload: { count: number; rows: IBidMainPage[] };
  type: string;
}

export interface IPostPerformersList {
  deliveryRequestId: number;
  acceptExecutorDetails: {
    deliveryRequestDetailsId: number;
    distance: number;
    volume: number;
  }[];
}
export interface IValueFieldsCreateExecutor {
  material?: string;
  quarryId?: string;
  remainBid?: string;
  carrierId?: string;
  percent?: string;
  volume?: string;
  distance?: string;
  cost?: string;
  freeCars?: string;
  unit?: string;
  message?: string;
  pass?: boolean;
}
export interface IValueFieldsCreateExecutorForBack {
  deliveryRequestId: number;
  carrierId: number;
  quarryId: number;
  distance: number;
  volume: number;
}

export interface IOptionsQuarryDelivery {
  value: string;
  label: string;
  distanceToObject: string;
}
export interface IOptionsQuarryDeliveryFromBack {
  id: number;
  title: string;
  distanceToObject: number;
}
