import React, { useRef, useState } from 'react';
import styles from './MoreInfoMaterialModal.module.scss';
import useClickOutsideNode from '../../../../../CustomHooks/useClickOutsideNode';
import EyeScanSvg from '../../../../../components/UI/svg-icon/EyeScanSvg';
interface IProps {
  textMaterial: string;
}
const MoreInfoMaterialModal = ({ textMaterial }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const infoContainerRef = useRef(null);
  useClickOutsideNode(infoContainerRef, setOpen);

  return (
    <div onClick={e => e.stopPropagation()} className={styles.container} ref={infoContainerRef}>
      <div onClick={() => setOpen(!open)}>
        <EyeScanSvg />
      </div>
      {open && (
        <div className={styles.modal}>
          <div className={styles.textModal}>{textMaterial}</div>
        </div>
      )}
    </div>
  );
};

export default MoreInfoMaterialModal;
