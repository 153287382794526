// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminQuarriesPage_container__KkpO2 {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.AdminQuarriesPage_titleAndSubtitleContainer__ePynm {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.AdminQuarriesPage_inputsAndSelectorsContainer__3XIu2 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n}\n\n.AdminQuarriesPage_customTableContainer__Jouzg {\n  padding: 8px;\n  border-radius: 12px;\n  background: var(--color-blue50);\n}\n\n@media (max-width: 768px) {\n  .AdminQuarriesPage_container__KkpO2 {\n    gap: 24px;\n  }\n  .AdminQuarriesPage_titleAndSubtitleContainer__ePynm {\n    gap: 4px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminQuarriesPage/AdminQuarriesPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;EACA,+BAAA;AACF;;AAEA;EACE;IACE,SAAA;EACF;EAEA;IACE,QAAA;EAAF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.titleAndSubtitleContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.inputsAndSelectorsContainer {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 16px;\n}\n\n.customTableContainer {\n  padding: 8px;\n  border-radius: 12px;\n  background: var(--color-blue50);\n}\n\n@media (max-width: 768px) {\n  .container {\n    gap: 24px;\n  }\n\n  .titleAndSubtitleContainer {\n    gap: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AdminQuarriesPage_container__KkpO2",
	"titleAndSubtitleContainer": "AdminQuarriesPage_titleAndSubtitleContainer__ePynm",
	"inputsAndSelectorsContainer": "AdminQuarriesPage_inputsAndSelectorsContainer__3XIu2",
	"customTableContainer": "AdminQuarriesPage_customTableContainer__Jouzg"
};
export default ___CSS_LOADER_EXPORT___;
