import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../../components/PageWrapper';
import styles from './MainPageDriver.module.scss';
import TodayEarnings from './Components/TodayEarnings/TodayEarnings';
import { fetchGet } from '../../../common/proxy-1C-fetch-auth';
import { ISalary } from './tipe';
import { CurrentFlight } from './Components/CurrentFlight/CurrentFlight';
import TitleAndSubTitleMain from '../../../components/UI/TitleAndSubtitle/TitleAndSubTitleMain';
import { GSMCardMainPage } from './Components/GSMCardMainPage/GSMCardMainPage';
import { TCardInfo } from 'src/Pages/GSMNewPage/GSMtypes';

const MainPageDriver = () => {
  const [todaySalary, setTodaySalary] = useState<ISalary>();
  const [monthsSalary, setMonthsSalary] = useState<any>();
  const [currentFlight, setCurrentFlight] = useState<any>();
  const [cardInfo, setCardInfo] = useState<TCardInfo | null>(null);
  const getCurrentFlight = async () => {
    try {
      const response = await fetchGet('/flights/driver/active', {});
      await setCurrentFlight(response?.response);
    } catch (e) {
      console.log(e);
    }
  };
  const getSalary = async () => {
    try {
      const response = await fetchGet('/salary', {});
      await setTodaySalary(response?.response);
    } catch (e) {
      console.log(e);
    }
  };
  const getMonthsSalary = async () => {
    try {
      const response = await fetchGet('/salary/details/months', {});
      await setMonthsSalary(response?.response);
    } catch (e) {
      console.log(e);
    }
  };
  const getCardInfo = async () => {
    try {
      const response = await fetchGet('/fuel-cards/driver', {});
      if (response?.error || !response?.response) return;
      const cardData = response?.response;
      setCardInfo({
        id: cardData?.id,
        cardNumber: cardData?.cardNumber,
        status: cardData?.status,
        limit: {
          id: cardData?.limit?.id,
          current: cardData?.limit?.current,
        },
        owner: cardData?.owner,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const getPercent = () => {
    return Math.round((todaySalary?.salaryFromMonthStart?.driversSalary / monthsSalary?.[1]?.driversSalary) * 100);
  };

  useEffect(() => {
    getCurrentFlight().catch(e => console.log(e));
    getSalary().catch(e => console.log(e));
    getMonthsSalary().catch(e => console.log(e));
    getCardInfo().catch(e => console.log(e));
  }, []);
  return (
    <>
      <PageWrapper>
        <>
          <TitleAndSubTitleMain title={'Главная'} subTitle={''} />
          <div className={styles.contentContainer}>
            <div className={styles.firstBlockContent}>
              <TodayEarnings todaySalary={todaySalary} />
              {cardInfo && <GSMCardMainPage cardInfo={cardInfo} />}
            </div>
            <CurrentFlight currentFlight={currentFlight} />
          </div>
        </>
      </PageWrapper>
    </>
  );
};

export default MainPageDriver;
