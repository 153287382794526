import React, { useEffect, useState } from 'react';
import styles from './AdminCalculationAddPage.module.scss';
import { PageWrapper } from '../../../components/PageWrapper';
import TitleMain from '../../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import {
  changeCalculationDetailsInfo,
  changeCheckedIndex,
  clearCalculationMaterialFields,
  clearCalculationOptionsFields,
  createNewCalculation, getClosestWeightBridgesCoordinates, getDetailRouteCoordinate,
  handleChangeMaterialValue,
  handleChangeValue,
  selectCalculationDetails,
  selectCalculationOptions,
  selectCheckedCalculationDetailsIndex, selectClosestWeightBridgesCoordinates, selectDetailRouteCoordinate,
  selectMaterialKindFields,
  selectOptionsMaterials,
} from '../AdminCalculationSupplies/AdminCalculationSuppliesSlice';
import { OptionField } from './components/OptionField/OptionField';
import {
  addPropertyName,
  ICalculationAddProperties,
  ICalculationDetails,
  IMaterialsOptionsForAdmin,
  INewCalculationRequest,
  typeOfValue,
} from '../AdminCalculationSupplies/type';
import CalculationDetailsList from '../AdminCalculationSupplies/components/CalculationDetailsList/CalculationDetailsList';
import { dataTableCalculationVariants } from '../AdminCalculationSupplies/const';
import CustomTable from 'src/components/newUI/CustomTable/CustomTable';
import BackButton from 'src/components/newUI/BackButton/BackButton';
import { shallowEqual } from 'react-redux';
import { useOptionsChangeCalculation } from 'src/CustomHooks/useOptionsChangeCalculation';
import dayjs from 'dayjs';
import { EmptyList } from 'src/components/EmptyList';
import { prepareDetailsList } from '../AdminCalculationSupplies/utils';
import CustomYMap from '../../../components/CustomYMap/CustomYMap';
import { ModalNew } from 'src/components/ModalNew/ModalNew';
import { toast } from 'react-toastify';
import { formPriceOnlyNumber } from '../../../common/form-price.helper';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import CustomYMapForCalculation from "./components/CustomYMapForQuarrys/CustomYMapForQuarrys";

export const AdminCalculationAddPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAddCalculationPath = pathname.endsWith('/create');
  const calculationId = pathname.split('/')[2];
  const properties: ICalculationAddProperties[] = useAppSelector(selectCalculationOptions, shallowEqual);
  const closestWeightBridgesCoordinates = useAppSelector(selectClosestWeightBridgesCoordinates);
  const detailRouteCoordinate = useAppSelector(selectDetailRouteCoordinate);
  const materialFields: ICalculationAddProperties[] = useAppSelector(selectMaterialKindFields, shallowEqual);
  const materialOptions: IMaterialsOptionsForAdmin[] = useAppSelector(selectOptionsMaterials);
  const calculationDetailsList: ICalculationDetails = useAppSelector(selectCalculationDetails);
  const checkedDetailRowId: number = useAppSelector(selectCheckedCalculationDetailsIndex);
  const lat = properties.find(item => item.name === 'latitude' || item.name === 'addressLatitude')?.value as string;
  const lon = properties.find(item => item.name === 'longitude' || item.name === 'addressLongitude')?.value as string;
  const object = properties.find(item => item.name === 'object' || item.name === 'objectId');
  const objectTitle = isAddCalculationPath
    ? object?.properties?.find(option => option.value === object?.value)?.label
    : object?.value;
  const address = properties.find(item => item.name === 'address')?.value as string;
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);
  // оставляем только маркеры с уникальным именем карьера
  const quarryCoordinates = calculationDetailsList?.calculationDetails
    ?.map((item, index) => {
      const isUnique =
        calculationDetailsList?.calculationDetails?.map(name => name?.quarry?.title)?.indexOf(item?.quarry?.title) ===
        index;
      if (isUnique)
        return {
          ...item?.quarry,
          title: `${item?.quarry.title} ${item?.distance} км, ${formPriceOnlyNumber(Number(item.results?.totalCostPerUnit), 'RUB', 'ru')} ₽/${item.results?.unit}`,
          checkTitle: item?.quarry?.title,
          color: index === 0 ? '#31986D' : '#4460C2',
        };
    })
    .filter(item => item?.title);
  useEffect(() => {
    return () => {
      dispatch(clearCalculationOptionsFields());
      dispatch(clearCalculationMaterialFields());
    };
  }, []);
  useEffect(()=>{
    dispatch(getClosestWeightBridgesCoordinates(Number(calculationId)))
    dispatch(getDetailRouteCoordinate(calculationDetailsList?.calculationDetails[0]?.id))
  },[calculationDetailsList])


  quarryCoordinates?.unshift({
    latitude: Number(lat),
    longitude: Number(lon),
    title: address || '',
    checkTitle: '',
    color: '#f4753a',
  });
  const [openMap, setOpenMap] = useState<boolean>(false);

  useOptionsChangeCalculation({ isDisabledButton, setIsDisabledButton });

  const createCalculation = () => {
    const calculationData: INewCalculationRequest = properties
      .filter(property => (property.name === addPropertyName.clientPhone ? property.value !== '+7' : property.value))
      .reduce((acc, property) => {
        if (property.name === addPropertyName.materialKindId) return acc;

        // преобразование дат
        const dateValue =
          property.name === addPropertyName.startDelivery || property.name === addPropertyName.endDelivery
            ? dayjs(property.value, 'DD.MM.YYYY').toISOString()
            : property.value;

        // преобразование телефона
        const phoneValue = property.name === addPropertyName.clientPhone ? '+' + property.value : dateValue;

        // преобразование ед.изм.

        const unitValue =
          property.name === addPropertyName.unit ? (property.value === 'тн' ? 'тонн' : property.value) : phoneValue;

        // преобразование адреса и radio
        const propertyValue =
          property.name === addPropertyName.workSchedule || property.name === addPropertyName.address
            ? property.value?.value
            : unitValue;

        // приведение к типу number
        const prerairedPropertyValue =
          property.typeOfValue === typeOfValue.NUMBER ? Number(propertyValue) : propertyValue;

        return {
          ...acc,
          [property.name]: prerairedPropertyValue,
        };
      }, {} as INewCalculationRequest);
    if (calculationData[addPropertyName.clientPhone]?.length < 11) {
      toast.error('Неверный формат телефона');
      return;
    }
    // преобразование kind для массива properties
    const materialKinds = properties?.find(item => item?.name === addPropertyName.materialKindId);
    const materialKindId = materialOptions.find(
      material => material?.id === calculationData[addPropertyName.materialTypeId],
    )?.materialKind?.id;
    const currentKind: {
      value: string | number;
      label: string;
    } = materialKinds?.properties?.find(value => value?.value === materialKinds?.value);
    const prepairedKind = { propertyId: materialKindId, value: currentKind?.label };

    // преобразование массива properties (свойства материала)
    const materialPropertiesData = [
      ...materialFields
        .filter(materialField => {
          return materialField?.value;
        })
        .map(item => {
          return {
            propertyId: Number(item?.id),
            value: item?.value,
          };
        }),
    ];
    if (materialKindId) materialPropertiesData.push(prepairedKind);
    calculationData[addPropertyName.properties] = materialPropertiesData;

    dispatch(createNewCalculation(calculationData)).then(data => {
      if (!data?.payload?.message?.length) navigate(`/calculation_supplies/${data?.payload?.id}`);
    });
  };

  const changeMainField = ({ name, value }: { name: string; value: any }) => {
    dispatch(handleChangeValue({ name, value }));
  };
  const changeMaterialField = ({ name, value }: { name: string; value: any }) => {
    dispatch(handleChangeMaterialValue({ name, value }));
  };
  return (
    <PageWrapper>
      <div className={styles.topContainer}>
        <BackButton
          textButton={'Вернуться к расчетам'}
          onClick={() => {
            navigate('/calculation_supplies');
          }}
        />
        <div className={styles.titleAndButtonContainer}>
          <TitleMain
            fontSize={'24px'}
            title={isAddCalculationPath ? 'Создать новый расчет' : `Расчет ${calculationId}`}
          />
        </div>
      </div>
      <div className={styles.mainContainer}>
        {!isAddCalculationPath && (
          <div className={styles.createdProperties}>
            <OptionField
              calculationId={calculationId}
              properties={properties.slice(0, 2)}
              changeValue={changeMainField}
            />
          </div>
        )}
        <div>
          <OptionField
            calculationId={calculationId}
            properties={properties.slice(2, 5)}
            changeValue={changeMainField}
          />
        </div>
        <div
          className={styles.materialProperties}
          style={{
            borderBottom: materialFields.length ? '1px solid var(--color-gray300)' : 'none',
            padding: materialFields.length ? '24px 0' : '0',
          }}
        >
          {materialFields.length ? (
            <OptionField calculationId={calculationId} properties={materialFields} changeValue={changeMaterialField} />
          ) : (
            <></>
          )}
        </div>
        <div>
          <OptionField
            calculationId={calculationId}
            properties={properties.slice(5)}
            changeValue={changeMainField}
            setOpenMap={setOpenMap}
          />
        </div>
        {isAddCalculationPath && (
          <div className={styles.buttonContainer}>
            <ButtonFilled
              text={'Рассчитать'}
              sizeText={'small'}
              width={'140px'}
              disabled={isDisabledButton}
              onClick={() => createCalculation()}
            />
          </div>
        )}
      </div>
      <div className={styles.detailsContainer}>
        {!isAddCalculationPath &&
          (calculationDetailsList?.isCanceled ? (
            calculationDetailsList.calculationDetails[0] ? (
              <>
                <CalculationDetailsList calculationId={calculationId} />
                <CustomYMapForCalculation
                    zoom={8}
                    detailRouteCoordinate={detailRouteCoordinate}
                    weightBridgesCoordinates={closestWeightBridgesCoordinates}
                    legend={true}
                  coordinates={quarryCoordinates}
                  height={'562.454px'}
                  calculationDetails={calculationDetailsList?.calculationDetails}
                />
                <div className={styles.customTableContainer}>
                  <CustomTable
                    columns={dataTableCalculationVariants}
                    data={prepareDetailsList(calculationDetailsList?.calculationDetails, checkedDetailRowId)}
                    dispatchFunction={index => {
                      dispatch(changeCheckedIndex(index));
                      dispatch(changeCalculationDetailsInfo());
                    }}
                    getRowData={(data)=>{
                      dispatch(getDetailRouteCoordinate(data.id));}}
                    isGetRowData={true}
                    emptyDescription=""
                    emptyTitle="По данному расчету ничего не найдено."
                    emptyFilterTitle=""
                  />
                </div>
              </>
            ) : (
              <EmptyList title={'Расчеты не найдены'} subTitle={'По заданным параметрам расчеты не найдены'} />
            )
          ) : (
            <div className={styles.loadingContainer}>
              <div className={styles.loadingText}>Идет расчет стоимости</div>
              <LoadingSpin height="100px" />
            </div>
          ))}
      </div>
      {openMap && (
        <ModalNew openModal={openMap} setOpenModal={setOpenMap} width={'672px'}>
          <div className={styles.modalContainer}>
            <TitleMain fontSize={'24px'} title={object?.value ? objectTitle : 'Объект на карте'} />
            <CustomYMap isDraggable={isAddCalculationPath} setCoordinates={(value)=> {
              dispatch(handleChangeValue({name: 'latitude', value: value[0].toFixed(5)}));
              dispatch(handleChangeValue({name: 'longitude', value: value[1].toFixed(5)}));
            }} latitude={lat||55.75396} longitude={lon||37.620393}  />
          </div>
        </ModalNew>
      )}
    </PageWrapper>
  );
};
