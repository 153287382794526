import React from 'react';
import styles from './ShowcaseDescriptionView.module.scss';
import { useMediaQuery } from 'react-responsive';
import defaultLogoImage from '../../images/default-logo.png';
import { useAppSelector } from 'src/CustomHooks/storeHooks';
import { selectCurrentShowcaseProperties } from '../../services/showcase/showcaseSlice';

export const ShowcaseDescriptionView = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const info = useAppSelector(selectCurrentShowcaseProperties);
  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.desktopContainer}>
        <div className={styles.logoContainer}>
          <div
            className={styles.logoImage}
            style={{
              backgroundImage: `url(${info?.logoImage?.location || defaultLogoImage})`,
            }}
          />
        </div>
        <div className={styles.titleContainer}>
          <div className={(isMobile ? 'textH4 textH4_medium ' : 'textH3 textH3_medium ') + styles.carrierName}>
            {info?.title}
          </div>
          {!isMobile && <div className={styles.carrierDescription}>{info?.description}</div>}
        </div>
      </div>
      {isMobile && (
        <div className={styles.mobileContainer}>
          <div className={styles.carrierDescription}>{info?.description}</div>
        </div>
      )}
    </div>
  );
};
