import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { DatePicker, DatePickerProps } from 'antd';
import { CloseCircleNewSvg } from '../../UI/svg-icon/CloseCircleNewSvg';
import './CustomDatePicker.css';
import locale from 'antd/es/date-picker/locale/ru_RU';
import 'dayjs/locale/ru';
import dayjs, { Dayjs } from 'dayjs';
import useClickOutsideNode from '../../../CustomHooks/useClickOutsideNode';
import { useEffectSkipFirstRender } from '../../../CustomHooks/useEffectSkipFirstRender';

interface IProps {
  placeholder?: string;
  value?: string | Dayjs;
  setValue: Dispatch<any>;
  disabled?: any;
  width?: string;
  defaultToday?: boolean;
  height?: string;
  minDate?: string;
  maxDate?: string;
  paddingLeft?: string;
  paddingRight?: string;
  closingConditionOfCalendar?: any;
  plusOneDayToApplyButton?: boolean;
  clearButton?: boolean;
}

const nextDay = dayjs(new Date(new Date().setUTCDate(new Date().getUTCDate() + 1)));
const currentDay = dayjs(new Date());
const CustomDatePicker: React.FC<IProps> = ({
  placeholder,
  value,
  defaultToday = false,
  setValue,
  disabled,
  minDate = '',
  maxDate = '',
  width = '228px',
  height = '56px',
  paddingLeft = '5px',
  paddingRight = '5px',
  closingConditionOfCalendar,
  plusOneDayToApplyButton,
  clearButton = true,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const containerRef = useRef(null);
  useClickOutsideNode(containerRef, setOpen);
  const [valueDate, setValueDate] = useState<any>(plusOneDayToApplyButton ? nextDay : currentDay);

  const onChangeRangePicker = (dates, dateStrings) => {
    setValueDate(dates);
    setOpen(false);
  };
  useEffectSkipFirstRender(() => {
    setValue(valueDate);
  }, [valueDate]);
  useEffect(() => {
    setOpen(false);
  }, [closingConditionOfCalendar]);
  const dateFormat = 'DD.MM.YYYY';
  const disabledDaysDate: DatePickerProps['disabledDate'] = (current, { from }) => {
    let startDate = dayjs(minDate);
    let endDate = dayjs(maxDate);
    return current < startDate.startOf('day') || current > endDate.endOf('day');
  };
  return (
    <>
      <div ref={containerRef} style={{ position: 'relative' }}>
        <DatePicker
          disabled={disabled}
          disabledDate={disabledDaysDate}
          style={{
            width: width,
            height: height,
            padding: '8px 16px',
          }}
          open={open}
          format={dateFormat}
          onClick={e => {
            setOpen(!open);
          }}
          onChange={onChangeRangePicker}
          value={value}
          placeholder={placeholder}
          locale={locale}
          allowClear={false}
          showToday={false}
          suffixIcon={null}
          getPopupContainer={trigger => {
            if (containerRef.current) {
              return containerRef.current;
            }
            return trigger.parentNode as HTMLElement;
          }}
        />
        {clearButton && value && (
          <div
            onClick={e => {
              setOpen(false);
              defaultToday ? setValue(currentDay) : setValue(null);
            }}
            className={'svgContainer'}
          >
            <CloseCircleNewSvg width={'20px'} color={'var(--color-gray800)'} />
          </div>
        )}
      </div>
    </>
  );
};

export default CustomDatePicker;
