import React from 'react';
import { removeImage } from 'src/common/removeImage';
import { ImageContainerInOrder } from './ImageContainerInOrder';
import styles from '../InputFileInOrder.module.scss';
import { CloseSvg } from '../../../../../../components/UI/svg-icon/CloseSvg';

const ImagesContainerInOrder = ({ setImages, images, openPopupFunc, deleteFile, disabled = false, dataOrder }) => {
  return (
    <div className={styles.imagesContainer}>
      {images.map(({ id, location, name, size, isSize, date, isDate, mimeType, communicationCategory }) => (
        <div key={id}>
          <ImageContainerInOrder
            id={id}
            value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location}
            name={name}
            size={size}
            isSize={isSize}
            date={date}
            isDate={isDate}
            type={mimeType}
            openPopUp={() => (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupFunc(location)}
          >
            {dataOrder?.specificationClientForm && ( //Если по нашей форме, то крестик убираем(так как удалять не нужно)
              <div
                className={styles.svgContainer}
                id={id}
                onClick={({ target }) => {
                  !disabled && deleteFile(id, name, communicationCategory);
                  !disabled && removeImage(id, images, setImages);
                }}
              >
                <CloseSvg width={'15px'} />
              </div>
            )}
          </ImageContainerInOrder>
        </div>
      ))}
    </div>
  );
};

export default ImagesContainerInOrder;
