import React from 'react';
import styles from './CheckBoxCalculation.module.scss';
import { SuccessSvg } from '../../../../../components/UI/svg-icon/SuccessSvg';
interface IProps {
  check: boolean;
}
const CheckBoxCalculation = ({ check }: IProps) => {
  return (
    <div className={styles.container}>
      {check && (
        <div className={styles.svgContainer}>
          <SuccessSvg />
        </div>
      )}
    </div>
  );
};

export default CheckBoxCalculation;
