import { TGSMOperation } from './GSMtypes';

export const mockGSMOperationsHistoryTable: TGSMOperation[] = [
  { date: '06.11.2024 (23:59)', product: { name: 'ДТ' }, type: 'Заправка (списание средств с карты)', amount: 4500 },
  { date: '05.11.2024 (22:45)', product: { name: '' }, type: 'Оплата ГСМ', amount: 30000 },
  { date: '04.11.2024 (19:30)', product: { name: '95' }, type: 'Заправка (списание средств с карты)', amount: 6200 },
  { date: '03.11.2024 (16:15)', product: { name: 'ДТ' }, type: 'Заправка (списание средств с карты)', amount: 5000 },
  { date: '02.11.2024 (12:00)', product: { name: '92' }, type: 'Заправка (списание средств с карты)', amount: 3400 },
  { date: '01.11.2024 (08:45)', product: { name: '' }, type: 'Оплата ГСМ', amount: 45000 },
  { date: '31.10.2024 (23:59)', product: { name: 'ДТ' }, type: 'Заправка (списание средств с карты)', amount: 7800 },
  { date: '30.10.2024 (20:20)', product: { name: '92' }, type: 'Заправка (списание средств с карты)', amount: 6000 },
  { date: '29.10.2024 (17:10)', product: { name: '95' }, type: 'Заправка (списание средств с карты)', amount: 5500 },
  { date: '28.10.2024 (14:05)', product: { name: 'ДТ' }, type: 'Заправка (списание средств с карты)', amount: 3200 },
  { date: '27.10.2024 (11:55)', product: { name: '' }, type: 'Оплата ГСМ', amount: 41000 },
  { date: '26.10.2024 (09:30)', product: { name: '95' }, type: 'Заправка (списание средств с карты)', amount: 4700 },
];

export const mockGSMStatisticsData = [
  { date: '2023-10-01', sum: 8000 },
  { date: '2023-10-02', sum: 5000 },
  { date: '2023-10-03', sum: 7000 },
  { date: '2023-10-04', sum: 4500 },
  { date: '2023-10-05', sum: 2000 },
  { date: '2023-10-06', sum: 2000 },
  { date: '2023-10-07', sum: 1500 },
];

export const plotOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      border: {
        color: '#E8EBF0',
        width: 1,
      },
      grid: {
        color: '#E8EBF0',
      },
      ticks: {
        maxTicksLimit: 5,
        font: {
          size: 12,
          family: 'LiberationSans',
          weight: 'normal' as const,
        },
        color: '#6C779C',
      },
    },
    x: {
      border: {
        color: '#E8EBF0',
        width: 1,
      },
      grid: {
        color: '#E8EBF0',
      },
      ticks: {
        font: {
          size: 12,
          family: 'Helvetica',
          weight: 'normal' as const,
          lineHeight: 1.5,
        },
        color: '#6C779C',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export const fuelOptions = [
  { value: 'Все', label: 'Все' },
  { value: 'АИ-92', label: 'АИ-92' },
  { value: 'АИ-95', label: 'АИ-95' },
  { value: 'АИ-101', label: 'АИ-101' },
  { value: 'АИ-80', label: 'АИ-80' },
  { value: 'ДТ-Лет', label: 'ДТ-Лет' },
  { value: 'СУГ', label: 'СУГ' },
  { value: 'АИ-95-Фирм', label: 'АИ-95-Фирм' },
  { value: 'АИ-92-Фирм', label: 'АИ-92-Фирм' },
  { value: 'ДТ-Зим', label: 'ДТ-Зим' },
  { value: 'АИ-98-Фирм', label: 'АИ-98-Фирм' },
  { value: 'ДТ-Фирм', label: 'ДТ-Фирм' },
  { value: 'ДТ-Аркт', label: 'ДТ-Аркт' },
  { value: 'ДТ-Меж', label: 'ДТ-Меж' },
  { value: 'КПГ (Метан)', label: 'КПГ (Метан)' },
  { value: 'АИ-100', label: 'АИ-100' },
  { value: 'АИ-100-Фирм', label: 'АИ-100-Фирм' },
  { value: 'АИ-98', label: 'АИ-98' },
];

export const operationOptions = [
  { value: 'Все', label: 'Все' },
  { value: 'Обслуживание', label: 'Обслуживание' },
  { value: 'Возврат на счет', label: 'Возврат на счет' },
];

export const pieColors = [
  '#F4753A',
  '#FFCF94',
  '#FFBB80',
  '#FFD15B',
  '#FFEDC1',
  '#F1A35D',
  '#F69F1B',
  '#FCB345',
  '#F45B3A',
  '#FF8362',
  '#FFB4AA',
  '#FF894E',
  '#EED38E',
  '#FFC58A',
  '#FFC90B',
  '#FFE486',
];
