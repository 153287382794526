import { SUBSERVER } from './common/sub-server.constant';
import axios from 'axios';
export const instanceApi = axios.create({
  baseURL: SUBSERVER,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
});

instanceApi.interceptors.request.use(
  config => {
    const apiKey = localStorage.getItem('apiKey');
    if (apiKey) {
      config.headers['Authorization'] = `Bearer ${apiKey}`;
    } else {
      console.warn('API key not found.');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);
