import styles from './SelectLk.module.scss';
import { IOption } from './type';
export enum sizeEnum {
  BIG = 'big',
  SMALL = 'small',
}
export const generateStyleButtonContainer = (
  size: string,
  openSelectWindow: boolean,
  error: boolean,
  disabled: boolean,
) => {
  const styleButtonSmall = size === sizeEnum.SMALL && styles.buttonSmallContainer;
  const styleButtonDisabled = disabled && styles.buttonDisabledContainer;
  const styleButtonContainerProcessing = openSelectWindow && styles.buttonContainerProcessing;
  const styleButtonError = error && styles.buttonContainerError;
  return [
    styles.buttonContainer,
    styleButtonSmall,
    styleButtonContainerProcessing,
    styleButtonError,
    styleButtonDisabled,
  ];
};
export const generateStyleButtonText = (size: string, value: string | number) => {
  const styleButtonSelectedText = value && styles.buttonSelectedText;
  const styleButtonSmallText = size === sizeEnum.SMALL && styles.buttonSmallText;
  return [styles.buttonText, styleButtonSelectedText, styleButtonSmallText];
};
export const generateStyleOptionContent = (inputValue: string, option: IOption, size: string) => {
  const styleButtonSelectedOption = inputValue === option.label && styles.buttonSelectedOption;
  const styleOptionButtonSmallText = size === sizeEnum.SMALL && styles.optionContentSmallText;
  return [styles.optionContent, styleButtonSelectedOption, styleOptionButtonSmallText];
};
