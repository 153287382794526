import React from 'react';

export const FundsSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.59961 12.4C7.59961 10.3258 7.59961 9.28873 8.39559 8.64437C9.19157 8 10.4727 8 13.0349 8H17.5643C20.1265 8 21.4076 8 22.2036 8.64437C22.9996 9.28873 22.9996 10.3258 22.9996 12.4C22.9996 14.4742 22.9996 15.5113 22.2036 16.1556C21.4076 16.8 20.1265 16.8 17.5643 16.8H13.0349C10.4727 16.8 9.19157 16.8 8.39559 16.1556C7.59961 15.5113 7.59961 14.4742 7.59961 12.4Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M17.4996 12.4002C17.4996 13.6152 16.5146 14.6002 15.2996 14.6002C14.0846 14.6002 13.0996 13.6152 13.0996 12.4002C13.0996 11.1852 14.0846 10.2002 15.2996 10.2002C16.5146 10.2002 17.4996 11.1852 17.4996 12.4002Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M19.6992 14.0508L19.6992 10.7508" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M10.9004 14.0508L10.9004 10.7508" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M1 9.10059L5.4 9.10059" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M2.09961 12.4004H5.39961" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3.19922 15.7002H5.39922" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
