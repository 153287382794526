import React, { useRef } from 'react';
// @ts-ignore
import styles from './NewAsideNavSupplier.module.css';
import NewNavItem from '../newUI/NewNavItem/NewNavItem';
import { HelpSvg } from '../UI/svg-icon/HelpSvg';
import { HelpSvgActive } from '../UI/svg-icon/HelpSvgActive';
import { AdminPanelSvg } from '../UI/svg-icon/AdminPanelSvg';
import { AdminPanelActiveSvg } from '../UI/svg-icon/AdminPanelActiveSvg';
import { MapMarkerMultipleSvg } from '../UI/svg-icon/MapMarkerMultipleSvg';
import { MapMarkerMultipleSvgActive } from '../UI/svg-icon/MapMarkerMultipleSvgActive';
import { TariffsSvg } from '../UI/svg-icon/TariffsSvg';
import { TariffsSvgActive } from '../UI/svg-icon/TariffsSvgActive';
import CalculationSuppliesSvg from '../UI/svg-icon/CalculationSuppliesSvg';
import CalculationSuppliesActiveSvg from '../UI/svg-icon/CalculationSuppliesActiveSvg';
import { CartSvg } from '../UI/svg-icon/CartSvg';
import { CartSvgActive } from '../UI/svg-icon/CartSvgActive';
import MonitorDashboardSvg from '../UI/svg-icon/MonitorDashboardSvg';
import MonitorDashboardActiveSvg from '../UI/svg-icon/MonitorDashboardActiveSvg';

const NewAsideNavAdmin = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const adminNavLink = [
    {
      name: 'Панель администратора',
      ref: ref1,
      link: '/panel',
      type: ['root'],
      notActiveProfile: false,
      svg: <AdminPanelSvg />,
      svgActive: <AdminPanelActiveSvg />,
      errorProfile: false,
    },
    {
      name: 'Блокировка карьеров',
      link: '/quarries',
      ref: ref2,
      type: ['root'],
      notActiveProfile: false,
      disable: true,
      svg: <MapMarkerMultipleSvg />,
      svgActive: <MapMarkerMultipleSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Тарифы',
      link: '/tariffs',
      ref: ref3,
      type: ['root'],
      notActiveProfile: false,
      disable: true,
      svg: <TariffsSvg />,
      svgActive: <TariffsSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Расчет поставок',
      ref: ref4,
      link: '/calculation_supplies',
      type: ['root'],
      notActiveProfile: false,
      svg: <CalculationSuppliesSvg />,
      svgActive: <CalculationSuppliesActiveSvg />,
      errorProfile: false,
    },
    {
      name: 'Заказы',
      ref: ref5,
      link: '/order',
      type: ['root'],
      notActiveProfile: false,
      svg: <CartSvg />,
      svgActive: <CartSvgActive />,
      errorProfile: false,
    },
  /*  {
      name: 'Заявки',
      ref: ref6,
      link: '/bids',
      type: ['root'],
      notActiveProfile: false,
      svg: <MonitorDashboardSvg />,
      svgActive: <MonitorDashboardActiveSvg />,
      errorProfile: false,
    },*/
    {
      name: 'Помощь',
      link: '/help',
      ref: ref7,
      type: ['root'],
      notActiveProfile: false,
      disable: true,
      svg: <HelpSvg />,
      svgActive: <HelpSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
  ];
  return (
    <div className={styles.AsideBox} style={{ zIndex: '8000', paddingBottom: '20px' }}>
      {adminNavLink.map(
        ({ name, type, ref, link, notActiveProfile, svg, svgActive, svgProfileError, errorProfile }) => {
          return (
            <div key={name} ref={ref}>
              <NewNavItem
                ref={ref}
                disabled={null}
                typeAccess={type}
                key={name}
                name={name}
                link={link}
                notActiveProfile={notActiveProfile}
                svg={svg}
                svgActive={svgActive}
                svgProfileError={svgProfileError}
                errorProfile={errorProfile}
              />
            </div>
          );
        },
      )}
    </div>
  );
};

export default NewAsideNavAdmin;
