// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CheckBoxCalculation_container__WyxiD {\n  display: flex;\n  width: 20px;\n  height: 20px;\n  padding: 2px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 2px;\n  background: var(--color-gray200);\n}\n\n.CheckBoxCalculation_svgContainer__tIPJb {\n  width: 12px;\n  color: #9BA1B4;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminCalculationSupplies/components/CheckBoxCalculation/CheckBoxCalculation.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EAEA,kBAAA;EACA,gCAAA;AAAF;;AAGA;EACE,WAAA;EACA,cAAA;AAAF","sourcesContent":[".container{\n  display: flex;\n  width: 20px;\n  height: 20px;\n  padding: 2px;\n  justify-content: center;\n  align-items: center;\n\n  border-radius: 2px;\n  background: var(--color-gray200);\n}\n\n.svgContainer{\n  width: 12px;\n  color: #9BA1B4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CheckBoxCalculation_container__WyxiD",
	"svgContainer": "CheckBoxCalculation_svgContainer__tIPJb"
};
export default ___CSS_LOADER_EXPORT___;
