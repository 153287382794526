import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction, UnknownAction } from '@reduxjs/toolkit';
import { initCalculationCreatedFields } from './components/const';
import { RootState } from '../../../store';
import { IOptionsForAdmin } from '../AdminCalculationSupplies/type';
import { toastError } from '../../../common/toastError.helper';
import {
  deleteMaterialOrder,
  getContractOrders,
  getEDOIdentificator,
  getListOrders,
  getOrderById,
  getSpecificationTemplate,
  patchMarginOrderId,
  patchOrderId,
  postSpecificationEDO,
  postUploadImages,
} from './api';
import {
  IConvertGetListTableOrders,
  IDataOrderId,
  IGetListOrdersRequestData,
  IGetListTableOrders,
  IInformationEDO,
  IRequestDataPatchOrderId,
  ISpecificationRequest,
} from './type';
import { postCreateOrder } from './api';
import { ICreateOrderProperties, orderPropertyName, IOrderCreateRequestDTO } from './type';
import { convertGetListTableOrders } from './utils';
import { toast } from 'react-toastify';
import { clearObject } from 'src/common/ClearObject.helper';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;

type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

function isPendingAction(action: UnknownAction): action is PendingAction {
  return typeof action.type === 'string' && action.type.endsWith('/pending');
}

function isRejectedAction(action: UnknownAction): action is RejectedAction {
  return typeof action.type === 'string' && action.type.endsWith('/rejected');
}

function isFulfilledAction(action: UnknownAction): action is FulfilledAction {
  return typeof action.type === 'string' && action.type.endsWith('/fulfilled');
}

export const getOrders = createAsyncThunk('adminCreateOrder/getOrders', async (id: number, { rejectWithValue }) => {
  try {
    const response = await getContractOrders(id);
    return response.data;
  } catch (e) {
    const errorResponse = JSON.parse(e.response.request.response);
    toastError(errorResponse);
    return rejectWithValue(e);
  }
});
export const createOrder = createAsyncThunk(
  'adminCreateOrder/createOrder',
  async (date: IOrderCreateRequestDTO, { rejectWithValue }) => {
    try {
      const response = await postCreateOrder(date);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const getListTableOrders = createAsyncThunk(
  'adminCreateOrder/getListTableOrders',
  async (data: IGetListOrdersRequestData, { rejectWithValue }) => {
    try {
      const response = await getListOrders(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const getCurrentOrder = createAsyncThunk(
  'adminCreateOrder/getCurrentOrder',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getOrderById(id);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const deleteMaterialOrderId = createAsyncThunk(
  'adminCreateOrder/deleteMaterialOrderId',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteMaterialOrder(id);
      dispatch(deleteMaterialFromCurrentOrder({ id }));
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const patchCurrentOrder = createAsyncThunk(
  'adminCreateOrder/patchCurrentOrder',
  async ({ data, id }: { data: IRequestDataPatchOrderId; id: number }, { rejectWithValue }) => {
    try {
      const response = await patchOrderId(id, data);
      toast.success('Данные успешно сохранены');
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const patchMarginCurrentOrder = createAsyncThunk(
  'adminCreateOrder/patchMarginCurrentOrder',
  async ({ data, id }: { data: { plannedMargin: number }; id: number }, { rejectWithValue, dispatch }) => {
    try {
      const response = await patchMarginOrderId(id, data);
      dispatch(patchMaterialMarginCurrentOrder({ id: id, plannedMargin: data.plannedMargin }));
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const sendFilesFormDataCurrentOrder = createAsyncThunk(
  'adminCreateOrder/sendFilesFormData',
  async ({ uploadImages, id }: { uploadImages: any; id: string }, { rejectWithValue }) => {
    try {
      if (uploadImages.length) {
        let formData = new FormData();
        uploadImages.forEach(image => {
          formData.append('file', image);
        });
        formData.append('fileBelongsId', id);
        formData.append('communicationCategory', 'orderSpecifications');
        formData.append('category', 'Спецификация');
        const response = await postUploadImages(formData);
        return response.data;
      }
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const loadSpecificationEDO = createAsyncThunk(
  'adminCreateOrder/loadSpecificationEDO',
  async ({ id, data }: { id: number; data?: ISpecificationRequest }, { rejectWithValue }) => {
    try {
      const response = await postSpecificationEDO(clearObject({ id, data: data || undefined }));
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getSpecTemplate = createAsyncThunk(
  'adminCreateOrder/getSpecTemplate',
  async (orderId: number, { rejectWithValue }) => {
    try {
      const response = await getSpecificationTemplate(orderId);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getIdentificator = createAsyncThunk(
  'adminCreateOrder/getIdentificator',
  async (companyId: number, { rejectWithValue }) => {
    try {
      const response = await getEDOIdentificator(companyId);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export interface IState {
  createOrderFields: ICreateOrderProperties[];
  currentOrder: IDataOrderId;
  dataTableOrder: IConvertGetListTableOrders[];
  totalCountOrderPage: number;
  loading: boolean;
  informationEDO: IInformationEDO[];
}

const initialState: IState = {
  createOrderFields: initCalculationCreatedFields,
  currentOrder: null,
  dataTableOrder: [],
  totalCountOrderPage: 0,
  loading: false,
  informationEDO: [],
};
export const adminOrderSlice = createSlice({
  name: 'adminOrderSlice',
  initialState,
  reducers: {
    changeOrderFiledValue: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      const field = state.createOrderFields.find(field => field.name === name);
      if (field) {
        field.value = value;
      }
    },
    changeOrderFiledIsDisabled: (state, action: PayloadAction<{ name: string; value: boolean }>) => {
      const { name, value } = action.payload;
      const field = state.createOrderFields.find(field => field.name === name);
      if (field) {
        field.isDisabled = value;
      }
    },
    substituteValueFromCalculation: (
      state,
      action: PayloadAction<{ name: string; value: any; fieldType?: string; isDisabled?: boolean }>,
    ) => {
      const { name, value, fieldType = '', isDisabled } = action.payload;
      const field = state.createOrderFields.find(field => field.name === name);
      if (field) {
        field.value = value;
        field.fieldType = fieldType && field.fieldType !== 'radio' ? fieldType : field.fieldType;
        field.isDisabled = isDisabled;
      }
    },
    changeFiledOptions: (state, action: PayloadAction<{ name: string; properties: IOptionsForAdmin[] }>) => {
      const { name, properties } = action.payload;

      const field = state.createOrderFields.find(field => field.name === name);
      if (field) {
        field.properties = properties.map(property => {
          if (property.inn) {
            return {
              value: String(property?.id),
              label: property?.fullName
                ? `${property?.fullName} ${property.inn}`
                : `${property?.title} ${property.inn}`,
            };
          }
          return { value: String(property?.id), label: property?.fullName || property?.title };
        });
      }
    },
    deleteMaterialFromCurrentOrder: (state, action: PayloadAction<{ id: number }>) => {
      const id = action.payload.id;
      state.currentOrder.materials = state.currentOrder.materials.filter(item => item.id !== id); //удаляем из нашего общего state материал по id
    },
    patchMaterialMarginCurrentOrder: (state, action: PayloadAction<{ id: number; plannedMargin: number }>) => {
      const { id, plannedMargin } = action.payload;

      state.currentOrder.materials = state.currentOrder.materials.map(item =>
        item.id === id ? { ...item, plannedMarginWithId: { margin: plannedMargin, id } } : item,
      );
    },
    setInitStateFor: state => {
      state.createOrderFields = initCalculationCreatedFields;
    },
  },
  extraReducers: builder => {
    builder.addCase(getOrders.fulfilled, (state, action: PayloadAction<any>) => {
      const field = state.createOrderFields.find(field => field.name === orderPropertyName.addToOrder);
      field.properties = action?.payload.orders.map(item => {
        return {
          value: item.id,
          label: `${item.id}: ${item.objectTitle}`,
        };
      });
    });
    builder.addCase(getCurrentOrder.fulfilled, (state, action: PayloadAction<any>) => {
      state.currentOrder = {
        ...action.payload,
        materials: [...action.payload.materials].map(item => {
          return {
            ...item,
            plannedMarginWithId: {
              margin: item.plannedMargin,
              id: item.id,
            },
            dataForShowMore: {
              id: item.id,
              deliveryCalculationId: item.deliveryCalculationId,
              unit: item.unit,
            },
          };
        }),
      };
    });
    builder.addCase(getCurrentOrder.rejected, state => {
      state.currentOrder = null;
    });
    builder.addCase(createOrder.fulfilled, (state, action: PayloadAction<any>) => {
      console.log(action);
    });
    builder.addCase(getListTableOrders.fulfilled, (state, action: IGetListTableOrders) => {
      const correctedListTableOrders = action.payload.rows.map(item => convertGetListTableOrders(item));
      state.dataTableOrder =
        action.meta.arg.offset > 0 ? [...state.dataTableOrder, ...correctedListTableOrders] : correctedListTableOrders;
      state.totalCountOrderPage = action.payload.count;
    });
    builder.addCase(getIdentificator.fulfilled, (state, action: PayloadAction<IInformationEDO[]>) => {
      state.informationEDO = action?.payload;
    });
    builder.addMatcher(isPendingAction, state => {
      state.loading = true;
    });

    builder.addMatcher(isRejectedAction, state => {
      state.loading = false;
    });

    builder.addMatcher(isFulfilledAction, state => {
      state.loading = false;
    });
  },
});

export const {
  changeOrderFiledValue,
  changeFiledOptions,
  substituteValueFromCalculation,
  changeOrderFiledIsDisabled,
  deleteMaterialFromCurrentOrder,
  patchMaterialMarginCurrentOrder,
  setInitStateFor,
} = adminOrderSlice.actions;
export const selectCreateOrderFields = (state: RootState) => state?.adminOrder.createOrderFields;
export const selectTotalCountOrderPage = (state: RootState) => state?.adminOrder.totalCountOrderPage;
export const selectDataTableOrder = (state: RootState) => state?.adminOrder.dataTableOrder;
export const selectCurrentOrder = (state: RootState) => state?.adminOrder.currentOrder;
export const selectLoadingCurrentOrder = (state: RootState) => state?.adminOrder.loading;
export const selectCurrentOrderMaterials = (state: RootState) => state?.adminOrder?.currentOrder?.materials;
export const selectInformationEDO = (state: RootState) => state?.adminOrder.informationEDO;
