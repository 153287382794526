// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainFilters_containerFilterBox__Ia7X\\+ {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.MainFilters_titleText__aYdpz {\n  color: var(--color-gray800);\n  font-family: \"Golos Text\";\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 24px;\n}\n\n.MainFilters_contentFilterBox__vn0ZV {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.MainFilters_doubleCellFilterBox__hUZwW {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminCalculationSupplies/components/MainFilters/MainFilters.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,2BAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF","sourcesContent":[".containerFilterBox {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.titleText {\n  color: var(--color-gray800);\n  font-family: \"Golos Text\";\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 24px;\n}\n\n.contentFilterBox {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.doubleCellFilterBox {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerFilterBox": "MainFilters_containerFilterBox__Ia7X+",
	"titleText": "MainFilters_titleText__aYdpz",
	"contentFilterBox": "MainFilters_contentFilterBox__vn0ZV",
	"doubleCellFilterBox": "MainFilters_doubleCellFilterBox__hUZwW"
};
export default ___CSS_LOADER_EXPORT___;
