// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MoreInfoMaterialModal_container__1g-Bl {\n  position: relative;\n  height: 22px;\n}\n\n.MoreInfoMaterialModal_modal__qyR6w {\n  position: absolute;\n  right: -10px;\n  z-index: 1;\n  display: flex;\n  text-align: start;\n  width: 208px;\n  padding: 16px;\n  flex-direction: column;\n  border-radius: 8px;\n  border: 1px solid var(--color-gray200);\n  background: var(--color-white);\n  box-shadow: 0 4px 20px 0 rgba(138, 136, 255, 0.08);\n}\n\n.MoreInfoMaterialModal_textModal__uzJlF {\n  color: var(--color-gray900);\n  font-family: \"Golos Text\";\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminOrdersPage/components/MoreInfoMaterialModal/MoreInfoMaterialModal.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;;AACA;EACE,kBAAA;EACA,YAAA;EACA,UAAA;EAEA,aAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EAEA,kBAAA;EACA,sCAAA;EACA,8BAAA;EAEA,kDAAA;AADF;;AAGA;EACE,2BAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAAF","sourcesContent":[".container{\n  position: relative;\n  height: 22px;\n}\n.modal{\n  position: absolute;\n  right: -10px;\n  z-index: 1;\n\n  display: flex;\n  text-align: start;\n  width: 208px;\n  padding: 16px;\n  flex-direction: column;\n\n  border-radius: 8px;\n  border: 1px solid var(--color-gray200);\n  background: var(--color-white);\n\n  box-shadow: 0 4px 20px 0 rgba(138, 136, 255, 0.08);\n}\n.textModal {\n  color: var(--color-gray900);\n  font-family: \"Golos Text\";\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MoreInfoMaterialModal_container__1g-Bl",
	"modal": "MoreInfoMaterialModal_modal__qyR6w",
	"textModal": "MoreInfoMaterialModal_textModal__uzJlF"
};
export default ___CSS_LOADER_EXPORT___;
