import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction, Slice, UnknownAction } from '@reduxjs/toolkit';
import {
  deleteMaterial,
  getMaterialProperties,
  getMaterials,
  getMaterialsForEdit,
  getMaterialsGroupProperties,
  patchEditMaterial,
  postCreateMaterial,
} from './materialApi';
import { IMaterialField, IMaterials, IMaterialType, IRequestCreateMaterialObject } from '../../type';
import { RootState } from '../../../../store';
import { IMaterialGroupData, IMaterialsGroup } from '../types';
import { toastError } from 'src/common/toastError.helper';

export const getAllMaterials = createAsyncThunk('materials/getAllMaterials', async (_, { rejectWithValue }) => {
  try {
    const response = await getMaterials();
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const getCurrentMaterialsGroup = createAsyncThunk(
  'materials/getMaterialsGroup',
  async (data: IMaterialGroupData, { rejectWithValue }) => {
    try {
      const response = await getMaterialsGroupProperties(data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
export const getCurrentMaterialProperties = createAsyncThunk(
  'materials/getCurrentMaterial',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getMaterialProperties(id);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
export const deleteCurrentMaterial = createAsyncThunk(
  'materials/deleteCurrentMaterial',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await deleteMaterial(id);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const getCurrentMaterialForEdit = createAsyncThunk(
  'materials/getCurrentMaterialForEdit',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getMaterialsForEdit(id);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const createMaterial = createAsyncThunk(
  'materials/createMaterial',
  async (data: IRequestCreateMaterialObject, { rejectWithValue }) => {
    try {
      const response = await postCreateMaterial(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const editMaterial = createAsyncThunk(
  'materials/editMaterial',
  async (reqObj: { id: string; data: IRequestCreateMaterialObject }, { rejectWithValue }) => {
    try {
      const response = await patchEditMaterial(reqObj);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

function isPendingAction(action: UnknownAction): action is PendingAction {
  return typeof action.type === 'string' && action.type.endsWith('/pending');
}
function isRejectedAction(action: UnknownAction): action is RejectedAction {
  return typeof action.type === 'string' && action.type.endsWith('/rejected');
}
function isFulfilledAction(action: UnknownAction): action is FulfilledAction {
  return typeof action.type === 'string' && action.type.endsWith('/fulfilled');
}
export interface MaterialState {
  materialTypes: IMaterialType[];
  activeMaterialTypeId: number;
  currentMaterialProperties: IMaterialField[];
  currentMaterialsGroup: IMaterialsGroup;
  isLoading: boolean;
}

const initKindMaterialsOption = { value: 'Все', label: 'Все' };

const initCurrentMaterialsGroup = {
  title: '',
  materialsOptions: [initKindMaterialsOption],
  currentMaterialOption: initKindMaterialsOption,
  materials: [],
};

const initialState: MaterialState = {
  materialTypes: [],
  activeMaterialTypeId: 0,
  currentMaterialProperties: [],
  currentMaterialsGroup: initCurrentMaterialsGroup,
  isLoading: false,
};

export const materialSlice: Slice = createSlice({
  name: 'material',
  initialState,
  reducers: {
    handleChangeFieldValue: (state, action: PayloadAction<{ value: string; index?: number }>) => {
      state.currentMaterialProperties[action.payload.index].value = action.payload.value;
    },
    handleChangeActiveMaterial: (state, action: PayloadAction<number>) => {
      state.activeMaterialTypeId = action.payload;
    },
    handleChangeMaterialGroupOptions: state => {
      const materialsGroup = state.currentMaterialsGroup;
      const splittedTitle = materialsGroup.materials[0].title.split(' ');
      if (materialsGroup.materials?.length)
        materialsGroup.title =
          splittedTitle[0] +
          (splittedTitle[0] === 'Вторичный' || splittedTitle[0] === 'Бетонные' ? ' ' + splittedTitle[1] : '');
    },
    handleChangeCurrentMaterialKindOption: (state, action: PayloadAction<{ string }>) => {
      const materialsGroup = state.currentMaterialsGroup;
      materialsGroup.currentMaterialOption = materialsGroup.materialsOptions.find(
        item => item.value === action.payload,
      );
    },
    clearStateValue: state => {
      state.materialTypes = [];
      state.currentMaterialProperties = [];
      state.currentMaterialsGroup.materials = initCurrentMaterialsGroup;
    },
    clearCurrentMaterialProperties: state => {
      state.currentMaterialProperties = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllMaterials.fulfilled, (state, action: PayloadAction<{ response: IMaterialType[] }>) => {
      state.materialTypes = action.payload.response;
    });
    builder.addCase(
      getCurrentMaterialsGroup.fulfilled,
      (state, action: PayloadAction<{ response: { kindFilter: string[]; materials: IMaterials[] } }>) => {
        state.currentMaterialsGroup.materials = action.payload.response.materials;
        const materialsGroupData = action.payload.response.kindFilter;
        state.currentMaterialsGroup.materialsOptions = [
          initKindMaterialsOption,
          ...materialsGroupData.map(item => {
            return { value: item, label: item };
          }),
        ];
      },
    );
    builder.addCase(
      getCurrentMaterialProperties.fulfilled,
      (state, action: PayloadAction<{ response: IMaterialField[] }>) => {
        state.currentMaterialProperties = action.payload.response;
      },
    );
    builder.addCase(getCurrentMaterialForEdit.fulfilled, (state, action: PayloadAction<{ response: any }>) => {
      state.currentMaterialProperties = action.payload.response.materialProperties;
    });
    builder.addMatcher(isPendingAction, state => {
      state.isLoading = true;
    });

    builder.addMatcher(isRejectedAction, state => {
      state.isLoading = false;
    });

    builder.addMatcher(isFulfilledAction, state => {
      state.isLoading = false;
    });
  },
});

export const selectMaterialTypes = (state: RootState) => state.material.materialTypes;
export const selectActiveMaterialTypeId = (state: RootState) => state.material.activeMaterialTypeId;
export const selectCurrentMaterialProperties = (state: RootState) => state.material.currentMaterialProperties;
export const selectCurrentMaterialsGroup = (state: RootState) => state.material.currentMaterialsGroup;
export const selectIsLoadingMaterialsInfo = (state: RootState) => state.material.isLoading;
export const {
  handleChangeFieldValue,
  handleChangeActiveMaterial,
  handleChangeMaterialGroupOptions,
  handleChangeCurrentMaterialKindOption,
  clearStateValue,
  clearCurrentMaterialProperties,
} = materialSlice.actions;
