import React, { useContext, useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { TitleName } from '../../components/TitleName';
import { InputText } from '../../components/UI/inputs/InputText';
import { MyPhoneInput } from '../../components/UI/inputs/MyPhoneInput';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { SkeletonCarrierProfile } from '../../components/skeletons/SkeletonCarrierProfile';
import { Context } from '../../context/context';
import { ErrorText } from '../../components/ErrorText';
import { localStorageWrite } from '../../common/user/local-storage.helper';
import { profileData } from '../../common/profile.type';
import { getBank, getOrganization } from '../../common/fetch/fetch-dadata.function';
import { toast, ToastContainer } from 'react-toastify';
import { ImageContainer } from '../../components/ImageContainer';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import { Image } from '../../common/image.type';
import { ProfilePageUploading } from '../../components/ProfilePageUploading';
import { validateEmail } from '../../common/validation-email.function';
import { removeImage } from '../../common/removeImage';
import styles from './Profile.module.scss';
import RadioGroup from '../../components/newUI/RadioGroup/RadioGroup';
import { CheckBoxCustom } from '../../components/UI/checkBox/CheckBoxCustom';
import { SUBSERVER } from '../../common/sub-server.constant';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { typeTranslations } from '../EmployeesPage/Constans';
import { BICValidation, styleStatusProfile } from './constans';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import { upperCaseFirstLetter } from 'src/common/uppercase-first-letter.helper';
import { validateName } from 'src/common/validate-fio.function';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import { useMediaQuery } from 'react-responsive';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import { toastError } from 'src/common/toastError.helper';
import { validationInn } from 'src/common/validation-inn.function';
type NamesUploadingFilesProps = {
  active: boolean;
  category: string;
  name: string;
  desc: string;
  images: any;
  count?: number;
  uploadImages: any;
  setImages: React.Dispatch<any>;
  setUploadImages: React.Dispatch<any>;
}[];

export const ProfileCarrierPage = () => {
  const { user, setUser } = useContext(Context);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [images2, setImages2] = useState<Image[]>([]);
  const [images3, setImages3] = useState<Image[]>([]);
  const [images4, setImages4] = useState<Image[]>([]);
  const [uploadImages2, setUploadImages2] = useState<Image[]>([]);
  const [uploadImages3, setUploadImages3] = useState<Image[]>([]);
  const [uploadImages4, setUploadImages4] = useState<Image[]>([]);
  const namesUploadingFiles: NamesUploadingFilesProps = [
    {
      active: true,
      category: 'ogrn',
      name: 'ОГРН',
      desc: '',
      count: 1,
      images: images2,
      uploadImages: uploadImages2,
      setImages: setImages2,
      setUploadImages: setUploadImages2,
    },
    {
      active: true,
      category: 'inn',
      name: 'ИНН',
      desc: '',
      count: 1,
      images: images3,
      uploadImages: uploadImages3,
      setImages: setImages3,
      setUploadImages: setUploadImages3,
    },
    {
      active: true,
      category: 'other',
      images: images4,
      uploadImages: uploadImages4,
      count: 3,
      setImages: setImages4,
      setUploadImages: setUploadImages4,
      name: 'Дополнительные документы',
      desc: 'Необязательные документы, которые подтверждают вашу благонадежность. Не более 3 файлов.',
    },
  ];

  const [isActive, setIsActive] = useState(false);
  const [send, setSend] = useState(true);
  const [isErrorPhone, setIsErrorPhone] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [sending, setSending] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [sameAsLegal, setSameAsLegal] = useState({ id: '', active: false });
  const [sameAsActual, setSameAsActual] = useState({ id: '', active: false });
  const [typeOrg, setTypeOrg] = useState();
  const [taxRegime, setTaxRegime] = useState();
  const [, setSecondError] = useState('');
  const [updateProfile, setUpdateProfile] = useState(false);
  const [profile, setProfile] = useState(profileData);
  const [popUpActive, setPopUpActive] = useState(false);
  const [image, setImage] = useState('');

  let emailErrorMessage = null;
  if (!validateEmail(profile.email)) emailErrorMessage = 'Неверный формат';
  if (!profile.email) emailErrorMessage = 'Поле обязательно для заполнения';

  const validateAccRaschet = value => {
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      setProfile({
        ...profile,
        company: { ...profile.company, accRaschet: value },
      });
    }
  };

  const pickCheck = id => {
    if (id === 'sameAsLegal') {
      if (sameAsLegal.id === 'sameAsLegal') {
        setSameAsLegal({ id: '', active: false });
        setProfile({
          ...profile,
          company: { ...profile.company, actualAddress: '' },
        });
      } else {
        setSameAsLegal({ id: 'sameAsLegal', active: true });
        setProfile({
          ...profile,
          company: { ...profile.company, actualAddress: profile.company.address },
        });
      }
    }
    if (id === 'sameAsActual') {
      if (sameAsActual.id === 'sameAsActual') {
        setSameAsActual({ id: '', active: false });
        setProfile({
          ...profile,
          company: { ...profile.company, mailingAddress: '' },
        });
      } else {
        setSameAsActual({ id: 'sameAsActual', active: true });
        setProfile({
          ...profile,
          company: { ...profile.company, mailingAddress: profile.company.actualAddress },
        });
      }
    }
  };
  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActive(openPopUp);
  };
  const allPoi = [];

  useEffect(() => {
    (async () => {
      try {
        const {
          response: { id, surname, name, patronymic, phone, profile, company },
        } = await fetchGet(`/users`, {
          phone: `+${user.phone}`,
        });
        if (company?.verifications[0].status.title === 'Первичная регистрация') {
          setIsFirstLogin(true);
        }
        if (company?.verifications[0].status.title === 'Проверен') {
          setUser({ ...user, verified: true });
        }
        const responseFile = await fetch(
          `${SUBSERVER}/api/files/?fileBelongsId=${company.id}&communicationCategory=companies`,
          {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
            credentials: 'include',
          },
        );
        const dataFile = await responseFile.json();
        dataFile.response.map(file => {
          if (file.category === 'Дополнительные документы') {
            setUploadImages4(prevSate =>
              [...prevSate, file].filter(
                (value, index, array) => array.findIndex(value2 => value2.name === value.name) === index,
              ),
            );
            setImages4([]);
          } else if (file.category === 'ИНН') {
            setUploadImages3([file]);
            setImages3([]);
          } else if (file.category === 'ОГРН') {
            setUploadImages2([file]);
            setImages2([]);
          }
        });
        const companyObj = company;
        const apiKeyLS = localStorage.getItem('apiKey');
        const res = await getOrganization(
          `${SUBSERVER}/api/companies/company-information?inn=${companyObj.inn}`,
          apiKeyLS,
        );

        setProfile({
          ...profile,
          id,
          surname: surname || '',
          name: name || '',
          patronymic: patronymic || '',
          email: profile?.email || '',
          phone: phone || '',
          company: {
            ...profile?.company,
            inn: companyObj?.inn || '',
            accBik: companyObj?.bank?.accBik || '',
            accRaschet: companyObj?.accRaschet || '',
            title: res?.response?.title || '',
            kpp: res?.response?.kpp || '',
            address: res?.response?.address || '',
            id: companyObj?.id || '',
            uuid: companyObj?.uuid || '',
            mailingAddress: companyObj?.postAddress?.address || '',
            actualAddress: companyObj?.actualAddress?.address || '',
            bankName: companyObj?.bank?.title || '',
            accKorschet: companyObj?.bank?.accKorschet || '',
            verifications: companyObj?.verifications?.[0]?.status?.title,
          },
        });
        if (
          res?.response?.address === companyObj?.postAddress?.address &&
          companyObj?.postAddress?.address !== undefined
        ) {
          setSameAsActual({ id: 'sameAsActual', active: true });
        }
        if (
          res?.response?.address === companyObj?.actualAddress?.address &&
          companyObj?.actualAddress?.address !== undefined
        ) {
          setSameAsLegal({ id: 'sameAsLegal', active: true });
        }
        setTaxRegime(companyObj?.tax);
        setTypeOrg(companyObj?.organizationType);

        setSkeleton(false);
      } catch (e) {
        setTimeout(() => {
          setSkeleton(false);
        }, 800);
        console.log(e);
      }
    })();
  }, [!updateProfile]);
  useEffect(() => {
    (async () => {
      try {
        if (profile?.company?.inn?.length > 9) {
          const apiKeyLS = localStorage.getItem('apiKey');
          const responseOrganization = await getOrganization(
            `${SUBSERVER}/api/companies/company-information?inn=${profile?.company?.inn}`,
            apiKeyLS,
          );
          const OrgType = responseOrganization.response.type === 'LEGAL' ? 'ООО' : 'ИП';
          // @ts-ignore
          setTypeOrg(OrgType);

          setProfile(prevState => ({
            ...prevState,
            company: {
              ...prevState?.company,
              title: responseOrganization?.response?.title,
              kpp: responseOrganization?.response?.kpp,
              address: responseOrganization?.response?.address,
            },
          }));
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [profile?.company?.inn]);

  useEffect(() => {
    (async () => {
      try {
        if (profile.company?.accBik?.length > 8) {
          const apiKeyLS = localStorage.getItem('apiKey');
          const responseBank = await getBank(
            `${SUBSERVER}/api/companies/bank-information?accBik=${profile.company?.accBik}`,
            apiKeyLS,
          );

          setProfile(prevState => ({
            ...prevState,
            company: {
              ...prevState.company,
              bankName: responseBank?.response?.bankName,
              accKorschet: responseBank?.response?.accKorschet,
            },
          }));
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [profile?.company?.accBik]);

  useEffect(() => {
    setSecondError('');
  }, [profile?.company?.accRaschet]);

  useEffect(() => {
    setSend(true);
    setDisabled(
      !(
        (uploadImages2.length > 0 || images2.length > 0) &&
        (uploadImages3.length > 0 || images3.length > 0) &&
        validateEmail(profile?.email) &&
        validateName(profile?.patronymic) &&
        validateName(profile?.surname) &&
        validateName(profile?.name) &&
        taxRegime &&
        profile?.company?.accRaschet &&
        send
      ),
    );
  }, [
    uploadImages2,
    uploadImages3,
    images2,
    images3,
    images4,
    profile?.patronymic,
    profile?.surname,
    taxRegime,
    profile?.name,
    profile?.company?.accRaschet,
    profile?.email,
    send,
  ]);

  const deleteFile = async (id, name, communicationCategory) => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
      credentials: 'include',
      body: JSON.stringify({
        communicationCategory: communicationCategory,
        name: name,
        objectUuid: profile?.company?.uuid,
      }),
    };
    try {
      // @ts-ignore
      const response = await fetch(`${SUBSERVER}/api/files/${id}`, requestOptions);
      const data = await response.json();
      if (toastError(data)) return;
    } catch (e) {
      console.log(e);
    }
  };

  const sendPersonalData = async (event: Event) => {
    event.preventDefault();
    try {
      setSending(true);
      const patchUserData = await fetchPost(`/users`, 'PATCH', {
        phone: profile.phone.trim(),
        surname: profile.surname.trim(),
        name: profile.name.trim(),
        patronymic: profile.patronymic.trim(),
        profile: {
          email: profile.email.trim(),
        },
      });
      let responseCompanyId;
      let bitrixId;
      if (isFirstLogin && !patchUserData.error) {
        const responseCompany = await fetchPost(`/companies/register`, 'PATCH', {
          inn: profile?.company?.inn,
          accBik: profile?.company?.accBik,
          accRaschet: profile?.company?.accRaschet,
          tax: taxRegime,
          postAddress: profile?.company?.mailingAddress,
          actualAddress: profile?.company?.actualAddress,
        });
        bitrixId = responseCompany?.response?.companyBitrixId;
        responseCompanyId = responseCompany?.response?.id;
        if (toastError(responseCompany)) {
          setSending(false);
          return;
        }
        setIsFirstLogin(false);
      } else {
        const responseCompany = await fetchPost(`/companies`, 'PATCH', {
          accBik: profile?.company?.accBik,
          accRaschet: profile?.company?.accRaschet,
          tax: taxRegime,
          postAddress: profile?.company?.mailingAddress,
          actualAddress: profile?.company?.actualAddress,
        });
        if (toastError(responseCompany)) {
          setSending(false);
          return;
        }
      }

      const correctionArrayFilesBody = namesUploadingFiles
        .map(file => {
          if (file.images.length) {
            return {
              communicationCategory: 'companies',
              fileBelongsId: isFirstLogin ? responseCompanyId : profile?.company?.id,
              category: file?.name,
              files: [...file?.images.map(file => file.value)],
              objectUuid: profile?.company?.uuid,
            };
          }
        })
        .filter(item => item !== undefined);

      const responseFile = await fetch(`${SUBSERVER}/api/files`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
        body: JSON.stringify(correctionArrayFilesBody),
      });
      const dataFile = await responseFile.json();
      if (isFirstLogin) {
        await fetch(`https://b24-bnchwk.bitrix24.ru/rest/3250/c2cra3q4lruhrbia/crm.deal.update.json`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: bitrixId,
            fields: {
              UF_CRM_1724138409: dataFile.response
                .filter(file => file.category === 'Паспорт генерального директора')
                .map(itemFile => itemFile.location),
              UF_CRM_1724138434: dataFile.response
                .filter(file => file.category === 'ИНН')
                .map(itemFile => itemFile.location),
              UF_CRM_1724138452: dataFile.response
                .filter(file => file.category === 'ОГРН')
                .map(itemFile => itemFile.location),
              UF_CRM_1724138469: dataFile.response
                .filter(file => file.category === 'Дополнительные документы')
                .map(itemFile => itemFile.location),
            },
          }),
        });
      }

      if (toastError(dataFile)) return;
      allPoi.splice(0, allPoi.length);

      toast.success('Данные профиля успешно сохранены');
      setUser({ ...user, active: true });
      localStorageWrite(user);
      setSend(false);
      setUpdateProfile(!updateProfile);
      setSending(false);
    } catch (e) {
      setSending(false);
      console.log(e);
    }
  };
  const accessLimitation = ['admin', 'manager', 'accountant'];
  return (
    <>
      {skeleton ? (
        <PageWrapper>
          <SkeletonCarrierProfile />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <div>
            <ToastContainer />
            {accessLimitation.includes(user?.type) ? (
              <div className={styles.titleAndSubtitleContainer}>
                <TitleMain title="Профиль" />
                <p className={styles.subTitleText}>Личная информация сотрудника</p>
                <div style={styleStatusProfile[profile?.company?.verifications]} className={styles.statusProfile}>
                  {profile?.company?.verifications}
                </div>
              </div>
            ) : (
              <div>
                <div className={styles.profileTitleContainer}>
                  <TitleMain title="Профиль" />
                  <div style={styleStatusProfile[profile?.company?.verifications]} className={styles.statusProfile}>
                    {profile?.company?.verifications}
                  </div>
                </div>
                <SubTitleMain
                  subTitle={
                    'В вашем профиле хранится важная информация, необходимая для идентификации и настройки вашего аккаунта'
                  }
                />
              </div>
            )}
            <div className={styles.ProfilePage}>
              <form>
                <div style={{ marginTop: '24px' }}>
                  <div className={styles.container}>
                    <TitleName field={!accessLimitation.includes(user?.type)} children="Личные данные" />
                    <div className={styles.inputs}>
                      <InputText
                        type={''}
                        disabled={accessLimitation.includes(user?.type)}
                        isLabel={true}
                        value={profile.surname}
                        setValue={value => setProfile({ ...profile, surname: upperCaseFirstLetter(value) })}
                        margin="0"
                        error={true}
                        helpText={''}
                        widthHelp={0}
                        textError={
                          profile.surname.length === 0 ? 'Поле обязательное для заполнения' : 'Неверный формат'
                        }
                        placeholder="Фамилия"
                        showError={!validateName(profile.surname) || profile.surname.length === 0}
                      />
                      <InputText
                        type={''}
                        disabled={accessLimitation.includes(user?.type)}
                        isLabel={true}
                        value={profile.name}
                        setValue={value => setProfile({ ...profile, name: upperCaseFirstLetter(value) })}
                        margin=""
                        error={true}
                        helpText={''}
                        widthHelp={0}
                        textError={profile.name.length === 0 ? 'Поле обязательное для заполнения' : 'Неверный формат'}
                        placeholder="Имя"
                        showError={!validateName(profile.name) || profile.name.length === 0}
                      />
                      <InputText
                        type={''}
                        disabled={accessLimitation.includes(user?.type)}
                        isLabel={true}
                        value={profile.patronymic}
                        setValue={value => setProfile({ ...profile, patronymic: upperCaseFirstLetter(value) })}
                        margin=""
                        error={true}
                        helpText={''}
                        widthHelp={0}
                        textError={
                          profile.patronymic.length === 0 ? 'Поле обязательное для заполнения' : 'Неверный формат'
                        }
                        placeholder="Отчество"
                        showError={!validateName(profile.patronymic) || profile.patronymic.length === 0}
                      />
                      {accessLimitation.includes(user?.type) && (
                        <InputText
                          type={''}
                          disabled={accessLimitation.includes(user?.type)}
                          isLabel={true}
                          value={typeTranslations[user?.type]}
                          setValue={value => setProfile({ ...profile, patronymic: value })}
                          margin=""
                          error={true}
                          helpText={''}
                          widthHelp={0}
                          textError={'Поле обязательное для заполнения'}
                          placeholder="Должность"
                        />
                      )}

                      <MyPhoneInput
                        disabled={profile.phone}
                        setIsError={setIsErrorPhone}
                        isError={isErrorPhone}
                        isActive={isActive}
                        setIsActive={setIsActive}
                        value={profile.phone}
                        onChange={value => setProfile({ ...profile, phone: value })}
                        isFunction={null}
                        btnHelp={false}
                      />
                      <ErrorText
                        isError={isErrorPhone}
                        empty={false}
                        correctText={'Вы ввели некорректный номер телефона'}
                        emptyText={'Поле обязательное для заполнения'}
                      />
                      {!accessLimitation.includes(user?.type) && (
                        <InputText
                          type={''}
                          disabled={false}
                          isLabel={true}
                          value={profile.email}
                          setValue={value => setProfile({ ...profile, email: value.replace(/\s/g, '') })}
                          margin=""
                          error={true}
                          helpText={''}
                          widthHelp={0}
                          showError={validateEmail(profile.email) ? false : true}
                          textError={emailErrorMessage}
                          placeholder="Адрес e-mail"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {!accessLimitation.includes(user?.type) && (
                  <div>
                    <div>
                      <div className={styles.container} style={{ marginTop: '15px' }}>
                        <TitleName field={true} children="Юридические данные" />
                        <div className={styles.inputs}>
                          <InputText
                            type={'text'}
                            disabled={!isFirstLogin}
                            isLabel={true}
                            value={profile.company?.inn}
                            setValue={value =>
                              validationInn(value, setProfile, {
                                ...profile,
                                company: { ...profile.company, inn: value },
                              })
                            }
                            margin="0"
                            error={true}
                            helpText={''}
                            widthHelp={0}
                            textError={'Поле обязательное для заполнения'}
                            placeholder="ИНН"
                          />
                          <InputText
                            type={''}
                            disabled={user.active}
                            isLabel={true}
                            value={profile.company?.title}
                            setValue={value =>
                              setProfile({
                                ...profile,
                                company: { ...profile.company, title: value },
                              })
                            }
                            margin=""
                            error={true}
                            helpText={''}
                            widthHelp={0}
                            textError={'Поле обязательное для заполнения'}
                            placeholder="Наименование организации"
                          />
                          <div style={{ display: 'flex' }}>
                            <p className={styles.titleRadioGroup}>Тип организации</p>
                            <div style={{ marginLeft: 'auto', marginRight: '20px' }}>
                              <RadioGroup
                                disabled={true}
                                width={'200px'}
                                setValue={setTypeOrg}
                                firstTitle={'ООО'}
                                secondTitle={'ИП'}
                                value={typeOrg}
                              />
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p className={styles.titleRadioGroup}>Налоговый режим</p>
                            <div style={{ marginLeft: 'auto', marginRight: '0px' }}>
                              <RadioGroup
                                disabled={false}
                                width={'220px'}
                                setValue={setTaxRegime}
                                firstTitle={'УСН'}
                                secondTitle={'ОСНО'}
                                value={taxRegime}
                              />
                            </div>
                          </div>
                          <InputText
                            type={'number'}
                            disabled={user.active}
                            isLabel={true}
                            value={profile.company?.kpp}
                            setValue={value =>
                              setProfile({
                                ...profile,
                                company: { ...profile.company, kpp: value },
                              })
                            }
                            margin=""
                            error={false}
                            helpText={''}
                            widthHelp={0}
                            textError={'Поле обязательное для заполнения'}
                            placeholder="КПП"
                          />
                          <InputText
                            type={''}
                            disabled={user.active}
                            isLabel={true}
                            value={profile.company?.address}
                            setValue={value =>
                              setProfile({
                                ...profile,
                                company: { ...profile.company, address: value },
                              })
                            }
                            margin=""
                            error={true}
                            helpText={''}
                            widthHelp={0}
                            textError={'Поле обязательное для заполнения'}
                            placeholder="Юридический адрес организации"
                          />

                          <InputText
                            type={''}
                            disabled={false}
                            isLabel={true}
                            value={profile.company?.actualAddress}
                            setValue={value =>
                              setProfile({
                                ...profile,
                                company: { ...profile.company, actualAddress: value },
                              })
                            }
                            margin=""
                            error={true}
                            helpText={''}
                            widthHelp={0}
                            textError={'Поле обязательное для заполнения'}
                            placeholder="Фактический адрес организации"
                          />

                          <CheckBoxCustom
                            children={'Совпадает с юридическим'}
                            error={false}
                            check={sameAsLegal.active}
                            pick={pickCheck}
                            name={'sameAsLegal'}
                            id={'sameAsLegal'}
                          />
                          <InputText
                            type={''}
                            disabled={false}
                            isLabel={true}
                            value={profile.company?.mailingAddress}
                            setValue={value =>
                              setProfile({
                                ...profile,
                                company: { ...profile.company, mailingAddress: value },
                              })
                            }
                            margin=""
                            error={true}
                            helpText={''}
                            widthHelp={0}
                            textError={'Поле обязательное для заполнения'}
                            placeholder="Почтовый адрес организации"
                          />
                          <CheckBoxCustom
                            children={'Совпадает с фактическим'}
                            error={false}
                            check={sameAsActual.active}
                            pick={pickCheck}
                            name={'sameAsActual'}
                            id={'sameAsActual'}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.container} style={{ marginTop: '15px' }}>
                        <TitleName field={true} children="Банковские реквизиты" />
                        <div className={styles.inputs}>
                          <InputText
                            type={''}
                            disabled={!isFirstLogin}
                            isLabel={true}
                            value={profile.company?.accBik}
                            setValue={value => {
                              if (BICValidation(value)) {
                                setProfile({
                                  ...profile,
                                  company: { ...profile.company, accBik: value },
                                });
                              }
                            }}
                            margin="0"
                            error={true}
                            helpText={''}
                            widthHelp={0}
                            textError={''}
                            placeholder="БИК банка"
                          />
                          <InputText
                            type={''}
                            disabled={user.active}
                            isLabel={true}
                            value={profile.company?.bankName}
                            setValue={value =>
                              setProfile({
                                ...profile,
                                company: { ...profile.company, bankName: value },
                              })
                            }
                            margin=""
                            error={true}
                            helpText={''}
                            widthHelp={0}
                            textError={'Поле обязательное для заполнения'}
                            placeholder="Наименование банка"
                          />
                          <InputText
                            type={'number'}
                            disabled={user.active}
                            isLabel={true}
                            value={profile.company?.accKorschet}
                            setValue={value =>
                              setProfile({
                                ...profile,
                                company: { ...profile.company, accKorschet: value },
                              })
                            }
                            margin=""
                            error={true}
                            helpText={''}
                            widthHelp={0}
                            textError={'Поле обязательное для заполнения'}
                            placeholder="Корреспондентский счет"
                          />
                          <InputText
                            type={'text'}
                            disabled={false}
                            isLabel={true}
                            value={profile.company?.accRaschet}
                            setValue={value => validateAccRaschet(value)}
                            margin=""
                            error={true}
                            helpText={''}
                            widthHelp={0}
                            textError={'Поле обязательное для заполнения'}
                            placeholder="Расчетный счет"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.container} style={{ marginTop: '15px' }}>
                        <TitleName field={true} children="Учредительные документы" />
                        <div className={styles.inputs}>
                          {sending ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '300px',
                              }}
                            >
                              <div>
                                <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                              </div>
                            </div>
                          ) : (
                            namesUploadingFiles.map(
                              // @ts-ignore
                              (
                                {
                                  active,
                                  name,
                                  desc,
                                  images,
                                  uploadImages,
                                  setUploadImages,
                                  setImages,
                                  count,
                                }: {
                                  active: boolean;
                                  name: string;
                                  desc: string;
                                  images: any;
                                  uploadImages: any;
                                  setImages: React.Dispatch<any>;
                                  setUploadImages: React.Dispatch<any>;
                                  count: number;
                                },
                                index: number,
                              ) => (
                                <div key={index} className={styles.box}>
                                  <ProfilePageUploading
                                    name={name}
                                    desc={desc}
                                    setImages={setImages}
                                    images={images}
                                    count={count}
                                  />
                                  {images.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                                    <div className="image-container" key={id}>
                                      <ImageContainer
                                        id={id}
                                        value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                                        name={name}
                                        size={size}
                                        isSize={isSize}
                                        date={date}
                                        isDate={isDate}
                                        type={type}
                                        openPopUp={() =>
                                          (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)
                                        }
                                      >
                                        <ButtonClose
                                          id={id}
                                          onClick={({ target }) => {
                                            removeImage(target, images, setImages);
                                          }}
                                          hidden=""
                                        />
                                      </ImageContainer>
                                    </div>
                                  ))}
                                  {uploadImages.map(
                                    ({
                                      id,
                                      location,
                                      name,
                                      size,
                                      isSize,
                                      date,
                                      isDate,
                                      mimeType,
                                      communicationCategory,
                                    }) => (
                                      <div className="image-container" key={id}>
                                        <ImageContainer
                                          id={id}
                                          value={
                                            mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location
                                          }
                                          name={name}
                                          size={size}
                                          isSize={isSize}
                                          date={date}
                                          isDate={isDate}
                                          type={mimeType}
                                          openPopUp={() =>
                                            (mimeType === 'image/png' || mimeType === 'image/jpeg') &&
                                            openPopupWithImg(location)
                                          }
                                        >
                                          <ButtonClose
                                            id={id}
                                            onClick={({ target }) => {
                                              deleteFile(id, name, communicationCategory);
                                              removeImage(target, uploadImages, setUploadImages);
                                            }}
                                            hidden=""
                                          />
                                        </ImageContainer>
                                      </div>
                                    ),
                                  )}
                                </div>
                              ),
                            )
                          )}
                          <div style={{ marginTop: '40px' }}>
                            <ButtonFilled
                              disabled={disabled}
                              text={'Сохранить'}
                              onClick={sendPersonalData}
                              width={'100%'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>

          <div className={popUpActive ? 'Popup active' : 'Popup'}>
            <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
            <div className="Popup__wrapper">
              <div>
                <div>
                  <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
                  <div className="Popup__content">
                    <div className="Popup__box">
                      <img src={image} alt={'Транспортная накладная'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      )}
    </>
  );
};
