import React from 'react';
interface IProps {
  width?: string;
}
export const PlusSvg = ({ width = '100%' }: IProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 8H8V14H6V8H0V6H6V0H8V6H14V8Z" fill="currentColor" />
    </svg>
  );
};
