import React, { useEffect, useRef, useState } from 'react';
import styles from './ShowcasePage.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PageWrapper } from 'src/components/PageWrapper';
import { CreateEditButton } from '../components/CreateEditButton';
import { ShowcaseCover } from '../components/ShowcaseCover/ShowcaseCover';
import { ShowcaseDescription } from '../components/ShowcaseDescription/ShowcaseDescription';
import { ShowcaseMainInfo } from '../components/ShowcaseMainInfo/ShowcaseMainInfo';
import { MaterialCatalog } from '../components/MaterialCatalog/MaterialCatalog';
import { Basket } from 'src/components/UI/svg-icon/Basket';
import { ShowcaseDescriptionView } from '../components/ShowcaseDescription/ShowcaseDescriptionView';
import { DeleteShowcasePopup } from './DeleteShowcasePopup/DeleteShowcasePopup';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import { PublicShowcaseHeader } from './PublicShowcaseHeader/PublicShowcaseHeader';
import { ShowcaseDescriptionButtons } from '../components/ShowcaseDescription/ShowcaseDescriptionButtons/ShowcaseDescriptionButtons';
import useClickOutside from 'src/CustomHooks/useClickOutside';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import {
  addQuarryOption,
  changeCurrentShowcaseProperties,
  changeEnableLinkShowcase,
  changeQuarriesOptions,
  changeSelectedQuarry,
  deleteCurrentImage,
  editCurrentShowcase,
  getCurrentShowcaseProperties,
  getQuarriesOptions,
  getSelectedQuarryInfo,
  selectCurrentShowcaseProperties,
  selectQuarryInfo,
  selectShowcasesLoading,
} from '../services/showcase/showcaseSlice';
import { IShowcaseForRedux, showcaseParameters } from '../services/types';
import SelectLk from 'src/components/newUI/SelectLk/SelectLk';
import { IOption } from 'src/components/newUI/SelectLk/type';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';

//TODO: проверить в покупателе
export const ShowcasePage = () => {
  const { pathname } = useLocation();
  const urlShowcaseId = pathname.split('/')[pathname.split('/').length - 1];
  localStorage.setItem('currentShowcaseId', urlShowcaseId);
  const dispatch = useAppDispatch();
  const isLoading: boolean = useAppSelector(selectShowcasesLoading);
  const showcase: IShowcaseForRedux = useAppSelector(selectCurrentShowcaseProperties);
  localStorage.setItem('currentQuarryId', String(showcase?.quarry?.id));
  const editQuarryInfo: { quarries: IOption[]; currentQuarry: IOption } = useAppSelector(selectQuarryInfo);
  const [isChangedImages, setIsChangedImages] = useState<{ cover: boolean; logo: boolean }>({
    cover: false,
    logo: false,
  });
  const quarryType = showcase?.quarry?.quarryType;

  const location = useLocation();
  const isShowcasePath = !location.pathname.startsWith('/store');
  const isCatalogPath = location.pathname.startsWith('/catalog');
  const [isSharePopupOpen, setIsSharePopupOpen] = useState<boolean>(false);
  const modalRef = useRef();
  useClickOutside(modalRef, setIsSharePopupOpen);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [popUpActive, setPopUpActive] = useState(false);
  // const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const [errors, setErrors] = useState<{ [showcaseParameters.TITLE]: boolean; [showcaseParameters.QUARRYID]: boolean }>(
    {
      [showcaseParameters.TITLE]: false,
      [showcaseParameters.QUARRYID]: false,
    },
  );

  useEffect(() => {
    if (errors[showcaseParameters.TITLE] && showcase.title) setErrors({ ...errors, [showcaseParameters.TITLE]: false });
    if (errors[showcaseParameters.QUARRYID] && quarryType)
      setErrors({ ...errors, [showcaseParameters.QUARRYID]: false });
  }, [showcase?.title, quarryType]);

  useEffect(() => {
    getShowcase();
  }, [isEdit]);

  const getShowcase = async () => {
    dispatch(getCurrentShowcaseProperties(Number(id)))
      .then(data => {
        dispatch(changeCurrentShowcaseProperties(data.payload.response));
      })
      .then(() => {
        getQuarriesNames();
      });
  };

  const getQuarriesNames = async () => {
    dispatch(getQuarriesOptions())
      .then(() => {
        dispatch(changeQuarriesOptions(''));
      })
      .then(() => {
        dispatch(addQuarryOption(''));
      });
  };

  const editShowcase = async () => {
    const options = {
      title: showcase?.title,
      description: showcase?.description,
      quarryId: showcase?.quarry?.id,
      header: showcase?.coverImageNew,
      logo: showcase?.logoImageNew,
    };
    dispatch(editCurrentShowcase({ id: showcase?.id, options: options }));
  };

  const changeCurrentQuarry = async (id: string) => {
    dispatch(getSelectedQuarryInfo(Number(id))).then(data => {
      dispatch(changeSelectedQuarry(data.payload.response));
    });
  };

  const saveChanges = () => {
    if (!quarryType || !showcase?.title) {
      setErrors({
        [showcaseParameters.QUARRYID]: !quarryType,
        [showcaseParameters.TITLE]: !showcase?.title,
      });
      return;
    }
    editShowcase().then(() => {
      if (showcase?.coverImage && isChangedImages.cover) {
        deleteImages(showcase?.coverImage?.id, showcase?.coverImage?.name);
      }
      if (showcase?.logoImage && isChangedImages.logo) {
        deleteImages(showcase?.logoImage?.id, showcase?.logoImage?.name);
      }
      setIsChangedImages({
        cover: false,
        logo: false,
      });
    });
    setIsEdit(false);
  };

  const deleteImages = async (id: number, name: string) => {
    dispatch(deleteCurrentImage({ id, name }));
  };

  const publishShowcase = async () => {
    dispatch(changeEnableLinkShowcase(showcase?.id));
  };

  //favorites buyer

  // const handleFavoriteClick = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.stopPropagation();
  //   changeFavorite();
  // };

  // const changeFavorite = async () => {
  //   try {
  //     if (!isFavorite) {
  //       const response = await fetchPost(`/users/favourites/`, 'POST', {
  //         category: 'showcases',
  //         itemId: Number(quarryId),
  //       });
  //       if (toastError(response)) return;
  //     } else {
  //       const response = await fetchPost(`/users/favourites/${quarryId}`, 'DELETE', {
  //         category: 'showcases',
  //       });
  //       if (toastError(response)) return;
  //     }

  //     setIsFavorite(!isFavorite);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <>
      {!isShowcasePath && !isMobile && <PublicShowcaseHeader />}
      <div
        className={!isShowcasePath && 'Page'}
        style={isShowcasePath ? { width: '100%' } : isMobile ? {} : { marginTop: '24px' }}
      >
        <div className={!isShowcasePath && !isMobile && 'Container'} style={isShowcasePath ? { width: '100%' } : {}}>
          <PageWrapper style={!isShowcasePath && isMobile ? { padding: '40px 16px' } : {}}>
            {isLoading ? (
              <LoadingSpin />
            ) : (
              <>
                {isShowcasePath && (
                  <div
                    className={
                      styles.topContainer + ' ' + (isMobile && isCatalogPath ? styles.topContainerCatalog : '')
                    }
                  >
                    <Link
                      className="button-back"
                      to={isCatalogPath ? '' : '/showcases'}
                      onClick={() => {
                        if (isCatalogPath) {
                          navigate(-1);
                          return;
                        }
                        navigate('/showcases');
                      }}
                    >
                      <div className="button-back__svg">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                      {isCatalogPath ? 'Назад' : 'Вернуться к списку витрин'}
                    </Link>
                    {!isCatalogPath ? (
                      !isEdit && (
                        <ShowcaseDescriptionButtons
                          setIsEdit={setIsEdit}
                          isSharePopupOpen={isSharePopupOpen}
                          setIsSharePopupOpen={setIsSharePopupOpen}
                        />
                      )
                    ) : (
                      <></>
                      // <div
                      //   className={styles.favoriteButtonContainer}
                      //   onClick={e => {
                      //     handleFavoriteClick(e)
                      //   }}
                      // >
                      //   {isFavorite ? (
                      //     <FavoriteShowcasesSvg color="currentColor" width={isMobile ? '24px' : '20px'} />
                      //   ) : (
                      //     <FavoriteSvg width={isMobile ? '24px' : '20px'} />
                      //   )}
                      //   {!isMobile && (
                      //     <p className={styles.favoriteButtonText}>{isFavorite ? 'В избранном' : 'В избранное'}</p>
                      //   )}
                      // </div>
                    )}
                  </div>
                )}
                <div className={styles.container}>
                  {isEdit && (
                    <div className={styles.titleContainer}>
                      <div className={styles.title + ' textH4 textH4_medium'}>Редактирование витрины</div>

                      <div className={styles.buttonsContainer}>
                        <ButtonNotFilled
                          text={'Удалить витрину'}
                          disabled={false}
                          svg={<Basket />}
                          onClick={() => setPopUpActive(true)}
                          width={isMobile ? '100%' : '175px'}
                          height="40px"
                          sizeText="small"
                        />
                        <ButtonNotFilled
                          text={showcase?.isEnabledLink ? 'Снять с публикации' : 'Опубликовать витрину'}
                          disabled={false}
                          onClick={() => publishShowcase()}
                          width={isMobile ? '100%' : '186px'}
                          height="40px"
                          sizeText="small"
                        />
                        <CreateEditButton isCreatePage={false} disabled={false} createShowcase={saveChanges} />
                      </div>
                    </div>
                  )}
                  <ShowcaseCover isEdit={isEdit} setIsChangedImages={setIsChangedImages} />

                  {isEdit ? (
                    <ShowcaseDescription
                      error={errors[showcaseParameters.TITLE]}
                      setError={setErrors}
                      setIsChangedImages={setIsChangedImages}
                    />
                  ) : (
                    <ShowcaseDescriptionView />
                  )}

                  <div className={styles.mainInfoContainer}>
                    {isShowcasePath && isEdit && (
                      <SelectLk
                        options={editQuarryInfo.quarries}
                        setValue={value => {
                          changeCurrentQuarry(value);
                        }}
                        placeholder={'Выберите карьер из списка'}
                        error={!editQuarryInfo?.currentQuarry?.value}
                        value={editQuarryInfo?.currentQuarry?.value}
                        heightSelectWindow={'246px'}
                      />
                    )}
                    <div className={styles.mainInfoSubContainer} style={isEdit ? { paddingTop: '16px' } : {}}>
                      <div className={styles.mainInfoSubTitle}>Основная информация</div>
                      <ShowcaseMainInfo />
                    </div>
                  </div>
                  <MaterialCatalog />
                </div>
                {popUpActive && <DeleteShowcasePopup popUpActive={popUpActive} setPopUpActive={setPopUpActive} />}
              </>
            )}
          </PageWrapper>
        </div>
      </div>
    </>
  );
};
