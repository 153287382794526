import { fetchPost } from '../proxy-1C-fetch-auth';
import { toast } from 'react-toastify';
import { getMe } from './getMe';
import { localStorageWrite } from './local-storage.helper';
import { toastError } from '../toastError.helper';

export const backToRoot = async (user, setUser) => {
  try {
    const responseRoot = await fetchPost('/auth/logout/root-from-user', 'POST', {});
    if (responseRoot.statusCode === 403) {
      toast.error('Доступ запрещен');
      return;
    }
    localStorage.setItem('apiKey', responseRoot.token);
    await getMe(user, setUser);
    localStorageWrite({ ...user, phone: '79156586565', apiKey: responseRoot.token, activeProfile: false });
    setUser({ ...user, phone: '79156586565', apiKey: responseRoot.token, activeProfile: false });
    if (toastError(responseRoot)) return;
  } catch (e) {
    console.log(e);
  }
};
