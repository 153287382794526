import { instanceApi } from '../../../globalConstans';
import { recordToParams } from '../../../common/proxy-1C-fetch-auth';
import {
  IDataGetListCalculationInAdmin,
  IBuyersRequest,
  IContractsRequest,
  IDataGetListMaterialsInAdmin,
  IDataGetListUsersInAdmin,
  IMaterialTypeParam,
  IObjectsRequest,
  IQuarriesRequest,
  INewCalculationRequest,
  IPatchDetailsOfCalculation,
  IDataGetListOrdersInAdmin,
} from './type';

export const getListUsersInAdmin = async (data: IDataGetListUsersInAdmin) => {
  return await instanceApi.get(`/api/admin/users/list?${recordToParams(data)}`);
};
export const getListMaterialsInAdmin = async (data: IDataGetListMaterialsInAdmin) => {
  return await instanceApi.get(`/api/admin/materials/types?${recordToParams(data)}`);
};
export const getListOrdersInAdmin = async (data: IDataGetListOrdersInAdmin) => {
  return await instanceApi.get(`/api/admin/orders/list?${recordToParams(data)}`);
};
export const getListCalculationInAdmin = async (data: IDataGetListCalculationInAdmin) => {
  return await instanceApi.get(`/api/admin/calculations/list?${recordToParams(data)}`);
};

export const getAddress = async (lat: string, lon: string) => {
  const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address';
  const token = 'acd0b65bca29ba39870a3ced6049625e0e15405e';
  const query = { lat, lon, radius_meters: 1000 };

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(query),
  };
  return await fetch(url, options);
};

export const getMaterialTypeFields = async (id: string, data: IMaterialTypeParam) => {
  return await instanceApi.get(`/api/admin/materials/properties/${id}?${recordToParams(data)}`);
};

export const getMaxBodySizeFields = async () => {
  return await instanceApi.get(`/api/admin/cars/semitrailers`);
};

export const getBuyersCompaniesFields = async (data: IBuyersRequest) => {
  return await instanceApi.get(`/api/admin/companies/list?${recordToParams(data)}`);
};

export const getQuarriesFields = async (data: IQuarriesRequest) => {
  return await instanceApi.get(`/api/admin/quarries/list?${recordToParams(data)}`);
};

export const getContractsFields = async (data: IContractsRequest) => {
  return await instanceApi.get(`/api/admin/contracts/list?${recordToParams(data)}`);
};

export const getObjectsFields = async (data: IObjectsRequest) => {
  return await instanceApi.get(`/api/admin/objects/list?${recordToParams(data)}`);
};

export const postNewCalculation = async (data: INewCalculationRequest) => {
  return await instanceApi.post(`/api/admin/calculations`, data);
};

export const getCreatedCalculation = async (id: number) => {
  return await instanceApi.get(`/api/admin/calculations/${id}`);
};
export const getClosestWeightBridges = async (id: number) => {
  return await instanceApi.get(`/api/admin/calculations/closest-weight-bridges/${id}`);
};
export const getDetailRoute = async (id: number) => {
  return await instanceApi.get(`/api/admin/calculations/detail-route/${id}`);
};

export const getDetailsOfCalculation = async (id: number) => {
  return await instanceApi.get(`/api/admin/calculations/detail-results/${id}`);
};

export const patchDetailsOfCalculation = async (data: IPatchDetailsOfCalculation, id: number) => {
  return await instanceApi.patch(`/api/admin/calculations/${id}`, data);
};

export const getObjectInfo = async (id: number) => {
  return await instanceApi.get(`/api/admin/objects/${id}`);
};
