import React, { useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../../components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import { Button } from '../../components/UI/buttons/Button';
import { InputUploading } from '../../components/UI/inputs/inputUploading';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { InputText } from '../../components/UI/inputs/InputText';
import { ButtonClose } from '../../components/UI/buttons/buttonClose';
import { FlightState, flightStates } from '../../common/flight-states';
import { Line } from '../../components/UI/Line';
import { CustomSelect } from '../../components/UI/select/CustomSelect';
import { SuccessfulFormSubmission } from '../../components/SuccessfulFormSubmission';
import { AcceptDelete } from '../../components/AcceptDelete';
import { checkValidation } from '../../common/check-validation.function';
import { ImageContainer } from '../../components/ImageContainer';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { SuccessSvg } from '../../components/UI/svg-icon/SuccessSvg';
import { toast, ToastContainer } from 'react-toastify';
import { WarningSvg } from '../../components/UI/svg-icon/WarningSvg';
import { AirPlaneSvg } from '../../components/UI/svg-icon/AirPlaneSvg';
import { ErrorFullSvg } from '../../components/UI/svg-icon/ErrorFullSvg';
import { getImage } from '../../common/get-extension.function';
import { FileSvg } from '../../components/UI/svg-icon/FileSvg';
import { CopySvg } from '../../components/UI/svg-icon/CopySvg';
import { SkeletonCarrierFlight } from '../../components/skeletons/SkeletonCarrierFlight';
import { validationPhoneNumberFunction } from '../../common/validation-phone-number.function';
import { removeImage } from '../../common/removeImage';
import { SUBSERVER } from '../../common/sub-server.constant';
import CustomDatePicker from '../../components/newUI/CustomDatePicker/CustomDatePicker';
import dayjs from 'dayjs';
import { formPrice } from '../../common/form-price.helper';
// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import heic2any from 'heic2any';
import { Context } from '../../context/context';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from '../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { PriceMultiplicationSvg } from 'src/components/UI/svg-icon/PriceMultiplicationSvg';
import { PriceEqualSvg } from 'src/components/UI/svg-icon/PriceEqualSvg';
import { HelpTooltip } from 'src/components/HelpTooltip';
import { toastError } from 'src/common/toastError.helper';

const flightType = {
  доставка: () => (
    <div className={'flightControl__typeOfService'}>
      доставка
      <span>Оказание услуги доставки нашего материала до клиента</span>
    </div>
  ),
  поставка: () => (
    <div className={'flightControl__typeOfService'}>
      поставка
      <span>Оказание услуги поставки своего материала до клиента</span>
    </div>
  ),
};

export const CarrierFlightPage = () => {
  const { user } = useContext(Context);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [popUpActive, setPopUpActive] = useState(false);
  const [currentCarrier, setCurrentCarrier] = useState();
  const [isBalloon, setIsBalloon] = useState(false);
  const [driversSalary, setDriversSalary] = useState();
  const [errorImageList, setErrorImageList] = useState(false);
  const [imageList, setImageList] = useState<
    {
      nameFile: string;
      base64File: string;
      extension: string;
    }[]
  >([]);

  const [uploadImages, setUploadImages] = useState<any>([]);

  const [skeletonFlight, setSkeletonFlight] = useState(true);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [sendingFiles, setSendingFiles] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [sending, setSending] = useState<{
    onImages: boolean;
    sendingImages: boolean;
    sendingData: boolean;
  }>({
    onImages: true,
    sendingImages: false,
    sendingData: false,
  });
  const [updatePage, setUpdatePage] = useState(false);
  const [PROPERTIES, setPROPERTIES] = useState<any>();
  const [state, setState] = useState<FlightState>(flightStates['Запланирован']);
  const [images, setImages] = useState<any>([]);
  const [image, setImage] = useState('');
  const [billOfLoadingNumber, setBillOfLoadingNumber] = useState('');
  const [billOfLadingDate, setBillOfLadingDate] = useState<any>('');

  const [disabledPickDriver, setDisabledPickDriver] = useState(true);

  const [, setIsDriver] = useState(true);
  const [pickDriver, setPickDriver] = useState<any>('');
  const [driver, setDriver] = useState<{ value: number; label: string } | null>();
  const [car, setCar] = useState<{ value: number; label: string } | null>();
  const [pickCars, setPickCars] = useState<any>('');
  const [successfulFormSubmission, setSuccessfulFormSubmission] = useState(false);
  const [acceptDelete, setAcceptDelete] = useState(false);
  const [error, setError] = useState(false);
  const [statusFlight, setStatusFlight] = useState('');

  const id = localStorage.getItem('flightId');

  const validatePriceValue = value => {
    const regex = /^[0-9]*[\. || \,]{0,1}[0-9]{0,3}$/;
    if (regex.test(value)) {
      setDriversSalary(value);
    }
  };

  const pickDriverOrCar = (value, setValue) => {
    setValue(value);
    setDisabled(true);
  };
  const deleteFile = async (id, name, communicationCategory) => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
      credentials: 'include',
      body: JSON.stringify({
        communicationCategory: communicationCategory,
        name: name,
        objectUuid: PROPERTIES?.uuid,
      }),
    };
    try {
      // @ts-ignore
      const response = await fetch(`${SUBSERVER}/api/files/${id}`, requestOptions);
      const data = await response.json();
      if (toastError(data)) return;
    } catch (e) {
      console.log(e);
    }
  };

  const assignToFlight = async () => {
    if (state.index > 2) {
      toast.error('Нельзя сменить водителя');
      setUpdatePage(!updatePage);
      return;
    }

    try {
      const response = await fetchPost(`/flights/carrier/set-driver-car/${id}`, 'PATCH', {
        driverId: driver?.value || null,
        carId: car?.value || null,
        driversSalary: Number(driversSalary) || null,
      });

      if (toastError(response)) return;

      toast.success('Водитель и(или) машина закреплены');
    } catch (e) {
      console.log(e);
    }

    setIsDriver(state.index === 0);

    setDisabledPickDriver(true);
  };

  const cancelFlight = async () => {
    const response = await fetchPost(`/flights/${id}`, 'DELETE', {});

    if (response?.error) {
      toast.error(response?.message[0]);

      setAcceptDelete(false);
      return;
    }

    navigate('/flights');
  };

  const sendDataFlight = async () => {
    setDisabled(true);
    setSending({ ...sending, sendingData: true });

    try {
      const correctionArrayFilesBody = [
        {
          communicationCategory: 'flights',
          fileBelongsId: PROPERTIES?.id,
          category: 'Транспортные документы',
          files: uploadImages.map(file => file.value),
          objectUuid: PROPERTIES?.uuid,
        },
      ];

      setSendingFiles(true);
      const response = await fetch(`${SUBSERVER}/api/files`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
        credentials: 'include',
        body: JSON.stringify(correctionArrayFilesBody),
      });
      setSendingFiles(false);
      setUploadImages([]);

      const correctNewDateTn = new Date(billOfLadingDate);
      correctNewDateTn.setHours(3);
      const responseFile = await response?.json();
      if (responseFile?.response) {
        const responsePatchFlights = await fetchPost(`/flights/carrier/set-tn/${id}`, 'PATCH', {
          numberTn: billOfLoadingNumber,
          dateTn: new Date(correctNewDateTn),
        });
        if (responsePatchFlights?.error) {
          toast.error(responsePatchFlights?.message[0]);
          setSending({ ...sending, sendingData: false });
          return;
        }
      }
      const flights = await fetchGet(`/flights/${id}`, {});
      setPROPERTIES(flights.response);
      setUpdatePage(!updatePage);
      setSending({ ...sending, sendingData: false });
    } catch (e) {
      console.log(e);
    }
  };

  const changeStatusColor = (status: string) => {
    if (status === 'Запланирован') {
      return { statusText: 'Новый рейс', color: 'blue' };
    }

    if (status === 'Выполнен') {
      return { statusText: status, color: 'green' };
    }

    return { statusText: status, color: 'orange' };
  };

  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActive(openPopUp);
  };
  useEffect(() => {
    if (car && driver) {
      setDisabledPickDriver(false);
    } else {
      setDisabledPickDriver(true);
    }
  }, [car, driver]);

  useEffect(() => {
    (async () => {
      setSkeletonFlight(true);
      setPickDriver('');
      setPickCars('');

      try {
        const flights = await fetchGet(`/flights/${id}`, {});
        if (flights.response.dateTn == null) {
          setBillOfLadingDate('');
        } else {
          setBillOfLadingDate(dayjs(flights.response.dateTn).subtract(1, 'days'));
        }
        const drivers = await fetchGet(`/drivers/list`, {});

        const driversList: { value: string; label: string }[] = [];

        for (const { id: value, fullName: label } of drivers.response) {
          driversList.push({ value, label });
        }

        const cars = await fetchGet(`/cars/list`, {});

        const currentCars = cars.response;

        const carsList: { value: string; label: string }[] = [];

        for (const car of currentCars) {
          carsList.push({
            value: car.id,
            label: `${car.carBrand?.brand || ''} ${car?.stateNumber || ''}`,
          });
        }
        setCurrentCarrier(flights?.response?.carrier?.title);
        setPickCars(carsList);
        setCar(
          flights.response.car
            ? {
                value: flights.response.car?.id,
                label: `${flights.response.car?.carBrand?.brand || ''} ${flights.response.car?.stateNumber}`,
              }
            : null,
        );
        setPickDriver(driversList);
        setDriversSalary(flights.response?.driversSalary);
        setDriver(
          flights.response.driver
            ? {
                value: flights.response.driver?.id,
                label: flights.response.driver?.fullName,
              }
            : null,
        );

        setDisabled(
          (!flights.response.car?.stateNumber && state.index > 1) ||
            (state.index > 1 && !flights.response?.volumeDownload) ||
            (state.index > 1 && !flights.response?.volumeDownload && !uploadImages.length) ||
            !uploadImages.length ||
            !flights.response.numberTn,
        );
        setIsDriver(state.index === 0);

        setIsDriver(flights.response?.status === 'Запланирован');

        await setPROPERTIES(flights.response);

        if (flights) {
          const [, currentState] = Object.entries(flightStates).find(([key]) => {
            if (flights.response.status) {
              return key === flights.response.status;
            }

            return key;
          });
          setState(currentState);

          const [currentStatus] = Object.entries(flightStates).find(
            ([key, value]) => value.index === currentState.index && key,
          );

          setStatusFlight(currentStatus);
          setBillOfLoadingNumber(flights.response?.numberTn ? flights.response?.numberTn.trim() : '');

          setSkeletonFlight(false);
          setLoadingFiles(true);
          const responseFile = await fetch(
            `${SUBSERVER}/api/files/?fileBelongsId=${localStorage.getItem('flightId')}&communicationCategory=flights`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('apiKey')}`,
              },
              credentials: 'include',
            },
          );
          const dataFile = await responseFile.json();
          dataFile.response.map(file => {
            setImages(prevSate =>
              [...prevSate, file].filter(
                (value, index, array) => array.findIndex(value2 => value2.name === value.name) === index,
              ),
            );
          });

          setLoadingFiles(false);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [updatePage]);
  useEffect(() => {
    setDisabled(Boolean(!uploadImages.length || !billOfLoadingNumber));
  }, [error, billOfLoadingNumber, uploadImages]);
  const extensions = ['application/pdf', 'image/jpeg', 'image/png', 'image/heif', ''];
  const loadingImages = async (setImages, images, e, count) => {
    let [file] = e.target.files;
    if (!extensions.includes(file.type)) {
      return toast.error(`Не верный формат фото`);
    }

    if (['', 'image/heif'].includes(file.type)) {
      try {
        file = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 0.5,
        });
      } catch (error) {
        return toast.error(`Ошибка загрузки файла`);
      }
    }

    const result = await new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = console.log;
      reader.readAsDataURL(file);
    });

    e.target.value = null;

    if (images.length >= count) return;

    setImages(prevValue => [
      ...prevValue,
      {
        id: `img${prevValue.length}`,
        value: result as string,
        name: file.name,
        size: Math.round(file.size / 1024),
        isSize: true,
        isDate: false,
        date: '',
        type: file.type,
      },
    ]);
  };

  const nowDate = new Date();
  const minDate = new Date(nowDate.setDate(nowDate.getDate() - 7)).toISOString().split('T')[0];
  const maxDate = new Date().toISOString().split('T')[0];
  console.log(statusFlight);
  return (
    <>
      {skeletonFlight ? (
        <PageWrapper>
          <SkeletonCarrierFlight />
        </PageWrapper>
      ) : (
        <PageWrapper style={{ zIndex: popUpActive && 10000 }}>
          <ToastContainer />
          <div className="FlightPage">
            <div className="button-back" onClick={() => navigate(-1)}>
              <div className="button-back__svg">
                <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              К списку рейсов
            </div>
            <div className={'FlightPage__wrapper'}>
              <div className="flightControl">
                <div>
                  <div className="flightControl__title">
                    {PROPERTIES.flightName ? PROPERTIES.flightName.trim() : 'Номер рейса'}
                    {PROPERTIES?.flightType ? flightType[PROPERTIES?.flightType.toLowerCase()]() : ''}
                    <div className={`flightControl__typeOfService ${changeStatusColor(statusFlight).color}`}>
                      {changeStatusColor(statusFlight).statusText.toLowerCase()}
                    </div>
                  </div>
                  {!(driver && car) && (
                    <div className={`flightControl__notification `}>
                      <span>
                        <WarningSvg />
                      </span>
                      ТС и водитель не назначены на рейс. Чтобы начать рейс, пожалуйста, выберите ТС и водителя.
                    </div>
                  )}
                  <div className="flightControl__selects" style={{ marginTop: '24px' }}>
                    <div className="flightInformation__title">ТС и водитель</div>
                    <div className="flightInformation__select">
                      <CustomSelect
                        error={false}
                        isDisabled={state.index > 2}
                        defaultValue={car ? car : null}
                        setValue={value => pickDriverOrCar(value, setCar)}
                        options={pickCars}
                        placeholder={'Выберите ТС'}
                        noOptionsMessage={'Нет вариантов'}
                        isSearchable={true}
                        isClearable={true}
                      />
                    </div>
                    <div className="flightInformation__select">
                      <CustomSelect
                        error={false}
                        isDisabled={!car || state.index > 2}
                        defaultValue={driver ? driver : null}
                        value={!car ? null : driver}
                        setValue={value => pickDriverOrCar(value, setDriver)}
                        options={pickDriver}
                        placeholder={'Выберите водителя'}
                        noOptionsMessage={'Нет вариантов'}
                        isSearchable={true}
                        isClearable={true}
                      />
                    </div>
                  </div>
                  <div className="flightInformation__container" style={{ marginTop: '40px' }}>
                    <div className="flightInformation__title" style={{ marginBottom: '8px' }}>
                      Ставка за рейс для водителя
                    </div>
                    <div>
                      <HelpTooltip
                        color="#6C779C"
                        textHelp="Расчет стоимости происходит руб*км с грузом. Устанавливайте ставку исходя из своих обязательств перед сотрудниками."
                        isLeft={!isMobile}
                        style={
                          isMobile
                            ? { width: '235px', bottom: '38px', right: '-12px' }
                            : { width: '385px', bottom: '38px', left: '-16px' }
                        }
                      />
                    </div>
                  </div>
                  <div className="flightInformation__subtitle" style={{ marginBottom: '24px' }}>
                    Установленная ставка водителю на рейс за пройдённый километраж с грузом.
                  </div>

                  <div className="flightInformation__calculator" style={isMobile ? { gap: '14px' } : {}}>
                    <InputText
                      type={'text'}
                      disabled={currentCarrier === 'Неруд Центр OOO' || state.index > 0}
                      placeholder={''}
                      error={true}
                      secondError={false}
                      textError=""
                      helpText={''}
                      widthHelp={0}
                      fill={true}
                      margin={'0'}
                      value={driversSalary}
                      setValue={value => validatePriceValue(value)}
                      isLabel={true}
                      width={isMobile ? '80px' : '93px'}
                      modificator="price-input"
                    />
                    <PriceMultiplicationSvg />
                    <p className="flightInformation__calculator__price">{`${PROPERTIES?.distance} км`}</p>
                    <PriceEqualSvg />
                    <p className={`flightInformation__calculator__${isMobile ? '' : 'calculated-'}price`}>
                      {formPrice(Number(driversSalary) * PROPERTIES?.distance, 'RUB')}
                    </p>
                  </div>
                  {state.index < 2 && (
                    <div style={{ marginTop: '40px' }}>
                      <ButtonFilled
                        width={'100%'}
                        onClick={assignToFlight}
                        disabled={disabledPickDriver}
                        text={'Переназначить рейс'}
                      />
                    </div>
                  )}
                  {statusFlight === 'Выполнен' && (
                    <>
                      <Line />
                      <div>
                        <div className="flightInformation__title">Прикрепление транспортной накладной</div>

                        {isBalloon && (
                          <div className={errorImageList ? 'balloon-image balloon-image_error' : 'balloon-image'}>
                            <div className="balloon-image__svg">
                              {errorImageList ? <ErrorFullSvg /> : <SuccessSvg />}
                            </div>
                            <div className="balloon-image__text">
                              {errorImageList
                                ? 'Произошла ошибка при загрузке транспортных накладных'
                                : 'Транспортные накладные были успешно загружены'}
                            </div>
                          </div>
                        )}
                        {!state.requiredButton && (
                          <InputUploading
                            onChangeFunction={e => loadingImages(setUploadImages, uploadImages, e, 3)}
                            text={'загрузить накладную'}
                            subText={'В формате JPG, PNG, PDF, HEIC до 50 мегабайт'}
                          />
                        )}
                        {loadingFiles ? (
                          <div style={{ marginTop: '30px' }}>
                            <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                          </div>
                        ) : (
                          images?.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
                            <div className="image-container" key={id}>
                              <ImageContainer
                                id={id}
                                value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? null : location}
                                name={name}
                                size={size}
                                isSize={isSize}
                                date={date}
                                isDate={isDate}
                                type={mimeType}
                                openPopUp={() =>
                                  (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)
                                }
                              >
                                {!state.requiredButton && (
                                  <ButtonClose
                                    id={id}
                                    onClick={({ target }) => {
                                      deleteFile(id, name, 'flights');
                                      removeImage(target, images, setImages);
                                    }}
                                    hidden=""
                                  />
                                )}
                              </ImageContainer>
                            </div>
                          ))
                        )}
                        {sendingFiles ? (
                          <div style={{ marginTop: '30px' }}>
                            <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                          </div>
                        ) : (
                          uploadImages.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                            <div className="image-container" key={id}>
                              <ImageContainer
                                id={id}
                                value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                                name={name}
                                size={size}
                                isSize={isSize}
                                date={date}
                                isDate={isDate}
                                type={type}
                                openPopUp={() =>
                                  (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)
                                }
                              >
                                {!state.requiredButton && (
                                  <ButtonClose
                                    id={id}
                                    onClick={({ target }) => {
                                      deleteFile(id, name, 'flights');
                                      removeImage(target, uploadImages, setUploadImages);
                                    }}
                                    hidden=""
                                  />
                                )}
                              </ImageContainer>
                            </div>
                          ))
                        )}

                        <div className="loaded-image">
                          <div className="list-image">
                            {imageList.map(({ nameFile, base64File, extension }, index) => (
                              <div
                                onClick={() => extension !== 'pdf' && openPopupWithImg(base64File)}
                                className="list-image__item"
                                key={index}
                              >
                                <div className="list-image__img">
                                  {extension === 'pdf' ? (
                                    <a href={base64File} download>
                                      <FileSvg />
                                    </a>
                                  ) : (
                                    <>
                                      <img src={base64File} alt="" />
                                      <div className="list-image__svg">
                                        <FileSvg />
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div className="list-image__name">{nameFile || 'Фото'}</div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <Line />
                        <div className="flightInformation__title">Информация по накладной</div>
                        <div className={'flightInformation__inputs'}>
                          <InputText
                            type={''}
                            disabled={state.requiredButton && true}
                            placeholder="Номер транспортной накладной"
                            error={true}
                            secondError={false}
                            textError={state.index < 3 ? 'Пожалуйста, укажите номер ТН' : ''}
                            helpText={''}
                            widthHelp={0}
                            fill={true}
                            margin={'16px'}
                            value={billOfLoadingNumber}
                            setValue={setBillOfLoadingNumber}
                            isLabel={true}
                          />
                          <CustomDatePicker
                            maxDate={maxDate}
                            minDate={minDate}
                            disabled={PROPERTIES.status === 'Выполнен'}
                            value={billOfLadingDate}
                            setValue={setBillOfLadingDate}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {isMobile && <Line />}
              <div className="flightInformation">
                <div
                  className="flightInformation__infoProvider flightInformation__logist-block"
                  style={isMobile ? { margin: '0' } : {}}
                >
                  <div className="flightInformation__list">
                    <div className="flightInformation__subTitle">
                      <p>Номер ответственного логиста</p>
                      <p className="sub">
                        <a href={`tel:+${PROPERTIES?.logist?.phone}`}>
                          {validationPhoneNumberFunction(PROPERTIES?.logist?.phone)}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <Line />
                <div className="flightInformation__infoProvider">
                  <div className="flightInformation__title">Информация о поставщике</div>
                  <div className="flightInformation__list">
                    <div className="flightInformation__subTitle">
                      <p>Дата заказа</p>
                      <p className="sub">{checkValidation(`${new Date(PROPERTIES?.date).toLocaleDateString()}`)}</p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Грузоотправитель</p>
                      <p className="sub">{checkValidation(PROPERTIES?.organization)}</p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Карьер</p>
                      <p className="sub">{checkValidation(PROPERTIES?.quarry?.name)}</p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Погрузка</p>
                      <p className="sub">{checkValidation(PROPERTIES?.quarry?.address?.address)}</p>
                    </div>
                    <div className={'flightInformation__copyText'}>
                      <CopyToClipboard text={PROPERTIES?.quarry?.address?.address}>
                        <span onClick={() => toast.success('Ссылка скопирована в буфер обмена')}>
                          Скопировать адрес{' '}
                          <span>
                            <CopySvg />
                          </span>
                        </span>
                      </CopyToClipboard>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Материал</p>
                      <p className="sub">
                        {checkValidation(
                          PROPERTIES?.supplierSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
                        )}
                      </p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>График работы</p>
                      <p className="sub">
                        {PROPERTIES?.quarry?.workSchedulesGraph?.aroundTheClock
                          ? 'Круглосуточно'
                          : `${PROPERTIES?.quarry?.workSchedulesGraph?.from || ''} -${PROPERTIES?.quarry?.workSchedulesGraph?.to || ''}`}
                      </p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Диспетчер</p>
                      <p className="sub">
                        <a href={`tel:+${PROPERTIES?.quarry?.dispatcher?.phone}`}>
                          {validationPhoneNumberFunction(PROPERTIES?.quarry?.dispatcher?.phone)}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <Line />
                <div className="flightInformation__infoProvider">
                  <div className="flightInformation__title">Информация о получателе</div>
                  <div className="flightInformation__list">
                    <div className="flightInformation__subTitle">
                      <p>Грузополучатель</p>
                      <p className="sub">{checkValidation(PROPERTIES?.consignee?.title)}</p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Выгрузка</p>
                      <p className="sub">{checkValidation(PROPERTIES?.object?.address?.address)}</p>
                    </div>
                    <div className={'flightInformation__copyText'}>
                      <CopyToClipboard text={PROPERTIES?.object?.address?.address}>
                        <span onClick={() => toast.success('Ссылка скопирована в буфер обмена')}>
                          Скопировать адрес
                          <span>
                            <CopySvg />
                          </span>
                        </span>
                      </CopyToClipboard>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Материал</p>
                      <p className="sub">
                        {checkValidation(
                          PROPERTIES?.buyerSpecification?.nomenclatureSpecification?.nomenclature?.nameByDocument,
                        )}
                      </p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>График приемки</p>
                      <p className="sub">
                        {PROPERTIES?.object?.workSchedulesGraph?.aroundTheClock
                          ? 'Круглосуточно'
                          : `${PROPERTIES?.object?.workSchedulesGraph?.from || ''} -${PROPERTIES?.object?.workSchedulesGraph?.to || ''}`}
                      </p>
                    </div>
                    <div className="flightInformation__subTitle">
                      <p>Контакт</p>

                      <p className="sub">
                        <a href={`tel:+${PROPERTIES?.clientInformation?.contactPhone}`}>
                          {validationPhoneNumberFunction(PROPERTIES?.clientInformation?.contactPhone)}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                {state.index < 3 && (
                  <div style={{ marginTop: '40px' }}>
                    <ButtonNotFilled
                      width={'100%'}
                      text={'Отказаться от рейса'}
                      onClick={() => setAcceptDelete(true)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {successfulFormSubmission && (
            <SuccessfulFormSubmission
              updatePage={updatePage}
              setUpdatePage={setUpdatePage}
              successfulActive={setSuccessfulFormSubmission}
            />
          )}
          {acceptDelete && <AcceptDelete closePopUp={setAcceptDelete} cancelFlight={cancelFlight} />}
          <div className={popUpActive ? 'Popup active' : 'Popup'}>
            <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
            <div className="Popup__wrapper">
              <div>
                <div>
                  <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
                  <div className="Popup__content">
                    <div className="Popup__box">
                      <img src={image} alt={'Транспортная накладная'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PageWrapper>
      )}
    </>
  );
};
