import { useEffect } from 'react';

const useClickOutsideNode = (ref, callback) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback(false);
    }
  };
  useEffect(() => {
    if (callback) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);
};

export default useClickOutsideNode;
