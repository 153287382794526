import React, { useEffect, useState } from 'react';
import styles from './AdminOrderPage.module.scss';
import TitleMain from '../../../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../../../components/UI/svg-icon/NewPlusSvg';
import SubTitleMain from '../../../../components/UI/TitleAndSubtitle/SubTitleMain';
import CustomTable from '../../../../components/newUI/CustomTable/CustomTable';
import { PageWrapper } from '../../../../components/PageWrapper';
import OrderFilters from '../components/OrderFilters/OrderFilters';
import { columnsTableOrder, initStateMainFilters, initStateSubFilters } from '../const';
import { IMainFilters, ISubFilters } from '../type';
import { useNavigate } from 'react-router-dom';
import { getListTableOrders, selectDataTableOrder, selectTotalCountOrderPage } from '../OrderSlice';
import { clearObject } from '../../../../common/ClearObject.helper';
import { usePageScroll } from '../../../../CustomHooks/usePageScroll';
import { useAppDispatch, useAppSelector } from '../../../../CustomHooks/storeHooks';
import useDebounce from '../../../../CustomHooks/useDebounce';
import { useEffectSkipFirstRender } from '../../../../CustomHooks/useEffectSkipFirstRender';
import dayjs from 'dayjs';

const AdminOrderPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [offset, setOffset] = useState<number>(0);
  const [mainFilters, setMainFilters] = useState<IMainFilters>(initStateMainFilters);
  const [subFilters, setSubFilters] = useState<ISubFilters>(initStateSubFilters);

  const totalCount = useAppSelector(selectTotalCountOrderPage);
  const dataTableOrder = useAppSelector(selectDataTableOrder);
  const debounceSearch = useDebounce(mainFilters.searchString, 500);
  usePageScroll(setOffset, 10);
  const requestDataGetListOrders = {
    sort: mainFilters.sort.value,
    searchString: mainFilters.searchString || undefined,
    limit: 10,
    offset: offset,
    dateFrom: subFilters.dateFrom ? dayjs(subFilters.dateFrom).add(3, 'hour').toISOString() : undefined,
    dateTo: subFilters.dateTo ? dayjs(subFilters.dateTo).add(3, 'hour').toISOString() : undefined,
    managerId: Number(subFilters.managerId) || undefined,
    materialTypeId: Number(subFilters.materialTypeId) || undefined,
    volumeFrom: subFilters.volumeFrom || undefined,
    volumeTo: subFilters.volumeTo || undefined,
    status: subFilters.status || undefined,
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);
  useEffect(() => {
    if (totalCount >= offset) {
      dispatch(getListTableOrders(clearObject(requestDataGetListOrders)));
    }
  }, [offset]);
  useEffectSkipFirstRender(() => {
    setOffset(0);
    dispatch(getListTableOrders(clearObject(requestDataGetListOrders)));
  }, [subFilters, debounceSearch, mainFilters.sort]);

  return (
    <PageWrapper>
      <div className={styles.topContainer}>
        <div className={styles.titleAndButtonContainer}>
          <TitleMain title={'Заказы'} />
          <ButtonFilled
            text={'Создать заказ'}
            svg={<NewPlusSvg />}
            sizeText={'small'}
            width={'fit-content'}
            onClick={() => navigate('/calculation_supplies/create')}
          />
        </div>
        <SubTitleMain subTitle={'Список всех заказов'} />
      </div>
      <div className={styles.filtersContainer}>
        <OrderFilters
          mainFilters={mainFilters}
          setMainFilters={setMainFilters}
          subFilters={subFilters}
          setSubFilters={setSubFilters}
        />
      </div>
      <div className={styles.customTableContainer}>
        <CustomTable
          link={true}
          columns={columnsTableOrder}
          data={dataTableOrder}
          emptyDescription=""
          emptyTitle="Данных нет"
          emptyFilterTitle=""
        />
      </div>
    </PageWrapper>
  );
};

export default AdminOrderPage;
