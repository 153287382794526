import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../context/context';
import styles from './FiltersButtons.module.scss';
import CustomDatePicker from '../../../../components/newUI/CustomDatePicker/CustomDatePicker';
import CustomTooltip from '../../../../components/newUI/CustomTooltip/CustomTooltip';
import { WarningEmptySvg } from '../../../../components/UI/svg-icon/WarningEmptySvg';
import { arrayFilterButtonsPaymentProcess, mainTextOfFilterButtons } from '../../constans';
import { IButton, IFilterButtons, ISection } from '../../type';
import dayjs, { Dayjs } from 'dayjs';
import { fetchGet } from '../../../../common/proxy-1C-fetch-auth';
import { formPrice } from '../../../../common/form-price.helper';
import { useMediaQuery } from 'react-responsive';
interface IProps {
  getDataTable?: () => Promise<void>;
  tab: string;
}

const FiltersProcessingOfPaymentProcess = ({ getDataTable, tab }: IProps) => {
  const [filterButtons, setFilterButtons] = useState<IFilterButtons>(arrayFilterButtonsPaymentProcess);
  const { user } = useContext(Context);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const getGeneralCalculations = async () => {
    try {
      const response = await fetchGet(`/reports/general-calculations/${user.companyType}`, {});
      const newDataButtons = { ...filterButtons };
      Object.keys(newDataButtons).forEach(key => {
        if (key === user.companyType) {
          newDataButtons[key as keyof IFilterButtons].forEach(section => {
            section.buttons.forEach(button => {
              button.sum = response.response[button.value];
            });
          });
        }
      });
      await setFilterButtons(newDataButtons);
    } catch (e) {
      console.log(e);
    }
  };

  const handleFilter = () => {
    getDataTable();
  };

  useEffect(() => {
    handleFilter();
    if (tab === '1') {
      getGeneralCalculations();
    }
  }, [tab]);

  return (
    <div className={styles.container}>
      {/*Фильтры в зависимости от выбранного личного кабинета*/}
      <div className={styles.filterButtonsAndTitleContainer}>
        {filterButtons[user.companyType].map((container: ISection) => (
          <div style={{ width: isMobile ? '100%' : container.widthContainer }} className={styles.filterButtonsContent}>
            <h2 className={styles.filterButtonsTitleText}>{container?.mainTitle}</h2>
            <div className={styles.filterButtonsContainer}>
              {container.buttons.map((button: IButton) => (
                <div
                  style={{
                    borderColor: '#9BA1B4',
                  }}
                  className={styles.filterButtonContainer}
                >
                  <div className={styles.filterButtonContent}>
                    <div className={styles.filterButtonSubtitleAndTooltip}>
                      <p className={styles.filterSubtitleText}>{button?.title}</p>
                      <div onClick={e => e.stopPropagation()}>
                        <CustomTooltip
                          SideTooltip={'up'}
                          MobileSideTooltip={'left'}
                          svg={<WarningEmptySvg />}
                          text={button.tooltipText}
                          widthTooltip={'250px'}
                        />
                      </div>
                    </div>
                    <p style={{ color: '#373D50' }} className={styles.valueText}>
                      {formPrice(button.sum, 'RUB')}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FiltersProcessingOfPaymentProcess;
