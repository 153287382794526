// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditAndAddMaterialPage_headerContainer__BvjCe {\n  display: flex;\n  justify-content: space-between;\n  margin: 16px 0 40px;\n  align-items: center;\n}\n\n.EditAndAddMaterialPage_headerTitle__XVgnR {\n  color: var(--color-gray900);\n  font-family: \"Golos Text\";\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n}\n\n.EditAndAddMaterialPage_headerBtnContainer__FwlGA {\n  gap: 16px;\n  display: flex;\n}\n\n@media (max-width: 768px) {\n  .EditAndAddMaterialPage_mobileBtnContainer__IIuW6 {\n    gap: 16px;\n    display: flex;\n  }\n  .EditAndAddMaterialPage_headerContainer__BvjCe {\n    margin: 16px 0 24px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/ShowcasesPage/EditAndAddMaterialPage/EditAndAddMaterialPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,2BAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AACA;EACE,SAAA;EACA,aAAA;AAEF;;AACA;EACE;IACE,SAAA;IACA,aAAA;EAEF;EAAA;IACE,mBAAA;EAEF;AACF","sourcesContent":[".headerContainer {\n  display: flex;\n  justify-content: space-between;\n  margin: 16px 0 40px;\n  align-items: center;\n}\n\n.headerTitle {\n  color: var(--color-gray900);\n  font-family: 'Golos Text';\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n}\n.headerBtnContainer{\n  gap: 16px;\n  display: flex;\n}\n\n@media (max-width: 768px) {\n  .mobileBtnContainer{\n    gap: 16px;\n    display: flex;\n  }\n  .headerContainer {\n    margin: 16px 0 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContainer": "EditAndAddMaterialPage_headerContainer__BvjCe",
	"headerTitle": "EditAndAddMaterialPage_headerTitle__XVgnR",
	"headerBtnContainer": "EditAndAddMaterialPage_headerBtnContainer__FwlGA",
	"mobileBtnContainer": "EditAndAddMaterialPage_mobileBtnContainer__IIuW6"
};
export default ___CSS_LOADER_EXPORT___;
