// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrderFilters_containerFilterBox__MxUCL {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.OrderFilters_titleText__74GCN {\n  color: var(--color-gray800);\n  font-family: \"Golos Text\";\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 24px;\n}\n\n.OrderFilters_contentFilterBox__Jon7n {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.OrderFilters_doubleCellFilterBox__AbVZf {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.OrderFilters_inputsVolumeContainer__jasvv {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.OrderFilters_textVolume__E0\\+a7 {\n  color: var(--color-gray800);\n  font-family: \"Golos Text\";\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminOrdersPage/components/OrderFilters/OrderFilters.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,2BAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAEF;;AAAA;EACE,2BAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAGF","sourcesContent":[".containerFilterBox {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.titleText {\n  color: var(--color-gray800);\n  font-family: \"Golos Text\";\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 24px;\n}\n\n.contentFilterBox {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.doubleCellFilterBox {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n.inputsVolumeContainer{\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n.textVolume{\n  color: var(--color-gray800);\n  font-family: \"Golos Text\";\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerFilterBox": "OrderFilters_containerFilterBox__MxUCL",
	"titleText": "OrderFilters_titleText__74GCN",
	"contentFilterBox": "OrderFilters_contentFilterBox__Jon7n",
	"doubleCellFilterBox": "OrderFilters_doubleCellFilterBox__AbVZf",
	"inputsVolumeContainer": "OrderFilters_inputsVolumeContainer__jasvv",
	"textVolume": "OrderFilters_textVolume__E0+a7"
};
export default ___CSS_LOADER_EXPORT___;
