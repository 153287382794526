import React, { useRef, useState } from 'react';
import styles from './ShowMoreTable.module.scss';
import useClickOutsideNode from '../../../../../CustomHooks/useClickOutsideNode';
import { ShowMoreSVG } from 'src/components/UI/svg-icon/ShowMoreSvg';
import { useAppDispatch, useAppSelector } from '../../../../../CustomHooks/storeHooks';
import { deleteMaterialOrderId, selectCurrentOrderMaterials } from '../../OrderSlice';
import { useNavigate } from 'react-router-dom';
import CreateNewBid from '../../../AdminBidsPage/components/CreateNewBid/CreateNewBid';
import CancelBid from '../../../AdminBidsPage/components/CancelBid/CancelBid';
interface IProps {
  orderDetailsId?: number;
  bidId?: number;
  deliveryCalculationId?: number;
  type: 'orders' | 'bids';
  unit?: string;
}
const ShowMoreAdminTariffs = ({ orderDetailsId, bidId, deliveryCalculationId, type, unit }: IProps) => {
  const dispatch = useAppDispatch();
  const refContainer = useRef();
  const navigate = useNavigate();
  const [openWindow, setOpenWindow] = useState<boolean>(false);
  const [openCreateBid, setOpenCreateBid] = useState<boolean>(false);
  const [openCancelBid, setOpenCancelBid] = useState<boolean>(false);
  const materialsOrder = useAppSelector(selectCurrentOrderMaterials);

  useClickOutsideNode(refContainer, setOpenWindow);

  const arrayButtonsOrders: { title: string; onClick: () => void }[] = [
   /* {
      title: 'Создать заявку',
      onClick: () => setOpenCreateBid(true),
    },*/
    {
      title: 'Удалить материал',
      onClick: () => {
        dispatch(deleteMaterialOrderId(orderDetailsId));
        setOpenWindow(false);
      },
    },
    {
      title: 'Исходный расчет',
      onClick: () => navigate(`/calculation_supplies/${deliveryCalculationId}`),
    },
  ];

  const arrayButtonsBids: { title: string; onClick: () => void }[] = [
/*    {
      title: 'Отменить заявку',
      onClick: () => {
        setOpenCancelBid(true);
      },
    },*/
    {
      title: 'Редактировать суточный объем',
      onClick: () => {},
    },
  ];

  return (
    <div onClick={e => e.stopPropagation()} ref={refContainer}>
      <div
        onClick={e => {
          e.stopPropagation();
          setOpenWindow(!openWindow);
        }}
        className={styles.svgContainer}
      >
        <ShowMoreSVG />
      </div>
      {openWindow && (
        <div className={styles.windowContainer} style={{ width: type === 'orders' ? '179px' : '290px' }}>
          {(type === 'orders' ? arrayButtonsOrders : arrayButtonsBids).map(button => {
            if (materialsOrder.length <= 1 && button.title === 'Удалить материал') return;
            return (
              <div key={button.title} onClick={button.onClick} className={styles.windowButtonContainer}>
                <p className={styles.windowButtonText}>{button.title}</p>
              </div>
            );
          })}
        </div>
      )}
      <CreateNewBid
        place={'order'}
        dataProp={{ orderDetailsId: String(orderDetailsId) }}
        openModal={openCreateBid}
        setOpenModal={setOpenCreateBid}
      />
      <CancelBid openModal={openCancelBid} setOpenModal={setOpenCancelBid} bidId={bidId} />
    </div>
  );
};

export default ShowMoreAdminTariffs;
