import React from 'react';

const ClockTimeEightOutlineSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12ZM12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM12.75 8C12.75 7.58579 12.4142 7.25 12 7.25C11.5858 7.25 11.25 7.58579 11.25 8V11.6893L8.96967 13.9697C8.67678 14.2626 8.67678 14.7374 8.96967 15.0303C9.26256 15.3232 9.73744 15.3232 10.0303 15.0303L12.5303 12.5303C12.671 12.3897 12.75 12.1989 12.75 12V8Z"
        fill="#1C274C"
      />
    </svg>
  );
};

export default ClockTimeEightOutlineSvg;
