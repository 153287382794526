import React, {FC, useRef} from 'react';
import { Map, Placemark, TypeSelector, YMaps } from '@pbe/react-yandex-maps';
import styles from './CustomYMap.module.scss';
import { zoomForMapsDefault } from './const';

interface IProps {
  coordinates?: {
    latitude: number;
    longitude: number;
    title: string;
    color?: string;
  }[];
  latitude?: string | number;
  height?: string;
  setCoordinates?: any;
  zoom?: number;
  isDraggable?:boolean
  longitude?: string | number;
}

const CustomYMap: FC<IProps> = ({
  zoom = zoomForMapsDefault,
  height = '350px',
  latitude = 22,
  longitude = 22,
  coordinates = [],
    setCoordinates,
    isDraggable=false,

}) => {
  const pointRef=useRef()
  const handleDragEnd = (event) => {
    const newCoordinates = event.get('target').geometry.getCoordinates();
    console.log(newCoordinates);
    setCoordinates(newCoordinates);
  };
  return (
    <div>
      <YMaps
        query={{
          apikey: '0e34bd35-ff2f-46ac-abbd-6a8143e395c3',
        }}
      >
        <div>
          <Map
              instanceRef={pointRef}
            style={{ height: height }}
            className={styles.yMapsObjectsPage}
            state={{
              center: coordinates.length
                ? [Number(coordinates[0].latitude), Number(coordinates[0].longitude)]
                : [Number(latitude), Number(longitude)],
              zoom: zoom,
            }}
          >
            {coordinates.length ? (
              coordinates.map((item, index) => (
                <Placemark
                  geometry={[Number(item.latitude), Number(item.longitude)]}
                  key={index}
                  options={{ draggable:true, iconColor: item.color || 'gray', preset: 'islands#blueMountainCircleIcon' }}
                  properties={{

                    iconCaption: item.title || '',
                  }}
                />
              ))
            ) : (
              <Placemark  onDragEnd={handleDragEnd}  options={{ draggable:isDraggable}}  geometry={[Number(latitude), Number(longitude)]} />
            )}

            {/*@ts-ignore*/}
            <TypeSelector options={{ float: 'right' }} />
          </Map>
        </div>
      </YMaps>
    </div>
  );
};

export default CustomYMap;
