import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import styles from './GSMOperationsHistoryTable.module.scss';
import { TGSMOperation, TOperationsFilter, TOperationsSort, TSortBox } from '../GSMtypes';
import cx from 'classnames';
import { formPrice } from 'src/common/form-price.helper';
import { SortArrowsSvg } from 'src/components/UI/svg-icon/SortArrowsSvg';
import ChevronDownSvg from 'src/components/newUI/SelectLk/assets/ChevronDownSvg';
import { useMediaQuery } from 'react-responsive';
import { convertDate } from '../GSMHelpers';
import { SortBox } from 'src/components/SortBox/SortBox';
import { fuelOptions, operationOptions } from '../GSMconstants';
import { EmptyList } from 'src/components/EmptyList';

type TProps = {
  operations: TGSMOperation[];
  operationsSort: TOperationsSort;
  setOperationsSort: Dispatch<SetStateAction<TOperationsSort>>;
  filter: TOperationsFilter;
  setFilter: Dispatch<SetStateAction<TOperationsFilter>>;
};

const headers = ['Дата', 'Вид топлива', 'Вид операции', 'Сумма, ₽'];

const changeVisualPrice = (price: string, isMobile: boolean) => {
  let formattedPrice = formPrice(Number(price), 'RUB', 'ru');
  return formattedPrice.slice(0, isMobile ? formattedPrice.length : formattedPrice.length - 2);
};

export const GSMOperationsHistoryTable = ({
  operations,
  operationsSort,
  setOperationsSort,
  filter,
  setFilter,
}: TProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [openFuelFilter, setOpenFuelFilter] = useState<boolean>(false);
  const [openOperationFilter, setOpenOperationFilter] = useState<boolean>(false);
  const refFuel = useRef(null);
  const refOperation = useRef(null);
  const isEmptyFilter =
    typeof filter?.fuel === 'string' ||
    typeof filter?.operation === 'string' ||
    (filter?.fuel?.value === 'Все' && filter?.operation?.value === 'Все');

  const handleSortClick = (type: 'date' | 'money') => {
    if (type === 'date') {
      setOperationsSort(operationsSort === 'lower-date' ? 'higher-date' : 'lower-date');
      return;
    }
    setOperationsSort(operationsSort === 'lower-money' ? 'higher-money' : 'lower-money');
  };

  const handleFilterClick = (type: 'fuel' | 'operation') => {
    if (type === 'fuel') {
      setOpenFuelFilter(!openFuelFilter);
      return;
    }
    setOpenOperationFilter(!openOperationFilter);
  };

  return (
    <div className={styles.container}>
      {!isMobile && !(!operations?.length && isEmptyFilter) && (
        <div className={cx(styles.row, styles.headerRow)}>
          <div className={cx(styles.cell, styles.headerCell, styles.leftCell)}>
            <div className={styles.filterButton} onClick={() => handleSortClick('date')}>
              <span>{headers[0]}</span>
              <SortArrowsSvg width="16px" color={'var(--color-blue900)'} />
            </div>
          </div>

          <div className={cx(styles.cell, styles.headerCell)}>
            <SortBox
              sortOptions={fuelOptions}
              openSort={openFuelFilter}
              setOpenSort={setOpenFuelFilter}
              sort={filter?.fuel}
              setSort={(value: TSortBox) => {
                setFilter(prevState => ({
                  ...prevState,
                  fuel: value,
                }));
              }}
              isSelectHidden={true}
              widthButton={'200px'}
              refItem={refFuel}
            />
            <div className={styles.filterButton} onClick={() => handleFilterClick('fuel')} ref={refFuel}>
              <span>{headers[1]}</span>
              <ChevronDownSvg width="16px" color={'var(--color-blue900)'} />
            </div>
          </div>

          <div className={cx(styles.cell, styles.headerCell)}>
            <SortBox
              sortOptions={operationOptions}
              openSort={openOperationFilter}
              setOpenSort={setOpenOperationFilter}
              sort={filter?.operation}
              setSort={(value: TSortBox) => {
                setFilter(prevState => ({
                  ...prevState,
                  operation: value,
                }));
              }}
              isSelectHidden={true}
              widthButton={'200px'}
              refItem={refOperation}
            />
            <div className={styles.filterButton} onClick={() => handleFilterClick('operation')} ref={refOperation}>
              <span>{headers[2]}</span>
              <ChevronDownSvg width="16px" color={'var(--color-blue900)'} />
            </div>
          </div>

          <div className={cx(styles.cell, styles.headerCell, styles.rightCell)}>
            <div className={styles.filterButton} onClick={() => handleSortClick('money')}>
              <span>{headers[3]}</span>
              <SortArrowsSvg width="16px" color={'var(--color-blue900)'} />
            </div>
          </div>
        </div>
      )}
      {operations?.length ? (
        <>
          {operations?.map((operation, index) => {
            const [date, time] = convertDate(operation?.date, isMobile);
            return (
              <div key={`operation${index}`} className={styles.mobileTable}>
                {isMobile && <span className={styles.tableTitle}>{date}</span>}
                <div className={styles.row}>
                  <div className={cx(styles.cell, styles.leftCell)}>
                    {isMobile ? time : `${date} (${time?.slice(1, time?.length)})`}
                  </div>
                  {!isMobile && <div className={styles.cell}>{operation?.product?.name || '–'}</div>}
                  <div className={styles.cell}>
                    {operation?.type + (isMobile ? ' ' + operation?.product?.name : '')}
                  </div>
                  <div
                    className={cx(
                      styles.cell,
                      styles.rightCell,
                      operation?.type === 'Обслуживание' ? styles.redPrice : styles.greenPrice,
                    )}
                  >
                    {(operation?.type === 'Обслуживание' ? '-' : '+') +
                      changeVisualPrice(operation?.amount.toString(), isMobile)}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <EmptyList
          title={'История операций отсутствует'}
          subTitle={'История появится как только выполните первую заправку'}
        />
      )}
    </div>
  );
};
