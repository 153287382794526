import mobileDriverTour from '../../../../assets/img/TourImages/mobileTourImages/mobileDriverTour.png';
import mobileDispatchingTour from '../../../../assets/img/TourImages/mobileTourImages/mobileDispatchingTour.png';
import mobileFlightTour from '../../../../assets/img/TourImages/mobileTourImages/mobileFlightTour.png';
import mobileCarTour from '../../../../assets/img/TourImages/mobileTourImages/mobileCarTour.png';
import mobileDocumentTour from '../../../../assets/img/TourImages/mobileTourImages/mobileDocumentTour.png';
import mobileProfileTour from '../../../../assets/img/TourImages/mobileTourImages/mobileProfileTour.png';
import mobileEmploerTour from '../../../../assets/img/TourImages/mobileTourImages/mobileEmploerTour.png';
import mobilePaymentProcessTour from '../../../../assets/img/TourImages/mobileTourImages/mobilePaymentProcessTour.png';
import mobileHelpTour from '../../../../assets/img/TourImages/mobileTourImages/mobileHelpTour.png';
import mobileGsmTour from '../../../../assets/img/TourImages/mobileTourImages/mobileGsmTour.png';
import mobileMainTour from '../../../../assets/img/TourImages/mobileTourImages/mobileMainTour.png';
import mobileFlightDriverTour from '../../../../assets/img/TourImages/mobileTourImages/mobileFlightDriverTour.png';
import mobileGsmDriverTour from '../../../../assets/img/TourImages/mobileTourImages/mobileGsmDriverTour.png';

export const arrayOfMobileTourContent = {
  carrier: [
    {
      title: 'Диспетчеризация',
      discription:
        'Распределяйте груз между рейсами и транспортными средствами, назначайте водителей. ' +
        'Отказывайтесь от остатков в случае необходимости и редактируйте планирование. ' +
        'Просматривайте общий список выполненных объемов в подразделе «Выполненные заявки».',
      src: mobileDispatchingTour,
    },
    {
      title: 'Рейсы',
      discription:
        'Отслеживайте статус выполнения рейсов водителями, просматривайте полную информацию по рейсам, ' +
        'переназначайте водителей и транспортное средство, отказывайтесь от рейсов до выезда водителей.',
      src: mobileFlightTour,
    },
    {
      title: 'Водители',
      discription:
        'Добавляйте и удаляйте водителей, чтобы назначать их на рейсы. Укажите их личные и паспортные данные,' +
        ' а также прикрепите водительское удостоверение и паспорт для регистрации.\n' +
        'После добавления водитель получит уведомление о регистрации и сможет войти в свой личный кабинет. ' +
        'В случае удаления водителя его номер телефона будет удален из системы, а доступ к личному кабинету - потерян.\n',
      src: mobileDriverTour,
    },
    {
      title: 'Транспортные средства',
      discription:
        'Добавляйте и удаляйте транспортные средства, распределяйте их между рейсами. Укажите номер и марку автомобиля, ' +
        'добавьте файл СТС и договор лизинга при наличии.',
      src: mobileCarTour,
    },
    {
      title: 'Сотрудники',
      discription:
        'Добавляйте и удаляйте сотрудников и назначайте им роли менеджера, бухгалтера и администратора. ' +
        'У каждого сотрудника доступен свой функционал личного кабинета, необходимый для работы.\n' +
        'После добавления сотрудника он получит уведомление о регистрации и сможет войти в личный кабинет.' +
        ' При удалении сотрудник потеряет доступ к кабинету, его номер также удалится из системы.',
      src: mobileEmploerTour,
    } /*
    {
      title: 'ГСМ',
      discription:
        'Добавляйте карты ГСМ, распределяйте их на себя и водителей, пополняйте и закрывайте карты. ' +
        'Просматривайте статистику по всем картам ГСМ (или по конкретной).',
      src: mobileGsmTour,
    },*/,
    {
      title: 'Документы',
      discription:
        'Формируйте договоры поставки или перевозки и заверяйте их электронной подписью. ' +
        'Просматривайте уже сформированные договоры, а также спецификации к ним.\n' +
        'В подразделе «Реестры» вы можете формировать электронный список реестров и отправлять его на проверку.',
      src: mobileDocumentTour,
    },
    {
      title: 'Взаиморасчеты',
      discription:
        'Просматривайте количество оплаченных и неоплаченных рейсов, следите за суммой оплаты по оригиналам и сканам транспортных накладных.',
      src: mobilePaymentProcessTour,
    },
    {
      title: 'Профиль',
      discription:
        'Добавляйте и просматривайте данные, редактируйте их при необходимости. \n' +
        'Завершите регистрацию: заполните личные и юридические данные, прикрепите учредительные документы. ' +
        'Затем ваш профиль отправится на проверку, после прохождения которой вы сможете работать в личном кабинете.\n',
      src: mobileProfileTour,
    },
    {
      title: 'Помощь',
      discription: 'Изучайте ответы на наиболее часто задаваемые вопросы.',
      src: mobileHelpTour,
    },
  ],
  driver: [
    {
      title: 'Главная',
      discription:
        'Отслеживайте размер своей заработной платы за выполненные рейсы за день, месяц или период. ' +
        'Просматривайте информацию по активному рейсу в удобном формате.',
      src: mobileMainTour,
    },
    {
      title: 'Рейсы',
      discription:
        'Отслеживайте количество активных и выполненных рейсов и просматривайте информацию о них. ' +
        'Проставляйте различные статусы по мере выполнения рейса: «Выехал на загрузку», «Начал загрузку», «Загрузился»,' +
        ' «Начал выгрузку», «Выгрузился», «Выполнен». Добавляйте транспортную накладную в карточке рейса. ' +
        'В этом же разделе вам доступен индивидуальный QR-код, который необходимо показать на месте загрузки материала',
      src: mobileFlightDriverTour,
    },
    {
      title: 'ГСМ',
      discription: 'Просматривайте информацию о карте ГСМ и заправляйтесь по ней.',
      src: mobileGsmDriverTour,
    },
    {
      title: 'Профиль',
      discription: 'Просматривайте свои личные данные, а также информацию о паспорте и водительском удостоверении.',
      src: mobileProfileTour,
    },
    {
      title: 'Помощь',
      discription: 'Изучайте ответы на наиболее часто задаваемые вопросы.',
      src: mobileHelpTour,
    },
  ],
};
