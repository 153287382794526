import styles from './EmployeesPage.module.scss';
import React from 'react';
import { regularPhone } from '../../common/regular-phone';

export const typeTranslations = {
  accountant: 'Бухгалтер',
  manager: 'Менеджер',
  admin: 'Администратор',
};
export const columnsEmployees = [
  {
    title: 'Фамилия Имя Отчество',
    dataIndex: 'fullName',
    width: 400,
    key: 'fullName',
    render: text => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          padding: '0 0 0 24px',
          height: '42px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '4px' }}>
          <p className={styles.text}>{text}</p>
        </div>
        <div
          style={{
            color: 'var(--color-gray200)',
            height: '28px',
            border: '1px solid var(--color-gray200)',
            margin: '0 24px',
          }}
        ></div>
      </div>
    ),
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone',
    width: 150,
    key: 'phone',
    render: text => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: '42px',
        }}
      >
        <p className={styles.textSoft}>{regularPhone(text)}</p>
        <div
          style={{
            color: 'var(--color-gray200)',
            height: '28px',
            border: '1px solid var(--color-gray200)',
            margin: '0 24px',
          }}
        ></div>
      </div>
    ),
  },
  {
    title: 'Должность',
    dataIndex: 'type',
    width: 150,
    key: 'type',
    render: text => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: '42px',
        }}
      >
        <p className={styles.textSoft}>{typeTranslations[text]}</p>
      </div>
    ),
  },
];
export const textTooltipByTypeOfUser = {
  carrier: (
    <p>
      Администратор – доступны все разделы (кроме раздела «Сотрудники») <br />
      Менеджер – доступны разделы: Диспетчеризация, Рейсы и Профиль <br />
      Бухгалтер – доступны разделы: Взаиморасчеты, УПД и Профиль
    </p>
  ),
  buyer: (
    <p>
      Администратор – доступны все разделы (кроме раздела «Сотрудники») <br />
      Менеджер – доступны разделы: Заказы, Объекты, Договора и спецификации, Профиль <br />
      Бухгалтер – доступны разделы: Взаиморасчеты, УПД, Договора и спецификации, Профиль
    </p>
  ),
  supplier: (
    <p>
      Администратор – доступны все разделы (кроме раздела «Сотрудники») <br />
      Менеджер – доступны разделы: Отгрузки, Карьеры, Договора и спецификации, Профиль <br />
      Бухгалтер – доступны разделы: Взаиморасчеты, УПД, Договора и спецификации, Профиль
    </p>
  ),
};
