import React, { useEffect, useState } from 'react';
import styles from './ReplenishmentsPage.module.scss';
import { PageWrapper } from 'src/components/PageWrapper';
import { Link, useNavigate } from 'react-router-dom';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { useMediaQuery } from 'react-responsive';
import Filters from '../components/Filters/Filters';
import { IDataCards, IFilters } from '../type';
import { initStateFiltersReplenishments } from '../constans';
import { CardWithCheckboxContainer } from './CardWithCheckboxContainer/CardWithCheckboxContainer';
import { fetchGet } from '../../../common/proxy-1C-fetch-auth';
import { toastError } from '../../../common/toastError.helper';
import BackButton from '../../../components/newUI/BackButton/BackButton';
import useDebounce from '../../../CustomHooks/useDebounce';
import { clearObject } from '../../../common/ClearObject.helper';

export const ReplenishmentsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const [filters, setFilters] = useState<IFilters>(initStateFiltersReplenishments);
  const [isAllClicked, setIsAllClicked] = useState<boolean>(false);
  const [dataCards, setDataCards] = useState<IDataCards[]>([]);
  const debouncedSearch = useDebounce(filters.search, 500);
  const getDataCards = async () => {
    try {
      const responseCards = await fetchGet(
        '/fuel-cards',
        clearObject({
          searchString: filters.search || undefined,
          order: filters.dateAdded,
          minBalance: Number(filters.balance.from) || undefined,
          maxBalance: Number(filters.balance.to) || undefined,
        }),
      );
      if (toastError(responseCards)) {
        return;
      }
      const activeCards: IDataCards[] = responseCards?.response?.filter(filterItem => filterItem.status === 'Активна');
      const correctDataCards: IDataCards[] = activeCards.map((mapItem: IDataCards) => {
        return {
          ...mapItem,
          checkActive: false,
        };
      });
      setDataCards(correctDataCards);
    } catch (e) {
      console.log(e);
    }
  };
  const checkedAllCards = checkActive => {
    setDataCards(prevState => prevState.map(card => ({ ...card, checkActive })));
  };
  const filteredArrayByCheckActive: IDataCards[] = dataCards.filter(filteredItem => filteredItem.checkActive === true);
  const getIdSelectedCardsToLocalStorage = (): void => {
    let arraySelectedId: number[] = filteredArrayByCheckActive.map((idItem: IDataCards) => idItem.id);
    let convertToStringArrayId: string = arraySelectedId.join();
    localStorage.setItem('selectedFuelCards', convertToStringArrayId);
  };
  useEffect(() => {
    getDataCards();
  }, [filters.dateAdded, filters.balance, debouncedSearch]);
  return (
    <PageWrapper style={{ backgroundColor: `${isMobile ? 'white' : 'var(--color-gray50)'}` }}>
      <BackButton onClick={() => navigate('/gsm')} textButton={'Вернуться к ГСМ'} />
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <span className={styles.title}>Пополнение карт</span>
          <ButtonFilled
            text="Пополнить"
            width={isMobile ? '100%' : '101px'}
            sizeText="small"
            onClick={() => {
              getIdSelectedCardsToLocalStorage();
              navigate('form');
            }}
            disabled={!filteredArrayByCheckActive.length}
          />
        </div>
        <Filters filters={filters} setFilters={setFilters} />
        <div className={styles.buttonAndCards}>
          <div
            className={styles.checkAllButton}
            onClick={() => {
              setIsAllClicked(!isAllClicked);
              checkedAllCards(!isAllClicked);
            }}
          >
            Выбрать все
          </div>
          <div className={filters.grid === 'Сетка' ? styles.cardsContainer : styles.cardsContainerList}>
            {dataCards?.map(dataCard => (
              <CardWithCheckboxContainer
                setDataCards={setDataCards}
                key={dataCard?.id}
                dataCard={dataCard}
                filters={filters}
                isAllClicked={isAllClicked}
              />
            ))}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
