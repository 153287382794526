import { SUBSERVER } from 'src/common/sub-server.constant';
import { instanceApi } from '../../../../globalConstans';
import { IShowcaseOptions } from '../types';

export const getShowcases = async () => {
  return await instanceApi.get(`/api/supplier/showcases`);
};
export const getShowcaseProperties = async (id: number) => {
  return await instanceApi.get(`/api/supplier/showcases/${id}`);
};
export const getQuarries = async () => {
  return await instanceApi.get(`/api/quarries/list`);
};

export const getSelectedQuarry = async (id: number) => {
  return await instanceApi.get(`/api/quarries/${id}`);
};

export const changeShowcase = async (id: number, options: IShowcaseOptions) => {
  return await instanceApi.patch(`/api/supplier/showcases/${id}`, options);
};

export const createShowcase = async (options: IShowcaseOptions) => {
  return await instanceApi.post(`/api/supplier/showcases/`, options);
};

export const deleteShowcase = async (id: number) => {
  return await instanceApi.delete(`/api/supplier/showcases/${id}`);
};

export const changePublicShowcase = async (id: number) => {
  return await instanceApi.patch(`/api/supplier/showcases/switch-link-mode/${id}`);
};

export const deleteImage = async (id: number, name: string) => {
  return await fetch(`${SUBSERVER}/api/files/${id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('apiKey')}` },
    credentials: 'include',
    body: JSON.stringify({
      communicationCategory: 'showcases',
      name,
      objectUuid: '',
    }),
  });
};
