import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import styles from './FormReplenishment.module.scss';
import { formPrice } from 'src/common/form-price.helper';
import CustomInput from '../../../../../components/newUI/CustomInput/CustomInput';
import { useMediaQuery } from 'react-responsive';
import CustomTooltip from '../../../../../components/newUI/CustomTooltip/CustomTooltip';
import InfoSvg from '../../../../../components/UI/svg-icon/InfoSvg';
import { Progress } from 'antd';
import ButtonFilled from '../../../../../components/newUI/ButtonFilled/ButtonFilled';
import { InputVerification } from '../../../../../components/UI/inputs/InputVerification';
import { IDataCards, IInputsFormReplenishment } from '../../../type';
import SuccessSvgVer2 from '../../../../../components/UI/svg-icon/SuccessSvgVer2';
import { useNavigate } from 'react-router-dom';
import { WalletGSMSvg } from '../../../../../components/UI/svg-icon/WalletGSMSvg';
import GSMCard from '../../../components/GSMCard/GSMCard';
import { fetchGet, fetchPost } from '../../../../../common/proxy-1C-fetch-auth';
import { toastError } from '../../../../../common/toastError.helper';
import { Context } from '../../../../../context/context';
import { formatPhoneNumber } from '../../../../../common/formatPhoneNumber.helper';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';
import { regForNumberWithComma } from 'src/common/number.helper';
import { ICommissionList } from '../../../../PaymentProcessPage/type';
interface IProps {
  codeValue: string;
  setCodeValue: React.Dispatch<string>;
  step: 'transfer' | 'smsAuth' | 'success';
  setStep: React.Dispatch<'transfer' | 'smsAuth' | 'success'>;
  inputsFormReplenishment: IInputsFormReplenishment;
  setInputsFormReplenishment: Dispatch<React.SetStateAction<IInputsFormReplenishment>>;
}
const FormReplenishment = ({
  codeValue,
  setCodeValue,
  step,
  setStep,
  inputsFormReplenishment,
  setInputsFormReplenishment,
}: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const refCode = useRef<HTMLInputElement | null>();
  const { user } = useContext(Context);
  const [focus, setFocus] = useState<boolean>(false);
  const [errorSending, setErrorSending] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const [showCards, setShowCards] = useState<boolean>(false);
  const [commissionList, setCommissionList] = useState<ICommissionList[]>([]);
  const [dataCards, setDataCards] = useState<IDataCards[]>([]);
  const [currentBalance, setCurrentBalance] = useState<number>(null);
  const [isLoadingTransferButton, setIsLoadingTransferButton] = useState<boolean>(false);
  const selectedFuelCardsFromLocalStorage: string = localStorage.getItem('selectedFuelCards');
  const currentQuantityCards: number = dataCards?.length;
  const calculateMoney = formPrice(
    Number(inputsFormReplenishment.sum.replace(',', '.')) * currentQuantityCards,
    'RUB',
    'ru',
  );
  const calculateMoneyForButtonTransfer = String(
    +(Number(inputsFormReplenishment.sum.replace(',', '.')) * currentQuantityCards).toFixed(2),
  ).replace('.', ',');
  const disableButtonByInputSum = !(inputsFormReplenishment?.sum === '0' || inputsFormReplenishment?.sum === '0,');
  const comparingSelectedBalanceAndInputValue =
    Number(inputsFormReplenishment.sum.replace(',', '.')) * currentQuantityCards <= currentBalance;
  const disableTransferButton = !(
    inputsFormReplenishment?.sum &&
    disableButtonByInputSum &&
    dataCards?.length &&
    comparingSelectedBalanceAndInputValue &&
    !isLoadingTransferButton
  );
  const getDataCards = async () => {
    try {
      const responseCards = await fetchGet('/fuel-cards', { cardsId: selectedFuelCardsFromLocalStorage });
      if (toastError(responseCards)) {
        return;
      }
      setDataCards(responseCards?.response);
    } catch (e) {
      console.log(e);
    }
  };
  const getDataBalance = async () => {
    try {
      const responseBalance = await fetchGet('/limits/available', {});
      if (toastError(responseBalance)) return;
      setCurrentBalance(responseBalance?.response?.limit);
    } catch (e) {
      console.log(e);
    }
  };
  const getListCommission = async () => {
    try {
      const response = await fetchGet(`/finance/commissions`, { type: 'ГСМ' });
      toastError(response);
      setCommissionList(response.response);
    } catch (e) {
      console.log(e);
    }
  };
  const transferLimit = async () => {
    try {
      const responseTransfer = await fetchPost('/limits/group-update', 'POST', {
        cardsId: dataCards.map(item => item.id),
        limit: Number(inputsFormReplenishment.sum.replace(',', '.')),
        code: Number(codeValue),
      });
      if (toastError(responseTransfer)) {
        setErrorSending(true);
        return;
      }
      setStep('success');
    } catch (e) {
      setErrorSending(true);
      console.log(e);
    }
  };
  const sendSms = async () => {
    setIsLoadingTransferButton(true);
    try {
      const responseSendSms = await fetchPost('/limits/send-sms', 'POST', {});
      if (toastError(responseSendSms)) return;
      setStep('smsAuth');
      setTimer(59);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingTransferButton(false);
    }
  };
  const closeButtonFunction = (id: number) => {
    setDataCards(dataCards.filter(item => item.id !== id));
  };
  const inputVerificationChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setCodeValue(value.replace(/\D/gu, '').slice(0, 5));
  };
  const convertVisualOfTimerCount = (): string => {
    if (timer < 10) {
      return `00:0${timer}`;
    } else {
      return `00:${timer}`;
    }
  };
  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);
  useEffect(() => {
    getDataBalance();
    getDataCards();
    getListCommission();
  }, []);
  useEffect(() => {
    return () => {
      localStorage.removeItem('selectedFuelCards');
    };
  }, []);
  useEffect(() => {
    if (codeValue.length === 5) {
      transferLimit();
    }
  }, [codeValue]);
  useEffect(() => {
    setCodeValue('');
    if (step === 'transfer') {
      setInputsFormReplenishment(prevState => ({ ...prevState, sum: '' }));
    }
  }, [step]);
  return (
    <>
      {step === 'transfer' && (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.transferContainer}>
              <div className={styles.blockTransferBalance}>
                <p className={styles.contentText}>Текущий баланс</p>
                <div className={styles.moneyContainer}>
                  <WalletGSMSvg />
                  <p className={styles.moneyText}>{formPrice(currentBalance, 'RUB', 'ru')}</p>
                </div>
                <div className={styles.commissionListContainer}>
                  {commissionList?.map((commission: ICommissionList, index) => {
                    return (
                      <div className={styles.commissionItemContainer}>
                        <div key={commission.id} className={styles.modalText}>
                          {commission.displayString}
                        </div>
                        {index === 0 && (
                          <CustomTooltip
                            svg={<InfoSvg color={'var(--color-blue800)'} />}
                            text={'При переводе с общего баланса на карты'}
                            widthTooltip={'307px'}
                            widthSvg={'16px'}
                            positionTooltipRight={'-13px'}
                            positionTooltipBottom={'23px'}
                            MobileSideTooltip={'left'}
                            SideTooltip={'left'}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={styles.blockTransfer}>
                <div className={styles.sumAndTooltipContainer}>
                  <p className={styles.contentText}>Сумма</p>
                  <CustomTooltip
                    svg={<InfoSvg color={'var(--color-blue800)'} />}
                    text={'Сумма кратна пополнению одной карты'}
                    widthTooltip={'300px'}
                    widthSvg={'16px'}
                    positionTooltipRight={'-13px'}
                    positionTooltipBottom={'23px'}
                    MobileSideTooltip={'left'}
                    SideTooltip={'left'}
                  />
                </div>
                <CustomInput
                  height={'48px'}
                  disabled={false}
                  value={inputsFormReplenishment.sum}
                  placeholder={'0,00 ₽'}
                  suffix={'₽'}
                  setValue={e => {
                    if ((regForNumberWithComma.test(e) || e === '') && Number(e.replace(',', '.')) <= 9999999.99) {
                      setInputsFormReplenishment(prevState => ({ ...prevState, sum: e }));
                    }
                  }}
                />
              </div>
            </div>
            <div className={styles.cardsAndButtonContainer}>
              <ButtonSimple
                text={showCards ? 'Скрыть карты' : 'Показать карты'}
                onClick={() => setShowCards(!showCards)}
              />
              {showCards &&
                dataCards?.map(dataCard => (
                  <GSMCard
                    closeButtonFunction={closeButtonFunction}
                    closeButton={true}
                    switcher={false}
                    key={dataCard.id}
                    dataCard={dataCard}
                    filters={{ grid: 'Список' }}
                  />
                ))}
            </div>
            {!isMobile && (
              <>
                <div className={styles.progressLineContainer}>
                  <Progress
                    trailColor={'white'}
                    showInfo={false}
                    percent={
                      (Number(inputsFormReplenishment.sum.replace(',', '.')) * currentQuantityCards * 100) /
                      currentBalance
                    }
                    size={['100%', 8]}
                    strokeColor="var(--color-orange10)"
                  />
                </div>
                {comparingSelectedBalanceAndInputValue ? (
                  <p className={styles.distribution}>Распределено {calculateMoney}</p>
                ) : (
                  <p className={styles.distributionError}>Сумма {calculateMoney} превышает текущий баланс</p>
                )}
              </>
            )}
          </div>
          {isMobile && (
            <>
              <div style={{ marginTop: '16px' }} className={styles.progressLineContainer}>
                <Progress
                  trailColor={'white'}
                  showInfo={false}
                  percent={
                    (Number(inputsFormReplenishment.sum.replace(',', '.')) * currentQuantityCards * 100) /
                    currentBalance
                  }
                  size={['100%', 8]}
                  strokeColor="var(--color-orange10)"
                />
              </div>
              <div className={styles.mobileDistributionContainer}>
                {comparingSelectedBalanceAndInputValue ? (
                  <p className={styles.distribution}>Распределено {calculateMoney}</p>
                ) : (
                  <p className={styles.distributionError}>Сумма {calculateMoney} превышает текущий баланс</p>
                )}
              </div>
            </>
          )}
          <ButtonFilled
            disabled={disableTransferButton}
            width={isMobile ? '100%' : '520px'}
            text={`Перевести ${calculateMoneyForButtonTransfer} ₽`}
            onClick={() => {
              sendSms();
            }}
          />
        </div>
      )}
      {step === 'smsAuth' && (
        <div className={styles.smsContainer}>
          <div className={styles.smsAuthContainer}>
            <p className={styles.smsAuthTitle}>Для подтверждения операции введите код из смс</p>
            <div className={styles.smsAuthSubTitle} style={{ display: 'inline' }}>
              Мы отправили код подтверждения на номер&ensp;{' '}
              <span className={styles.smsAuthNumber}>{formatPhoneNumber(user.phone)}</span>
            </div>
            <InputVerification
              style={{ height: '80px', padding: '16px' }}
              placeholder="00000"
              errorSending={errorSending}
              setErrorSending={setErrorSending}
              onInput={inputVerificationChange}
              value={codeValue}
              refNew={refCode}
              focus={focus}
              setFocus={setFocus}
              autoFocus={true}
            />
            {errorSending && <div className={styles.descriptionError}>Вы ввели неверный код. Попробуйте еще раз.</div>}
            <div style={{ marginTop: '24px' }}>
              {timer > 0 ? (
                <div className={styles.newCodeCounter}>
                  Получить новый код можно <br />
                  через {convertVisualOfTimerCount()}
                </div>
              ) : (
                <ButtonFilled
                  text={'Отправить код повторно'}
                  width={'100%'}
                  onClick={() => sendSms()}
                  disabled={isLoadingTransferButton}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {step === 'success' && (
        <div className={styles.successContainer}>
          <div className={styles.successContent}>
            <SuccessSvgVer2 />
            <div className={styles.successContentText}>
              <p className={styles.successThickText}>Карта успешно пополнена</p>
              <p className={styles.successSoftText}>Средства будут зачислены в течение 24 часов</p>
            </div>
          </div>
          <ButtonFilled width={isMobile ? '100%' : '520px'} text={'Готово'} onClick={() => navigate('/gsm')} />
        </div>
      )}
    </>
  );
};

export default FormReplenishment;
