import { recordToParams } from 'src/common/proxy-1C-fetch-auth';
import { instanceApi } from '../../../globalConstans';
import {
  IBidsListRequest,
  IGetFlightsRequest,
  IPostPerformersList,
  ITypeOfTab,
  IValueFieldsCreateAppForBackend,
  IValueFieldsCreateExecutorForBack,
} from './types';

export const getBidsTable = async (id: number, data: ITypeOfTab) => {
  return await instanceApi.get(`/api/admin/delivery-requests/manager/by-order/${id}?${recordToParams(data)}`);
};

export const getBidsMaterialList = async (id: number) => {
  return await instanceApi.get(`/api/admin/orders/material-list/${id}`);
};

export const postBid = async (data: IValueFieldsCreateAppForBackend) => {
  return await instanceApi.post(`/api/admin/delivery-requests`, data);
};

export const getBidsCancels = async () => {
  return await instanceApi.get(`/api/admin/delivery-requests/cancel-reasons`);
};

export const getDetailsListOrders = async (data: { orderId: number }) => {
  return await instanceApi.get(`/api/admin/orders/details/list?${recordToParams(data)}`);
};

export const deleteBid = async (id: number, data: { deliveryRequestsCancelReasonId: number }) => {
  return await instanceApi.delete(`/api/admin/delivery-requests/manager/reject/${id}`, { data });
};

export const getBidsList = async (data: IBidsListRequest) => {
  return await instanceApi.get(`/api/admin/delivery-requests/logist/list?${recordToParams(data)}`);
};

export const getBidDeliveryRequestsForLogist = async (id: number) => {
  return await instanceApi.get(`/api/admin/delivery-requests/logist/${id}`);
};

export const postPerformersListInfo = async (data: IPostPerformersList) => {
  return await instanceApi.post(`/api/admin/delivery-requests/logist/accept-executors`, data);
};

export const deletePerformersListInfo = async (id: number) => {
  return await instanceApi.delete(`/api/admin/delivery-requests/logist/reject/${id}`);
};

export const getBidsFlightsList = async (data: IGetFlightsRequest) => {
  return await instanceApi.get(`/api/admin/flights/list?${recordToParams(data)}`);
};

export const postNewExecutor = async (data: IValueFieldsCreateExecutorForBack) => {
  return await instanceApi.post(`/api/admin/delivery-requests/logist/add-new-executor`, data);
};

export const getCalculateExecutor = async (data: IValueFieldsCreateExecutorForBack) => {
  return await instanceApi.get(`/api/admin/delivery-requests/logist/calculate-executor?${recordToParams(data)}`);
};
