import React from 'react';
import styles from './MapLegend.module.scss';
import WeightFrameSvg from '../../../../../components/UI/svg-icon/WeightFrameSvg';
import TruckV2Svg from '../../../../../components/UI/svg-icon/TruckV2';
import QuarrySvg from '../../../../../components/UI/svg-icon/QuarrySvg';

interface ILegendList {
  icon: JSX.Element;
  title: string;
}
interface IProps {
  icon: JSX.Element;
  color: string;
}
const IconProperty: React.FC<IProps> = ({ icon, color }) => (
  <div className={styles.iconContainer} style={{ border: `5px solid ${color}` }}>
    {icon}
  </div>
);

const legendList: ILegendList[] = [
  {
    icon: <IconProperty icon={<TruckV2Svg />} color="var(--color-orange400)" />,
    title: 'Объект клиента',
  },
  {
    icon: <IconProperty icon={<QuarrySvg />} color="var(--color-orange400)" />,
    title: 'Выбранный карьер',
  },
  {
    icon: <IconProperty icon={<QuarrySvg />} color="var(--color-green700)" />,
    title: 'Карьер с лучшей ценой',
  },
  {
    icon: <IconProperty icon={<QuarrySvg />} color="var(--color-blue700)" />,
    title: 'Остальные карьеры',
  },
  {
    icon: <WeightFrameSvg />,
    title: 'Рамки',
  },
];

const MapLegend = () => {
  return (
    <div className={styles.container}>
      {legendList.map(item => (
        <div className={styles.contentContainer} key={item.title}>
          <p>{item.icon}</p>
          <p className={styles.contentText}>{item.title}</p>
        </div>
      ))}
    </div>
  );
};

export default MapLegend;
