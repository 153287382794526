import React from 'react';

const DeleteOutlineBlueSvg = ({ color = 'var(--color-blue800)' }: { color?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_31594_168670)">
        <path
          d="M4.99866 11L10.998 5M10.9987 11L4.99805 5M0.498657 8C0.498657 9.98912 1.28883 11.8968 2.69536 13.3033C4.10188 14.7098 6.00953 15.5 7.99866 15.5C9.98778 15.5 11.8954 14.7098 13.302 13.3033C14.7085 11.8968 15.4987 9.98912 15.4987 8C15.4987 6.01088 14.7085 4.10322 13.302 2.6967C11.8954 1.29018 9.98778 0.5 7.99866 0.5C6.00953 0.5 4.10188 1.29018 2.69536 2.6967C1.28883 4.10322 0.498657 6.01088 0.498657 8Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_31594_168670">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteOutlineBlueSvg;
