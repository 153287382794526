import React from 'react';
import styles from './ButtonNotFilled.module.scss';
interface IProps {
  width?: string;
  margin?: string;
  widthSvg?: string;
  height?: string;
  text?: string;
  svg?: any;
  onClick?: any;
  disabled?: boolean;
  htmlType?: 'submit' | 'button' | 'reset' /*Для Form.List*/;
  sizeText?: 'big' | 'small';
  color?: string;
}
const ButtonNotFilled = ({
  width,
  height,
  text,
  svg,
  onClick,
  htmlType,
  disabled,
  sizeText = 'big',
  color,
  widthSvg = '24px',
  margin,
}: IProps) => {
  const size = {
    big: [styles.bigTextButton, styles.bigContainerButton],
    small: [styles.smallTextButton, styles.smallContainerButton],
  };

  return (
    <>
      <button
        style={{
          color: color ? color : '',
          borderColor: color ? color : '',
          height: height ? height : '',
          width: width ? width : '588px',
          margin: margin ? margin : '',
        }}
        onClick={onClick}
        className={size[sizeText][1]}
        type={htmlType}
        disabled={disabled}
      >
        <div className={styles.textAndIconOfButton}>
          {svg && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: widthSvg }}>
              {svg}
            </div>
          )}
          {text && <p className={size[sizeText][0]}>{text}</p>}
        </div>
      </button>
    </>
  );
};

export default ButtonNotFilled;
