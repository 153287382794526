import React, { Dispatch, SetStateAction } from 'react';
import { ClipSvg } from '../../../../../components/UI/svg-icon/ClipSvg';

interface IProps {
  setFiles: any;
  disabled?: boolean;
}
const ImageUploadInput = ({ setFiles, disabled = false }: IProps) => {
  return (
    <div className="ProfilePageUploading">
      <input
        id="InputUploading"
        type={'file'}
        accept={'image/, .png,.jpg,.jpeg,.pdf,.heic'}
        onChange={({ target }) => {
          setFiles(prevState => [...prevState, target.files[0]]);
        }}
        disabled={disabled}
      />
      <label className="ProfilePageUploading__label" htmlFor="InputUploading">
        <div className="ProfilePageUploading__title">
          <div className="ProfilePageUploading__svg">
            <ClipSvg />
          </div>
          <div className="ProfilePageUploading__text">
            <span>Приложите файлы или документ</span>
            <span>в формате PNG, JPEG, PDF</span>
          </div>
        </div>
      </label>
    </div>
  );
};

export default ImageUploadInput;
