import React from 'react';
import styles from './MaterialCatalog.module.scss';
import { useMediaQuery } from 'react-responsive';
import { CirclePlusSvg } from 'src/components/UI/svg-icon/CirclePlusSvg';
import { useLocation, useNavigate } from 'react-router-dom';
import { IMaterials } from '../../type';
import { MaterialItem } from './MaterialItem/MaterialItem';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';
import { useAppSelector } from 'src/CustomHooks/storeHooks';
import { selectMaterialGroups, selectQuarryInfo } from '../../services/showcase/showcaseSlice';
import { IOption } from 'src/Pages/CarsPage/const';

export const MaterialCatalog = () => {
  const materials: IMaterials[] = useAppSelector(selectMaterialGroups);
  const quarryInfo: { quarries: IOption[]; currentQuarry: IOption } = useAppSelector(selectQuarryInfo);
  const navigate = useNavigate();
  const location = useLocation();
  const isPublicPath = location.pathname.startsWith('/store');
  const isCatalogPath = location.pathname.startsWith('/catalog');
  const currentShowcaseId = localStorage.getItem('currentShowcaseId');
  const currentQuarryId = localStorage.getItem('currentQuarryId');
  const isMobile = useMediaQuery({ maxWidth: 768 });
  let width = isMobile ? '24px' : '16px';

  return (
    <div className={styles.container}>
      <div className={styles.containerTitle}>
        <div className={styles.containerTitleText}>Каталог материалов</div>
        {!isCatalogPath && !isPublicPath && (
          <ButtonSimple
            isText={!isMobile}
            text={'Добавить материал'}
            svg={<CirclePlusSvg width={width} />}
            onClick={() => navigate(`/showcases/materials/${currentShowcaseId}`)}
          />
        )}
      </div>
      <div className={styles.cardsContainer + ' ' + (isCatalogPath ? styles.cardsContainerCatalog : '')}>
        {materials?.map((material, index) => {
          const link = isPublicPath
            ? `/store/product/${material?.materialTypeId}`
            : `/${isCatalogPath ? 'catalog' : 'showcases'}` +
              `/materials` +
              `/${material?.materialTypeId}${isCatalogPath || isPublicPath ? '' : '/' + currentShowcaseId}`;
          return (
            <div key={material?.materialTypeId + '_' + index}>
              <MaterialItem material={material} quarry={quarryInfo?.currentQuarry} link={link} sellUnitProps={'тн'} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
