import React from 'react';

export const TariffsSvgActive = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 11.11V5C21 3.9 20.11 3 19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H11.11C12.37 22.24 14.09 23 16 23C19.87 23 23 19.87 23 16C23 14.09 22.24 12.37 21 11.11ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM6 7H18V9H6V7ZM9.08 17H6V15H9.08C9.03 15.33 9 15.66 9 16C9 16.34 9.03 16.67 9.08 17ZM6 13V11H11.11C10.5 11.57 10.04 12.25 9.68 13H6ZM16 21C13.24 21 11 18.76 11 16C11 13.24 13.24 11 16 11C18.76 11 21 13.24 21 16C21 18.76 18.76 21 16 21ZM16.5 16.25L19.36 17.94L18.61 19.16L15 17V12H16.5V16.25Z"
        fill="currentColor"
      />
    </svg>
  );
};
