import React, { Dispatch, useContext, useEffect, useRef, useState } from 'react';
import styles from './SmsVerification.module.scss';
import { useMediaQuery } from 'react-responsive';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { InputVerification } from 'src/components/UI/inputs/InputVerification';
import SuccessSvgVer2 from 'src/components/UI/svg-icon/SuccessSvgVer2';
import { fetchPost } from 'src/common/proxy-1C-fetch-auth';
import { toastError } from 'src/common/toastError.helper';
import { Context } from '../../../../context/context';
import { formatPhoneNumber } from 'src/common/formatPhoneNumber.helper';
import { CloseSvg } from 'src/components/UI/svg-icon/CloseSvg';
import { formPrice, formPriceOnlyNumber } from 'src/common/form-price.helper';
import { declinationWord } from 'src/common/declinationWord.helper';
import { logout } from 'src/common/logout.mapping';
import { useNavigate } from 'react-router-dom';
import { steps } from '../FiltersProcessingOfPaymentProcess/FiltersProcessingOfPaymentProcess';
import { ICommissionList, IFundsInfo } from '../../type';
import img from '../../../../assets/img/ErrorPng.png';
import { WalletGSMSvg } from 'src/components/UI/svg-icon/WalletGSMSvg';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';
import { PaymentsFundsSvg } from 'src/components/UI/svg-icon/PaymentsFundsSvg';
import CustomTooltip from 'src/components/newUI/CustomTooltip/CustomTooltip';
import { QuestionSvg } from 'src/components/UI/svg-icon/QuestionSvg';
import CustomInput from 'src/components/newUI/CustomInput/CustomInput';
import { regForNumberWithComma } from 'src/common/number.helper';

enum links {
  AUTH = '/auth',
}

const minutes = ['30', '60'];

interface IProps {
  codeValue: string;
  setCodeValue: React.Dispatch<string>;
  step: steps;
  setStep: React.Dispatch<steps>;
  inputsFormReplenishment: string;
  setInputsFormReplenishment: Dispatch<React.SetStateAction<string>>;
  currentBalance: string;
  fundsInfo: IFundsInfo;
  closeModal: () => void;
  commissionList: ICommissionList[];
}

export const SmsVerification = ({
  commissionList,
  codeValue,
  setCodeValue,
  step,
  setStep,
  inputsFormReplenishment,
  setInputsFormReplenishment,
  currentBalance,
  fundsInfo,
  closeModal,
}: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { user, setUser } = useContext(Context);
  const navigate = useNavigate();
  const [focus, setFocus] = useState<boolean>(false);
  const [errorSending, setErrorSending] = useState<boolean>(false);
  const [isLoadingTransferButton, setIsLoadingTransferButton] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const refCode = useRef<HTMLInputElement | null>();
  const [attempts, setAttempts] = useState<string>('');
  const [blockMinutes, setBlockMinutes] = useState<number>(0);
  const { minimumAmount, commission } = fundsInfo;
  const formattedMinimalAmount = formPrice(minimumAmount, 'RUB', 'ru').replace(',00', '');
  const isSumMore = Number(inputsFormReplenishment?.replace(',', '.')) > Number(currentBalance?.replace(',', '.'));
  const isSumLess = Number(inputsFormReplenishment.replace(',', '.')) < minimumAmount;
  const isPhoneError = Boolean(inputsFormReplenishment) && (isSumMore || isSumLess);
  const isErrorText =
    Number(inputsFormReplenishment.replace(',', '.')) < minimumAmount
      ? `Сумма не может быть < ${formattedMinimalAmount}`
      : 'Сумма не может превышать общий баланс';
  const errorWordLeft = declinationWord(Number(attempts), ['Осталась', 'Осталось']);
  const errorWordAttempts = declinationWord(Number(attempts), ['попытка', 'попытки', 'попыток']);
  const blockDescription =
    'Вы ввели неверный код 3 раза. ' +
    (blockMinutes === 3
      ? 'С вами свяжется администратор для уточнения деталей'
      : `Аккаунт будет разблокирован через  ${minutes[blockMinutes ? blockMinutes - 1 : 0]} минут`);

  const inputVerificationChange = ({ target: { value } }) => {
    setCodeValue(value.replace(/\D/, '').slice(0, 5));
  };

  const setFormattedPhone = (value: string) => {
    if (Number(value?.replace(',', '.')) > Number(currentBalance?.replace(',', '.'))) {
      setInputsFormReplenishment(currentBalance?.replace('.', ','));
      return;
    }
    if ((regForNumberWithComma?.test(value) || value === '') && Number(value?.replace(',', '.')) <= 9999999.99) {
      setInputsFormReplenishment(value);
    }
  };

  const sendSms = async () => {
    setIsLoadingTransferButton(true);
    try {
      const responseSendSms = await fetchPost('/finance/withdrawal/send-sms', 'POST', {});
      if (toastError(responseSendSms)) {
        return;
      }
      setStep(steps.SMSAUTH);
      setTimer(59);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingTransferButton(false);
    }
  };

  const transfer = async () => {
    try {
      const responseTransfer = await fetchPost('/finance/withdrawal/confirm', 'POST', {
        code: Number(codeValue),
        amount: Number(inputsFormReplenishment?.replace(',', '.')),
      });
      if (responseTransfer?.error) {
        if (responseTransfer?.message[0]?.count === 0) {
          setBlockMinutes(responseTransfer?.message[0]?.blockNumber);
          setStep(steps.ERROR);
          setTimeout(() => {
            logout(user, setUser);
            navigate(links.AUTH);
          }, 5000);
        }
        setErrorSending(true);
        setAttempts(responseTransfer?.message[0]?.count || '');
        setCodeValue('');
        return;
      }
      setStep(steps.SUCCESS);
    } catch (e) {
      setErrorSending(true);
      console.log(e);
    }
  };

  const convertVisualOfTimerCount = (): string => {
    if (timer < 10) {
      return `00:0${timer}`;
    }
    return `00:${timer}`;
  };

  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  useEffect(() => {
    if (codeValue.length === 5) {
      transfer();
    }
  }, [codeValue]);

  return (
    <div className={styles.modalContainer}>
      {step === steps.TRANSFER && (
        <>
          <div className={styles.titlesContainer}>
            <div className={styles.mobileCloseButtonAndTitle}>
              <p className={styles.modalTitle}>Вывод средств</p>
              {isMobile && (
                <div className={styles.closeButton} onClick={() => closeModal()}>
                  <CloseSvg />
                </div>
              )}
            </div>
            <p className={styles.secondaryTitle}>Банковский перевод будет выполнен в течение одного рабочего дня</p>
          </div>
          <div className={styles.blockTransferBalance}>
            <p className={styles.modalSubtitle}>Средства к выводу</p>
            <div className={styles.moneyAndButton}>
              <div className={styles.moneyContainer}>
                <div style={{ paddingTop: '8px' }}>
                  <WalletGSMSvg />
                </div>
                <p className={styles.moneyText}>{formPrice(Number(currentBalance) || 0, 'RUB', 'ru')}</p>
              </div>
              <ButtonSimple
                text={'Вывести все'}
                svg={<PaymentsFundsSvg size={isMobile ? '20px' : '24px'} />}
                onClick={() => setInputsFormReplenishment(currentBalance?.replace('.', ','))}
              />
            </div>
          </div>
          <div className={styles.blockTransfer}>
            <div className={styles.sumAndTooltipContainer}>
              <p className={styles.modalSubtitle}>Введите сумму к выводу</p>
              <CustomTooltip
                svg={<QuestionSvg color={'var(--color-blue800)'} />}
                text={`Сумма не может превышать общий баланс. Сумма не может быть < ${formattedMinimalAmount}`}
                widthTooltip={'318px'}
                widthSvg={'16px'}
                positionTooltipBottom={'20px'}
                MobileSideTooltip={'up'}
                SideTooltip={'up'}
                isRight={isMobile}
              />
            </div>
            <CustomInput
              height={'48px'}
              disabled={false}
              value={inputsFormReplenishment}
              placeholder={'0 ₽'}
              suffix={'₽'}
              error={isPhoneError}
              errorText={isErrorText}
              widthError={Number(inputsFormReplenishment.replace(',', '.')) < minimumAmount ? '239px' : '312px'}
              setValue={value => setFormattedPhone(value)}
            />
            <div className={styles.commissionListContainer}>
              {commissionList.map((commission: ICommissionList) => {
                return (
                  <div key={commission.id} className={styles.modalText}>
                    {commission.displayString}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.modalButtonsContainer}>
            <ButtonFilled
              disabled={!inputsFormReplenishment || isPhoneError}
              width={isMobile ? '100%' : '520px'}
              text={`Вывести ${formPriceOnlyNumber(Number(inputsFormReplenishment.replace(',', '.')) || 0, 'RUB', 'ru')} ₽`}
              onClick={() => sendSms()}
            />
          </div>
        </>
      )}
      {step === steps.SMSAUTH && (
        <div className={styles.smsContainer}>
          <div className={styles.smsAuthContainer}>
            <div className={styles.mobileCloseButtonAndTitle}>
              <p className={styles.smsAuthTitle}>Для подтверждения операции введите код из смс</p>
              {isMobile && (
                <div
                  className={styles.closeButton}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <CloseSvg />
                </div>
              )}
            </div>
            <div className={styles.smsAuthSubTitle} style={{ display: 'inline' }}>
              Мы отправили код подтверждения на номер&ensp;
              <span className={styles.smsAuthNumber}>{formatPhoneNumber(user.phone)}</span>
            </div>
            <div
              className={styles.codeAndTextContainer}
              style={{ gap: `${errorSending || isMobile ? '24px' : '32px'}` }}
            >
              <div>
                <InputVerification
                  style={{ height: '80px', padding: '16px' }}
                  placeholder="00000"
                  errorSending={errorSending}
                  setErrorSending={setErrorSending}
                  onInput={inputVerificationChange}
                  value={codeValue}
                  refNew={refCode}
                  focus={focus}
                  setFocus={setFocus}
                  autoFocus={true}
                />
                {errorSending && (
                  <div className={styles.descriptionError}>
                    {Number(attempts)
                      ? `Вы ввели неверный код. ${errorWordLeft} ${attempts} ${errorWordAttempts} (максимум 3).`
                      : attempts}
                  </div>
                )}
              </div>
              <div>
                {timer > 0 ? (
                  <div className={styles.newCodeCounter}>
                    Получить новый код можно <br />
                    через {convertVisualOfTimerCount()}
                  </div>
                ) : (
                  <ButtonFilled
                    text={'Получить новый код'}
                    width={'100%'}
                    onClick={() => sendSms()}
                    disabled={isLoadingTransferButton}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {step === steps.SUCCESS && (
        <div className={styles.successContainer}>
          {isMobile && (
            <div
              className={isMobile ? styles.closeButtonOne : styles.closeButton}
              onClick={() => {
                closeModal();
              }}
            >
              <CloseSvg />
            </div>
          )}
          <div className={styles.successContent}>
            <SuccessSvgVer2 size={isMobile ? '147px' : '184px'} />
            <div className={styles.successContentText}>
              <p className={styles.successThickText}>Вывод средств выполняется</p>
              <p className={styles.successSoftText}>Ожидайте перевода на следующий рабочий день</p>
            </div>
          </div>
          <ButtonFilled
            width={'100%'}
            text={'Готово'}
            onClick={() => {
              closeModal();
            }}
          />
        </div>
      )}
      {step === steps.ERROR && (
        <div className={styles.successContainer}>
          <div className={styles.successContent}>
            <img className={styles.errorImage} width={isMobile ? '290px' : '432px'} src={img} alt="" />
            <div className={styles.successContentText} style={{ padding: '16px' }}>
              <p className={styles.successThickText}>Ваш аккаунт заблокирован</p>
              <p className={styles.successSoftText}>{blockDescription}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
