import { instanceApi } from '../../../globalConstans';
import { IOrderCreateRequestDTO, ISpecificationRequest } from './type';
import { recordToParams } from '../../../common/proxy-1C-fetch-auth';
import { IGetListOrdersRequestData, IRequestDataPatchOrderId } from './type';

export const getContractOrders = async (id: number) => {
  return await instanceApi.get(`/api/admin/orders/contract-orders?contractId=${id}`);
};

export const getOrderById = async (id: number) => {
  return await instanceApi.get(`/api/admin/orders/${id}`);
};
export const postCreateOrder = async (date: IOrderCreateRequestDTO) => {
  return await instanceApi.post(`/api/admin/orders`, date);
};

export const getListOrders = async (data: IGetListOrdersRequestData) => {
  return await instanceApi.get(`/api/admin/orders?${recordToParams(data)}`);
};

export const deleteMaterialOrder = async (id: number) => {
  return await instanceApi.delete(`/api/admin/orders/details/${id}`);
};

export const patchOrderId = async (id: number, data: IRequestDataPatchOrderId) => {
  return await instanceApi.patch(`/api/admin/orders/${id}`, data);
};

export const patchMarginOrderId = async (id: number, data: { plannedMargin: number }) => {
  return await instanceApi.patch(`/api/admin/orders/details/${id}`, data);
};

export const postUploadImages = async (formData: any) => {
  return await instanceApi.post(`/api/gateway/files`, formData);
};

export const postSpecificationEDO = async ({ id, data }: { id: number; data?: ISpecificationRequest }) => {
  return await instanceApi.post(`/api/admin/orders/add-specification/${id}`, data);
};

export const getSpecificationTemplate = async (id: number) => {
  return await instanceApi.get(`/api/admin/orders/specification-template?orderId=${id}`);
};

export const getEDOIdentificator = async (id: number) => {
  return await instanceApi.get(`/api/admin/information/edo-identificators?companyId=${id}`);
};
