import React from 'react';

const WeightFrameSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="black">
      <path
        d="M1 24V9M23 24V9M1 9V6C1 4.89543 1.89543 4 3 4H6.5M1 9H12M1 9L6.5 4M23 9V6C23 4.89543 22.1046 4 21 4H17.5M23 9H12M23 9L17.5 4M12 9L17.5 4M12 9L6.5 4M17.5 4H6.5"
        stroke="#373D50"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default WeightFrameSvg;
