import React, { useEffect } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import styles from './ShowcasesPage.module.scss';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import { useMediaQuery } from 'react-responsive';
import ShowcaseCards from './components/ShowcaseCards/ShowcaseCards';
import LoadingSpin from '../../components/newUI/LoadingSpin/LoadingSpin';
import { EmptyList } from '../../components/EmptyList';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import { getAllShowcases, selectShowcases, selectShowcasesLoading } from './services/showcase/showcaseSlice';

const ShowcasesPage = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectShowcasesLoading);
  const showcases = useAppSelector(selectShowcases);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const getShowcases = async () => {
    dispatch(getAllShowcases());
  };

  const createShowcase = async () => {
    navigate(`/showcases/create`);
  };

  useEffect(() => {
    getShowcases();
  }, []);

  return (
    <PageWrapper>
      <div className={styles.titleAndSubtitleAndButtonContainer}>
        <div className={styles.titleAndButtonContainer}>
          <TitleMain title={'Витрины'} />
          {!isMobile && (
            <ButtonFilled
              width={'186px'}
              sizeText={'small'}
              svg={<NewPlusSvg />}
              text={'Добавить витрину'}
              onClick={() => createShowcase()}
            />
          )}
        </div>
        <div className={styles.subTitleContainer}>
          <SubTitleMain subTitle={'Просмотр и добавление витрин для карьеров'} />
        </div>
        {isMobile && (
          <ButtonFilled
            width={'100%'}
            sizeText={'small'}
            svg={<NewPlusSvg />}
            text={'Добавить витрину'}
            onClick={() => createShowcase()}
          />
        )}
      </div>
      {loading ? (
        <LoadingSpin />
      ) : (
        <>
          {showcases.length ? (
            <ShowcaseCards />
          ) : (
            <EmptyList
              title={'Витрины отсутствуют'}
              subTitle={'В данный момент у вас нет ни одной витрины. Добавьте данные, чтобы начать работу.'}
            />
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default ShowcasesPage;
