import React, { useEffect, useState } from 'react';
import { formPrice } from 'src/common/form-price.helper';
import styles from './GSMCard.module.scss';
import BackGroundGSMCardSvg from '../../../../components/UI/svg-icon/BackGroundGSMCardSvg';
import { useMediaQuery } from 'react-responsive';
import { Switch } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { formCardNumber } from 'src/common/formCardNumber.helper';
import { IDataCards, IFilters, ISwitcherState } from '../../type';
import cx from 'classnames';
import { fetchPost } from '../../../../common/proxy-1C-fetch-auth';
import { toastError } from '../../../../common/toastError.helper';
import { CloseCircleNewSvg } from '../../../../components/UI/svg-icon/CloseCircleNewSvg';
import CustomTooltip from '../../../../components/newUI/CustomTooltip/CustomTooltip';
import InfoSvg from '../../../../components/UI/svg-icon/InfoSvg';
import { declinationWord } from 'src/common/declinationWord.helper';
import { TimerGSM } from 'src/components/UI/svg-icon/TimerGSM';
import { initStateSwitcherState } from '../../constans';
interface IProps {
  filters: IFilters;
  dataCard?: IDataCards;
  switcher?: boolean;
  closeButton?: boolean;
  closeButtonFunction?: (id: number) => void;
}

const GsmCard = ({ filters, dataCard, switcher = true, closeButton = false, closeButtonFunction }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [switchState, setSwitchState] = useState<ISwitcherState>(initStateSwitcherState);

  const switchText = switchState.check ? 'Активна' : 'Заблокирована';
  const isReplenishmentsPage = pathname.indexOf('replenishments') !== -1;
  const switchCardStatus = async (id: number) => {
    setSwitchState(prevState => ({ ...prevState, sending: true, check: !switchState.check }));
    try {
      const responseSwitchCardStatus = await fetchPost(`/fuel-cards/switch-card-status/${id}`, 'PATCH', {
        status: switchState.check ? 'Заблокирована' : 'Активна',
      });
      if (toastError(responseSwitchCardStatus)) {
        setSwitchState(prevState => ({ ...prevState, sending: false, check: switchState.check }));
        return;
      }
      setSwitchState(prevState => ({ ...prevState, sending: false }));
    } catch (e) {
      setSwitchState(prevState => ({ ...prevState, sending: false, check: switchState.check }));
      console.log(e);
    }
  };
  const closeTime: number = Math.floor(
    (new Date(dataCard?.deletedAt).getTime() + 3 * 1000 * 60 * 60 - new Date().getTime()) / (1000 * 60 * 60) + 24,
  );
  useEffect(() => {
    setSwitchState(prevState => ({ ...prevState, check: dataCard?.status === 'Активна' }));
  }, []);
  return (
    <>
      {/*Сетка ПК и Мобильная версия*/}
      {filters.grid === 'Сетка' && (
        <div
          className={cx(styles.container, isReplenishmentsPage ? styles.containerReplenishments : '')}
          onClick={() => navigate(`/gsm/${dataCard?.id}`)}
        >
          <div className={styles.moneyAndClosedStatus}>
            <div className={styles.moneyAndActiveButtonContainer}>
              <p className={styles.textMoney}>
                {formPrice(dataCard.limit === null ? 0 : dataCard?.limit?.current || 0, 'RUB', 'ru')}
              </p>
              {!isReplenishmentsPage && switcher && (
                <div className={styles.activeContainer} onClick={event => event.stopPropagation()}>
                  {/*Верстка в зависимости от статуса*/}
                  {dataCard?.status === 'Активна' || dataCard?.status === 'Заблокирована' ? (
                    <>
                      <p className={styles.activeText}>{switchText}</p>
                      <Switch
                        disabled={switchState.sending}
                        className={styles.switcher}
                        checked={switchState.check}
                        onChange={() => switchCardStatus(dataCard?.id)}
                      />
                    </>
                  ) : (
                    <p className={styles.activeText}>{dataCard?.status}</p>
                  )}
                </div>
              )}
            </div>
            {dataCard?.status === 'Закрыта' && (
              <span
                className={styles.closedStatus}
              >{`Исчезнет через ${closeTime} ${declinationWord(closeTime, ['час', 'часа', 'часов'])}`}</span>
            )}
          </div>
          <div className={styles.cardHolderAndNumberContainer}>
            <div className={styles.cardHolderContainer}>
              <p className={styles.cardHolderTitle}>Владелец</p>
              <p className={styles.cardHolderName}>{dataCard?.owner}</p>
            </div>
            {dataCard?.status === 'Выпускается' ? (
              <p className={styles.releaseText}>Номер присвоится после выпуска</p>
            ) : (
              <p className={styles.numberText}>{formCardNumber(dataCard?.cardNumber)}</p>
            )}
          </div>
          <div className={styles.backGround}>
            <BackGroundGSMCardSvg width={isMobile ? '134px' : '165px'} height={isMobile ? '153px' : '188px'} />
          </div>
        </div>
      )}
      {/*Список ПК*/}
      {filters.grid === 'Список' && !isMobile && (
        <div className={styles.listContainer} onClick={() => navigate(`/gsm/${dataCard?.id}`)}>
          <p
            style={{ width: '200px' }}
            className={dataCard?.status === 'Выпускается' ? styles.listReleaseThickText : styles.listThickText}
          >
            {dataCard?.status === 'Выпускается'
              ? 'Номер присвоится после выпуска'
              : formCardNumber(dataCard?.cardNumber)}
          </p>
          {!isReplenishmentsPage && (
            <>
              <div className={styles.borderList} />
              {switcher && (
                <>
                  <div
                    style={{ width: '165px' }}
                    className={styles.activeContainer}
                    onClick={event => event.stopPropagation()}
                  >
                    {/*Верстка в зависимости от статуса*/}
                    {dataCard?.status === 'Активна' || dataCard?.status === 'Заблокирована' ? (
                      <>
                        <p className={styles.listThickTextStatus}>{switchText}</p>
                        <Switch
                          disabled={switchState.sending}
                          className={styles.switcher}
                          checked={switchState.check}
                          onChange={() => switchCardStatus(dataCard?.id)}
                        />
                      </>
                    ) : (
                      <>
                        <p className={styles.listThickTextStatusWithoutCheckbox}>{dataCard?.status}</p>
                        {dataCard?.deletedAt && (
                          <CustomTooltip
                            SideTooltip={'up'}
                            MobileSideTooltip={'up'}
                            svg={<TimerGSM size={isMobile ? '20px' : '24px'} />}
                            text={`Исчезнет через ${closeTime} ${declinationWord(closeTime, ['час', 'часа', 'часов'])}`}
                            widthSvg={'24px'}
                            widthTooltip={'200px'}
                            positionTooltipBottom={'28px'}
                            isRight={isMobile}
                          />
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
              <div className={styles.borderList} />
            </>
          )}
          <div className={styles.cardHolderContainerList}>
            <p className={styles.listNormalText}>Владелец:</p>
            <p className={styles.listThickText}>{dataCard?.owner}</p>
          </div>
          <div className={styles.borderList} />
          <div className={styles.closeButtonAndMoneyContainer}>
            <p className={styles.textMoney}>
              {formPrice(dataCard.limit === null ? 0 : dataCard?.limit?.current, 'RUB', 'ru')}
            </p>
            {closeButton && (
              <div
                onClick={e => {
                  e.stopPropagation();
                  closeButtonFunction(dataCard.id);
                }}
                className={styles.closeButtonContainer}
              >
                <CloseCircleNewSvg color={'var(--color-blue800)'} width={'24px'} />
              </div>
            )}
          </div>
          <div style={{ borderRadius: '12px', height: '64px' }} className={styles.backGroundList}>
            <BackGroundGSMCardSvg width={isMobile ? '134px' : '165px'} height={isMobile ? '153px' : '188px'} />
          </div>
        </div>
      )}
      {/*Список Мобильная версия*/}
      {filters.grid === 'Список' && isMobile && (
        <div className={styles.listContainer} onClick={() => navigate(`/gsm/${dataCard?.id}`)}>
          <div className={styles.mobileListString}>
            <p className={styles.textMoneyList}>
              {formPrice(dataCard.limit === null ? 0 : dataCard?.limit?.current || 0, 'RUB', 'ru')}
            </p>
            {closeButton && (
              <div
                onClick={e => {
                  e.stopPropagation();
                  closeButtonFunction(dataCard.id);
                }}
                className={styles.closeButtonContainer}
              >
                <CloseCircleNewSvg color={'var(--color-blue800)'} width={'24px'} />
              </div>
            )}
            {switcher && !isReplenishmentsPage && (
              <div className={styles.activeContainer} onClick={e => e.stopPropagation()}>
                {/*Верстка в зависимости от статуса*/}
                {dataCard?.status === 'Активна' || dataCard?.status === 'Заблокирована' ? (
                  <>
                    <p className={styles.listSoftText}>{switchText}</p>
                    <Switch
                      disabled={switchState.sending}
                      className={styles.switcher}
                      checked={switchState.check}
                      onChange={() => switchCardStatus(dataCard?.id)}
                    />
                  </>
                ) : (
                  <>
                    <p className={styles.listSoftText}>{dataCard?.status}</p>
                    {dataCard?.deletedAt && (
                      <CustomTooltip
                        SideTooltip={'up'}
                        MobileSideTooltip={'left'}
                        svg={<TimerGSM size={isMobile ? '20px' : '24px'} />}
                        text={`Исчезнет через ${closeTime} ${declinationWord(closeTime, ['час', 'часа', 'часов'])}`}
                        widthSvg={'24px'}
                        widthTooltip={'200px'}
                        positionTooltipBottom={'28px'}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className={styles.mobileListString}>
            <p className={styles.listThickTextName}>{dataCard?.owner}</p>
            <p className={styles.listThickText}>
              {dataCard?.status === 'Выпускается' ? (
                <div onClick={e => e.stopPropagation()} className={styles.tooltipContainer}>
                  <CustomTooltip
                    svg={<InfoSvg color={'var(--color-gray600)'} />}
                    text={'Номер присвоится после выпуска'}
                    widthTooltip={'260px'}
                    widthSvg={'16px'}
                    positionTooltipRight={'-13px'}
                    positionTooltipBottom={'23px'}
                    MobileSideTooltip={'left'}
                  />
                </div>
              ) : (
                `*${dataCard?.cardNumber?.slice(-4)}`
              )}
            </p>
          </div>
          <div className={styles.backGroundList}>
            <BackGroundGSMCardSvg width={'165px'} height={'188px'} />
          </div>
        </div>
      )}
    </>
  );
};

export default GsmCard;
