import React, { useState } from 'react';
import styles from './MaterialItem.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { formVolume } from 'src/Pages/DispatchingPages/Constans/Constans';
import { formPrice } from 'src/common/form-price.helper';
import { IMaterials } from 'src/Pages/ShowcasesPage/type';
import { useMediaQuery } from 'react-responsive';
import { convertValueFromNameMaterial } from 'src/Pages/ShowcasesPage/constans';
import CustomTooltip from 'src/components/newUI/CustomTooltip/CustomTooltip';
import FileDocumentSvg from 'src/components/UI/svg-icon/FileDocumentSvg';
import { ImageContainer } from 'src/components/ImageContainer';
import { closePopUp, openPopUp } from 'src/common/open-close-popup.function';
import { ArrowRightSvg } from 'src/components/UI/svg-icon/ArrowRightSvg';
import { TruncatedList } from 'react-truncate-list';
import 'react-truncate-list/dist/styles.css';
import { QuarryLink } from 'src/Pages/Catalog/components/QuarryLink/QuarryLink';
import { sellUnitView } from '../../../../Catalog/constans';
import { capitalize } from 'src/Pages/ShowcasesPage/utils';
import { useAppDispatch } from 'src/CustomHooks/storeHooks';

interface IProps {
  material: IMaterials;
  link: string;
  quarry?:
    | string
    | {
        value: string;
        label: string;
      };
  sellUnitProps?: string;
}

export const MaterialItem = ({ material, link, quarry = '', sellUnitProps }: IProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const navigate = useNavigate();
  const location = useLocation();
  const isCatalogPath = location.pathname.startsWith('/catalog');
  const isEditPath = location.pathname.indexOf('/materials/') !== -1;
  // const isFavoritePath = location.pathname.indexOf('/favorites') !== -1;
  const [image, setImage] = useState('');
  const [popUpActive, setPopUpActive] = useState(false);
  const isShowcasePath = !location.pathname.startsWith('/store');
  // const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActive(openPopUp);
  };
  const kind = material?.title
    .split(' ')
    .slice(material?.title.split(' ')[0] === 'Вторичный' || material?.title.split(' ')[0] === 'Бетонные' ? 2 : 1)
    .join(' ');

  const materialPhotoFromFiles =
    material?.files?.find(item => item?.category === 'Фото материала')?.mimeType.slice(0, 5) === 'image'
      ? material?.files?.find(item => item?.category === 'Фото материала')?.location
      : '';
  console.log(materialPhotoFromFiles);
  const materialPhotoURL = material?.imageUrl || materialPhotoFromFiles || material?.defaultImage;

  const price = formPrice(material?.minimalPrice || material?.price || 0, 'RUB', 'ru');

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!e.defaultPrevented) {
      localStorage.setItem('currentKindMaterialForSelect', capitalize(kind) || '');
      navigate(link);
    }
  };

  // @TODO: Закомментирован функционал избранного из покупателя, потом посмотреть
  // useEffect(() => {
  //   if (material?.favorite) setIsFavorite(material?.favorite);
  //   if (isFavoritePath) setIsFavorite(true);
  // }, []);

  // const handleFavoriteClick = (e: React.MouseEvent<HTMLDivElement>) => {
  //   e.stopPropagation();
  //   changeFavorite();
  // };

  // const changeFavorite = async () => {
  //   try {
  //     if (!isFavorite) {
  //       const response = await fetchPost(`/users/favourites/`, 'POST', {
  //         category: 'materialGroups',
  //         itemId: material.id,
  //       });
  //       if (toastError(response)) return;
  //     } else {
  //       const response = await fetchPost(`/users/favourites/${material.id}`, 'DELETE', {
  //         category: 'materialGroups',
  //       });
  //       if (toastError(response)) return;
  //     }

  //     setIsFavorite(!isFavorite);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <div
      className={styles.cardContainer}
      style={
        isMobile && isCatalogPath
          ? { height: '207px', padding: '8px', minWidth: '143px', width: 'calc((50vw - 24px))' }
          : {}
      }
      onClick={handleContainerClick}
    >
      {/* {isCatalogPath && (
        <div className={styles.favoriteButton} onClick={e => handleFavoriteClick(e)}>
          {isFavorite ? (
            <FavoriteShowcasesSvg color="currentColor" width={isMobile ? '24px' : '32px'} />
          ) : (
            <FavoriteSvg width={isMobile ? '24px' : '32px'} />
          )}
        </div>
      )} */}
      {isMobile ? (
        !isCatalogPath ? (
          <>
            <div className={styles.cardContent}>
              <div className={styles.titleAndShowImageCard}>
                <p className={styles.titleCard}>{material?.title}</p>
                {isEditPath && !isCatalogPath && (
                  <div className={styles.svgContainer} onClick={e => e.stopPropagation()}>
                    <CustomTooltip
                      openTooltipOnClick={true}
                      svg={<FileDocumentSvg />}
                      widthTooltip={'fit-content'}
                      MobileSideTooltip={'left'}
                      text={
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                          {material?.files?.map(file => (
                            <>
                              {file.category === 'Паспорт' && (
                                <ImageContainer
                                  id={file.id}
                                  value={
                                    file.mimeType === 'image/png' || file.mimeType === 'image/jpeg'
                                      ? null
                                      : file.location
                                  }
                                  name={file.name}
                                  size={file.size}
                                  isSize={file.isSize}
                                  date={file.date}
                                  isDate={file.isDate}
                                  type={file.mimeType}
                                  openPopUp={() =>
                                    (file.mimeType === 'image/png' || file.mimeType === 'image/jpeg') &&
                                    openPopupWithImg(file.location)
                                  }
                                  children={undefined}
                                ></ImageContainer>
                              )}
                            </>
                          ))}
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
              {(material?.mainProperties?.length || material?.mainProperty) && (
                <div className={styles.fractionMaterialContainer}>
                  {typeof material?.mainProperties === 'object' ? (
                    material?.mainProperties?.map(item => (
                      <p key={material?.materialTypeId + '_fraction'} className={styles.fractionMaterial}>
                        {item}
                      </p>
                    ))
                  ) : (
                    <p key={material?.materialTypeId + '_fraction'} className={styles.fractionMaterial}>
                      {material?.mainProperty}
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className={styles.imageAndVolumeAndPrice}>
              <img className={styles.svgMaterialContainer} style={{ maxHeight: '144px' }} src={materialPhotoURL}></img>
              <div className={styles.volumeAndPrice}>
                {isCatalogPath && (
                  <div className={styles.infoContainer}>
                    <p className={styles.infoTextTitleMobile}>Карьер</p>
                    <div
                      className={styles.quarryLink}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <p className={styles.infoTextValueMobile}>Юрьев-Польский</p>
                      <ArrowRightSvg />
                    </div>
                  </div>
                )}
                <div className={styles.infoContainer}>
                  <p className={styles.infoTextTitleMobile}>Объем:</p>
                  <p className={styles.infoTextValueMobile}>
                    {formVolume(material?.volume, 'ru')} {sellUnitView[material?.unit] || sellUnitProps}
                  </p>
                </div>
                <div className={styles.infoContainer}>
                  <p className={styles.infoTextTitleMobile}>Цена:</p>
                  <p className={styles.infoTextValueMobile}>
                    от {price}/{sellUnitView[material?.unit] || sellUnitProps}
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.catalogCardContent}>
            <div className={styles.cardContent}>
              <img className={styles.svgMaterialContainer} style={{ height: '83px' }} src={materialPhotoURL}></img>
              <div className={styles.titleAndShowImageCard}>
                <p className={styles.titleCard} style={{ fontSize: '14px', lineHeight: '20px' }}>
                  {material?.title}
                </p>
              </div>
              {material?.mainProperties?.length && (
                <TruncatedList
                  className={styles.fractionMaterialContainer}
                  renderTruncator={() => <div>...</div>}
                  style={{
                    height: '24px',
                    flexWrap: 'nowrap',
                    width: '100%',
                  }}
                >
                  {material?.mainProperties?.map(item => (
                    <p
                      key={convertValueFromNameMaterial[material.breed] + '_' + material.id + '_fraction'}
                      className={styles.fractionMaterial}
                      style={{
                        fontSize: '10px',
                        lineHeight: '12px',
                      }}
                    >
                      {item}
                    </p>
                  ))}
                </TruncatedList>
              )}
            </div>
            <div className={styles.infoContainer}>
              <p className={styles.infoText}>
                от {formPrice(material?.minimalPrice, 'RUB', 'ru')}/{sellUnitView[material?.unit] || sellUnitProps}
              </p>
            </div>
          </div>
        )
      ) : (
        <>
          <img className={styles.svgMaterialContainer} style={{ maxHeight: '200px' }} src={materialPhotoURL}></img>
          <div className={styles.cardContent}>
            <div className={styles.titleAndShowImageCard}>
              <p className={styles.titleCard}>{material?.title}</p>
              {isEditPath && !isCatalogPath && (
                <div className={styles.svgContainer} onClick={e => e.stopPropagation()}>
                  <CustomTooltip
                    openTooltipOnClick={true}
                    svg={<FileDocumentSvg />}
                    widthTooltip={'fit-content'}
                    SideTooltip={'up'}
                    text={
                      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        {material?.files?.map(file => (
                          <>
                            {file.category === 'Паспорт' && (
                              <ImageContainer
                                id={file.id}
                                value={
                                  file.mimeType === 'image/png' || file.mimeType === 'image/jpeg' ? null : file.location
                                }
                                name={file.name}
                                size={file.size}
                                isSize={file.isSize}
                                date={file.date}
                                isDate={file.isDate}
                                type={file.mimeType}
                                openPopUp={() =>
                                  (file.mimeType === 'image/png' || file.mimeType === 'image/jpeg') &&
                                  openPopupWithImg(file.location)
                                }
                                children={undefined}
                              ></ImageContainer>
                            )}
                          </>
                        ))}
                      </div>
                    }
                  />
                </div>
              )}
            </div>
            {(material?.mainProperties?.length || material?.mainProperty) && (
              <TruncatedList
                className={styles.fractionMaterialContainer}
                renderTruncator={() => <div style={{ lineHeight: '27px' }}>...</div>}
                style={{
                  height: '27px',
                  flexWrap: 'wrap',
                  width: '100%',
                }}
              >
                {material?.mainProperties?.length ? (
                  material?.mainProperties?.map(item => (
                    <p key={material?.materialTypeId + '_fraction'} className={styles.fractionMaterial}>
                      {item}
                    </p>
                  ))
                ) : (
                  <p key={material?.materialTypeId + '_fraction'} className={styles.fractionMaterial}>
                    {material?.mainProperty}
                  </p>
                )}
              </TruncatedList>
            )}
            {isCatalogPath && (
              <div className={styles.infoContainer}>
                <p className={styles.infoText}>Витрина</p>
                <p className={styles.downBorder} />
                <div className={styles.quarryLinkContainer}>
                  <QuarryLink
                    onClick={() => {
                      if (typeof quarry !== 'string') return;
                      navigate(`/catalog/showcases/${material?.showcase?.id}`);
                    }}
                    quarryName={
                      typeof quarry !== 'string' && typeof quarry !== 'number'
                        ? quarry?.label
                        : material?.showcase?.title
                    }
                  />
                </div>
              </div>
            )}
            <div className={styles.infoContainer}>
              <p className={styles.infoText}>Объем:</p>
              <p className={styles.downBorder} />
              <p className={styles.infoText}>
                {formVolume(material?.volume, 'ru')} {sellUnitView[material?.unit] || sellUnitProps}
              </p>
            </div>
            <div className={styles.infoContainer}>
              <p className={styles.infoText}>Цена:</p>
              <p className={styles.downBorder} />
              <p className={styles.infoText}>
                от {price}/{sellUnitView[material?.unit] || sellUnitProps}
              </p>
            </div>
          </div>
        </>
      )}
      <div onClick={e => e.stopPropagation()} className={popUpActive ? 'Popup active' : 'Popup'}>
        <div onClick={() => setPopUpActive(closePopUp)} className="Popup__white"></div>
        <div className="Popup__wrapper">
          <div>
            <div>
              <div onClick={() => setPopUpActive(closePopUp)} className="Popup__close"></div>
              <div className="Popup__content">
                <div className="Popup__box">
                  <img src={image} alt={''} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
