import { IMaterialField } from 'src/Pages/ShowcasesPage/type';

export interface IAdminCalculationFilters {
  manager: string;
  material: string;
  checkCallback: boolean;
}
export interface IDataGetListUsersInAdmin {
  limit: number;
  offset: number;
  type?: string;
  searchString?: string;
}
export interface IDataGetListMaterialsInAdmin {
  withKinds: boolean;
}

export interface IDataGetListOrdersInAdmin {
  status?: string;
}

export interface IOption {
  value: string;
  label: string;
}
export interface IDataGetListCalculationInAdmin {
  limit: number;
  offset: number;
  searchString?: string;
  managerId?: number;
  materialTypeId?: number;
  callback?: boolean;
}
export interface IPayloadActionCalculationRows {
  id: number;
  client: {
    id: number;
    title: string;
    inn: string;
  };
  order: {
    title: string;
  };
  address: {
    address: string;
  };
  quarry: {
    id: number;
    title: string;
  };
  materialType: {
    id: number;
    title: string;
  };
  manager: {
    id: number;
    fullName: string;
  };
  createdAt: string;
  callback: boolean;
  clientName: string;
  clientPhone: string;
  price: number;
  unit: string;
  clientAndObject: {
    client: string;
    object: string;
  };
  materialAndQuarry: {
    material: string;
    quarry: string;
  };
  callbackClientAndPhone: {
    callback: boolean;
    phone: string;
    client: string;
  };
  idOrder: {
    id: number;
    order: string;
  };
  priceAndUnit: {
    price: number;
    unit: string;
  };
}
export interface IActionCalculation {
  meta: {
    arg: {
      limit: number;
      offset: number;
    };
    requestId: string;
    requestStatus: string;
  };
  payload: IPayloadActionCalculation;
  type: string;
}
export interface IPayloadActionCalculation {
  count: number;
  rows: IPayloadActionCalculationRows[];
}
export interface IDataGetListCalculationInAdmin {
  limit: number;
  offset: number;
  searchString?: string;
  managerId?: number;
  materialTypeId?: number;
  callback?: boolean;
}

export interface IObject {
  id: number;
  companyId: number;
  uuid: string;
  name: string;
  comment: string;
  addressId: number;
  workSchedulesGraphId: number;
  closed: boolean;
  semitrailerId: number;
  deletedAt: string;
  address: {
    id: number;
    uuid: string;
    address: string;
    latitude: number;
    longitude: number;
  };
  semitrailer: {
    id: number;
    title: string;
    rank: number;
  };
  workSchedulesGraph: {
    id: number;
    from: string;
    to: string;
    aroundTheClock: boolean;
  };
}
export interface ICoordinate {
  latitude: number;
  longitude: number;
}
export interface IInitStateSlicer {
  optionsManager: IOption[];
  optionsMaterials: IMaterialsOptionsForAdmin[];
  optionsOrders: IOption[];
  addCalculationOptions: ICalculationAddProperties[];
  addCalculationMaterialFields: ICalculationAddProperties[];
  calculationDetails: ICalculationDetails;
  calculationDetailsInfo: ICalculationDetailsInfo[];
  checkedCalculationDetailsIndex: number;
  isChangedCoordinates: boolean;
  dataTableCalculation: IPayloadActionCalculationRows[];
  closestWeightBridgesCoordinates: ICoordinate[];
  detailRouteCoordinate: ICoordinate[];
  currentObjectInfo: IObject;
  totalCount: number;
  loading: boolean;
}
export interface IRowsUsersAdmin {
  id: number;
  solberId: number;
  name: string;
  surname: string;
  patronymic: number;
  fullName: string;
  company: {
    title: string;
    inn: string;
  };
  phone: string;
  block: boolean;
  type: string;
}
export interface IActionConvertDataFromUsersAdminForOptions {
  count: number;
  rows: IRowsUsersAdmin[];
}
export interface IActionConvertDataFromMaterialsAdminForOptions {
  id: number;
  title: string;
}
export interface IActionConvertDataFromOrdersAdminForOptions {
  id: number;
  date: string;
  client: { id: number; title: string; inn: string };
}
export interface IOptionsForAdmin {
  inn?: string;
  id?: number;
  title?: string;
  value?: string;
  label?: string;
  fullName?: string;
  materialKind?: {
    id: number;
    title: string;
    properties: { id: number; value: string }[];
  };
}
export interface IMaterialsOptionsForAdmin {
  id?: number;
  title?: string;
  value?: string;
  label?: string;
  fullName?: string;
  materialKind?: {
    id: number;
    title: string;
    properties: { id: number; value: string }[];
  };
}

export interface ICalculationDetails {
  id: number;
  isCanceled: boolean;
  calculationDetails: ICalculationDetailsItem[];
}

export interface ICalculationDetailsItem {
  id: number;
  distance: number;
  tollDistance: number;
  manualDistance: number;
  quarry: {
    longitude: number;
    latitude: number;
    title: string;
  };
  semitrailersValue: { title: string; value: number }[];
  carrier: {
    title: string;
    inn: string;
  }[];
  supplier: {
    title: string;
    inn: string;
  };
  results: {
    supplierCost: number;
    carrierCost: number;
    carrierPriceOnRequest: boolean;
    supplierPriceOnRequest: boolean;
    totalCost: number;
    totalCostPerUnit: number;
    unit: string;
    semitrailer: string;
    flightsPerDay: number;
    deliveryDays: number;
    managerComment: string;
    margin: number;
    clientPricePerUnit: number;
    totalMargin: number;
    createdAt: string;
  };
}

export interface ICalculationAddProperties extends Omit<IMaterialField, 'value'> {
  value: any;
  width?: string;
  placeholder?: string;
  name?: addPropertyName | createdPropertyName;
  size?: propertySize;
  typeOfValue?: typeOfValue;
}

export enum typeOfValue {
  NUMBER = 'number',
  STRING = 'string',
  BOOLEAN = 'boolean',
  ANY = 'any',
}

export enum addPropertyName {
  materialTypeId = 'materialTypeId',
  materialKindId = 'materialKindId',
  volume = 'volume',
  volumePerDay = 'volumePerDay',
  unit = 'unit',
  semitrailerId = 'semitrailerId',
  quarryId = 'quarryId',
  startDelivery = 'startDelivery',
  endDelivery = 'endDelivery',
  clientId = 'clientId',
  clientContractId = 'clientContractId',
  objectId = 'objectId',
  address = 'address',
  latitude = 'latitude',
  longitude = 'longitude',
  clientName = 'clientName',
  clientPhone = 'clientPhone',
  comment = 'comment',
  workSchedule = 'workSchedule',
  callback = 'callback',
  managerId = 'managerId',
  properties = 'properties',
}

export enum createdPropertyName {
  materialTypeId = 'materialType',
  materialKindId = 'materialKind',
  volume = 'volume',
  volumePerDay = 'volumePerDay',
  unit = 'unit',
  semitrailerId = 'semitrailerTitle',
  quarryId = 'quarry',
  startDelivery = 'startDelivery',
  endDelivery = 'endDelivery',
  clientId = 'clientTitle',
  clientContractId = 'clientContract',
  objectId = 'object',
  address = 'address',
  latitude = 'addressLatitude',
  longitude = 'addressLongitude',
  clientName = 'clientName',
  clientPhone = 'clientPhone',
  comment = 'comment',
  workSchedule = 'workSchedule',
  callback = 'callback',
  managerId = 'managerFullName',
  materialProperties = 'materialProperties',
}

export interface IMaterialTypeParam {
  excludeKind: boolean;
}
export interface IMaterialTypeRequest {
  id: string;
  params: IMaterialTypeParam;
}

export interface IBuyersRequest {
  type: string;
  searchString?: string;
  deliveryRequestId?: number;
}

export interface IQuarriesRequest {
  materialTypeId?: string;
  companyId?: number;
  deliveryRequestId?: number;
}

export interface IContractsRequest {
  companyId: string;
}

export interface IObjectsRequest {
  companyId: string;
}

export interface ICreatedCalculationRequest
  extends Omit<INewCalculationRequest, addPropertyName.properties | addPropertyName.materialTypeId> {
  managerFullName: string;
  materialType: string;
  clientTitle: string;
  materialProperties: { title: string; value: string }[];
}

export enum propertySize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
export interface IPatchDetailsOfCalculation {
  margin?: number;
  managerId?: number;
  callback?: boolean;
  managerComment?: string;
}
export interface INewCalculationRequest {
  [addPropertyName.materialTypeId]: number;
  [addPropertyName.volume]: number;
  [addPropertyName.volumePerDay]: number;
  [addPropertyName.unit]: string;
  [addPropertyName.semitrailerId]: number;
  [addPropertyName.quarryId]?: number;
  [addPropertyName.managerId]?: number;
  [addPropertyName.startDelivery]?: string;
  [addPropertyName.endDelivery]?: string;
  [addPropertyName.clientId]?: number;
  [addPropertyName.clientContractId]?: number;
  [addPropertyName.objectId]?: number;
  [addPropertyName.address]: string;
  [addPropertyName.latitude]?: number;
  [addPropertyName.longitude]?: number;
  [addPropertyName.clientName]?: string;
  [addPropertyName.clientPhone]?: string;
  [addPropertyName.callback]?: boolean;
  [addPropertyName.comment]?: string;
  [addPropertyName.workSchedule]: string;
  [addPropertyName.properties]: {
    propertyId: number;
    value: string;
  }[];
}

export enum calculationDetailsInfoTitles {
  totalCostWithMargin = 'totalCostWithMargin',
  createdAt = 'createdAt',
  distance = 'distance',
  deliveryDays = 'deliveryDays',
  flightsPerDay = 'flightsPerDay',
  semitrailer = 'semitrailer',
  totalCostPerUnit = 'totalCostPerUnit',
  margin = 'margin',
  clientPricePerUnit = 'clientPricePerUnit',
  totalCost = 'totalCost',
  totalMargin = 'totalMargin',
  volume = 'volume',
  managerComment = 'managerComment',
}

export interface ICalculationDetailsInfo {
  name: calculationDetailsInfoTitles;
  value: any;
  label: string;
  isTitle?: boolean;
  type?: typeOfValue;
}
