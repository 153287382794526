import React, { useContext, useEffect } from 'react';
import styles from './SuccessContractModal.module.scss';
import FrameModalSvg from '../../../../components/UI/svg-icon/FrameModalSvg';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import SuccessSvgVer2 from '../../../../components/UI/svg-icon/SuccessSvgVer2';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';
import { TypeTitlesOfTheCreatedContract } from '../../type';
import { Context } from '../../../../context/context';
import { ModalNew } from 'src/components/ModalNew/ModalNew';

interface IProps {
  successContractModal: boolean;
  setSuccessContractModal: React.Dispatch<React.SetStateAction<boolean>>;
  getData: any;
  titlesOfTheCreatedContract: TypeTitlesOfTheCreatedContract;
  typeOfDocument?: string;
}
const SuccessContractModal = ({
  successContractModal,
  setSuccessContractModal,
  getData,
  titlesOfTheCreatedContract,
  typeOfDocument = '',
}: IProps) => {
  const { user } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const textTitle = typeOfDocument === 'spec' ? 'Подписание спецификации' : 'Добавление договора';
  const infoTitleText = typeOfDocument === 'spec' ? 'Спецификация' : user.type == 'supplier' ? 'Договор' : 'Договоры';
  const infoTitleTextSend =
    typeOfDocument === 'spec'
      ? 'отправлена на подписание контрагенту'
      : `${user.type == 'supplier' ? 'отправлен' : 'отправлены'} на проверку`;

  const closeSuccessContractModal = () => {
    setSuccessContractModal(false);
  };
  useEffect(() => {
    if (successContractModal) {
      getData();
    }
  }, [successContractModal]);
  return (
    <>
      {isMobile ? (
        <div className={successContractModal ? 'DriverPagePopup active' : 'DriverPagePopup'}>
          <div onClick={() => closeSuccessContractModal()} className="DriverPagePopup__white"></div>
          <div className="DriverPagePopup__line"></div>
          <div
            className="DriverPagePopup__wrapper"
            style={{
              height: '100vh',
              top: 'auto',
              bottom: '0',
              borderRadius: '12px 12px 0px 0px',
              background: `var(--color-blue50)`,
            }}
          >
            <div className={styles.drawerTriangleContainer}>
              <div className={styles.drawerTriangle} />
            </div>
            <div className={styles.container}>
              <div className={styles.topContainer}>
                <div className={styles.titleAndCloseButton}>
                  <p className={styles.textTitle}>{textTitle}</p>
                  <div className={styles.mobileCloseIconDrawer}>
                    <div className={styles.mobileCloseIconBlockDrawer} onClick={closeSuccessContractModal}>
                      <CloseSvg />
                    </div>
                  </div>
                </div>
                <div className={styles.infoContainer}>
                  <SuccessSvgVer2 />
                  <div className={styles.infoContainerDocument}>
                    <p className={styles.infoTitleText}>{infoTitleText} </p>
                    {titlesOfTheCreatedContract?.deliveryContract?.title && (
                      <p className={styles.infoTitleText}>
                        {titlesOfTheCreatedContract?.deliveryContract?.title} поставки
                      </p>
                    )}
                    {titlesOfTheCreatedContract?.transportationContractId?.title && (
                      <p className={styles.infoTitleText}>
                        {titlesOfTheCreatedContract?.transportationContractId?.title} перевозки
                      </p>
                    )}
                    {titlesOfTheCreatedContract?.usePlatformContract?.title && (
                      <p className={styles.infoTitleText}>
                        {titlesOfTheCreatedContract?.usePlatformContract?.title} использования платформы
                      </p>
                    )}
                  </div>
                  <p className={styles.infoTitleText}>{`${infoTitleTextSend}`}</p>
                  {typeOfDocument !== 'spec' && (
                    <p className={styles.infoSubTitleText}>{'Они появятся в общем списке договоров'}</p>
                  )}
                </div>
                <ButtonFilled
                  sizeText={'small'}
                  width={'100%'}
                  text={'Готово'}
                  onClick={() => setSuccessContractModal(false)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ModalNew width={'480px'} openModal={successContractModal} setOpenModal={setSuccessContractModal}>
          <div className={styles.container}>
            <div className={styles.topContainer}>
              <div className={styles.titleContainer}>
                <p className={styles.textTitle} style={{ fontSize: '28px' }}>
                  {textTitle}
                </p>
              </div>
              <SuccessSvgVer2 />
              <div className={styles.infoTitleText}>
                <p className={styles.infoTitleText}>{infoTitleText} </p>
                <div className={styles.infoContainerDocument}>
                  {titlesOfTheCreatedContract?.deliveryContract?.title && (
                    <p className={styles.infoTitleText}>
                      {titlesOfTheCreatedContract?.deliveryContract?.title} поставки
                    </p>
                  )}
                  {titlesOfTheCreatedContract?.transportationContractId?.title && (
                    <p className={styles.infoTitleText}>
                      {titlesOfTheCreatedContract?.transportationContractId?.title} перевозки
                    </p>
                  )}
                  {titlesOfTheCreatedContract?.usePlatformContract?.title && (
                    <p className={styles.infoTitleText}>
                      {titlesOfTheCreatedContract?.usePlatformContract?.title} использования платформы
                    </p>
                  )}
                </div>
                <p className={styles.infoTitleText}>{`${infoTitleTextSend}`}</p>
              </div>
              {typeOfDocument !== 'spec' && (
                <p className={styles.infoSubTitleText}>{'Они появятся в общем списке договоров'}</p>
              )}
            </div>
            <ButtonFilled width={'100%'} text={'Готово'} onClick={() => setSuccessContractModal(false)} />
          </div>
          <div className={styles.closeButton}>
            <FrameModalSvg onClick={closeSuccessContractModal} />
          </div>
        </ModalNew>
      )}
    </>
  );
};

export default SuccessContractModal;
