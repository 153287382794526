import React, { useContext, useState, useEffect } from 'react';
import styles from './AdminUsersPage.module.scss';
import TitleMain from 'src/components/UI/TitleAndSubtitle/TitleMain';
import { PageWrapper } from 'src/components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import SubTitleMain from 'src/components/UI/TitleAndSubtitle/SubTitleMain';
import { InputSearch } from 'src/components/UI/inputs/InputSearch';
import SelectLk from 'src/components/newUI/SelectLk/SelectLk';
import { toastError } from '../../../common/toastError.helper';
import { usePageScroll } from 'src/CustomHooks/usePageScroll';
import { clearObject } from 'src/common/ClearObject.helper';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import useDebounce from 'src/CustomHooks/useDebounce';
import { MobileAdminUsersPage } from './components/MobileAdminUsersPage/MobileAdminUsersPage';
import { IDataUsers, TFilter } from './types';
import { columnsForTableOfCompanies, roleOptions } from './constants';
import CustomTable from 'src/components/newUI/CustomTable/CustomTable';

const limit: number = 10;

export const AdminUsersPage = () => {
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const initialStateTypeOfUser =
    localStorage.getItem('rootTypeOfUserSelect') === 'null' ? '' : localStorage.getItem('rootTypeOfUserSelect');
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filter, setFilter] = useState<TFilter>({ search: '', typeOfUser: '' });
  const debouncedSearchTerm = useDebounce(filter.search, 500);
  const [companies, setCompanies] = useState<IDataUsers[]>([]);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const getCompanies = async (isPagination: boolean) => {
    if (isFirstRender && initialStateTypeOfUser) {
      return;
    }
    try {
      setLoading(true);
      const response = await fetchGet(
        '/admin/users/list',
        clearObject({
          limit: limit,
          offset: isPagination ? offset : 0,
          type: filter.typeOfUser || undefined,
          searchString: filter.search || undefined,
        }),
      );
      setTotalCount(response?.count);
      if (toastError(response)) return;
      setCompanies(prevState => prevState.concat(response?.rows));
    } catch (e) {
      console.log(e);
    } finally {
      setIsFirstRender(false);
      setLoading(false);
    }
  };
  const handleBlockChange = (id: number) => {
    setCompanies(prevState =>
      prevState.map(company => (company.id === id ? { ...company, block: !company.block } : company)),
    );
  };

  usePageScroll(setOffset, limit);

  useEffect(() => {
    setFilter(prevState => ({
      ...prevState,
      typeOfUser: initialStateTypeOfUser,
    }));
  }, []);

  useEffect(() => {
    if (totalCount > offset) {
      getCompanies(true);
    }
  }, [offset]);

  useEffect(() => {
    if (companies.length) setCompanies([]);
    if (offset !== 0) setOffset(0);
    localStorage.setItem('rootTypeOfUserSelect', filter.typeOfUser);
    getCompanies(false);
  }, [filter.typeOfUser, debouncedSearchTerm]);
  return (
    <PageWrapper>
      <div className={styles.container}>
        <div className={styles.container}>
          <div className={styles.titleAndSubtitleContainer}>
            <TitleMain title={'Панель администратора'} />
            <div className={styles.subTitleContainer}>
              <SubTitleMain subTitle={'Настройки администратора'} />
            </div>
          </div>
        </div>
        <>
          <div className={styles.inputsAndSelectorsContainer}>
            <div style={{ flex: 1 }}>
              <InputSearch
                placeholder={'Поиск'}
                onInput={({ currentTarget: { value: search } }) => setFilter(prevState => ({ ...prevState, search }))}
                value={filter.search}
              />
            </div>
            <SelectLk
              options={roleOptions}
              width={isMobile ? '100%' : '240px'}
              value={filter.typeOfUser}
              setValue={value =>
                setFilter(prevState => ({
                  ...prevState,
                  typeOfUser: value === roleOptions[0].value ? '' : value,
                }))
              }
              placeholder="Выбор роли"
            />
          </div>
          {isMobile ? (
            <MobileAdminUsersPage companies={companies} changeBlock={handleBlockChange} />
          ) : (
            <div className={styles.customTableContainer}>
              <CustomTable
                loading={loading}
                columns={columnsForTableOfCompanies(handleBlockChange)}
                data={companies}
                emptyDescription=""
                emptyTitle="Данных нет"
                emptyFilterTitle=""
              />
            </div>
          )}
        </>
      </div>
    </PageWrapper>
  );
};
