import React from 'react';
import styles from './ShowcaseCards.module.scss';
import ShowcaseCard from './ShowcaseCard/ShowcaseCard';
import { useAppSelector } from 'src/CustomHooks/storeHooks';
import { selectShowcases } from '../../services/showcase/showcaseSlice';
import { dataCardResponseType } from '../../type';

const ShowcaseCards = () => {
  const showcases: dataCardResponseType[] = useAppSelector(selectShowcases);
  return (
    <div className={styles.cardContainer}>
      {showcases.map(showcase => (
        <ShowcaseCard key={showcase?.id} showCase={showcase} />
      ))}
    </div>
  );
};

export default ShowcaseCards;
