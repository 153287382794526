// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowMoreTable_svgContainer__DdhfI {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n\n.ShowMoreTable_windowContainer__HZNRG {\n  position: absolute;\n  right: 0;\n  margin-top: 13px;\n  display: flex;\n  padding-right: 8px;\n  flex-direction: column;\n  z-index: 1;\n  border-radius: 8px;\n  border: 1px solid var(--color-gray200);\n  background: var(--color-white);\n  box-shadow: 0 4px 20px 0 rgba(138, 136, 255, 0.08);\n}\n\n.ShowMoreTable_windowButtonContainer__sDNtZ {\n  display: flex;\n  align-items: center;\n  padding: 12px;\n}\n\n.ShowMoreTable_windowButtonText__Ysm4B {\n  color: var(--color-gray800);\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  -webkit-user-select: none;\n          user-select: none;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminOrdersPage/components/ShowMoreTable/ShowMoreTable.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AACA;EACE,kBAAA;EACA,QAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,UAAA;EAEA,kBAAA;EACA,sCAAA;EACA,8BAAA;EACA,kDAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;AAEF;;AAAA;EACE,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;UAAA,iBAAA;AAGF","sourcesContent":[".svgContainer {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n.windowContainer {\n  position: absolute;\n  right: 0;\n  margin-top: 13px;\n  display: flex;\n  padding-right: 8px;\n  flex-direction: column;\n  z-index: 1;\n\n  border-radius: 8px;\n  border: 1px solid var(--color-gray200);\n  background: var(--color-white);\n  box-shadow: 0 4px 20px 0 rgba(138, 136, 255, 0.08);\n}\n.windowButtonContainer {\n  display: flex;\n  align-items: center;\n  padding: 12px;\n}\n.windowButtonText {\n  color: var(--color-gray800);\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svgContainer": "ShowMoreTable_svgContainer__DdhfI",
	"windowContainer": "ShowMoreTable_windowContainer__HZNRG",
	"windowButtonContainer": "ShowMoreTable_windowButtonContainer__sDNtZ",
	"windowButtonText": "ShowMoreTable_windowButtonText__Ysm4B"
};
export default ___CSS_LOADER_EXPORT___;
