export interface IStateBanner {
  images: IImageBanner[];
  buttonInsurance: IImageBanner[];
  isLoading: boolean;
}
export enum typeBanner {
  MAIN = 'main',
  BUTTON_INSURANCE='button_insurance'
}

export interface IImageBanner {
  id: number;
  description: string;
  type: string;
  dateTo: string;
  dateFrom: string;
  rank: number;
  title: string;
  urlRedirect: string;
  pictureUrl: string;
  userId: number;
  whoShow: string;
}
export interface IRequestImagesBanner {
  type: typeBanner;
  resolution: string;
}
