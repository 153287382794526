import React from 'react';

export const PaymentsFundsSvg = ({ size = '24px' }: { size: string }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 10C2 7.17157 2 5.75736 2.87868 4.87868C3.75736 4 5.17157 4 8 4H13C15.8284 4 17.2426 4 18.1213 4.87868C19 5.75736 19 7.17157 19 10C19 12.8284 19 14.2426 18.1213 15.1213C17.2426 16 15.8284 16 13 16H8C5.17157 16 3.75736 16 2.87868 15.1213C2 14.2426 2 12.8284 2 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M19.0001 7.07617C19.9751 7.17208 20.6315 7.38885 21.1214 7.87873C22.0001 8.75741 22.0001 10.1716 22.0001 13.0001C22.0001 15.8285 22.0001 17.2427 21.1214 18.1214C20.2427 19.0001 18.8285 19.0001 16.0001 19.0001H11.0001C8.17163 19.0001 6.75742 19.0001 5.87874 18.1214C5.38884 17.6315 5.17208 16.9751 5.07617 16"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M13 10C13 11.3807 11.8807 12.5 10.5 12.5C9.11929 12.5 8 11.3807 8 10C8 8.61929 9.11929 7.5 10.5 7.5C11.8807 7.5 13 8.61929 13 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path d="M16 12L16 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5 12L5 8" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
