import React from 'react';

const ArrowLeftOldSvg = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="arrow-left">
        <path
          id="Vector"
          d="M16.6666 10.75C17.0808 10.75 17.4166 10.4142 17.4166 10C17.4166 9.58579 17.0808 9.25 16.6666 9.25V10.75ZM16.6666 9.25H3.33325V10.75H16.6666V9.25Z"
          fill="var(--color-blue800)"
        />
        <path
          id="Vector_2"
          d="M8.33325 5L3.33325 10L8.33325 15"
          stroke="var(--color-blue900)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ArrowLeftOldSvg;
