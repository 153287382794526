import React from 'react';

const QuarrySvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="13" viewBox="0 0 20 13" fill="none">
      <path
        d="M5.95016 10.834L9.13016 7.61402L11.4502 9.93402L15.1102 6.23402L18.9702 10.214L19.8402 9.19402L11.1602 0.374023L0.160156 11.674L1.03016 12.534L4.50016 9.35402L5.95016 10.834Z"
        fill="#808285"
      />
    </svg>
  );
};

export default QuarrySvg;
