import React, { useEffect, useState } from 'react';
import styles from './CalculationDetailsList.module.scss';
import TitleMain from '../../../../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../../../../components/newUI/ButtonFilled/ButtonFilled';
import TextArea from 'antd/es/input/TextArea';
import ArrayDataOfCalculationDetails from '../ArrayDataOfCalculationDetails/ArrayDataOfCalculationDetails';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import {
  changeCalculationDetailsInfo,
  getDetails,
  handleChangeDetailInfoValue,
  patchDetail,
  selectCalculationDetailsInfo,
  selectCheckedCalculationDetailsIndex,
} from '../../AdminCalculationSuppliesSlice';
import { calculationDetailsInfoTitles, ICalculationDetailsInfo } from '../../type';
import { useLocation, useNavigate } from 'react-router-dom';

import useDebounce from '../../../../../CustomHooks/useDebounce';
interface IProps {
  calculationId?: string;
}
const CalculationDetailsList = ({ calculationId }: IProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isCreateOrderPath = pathname.includes('/orders/create');
  const dispatch = useAppDispatch();
  const list: ICalculationDetailsInfo[] = useAppSelector(selectCalculationDetailsInfo);
  const index: number = useAppSelector(selectCheckedCalculationDetailsIndex);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const descriptionFieldValue = useDebounce(list[11].value, isFirstRender ? 0 : 2000);

  useEffect(() => {
    if (!descriptionFieldValue) return;
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    dispatch(patchDetail({ data: { managerComment: descriptionFieldValue }, id: Number(calculationId) })).then(() => {
      dispatch(getDetails(Number(calculationId))).then(() => {
        dispatch(changeCalculationDetailsInfo());
      });
    });
  }, [descriptionFieldValue]);

  const handleChangeTextArea = (value: string) => {
    dispatch(
      handleChangeDetailInfoValue({
        name: calculationDetailsInfoTitles.managerComment,
        value,
      }),
    );
  };

  return (
    <div className={styles.container}>
      {!isCreateOrderPath && (
        <div className={styles.topContainer}>
          <div className={styles.titleAndDataContainer}>
            <TitleMain fontSize={'24px'} title={'Детализация расчета'} />
            <p className={styles.dataText}>{list[0].value}</p>
          </div>
          <ButtonFilled
            sizeText={'small'}
            text={'Создать заказ'}
            width={'140px'}
            onClick={() => navigate(`/orders/create/${calculationId}`)}
          />
        </div>
      )}
      <div className={styles.contentContainer} style={{ gap: isCreateOrderPath ? '16px' : '24px' }}>
        {isCreateOrderPath && <TitleMain fontSize={'24px'} title={'Данные расчета'} />}
        <div className={styles.topContent}>
          <div className={styles.firstDataContainer}>
            <ArrayDataOfCalculationDetails calculationId={calculationId} array={list.slice(1, 6)} />
          </div>
          <div className={styles.marginAndPriceContainer}>
            <div className={styles.marginContainer}>
              <ArrayDataOfCalculationDetails calculationId={calculationId} array={list.slice(6, 9)} />
            </div>
            <div className={styles.priceContainer}>
              <ArrayDataOfCalculationDetails calculationId={calculationId} array={list.slice(9, 11)} />
            </div>
          </div>
        </div>
        {!isCreateOrderPath && (
          <div className={styles.bottomContainer}>
            <p className={styles.bottomTitleText}>Примечания к расчету</p>
            <TextArea
              className={styles.descriptionField}
              value={list[11].value}
              maxLength={300}
              placeholder={'Примечания к расчету'}
              onChange={e => {
                handleChangeTextArea(e.target.value);
              }}
              disabled={false}
              rows={3}
              style={{ resize: 'none' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CalculationDetailsList;
