import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { loadingImages } from 'src/common/loading-image.function';
import { closePopUp, openPopUp } from 'src/common/open-close-popup.function';
import { fetchPost } from 'src/common/proxy-1C-fetch-auth';
import { toastError } from 'src/common/toastError.helper';
import { ClipSvg } from 'src/components/UI/svg-icon/ClipSvg';
import UploadImagesContainerInOrder from './components/UploadImagesContainerInOrder';
import ImagesContainerInOrder from './components/ImagesContainerInOrder';
import styles from './InputFileInOrder.module.scss';
import FileDocumentSvg from '../../../../../components/UI/svg-icon/FileDocumentSvg';
import { IDataOrderId } from '../../type';
interface IProps {
  images: any /*то что пришло с бэка*/;
  setImages: any;
  uploadImages: any /*то что загрузил пользователь*/;
  setUploadImages: any;
  showUploadImages: any;
  setShowUploadImages: any;
  limitFile?: number;
  titleText?: string;
  subTitleText?: string;
  dataOrder?: IDataOrderId;
}
const InputFileInOrder = ({
  titleText = 'Приложите файлы или документ',
  subTitleText = 'в формате PNG, JPEG, PDF',
  images,
  setImages,
  uploadImages,
  setUploadImages,
  showUploadImages,
  setShowUploadImages,
  limitFile = 3,
  dataOrder,
}: IProps) => {
  const [showImage, setShowImage] = useState<string>('');
  const [popUpActiveImg, setPopUpActiveImg] = useState(false);
  const checkQuantityImages: boolean = Boolean(images.length + showUploadImages.length);
  const openPopupWithImg = src => {
    setShowImage(src);
    setPopUpActiveImg(openPopUp);
  };
  const handleChangeFile = (target: EventTarget & HTMLInputElement) => {
    if (images.length + uploadImages.length >= limitFile) {
      toast.error(`Максимальное количество файлов - ${limitFile}`);
      target.value = '';
      return;
    }
    if (target?.files[0].size / 1024 / 1024 > 10) {
      toast.error(`Файл не должен превышать ${10} мб`);
      return;
    }
    if (uploadImages.length + images.length <= limitFile) {
      setUploadImages(prevState => [...prevState, target?.files[0]]);
    }
    loadingImages(setShowUploadImages, showUploadImages, target, limitFile, 10);
  };
  const deleteFile = async (id: string, name: string, communicationCategory: string) => {
    try {
      const response = await fetchPost(`/files/${id}`, 'DELETE', {
        communicationCategory: communicationCategory,
        name: name,
        objectUuid: '',
      });
      const data = await response.json();
      if (toastError(data)) return;
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <div className={styles.container}>
        {dataOrder?.specificationClientForm && ( //Если по нашей форме, то 'Приложите файлы' - убираем
          <div className="ProfilePageUploading">
            <input
              id="InputUploading"
              type={'file'}
              accept={'image/, .png,.jpg,.jpeg,.pdf,.heic'}
              onChange={({ target }) => {
                handleChangeFile(target);
              }}
            />
            <label className="ProfilePageUploading__label" htmlFor="InputUploading">
              <div className={styles.inputFileContainer}>
                <div className={styles.svgInputFileContainer}>
                  <ClipSvg />
                </div>
                <div className={styles.textInputFileContainer}>
                  {titleText && <span className={styles.titleTextInputFile}>{titleText}</span>}
                  {subTitleText && <span className={styles.subTitleTextInputFile}>{subTitleText}</span>}
                </div>
              </div>
            </label>
          </div>
        )}
        {checkQuantityImages && (
          <div style={{ paddingLeft: dataOrder.specificationClientForm && '24px' }} className={styles.svgContainer}>
            <FileDocumentSvg />
          </div>
        )}
        <div>
          <UploadImagesContainerInOrder
            images={uploadImages}
            setImages={setUploadImages}
            showImages={showUploadImages}
            setShowImages={setShowUploadImages}
            openPopupFunc={openPopupWithImg}
          />
          <ImagesContainerInOrder
            dataOrder={dataOrder}
            images={images}
            setImages={setImages}
            openPopupFunc={openPopupWithImg}
            deleteFile={deleteFile}
          />
        </div>
      </div>
      <div className={popUpActiveImg ? 'Popup active' : 'Popup'}>
        <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__white"></div>
        <div className="Popup__wrapper">
          <div>
            <div>
              <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__close"></div>
              <div className="Popup__content">
                <div className="Popup__box">
                  <img src={showImage} alt={'скан спецификации'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InputFileInOrder;
