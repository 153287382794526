import React, { useState, useEffect } from 'react';
import styles from './AdminTariffsPage.module.scss';
import { PageWrapper } from 'src/components/PageWrapper';
import TitleMain from 'src/components/UI/TitleAndSubtitle/TitleMain';
import SubTitleMain from 'src/components/UI/TitleAndSubtitle/SubTitleMain';
import { InputSearch } from 'src/components/UI/inputs/InputSearch';
import { usePageScroll } from 'src/CustomHooks/usePageScroll';
import useDebounce from 'src/CustomHooks/useDebounce';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import { clearObject } from 'src/common/ClearObject.helper';
import { toastError } from 'src/common/toastError.helper';
import {
  columnsForTableOfTariffs,
  initFilterTariffs,
  initFirstRenderFields,
  periodOptions,
  tariffsSortOptions,
} from './constants';
import { SortBox } from 'src/components/SortBox/SortBox';
import {
  contractorRolesForBack,
  IFilterTariffs,
  IFirstRenderFields,
  IMaterialOptions,
  ISelectValue,
  ITariff,
  sortForBack,
} from './types';
import FilterBox from 'src/components/newUI/FilterBox/FilterBox';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../../components/UI/svg-icon/NewPlusSvg';
import { useNavigate } from 'react-router-dom';
import { AdminTariffsFilter } from './components/AdminTariffsFilter/AdminTariffsFilter';
import CustomTable from 'src/components/newUI/CustomTable/CustomTable';
import { IOptions } from '../AdminTariffsAddPage/type';

const limit: number = 10;
const actions = ['filter', 'search', 'sort'] as const;

export const AdminTariffsPage = () => {
  const [isFirstRender, setIsFirstRender] = useState<IFirstRenderFields>(initFirstRenderFields);
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filter, setFilter] = useState<IFilterTariffs>(null);
  const [searchString, setSearchString] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchString, 500);
  const [tariffs, setTariffs] = useState<ITariff[]>([]);
  const [sort, setSort] = useState<ISelectValue>(tariffsSortOptions[0]);
  const [openSort, setOpenSort] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [showId, setShowId] = useState<number>(null);
  const [isDisabledFilterButton, setIsDisabledFilterButton] = useState<boolean>(true);
  const [organizationOptions, setOrganizationOptions] = useState<IOptions[]>([]);
  const [materialOptions, setMaterialOptions] = useState<IMaterialOptions[]>([]);
  const [materialTypeOptions, setMaterialTypeOptions] = useState<IOptions[]>([]);
  const navigate = useNavigate();
  const isEmptyFilters =
    !filter?.organizator && !filter?.typeOfContractor && !filter?.material && !filter?.validityPeriod;
  const savedFilter = JSON.parse(localStorage.getItem('adminTariffsFilter'));
  const isEmptySavedFilters =
    !savedFilter?.organizator &&
    !savedFilter?.typeOfContractor &&
    !savedFilter?.material &&
    !savedFilter?.validityPeriod;
  const initialStateSearch = localStorage.getItem('rootSearchTariffsField') || '';
  const initialStateSort = JSON.parse(localStorage.getItem('adminTariffsSort')) || tariffsSortOptions[0];

  const getTariffs = async (isPagination: boolean) => {
    try {
      setLoading(true);
      const response = await fetchGet(
        '/admin/tariffs/list',
        clearObject({
          limit: limit,
          offset: isPagination ? offset : 0,
          type: contractorRolesForBack[filter.typeOfContractor] || undefined,
          organizationId: Number(filter?.organizator) || undefined,
          tariffDate:
            filter?.validityPeriod || filter?.validityPeriod === '0' ? Number(filter?.validityPeriod) : undefined,
          materialId: Number(filter?.material) || undefined,
          searchString: searchString || undefined,
          order: sortForBack[sort?.label],
          materialKind: filter?.materialType || undefined,
        }),
      );
      setTotalCount(response?.count);
      if (toastError(response)) return;
      setTariffs(prevState => prevState.concat(response?.rows));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const getOrganizationOptions = async () => {
    try {
      const response = await fetchGet('/admin/companies/organizations', {});
      if (toastError(response)) return;
      setOrganizationOptions(
        response.map(item => {
          return {
            value: String(item.id),
            label: item.title,
          };
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const getMaterialOptions = async () => {
    try {
      const response = await fetchGet('/admin/materials/types', { withKinds: true });
      if (toastError(response)) return;
      setMaterialOptions(
        response.map(item => {
          return {
            value: item?.id,
            label: item?.title,
            materialKind: item?.materialKind?.properties?.map(kind => {
              return {
                value: kind?.value,
                label: kind?.value,
              };
            }),
          };
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const clearFilters = () => {
    setFilter(initFilterTariffs);
  };

  usePageScroll(setOffset, limit);

  useEffect(() => {
    setSearchString(initialStateSearch || null);
    setFilter(!isEmptySavedFilters ? savedFilter : initFilterTariffs);
    setSort(initialStateSort);
    if (!isEmptySavedFilters) {
      setIsDisabledFilterButton(false);
    }
    getOrganizationOptions();
    getMaterialOptions();
  }, []);

  useEffect(() => {
    if (totalCount > offset) {
      getTariffs(true);
    }
  }, [offset]);

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
    const countOfFirstRender = Object.values(isFirstRender).filter(value => value === true).length;
    const action = actions.find(action => isFirstRender[action]);
    setIsFirstRender(prev => ({ ...prev, [action]: false }));
    if (countOfFirstRender > 1) return;
    setIsDisabledFilterButton(isEmptyFilters);
    localStorage.setItem('adminTariffsFilter', JSON.stringify(filter));
    if (searchString !== null) localStorage.setItem('rootSearchTariffsField', searchString);
    localStorage.setItem('adminTariffsSort', JSON.stringify(sort));
    if (tariffs.length) setTariffs([]);
    if (offset !== 0) setOffset(0);
    setTotalCount(0);
    getTariffs(false);
  }, [filter, sort, debouncedSearchTerm]);

  return (
    <PageWrapper>
      <div className={styles.container}>
        <div className={styles.container}>
          <div className={styles.titleAndSubtitleContainer}>
            <div className={styles.titleAndButtonContainer}>
              <TitleMain title={'Тарифы'} />
              <ButtonFilled
                onClick={() => {
                  navigate('/tariffs/add');
                }}
                svg={<NewPlusSvg />}
                width={'140px'}
                sizeText={'small'}
                text={'Добавить'}
              />
            </div>
            <div className={styles.subTitleContainer}>
              <SubTitleMain subTitle={'Тарифы поставщиков и перевозчиков'} />
            </div>
          </div>
        </div>
        <>
          <div className={styles.inputsAndSelectorsContainer}>
            <div style={{ flex: 1 }}>
              <InputSearch
                placeholder={'Поиск по id, компании'}
                onInput={({ currentTarget: { value: search } }) => setSearchString(search)}
                value={searchString}
              />
            </div>
            <SortBox
              sortOptions={tariffsSortOptions}
              openSort={openSort}
              setOpenSort={setOpenSort}
              sort={sort}
              setSort={setSort}
            />
            <FilterBox
              clearFilters={clearFilters}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              disabled={isDisabledFilterButton}
            >
              <AdminTariffsFilter
                filter={filter || initFilterTariffs}
                setFilter={setFilter}
                materialTypeOptions={materialTypeOptions}
                setMaterialTypeOptions={setMaterialTypeOptions}
                organizationOptions={organizationOptions}
                materialOptions={materialOptions}
                periodOptions={periodOptions}
              />
            </FilterBox>
          </div>
          <div className={styles.customTableContainer}>
            <CustomTable
              setShowId={setShowId}
              link={true}
              loading={loading}
              columns={columnsForTableOfTariffs(showId)}
              data={tariffs}
              emptyDescription=""
              emptyTitle="Данных нет"
              emptyFilterTitle=""
            />
          </div>
        </>
      </div>
    </PageWrapper>
  );
};
