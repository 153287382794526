import React, { useEffect } from 'react';
import styles from './CreateOrderFields.module.scss';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import {
  getBuyers,
  getContracts,
  selectCalculationDetailsInfo,
  selectCalculationOptions,
} from 'src/Pages/AdminPages/AdminCalculationSupplies/AdminCalculationSuppliesSlice';
import { ICalculationAddProperties, ICalculationDetailsInfo } from 'src/Pages/AdminPages/AdminCalculationSupplies/type';
import { orderPropertyName } from '../../type';
import { useLocation } from 'react-router-dom';
import { OptionFieldOrder } from '../OptionFieldOrder/OptionFieldOrder';
import { CheckBoxCustom } from 'src/components/UI/checkBox/CheckBoxCustom';
import {
  changeFiledOptions,
  changeOrderFiledIsDisabled,
  changeOrderFiledValue,
  getCurrentOrder,
  getOrders,
  selectCreateOrderFields,
  substituteValueFromCalculation,
} from '../../OrderSlice';

export const CreateOrderFields = () => {
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const calculationId = pathname.split('/')[3];
  const createOrderFields = useAppSelector(selectCreateOrderFields);
  const list: ICalculationDetailsInfo[] = useAppSelector(selectCalculationDetailsInfo);
  let properties: ICalculationAddProperties[] | any = useAppSelector(selectCalculationOptions);
  const fieldNamesHaveMethod = ['clientTitle', 'clientContract', 'addToOrder', 'workSchedule'];

  const buyer = properties.find(field => field.name === 'clientTitle')?.value; // Покупатель из расчёта
  class changeOrderField {
    workSchedule() {
      dispatch(changeOrderFiledValue({ name: orderPropertyName.workScheduleTimeLeft, value: null }));
      dispatch(changeOrderFiledValue({ name: orderPropertyName.workScheduleTimeRight, value: null }));
    }
    clientTitle(value) {
      //Логика изменения при изменении покупателя
      dispatch(getContracts({ companyId: value })).then(data => {
        dispatch(changeFiledOptions({ name: orderPropertyName.clientContract, properties: data?.payload }));
        dispatch(changeOrderFiledValue({ name: orderPropertyName.clientContract, value: '' }));
        dispatch(changeOrderFiledValue({ name: orderPropertyName.addToOrder, value: '' }));
        dispatch(changeOrderFiledIsDisabled({ name: orderPropertyName.clientContract, value: false }));
        dispatch(changeOrderFiledIsDisabled({ name: orderPropertyName.addToOrder, value: true }));
      });
    }
    clientContract(value) {
      //Логика изменения при изменении договора
      dispatch(getOrders(Number(value)));
      dispatch(changeOrderFiledValue({ name: orderPropertyName.addToOrder, value: '' }));
      dispatch(changeOrderFiledIsDisabled({ name: orderPropertyName.addToOrder, value: false }));
    }
    addToOrder(value) {
      //Если нет значения, то очищаем поля
      if (!value) {
        dispatch(changeOrderFiledValue({ name: orderPropertyName.object, value: '' }));
        dispatch(changeOrderFiledValue({ name: orderPropertyName.address, value: '' }));
        dispatch(changeOrderFiledValue({ name: orderPropertyName.coordinates, value: '' }));
        dispatch(changeOrderFiledValue({ name: orderPropertyName.coordinates, value: '' }));
        dispatch(
          changeOrderFiledValue({
            name: orderPropertyName.workSchedule,
            value: '',
          }),
        );
        dispatch(
          changeOrderFiledValue({
            name: orderPropertyName.typeSchedule,
            value: '',
          }),
        );
        dispatch(changeOrderFiledValue({ name: orderPropertyName.comment, value: '' }));
        dispatch(changeOrderFiledValue({ name: orderPropertyName.clientName, value: '' }));
        dispatch(changeOrderFiledValue({ name: orderPropertyName.clientPhone, value: '' }));
      }

      //Логика изменения при изменении заказа
      value &&
        dispatch(getCurrentOrder(value)).then(data => {
          dispatch(changeOrderFiledValue({ name: orderPropertyName.object, value: data?.payload.objectTitle }));
          dispatch(changeOrderFiledValue({ name: orderPropertyName.address, value: data?.payload.address.address }));
          dispatch(
            changeOrderFiledValue({
              name: orderPropertyName.coordinates,
              value: `${data?.payload.address.longitude}; ${data?.payload.address.latitude}`,
            }),
          );
          //Сначала зануляем таймпикеры, чтобы точно не было значений.
          dispatch(changeOrderFiledValue({ name: orderPropertyName.workScheduleTimeLeft, value: '' }));
          dispatch(changeOrderFiledValue({ name: orderPropertyName.workScheduleTimeRight, value: '' }));
          //Дальше, если есть значение с бэка, то сеттаем их в таймпикеры
          if (data?.payload.workSchedulesGraph.aroundTheClock) {
            dispatch(
              changeOrderFiledValue({
                name: orderPropertyName.workSchedule,
                value: { value: 'Круглосуточная', label: 'Круглосуточная' },
              }),
            );
          }
          if (!data?.payload.workSchedulesGraph.aroundTheClock) {
            dispatch(
              changeOrderFiledValue({
                name: orderPropertyName.workSchedule,
                value: { value: 'Дневная', label: 'Дневная' },
              }),
            );
            dispatch(
              changeOrderFiledValue({
                name: orderPropertyName.workScheduleTimeLeft,
                value: data?.payload.workSchedulesGraph.from,
              }),
            );
            dispatch(
              changeOrderFiledValue({
                name: orderPropertyName.workScheduleTimeRight,
                value: data?.payload.workSchedulesGraph.to,
              }),
            );
          }
          dispatch(
            changeOrderFiledValue({
              name: orderPropertyName.typeSchedule,
              value: { value: data?.payload.acceptance, label: data?.payload.acceptance },
            }),
          );
          dispatch(changeOrderFiledValue({ name: orderPropertyName.comment, value: data?.payload.comment }));
          dispatch(
            changeOrderFiledValue({ name: orderPropertyName.clientName, value: data?.payload.contactPerson.person }),
          );
          dispatch(
            changeOrderFiledValue({ name: orderPropertyName.clientPhone, value: data?.payload.contactPerson.phone }),
          );
        });
    }
  }
  const changeCurrentOrderField = new changeOrderField();
  const changeDisableWhenMounting = (fieldName: string, value: any, isDisabled) => {
    if (fieldName === orderPropertyName.clientTitle && value) {
      return true;
    }
    if (fieldName === orderPropertyName.clientContract && value) return true;
    return isDisabled;
  };
  const changeValueWhenMounting = (fieldName: string, value: any) => {
    const longitude = properties.find(field => field.name === 'addressLatitude')?.value; // Широта из расчёта
    const latitude = properties.find(field => field.name === 'addressLongitude')?.value; // Долгота из расчёта
    if (fieldName === orderPropertyName.coordinates) {
      return `${longitude}; ${latitude}`;
    }
    if (fieldName === orderPropertyName.clientPricePerUnit) {
      return value.replaceAll(' ₽/м³', '').replaceAll(' ₽/тонн', '');
    }
    return value;
  };
  useEffect(() => {
    const correctionProperties: any = properties.concat(list);
    createOrderFields.forEach(filed => {
      dispatch(
        substituteValueFromCalculation({
          name: filed.name,
          fieldType:
            filed.fieldType !== 'text' &&
            filed.fieldType !== 'textArea' &&
            correctionProperties.find(item => item.name === filed.name)?.value
              ? 'input'
              : '',
          isDisabled: changeDisableWhenMounting(
            filed.name,
            correctionProperties.find(item => item.name === filed.name)?.value,
            filed.isDisabled,
          ),
          value: changeValueWhenMounting(
            filed.name,
            correctionProperties.find(item => item.name === filed.name)?.value,
          ),
        }),
      );
    });
  }, [properties, list]);
  useEffect(() => {
    if (buyer) {
      //Если из расчётов пришел покупатель, то дергаем завпрос на получение опцый договора(так как есть вероятностьчто покупатель будет без договора )
      const buyerId = createOrderFields[4].properties.find(item => item?.label.includes(buyer))?.value;
      dispatch(getContracts({ companyId: buyerId })).then(data => {
        if (data?.payload.length) {
          dispatch(changeOrderFiledIsDisabled({ name: orderPropertyName.clientContract, value: false }));
        }
        dispatch(changeFiledOptions({ name: orderPropertyName.clientContract, properties: data?.payload }));
      });
    }
  }, [buyer, createOrderFields[4].properties]);
  useEffect(() => {
    dispatch(getBuyers({ type: 'buyer' })).then(data => {
      dispatch(changeFiledOptions({ name: orderPropertyName.clientTitle, properties: data?.payload }));
    });
  }, []);
  createOrderFields.slice();
  return (
    <div className={styles.container}>
      <div className={styles.topOptionsContainer}>
        <OptionFieldOrder
          properties={createOrderFields.slice(0, 1)}
          changeValue={data => {
            dispatch(changeOrderFiledValue(data));
          }}
          calculationId={calculationId}
        />
        <OptionFieldOrder
          properties={createOrderFields.slice(1, 4)}
          changeValue={data => {
            dispatch(changeOrderFiledValue(data));
          }}
          calculationId={calculationId}
        />

        <div className={styles.option} style={{ width: '520px' }}>
          <p className={styles.materialTypeTitle}>{'Дополнительно'}</p>
          <div className={styles.checkboxesContainer}>
            {createOrderFields.slice(19, 22).map(item => (
              <CheckBoxCustom
                key={item.name}
                error={false}
                children={item?.title}
                check={item?.value}
                pick={() => {
                  dispatch(
                    changeOrderFiledValue({
                      name: item.name,
                      value: !item?.value,
                    }),
                  );
                }}
                name={item.name}
                id={item.name}
                disabled={item.isDisabled}
                size={item.size}
                additionalClassName={styles.checkboxText}
              />
            ))}
          </div>
        </div>
      </div>

      <OptionFieldOrder
        properties={createOrderFields.slice(4)}
        changeValue={data => {
          dispatch(changeOrderFiledValue(data));
          fieldNamesHaveMethod.includes(data.name) && changeCurrentOrderField[data.name](data.value);
        }}
        calculationId={calculationId}
      />
    </div>
  );
};
