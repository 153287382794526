import React from 'react';

const ChevronDownSvg = ({ width = '24px', color = 'var(--color-blue900)' }: { width?: string; color?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 24 25" fill="none">
      <path d="M19 10.5L12 16.5L5 10.5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ChevronDownSvg;
