import React, { useEffect, useState } from 'react';
import styles from './AdminCalculationSupplies.module.scss';
import { PageWrapper } from '../../../components/PageWrapper';
import TitleMain from '../../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../../components/UI/svg-icon/NewPlusSvg';
import SubTitleMain from '../../../components/UI/TitleAndSubtitle/SubTitleMain';
import MainFilters from './components/MainFilters/MainFilters';
import { IAdminCalculationFilters, IDataGetListCalculationInAdmin } from './type';
import { dataTableCalculations, initStateAdminCalculationFilters } from './const';
import CustomTable from '../../../components/newUI/CustomTable/CustomTable';
import { useAppDispatch, useAppSelector } from '../../../CustomHooks/storeHooks';
import {
  getAllCalculationInAdmin,
  selectLoadingCalculation,
  selectTableCalculation,
  selectTotalCountCalculation,
} from './AdminCalculationSuppliesSlice';
import { usePageScroll } from '../../../CustomHooks/usePageScroll';
import { clearObject } from '../../../common/ClearObject.helper';
import useDebounce from '../../../CustomHooks/useDebounce';
import { useNavigate } from 'react-router-dom';
import { ButtonSimple } from '../../../components/newUI/ButtonSimple/ButtonSimple';
import ModalCalculationFAQ from './components/ModalCalculationFAQ/ModalCalculationFAQ';

const AdminCalculationSupplies = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<IAdminCalculationFilters>(initStateAdminCalculationFilters);
  const [search, setSearch] = useState<string>('');
  const [offset, setOffset] = useState<number>(0);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const debounceSearch = useDebounce(search, 500);
  const totalCount = useAppSelector(selectTotalCountCalculation);
  const dataTable = useAppSelector(selectTableCalculation);
  const loading = useAppSelector(selectLoadingCalculation);
  const [openModalFAQ, setOpenModalFAQ] = useState<boolean>(false);
  const isEmptyFilters = !filters.checkCallback && !filters.manager && !filters.material && !search;
  usePageScroll(setOffset, 10);
  const requestDataGetListCalculationInAdmin: IDataGetListCalculationInAdmin = {
    limit: 10,
    offset: offset,
    searchString: search || undefined,
    managerId: Number(filters.manager) || undefined,
    materialTypeId: Number(filters.material) || undefined,
    callback: filters.checkCallback || undefined,
  };

  useEffect(() => {
    if (totalCount >= offset) {
      dispatch(getAllCalculationInAdmin(clearObject(requestDataGetListCalculationInAdmin)));
    }
  }, [offset]);
  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
    window.scrollTo(0, 0);
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    setOffset(0);
    dispatch(getAllCalculationInAdmin(clearObject(requestDataGetListCalculationInAdmin)));
  }, [filters, debounceSearch]);
  return (
    <PageWrapper>
      <ModalCalculationFAQ openModalFAQ={openModalFAQ} setOpenModalFAQ={setOpenModalFAQ} />
      <div className={styles.topContainer}>
        <div className={styles.titleAndButtonContainer}>
          <TitleMain title={'Расчет поставок'} />
          <div className={styles.buttonsContainer}>
            <ButtonSimple text={'Как это работает?'} onClick={() => setOpenModalFAQ(true)} />
            <ButtonFilled
              text={'Создать новый расчет'}
              svg={<NewPlusSvg />}
              sizeText={'small'}
              width={'fit-content'}
              onClick={() => navigate(`/calculation_supplies/create`)}
            />
          </div>
        </div>
        <SubTitleMain subTitle={'Калькулятор расчета поставки'} />
      </div>
      <div className={styles.filtersContainer}>
        <MainFilters filters={filters} setFilters={setFilters} search={search} setSearch={setSearch} />
      </div>
      <div className={styles.customTableContainer}>
        <CustomTable
          link={true}
          linkTextBeforeId={'/calculation_supplies'}
          loading={loading}
          columns={dataTableCalculations}
          data={dataTable}
        />
      </div>
    </PageWrapper>
  );
};

export default AdminCalculationSupplies;
