import { ICalculationDetailsItem, IPayloadActionCalculationRows } from './type';

export const formatDate = (isoString: string): string => {
  const date = new Date(isoString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};
export const convertDataForCalculationTableByMap = (item: IPayloadActionCalculationRows) => {
  return {
    ...item,
    clientAndObject: {
      client: item?.client?.title,
      object: item?.address?.address,
    },
    materialAndQuarry: {
      material: item?.materialType?.title,
      quarry: item?.quarry?.title,
    },
    callbackClientAndPhone: {
      callback: item?.callback,
      phone: item?.clientPhone,
      client: item?.client?.title,
    },
    idOrder: {
      id: item?.id,
      order: item?.order?.title,
    },
    priceAndUnit: {
      price: item.price,
      unit: item.unit,
    },
  };
};

export const prepareDetailsList = (list: ICalculationDetailsItem[], id: number) => {
  return list?.map((item, index) => {
    return {
      ...item,
      carrier: {
        ...item?.carrier[0],
        inn: item?.carrier[0]?.inn,
        semitrailersValue:item?.semitrailersValue,

      },
      quarry: {
        title: item?.quarry?.title,
        distance:
          `${item?.distance ?? 0} км` +
          (item?.tollDistance ? `, $$ ${item?.tollDistance} км` : '') +
          (item?.manualDistance ? `, логист ${item?.manualDistance} км` : ''),
        checked: index === id,
      },
      cost: {
        pricePerTon: item?.results?.supplierCost,
        unit: item?.results?.unit,
        actualPrice: item?.results?.supplierPriceOnRequest,
      },
      delivery: {
        price: item?.results?.carrierCost,
        actualPrice: item?.results?.carrierPriceOnRequest,
        unit: item?.results?.unit,
      },
      total: {
        amount: item?.results?.totalCost,
        totalCostPerUnit: item?.results?.totalCostPerUnit,
        unit: item?.results?.unit,
      },
    };
  });
};
