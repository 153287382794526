import React, { useRef, useState } from 'react';
import styles from './ChangeMarginInListMaterial.module.scss';
import Pen2Svg from '../../../../../components/UI/svg-icon/Pen2Svg';
import useClickOutsideNode from '../../../../../CustomHooks/useClickOutsideNode';
import { CloseSvg } from '../../../../../components/UI/svg-icon/CloseSvg';
import CustomInput from '../../../../../components/newUI/CustomInput/CustomInput';
import ButtonFilled from '../../../../../components/newUI/ButtonFilled/ButtonFilled';
import ButtonNotFilled from '../../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { regForFlakinessWithPoint } from '../../../../../common/number.helper';
import { useAppDispatch } from '../../../../../CustomHooks/storeHooks';
import { patchMarginCurrentOrder } from '../../OrderSlice';
interface IProps {
  id: number;
}
const ChangeMarginInListMaterial = ({ id }: IProps) => {
  const [valueMargin, setValueMargin] = useState<string>('');
  const [openWindow, setOpenWindow] = useState<boolean>(false);
  const refContainer = useRef(null);
  useClickOutsideNode(refContainer, setOpenWindow);
  const dispatch = useAppDispatch();
  const validationMargin = value => regForFlakinessWithPoint.test(value) || value === '';
  const closeWindow = () => {
    setOpenWindow(false);
  };
  const handleChangeMargin = e => {
    if (validationMargin(e)) {
      setValueMargin(e);
    }
  };
  const handleClickSaveButton = () => {
    dispatch(patchMarginCurrentOrder({ data: { plannedMargin: Number(valueMargin) }, id: id }));
    closeWindow();
  };

  return (
    <div ref={refContainer} className={styles.container}>
      <div className={styles.svgContainer} onClick={() => setOpenWindow(!openWindow)}>
        <Pen2Svg />
      </div>
      {openWindow && (
        <div className={styles.windowContainer}>
          <div className={styles.titleAndCloseContainer}>
            <p className={styles.titleContainer}>Плановая маржа</p>
            <div onClick={closeWindow} className={styles.svgContainer}>
              <CloseSvg />
            </div>
          </div>
          <CustomInput
            suffix={'%'}
            setValue={handleChangeMargin}
            value={valueMargin}
            placeholder={'Введите значение'}
          />
          <div className={styles.buttonsContainer}>
            <ButtonFilled text={'Сохранить'} width={'100%'} onClick={handleClickSaveButton} />
            <ButtonNotFilled text={'Отменить'} width={'100%'} onClick={closeWindow} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeMarginInListMaterial;
