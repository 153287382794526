import React, { useEffect } from 'react';
import styles from './AdminCreateOrderPage.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageWrapper } from 'src/components/PageWrapper';
import TitleMain from 'src/components/UI/TitleAndSubtitle/TitleMain';
import BackButton from 'src/components/newUI/BackButton/BackButton';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { CreateOrderTopInfo } from '../components/CreateOrderTopInfo/CreateOrderTopInfo';
import CalculationDetailsList from '../../AdminCalculationSupplies/components/CalculationDetailsList/CalculationDetailsList';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import {
  addInfoInCreatedCalculationFields,
  changeCalculationDetailsInfo,
  getCalculation,
  getDetails,
} from '../../AdminCalculationSupplies/AdminCalculationSuppliesSlice';
import { CreateOrderFields } from '../components/CreateOrderFields/CreateOrderFields';
import { prepareOrderDateToCreate } from '../utils';
import { createOrder, selectCreateOrderFields, setInitStateFor, substituteValueFromCalculation } from '../OrderSlice';
import { orderPropertyName } from '../type';

export const AdminCreateOrderPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const calculationId = pathname.split('/')[3];
  const dispatch = useAppDispatch();
  const createOrderFields = useAppSelector(selectCreateOrderFields);
  useEffect(() => {
    dispatch(getCalculation(Number(calculationId))).then(data => {
      dispatch(addInfoInCreatedCalculationFields(data?.payload));
    });
    dispatch(getDetails(Number(calculationId))).then(() => {
      dispatch(changeCalculationDetailsInfo());
    });
    return () => {
      dispatch(
        substituteValueFromCalculation({ name: orderPropertyName.clientContract, value: '', isDisabled: false }),
      );
      dispatch(setInitStateFor());
      dispatch(substituteValueFromCalculation({ name: orderPropertyName.clientTitle, value: '', isDisabled: false }));
    };
  }, []);
  const requiredFields = createOrderFields.filter(field => field.isRequired && !field.value);

  const handleCreateOrder = () => {
    dispatch(createOrder(prepareOrderDateToCreate(createOrderFields, calculationId))).then(res => {
      if (res.payload.id) {
        navigate(`/order/${res.payload.id}`);
      }
    });
  };
  return (
    <PageWrapper>
      <div className={styles.topContainer}>
        <BackButton
          textButton={'Вернуться назад'}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className={styles.titleAndButtonContainer}>
          <TitleMain fontSize={'24px'} title={'Создание заказа'} />
          <ButtonFilled
            text="Сохранить"
            disabled={!!requiredFields.length}
            onClick={() => {
              handleCreateOrder();
            }}
            width="95px"
            sizeText="small"
          />
        </div>
      </div>
      <div className={styles.mainContainer}>
        <CreateOrderTopInfo />
        <CalculationDetailsList />
        <CreateOrderFields />
      </div>
    </PageWrapper>
  );
};
