export interface ISelectValue {
  value: string;
  label: string;
}

export interface IFilterTariffs {
  organizator: string;
  typeOfContractor: string;
  validityPeriod: string;
  material: string;
  materialType: string;
}

export interface IMaterialOptions {
  value: string;
  label: string;
  materialKind: {
    value: string;
    label: string;
  }[];
}

export interface ITariff {
  id: number;
  organization: { inn: string; title: string };
  company: { title: string; type: string; inn: string };
  dateFrom: string;
  dateTo: string;
  margin: string;
  tariffDetailsInfo: ITariffDetails[];
  user: { id: number; fullName: string; updatedAt: string };
}

interface ITariffDetails {
  quarryTitle: string;
  cost: number;
  material: string;
  unit: string;
}

export interface IFirstRenderFields {
  sort: boolean;
  search: boolean;
  filter: boolean;
}

export enum contractorRoles {
  CARRIER = 'Перевозчик',
  SUPPLIER = 'Поставщик',
  SHIPMENT = 'Поставка',
}

export enum contractorRolesForBack {
  'Перевозчик' = 'carrier',
  'Поставщик' = 'supplier',
  'Поставка' = 'shipment',
}

export enum sortForBack {
  'Новые' = 'DESC',
  'Старые' = 'ASC',
}

export enum period {
  ACTIVE = 'Действующиие тарифы',
  THREEDAYS = 'Закончатся через 3 дня',
  TENDAYS = 'Закончатся через 10 дней',
  UNACTIVE = 'Неактивные тарифы',
}
