import React, { useEffect, useState } from 'react';
import styles from '../../../../../Pages/DocumentsPages/Components/AddContract/AddContract.module.scss';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import { Input } from 'antd';
import SelectLk from 'src/components/newUI/SelectLk/SelectLk';
import { sizeEnum } from 'src/components/newUI/SelectLk/utils';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { ModalNew } from 'src/components/ModalNew/ModalNew';
import SuccessContractModal from 'src/Pages/DocumentsPages/Components/SuccessContractModal/SuccessContractModal';
import FrameModalSvg from 'src/components/UI/svg-icon/FrameModalSvg';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import {
  getIdentificator,
  loadSpecificationEDO,
  selectInformationEDO,
  selectLoadingCurrentOrder,
} from '../../OrderSlice';
import { useLocation } from 'react-router-dom';

interface IProps {
  openAddContract: boolean;
  setOpenAddContract: React.Dispatch<React.SetStateAction<boolean>>;
  getData: () => Promise<void>;
  receiver?: string;
  typeOfDocument?: string;
  link?: string;
  id: number;
}

export const AddSpecification = ({
  id,
  openAddContract,
  setOpenAddContract,
  getData,
  receiver = 'Неруд центр',
  typeOfDocument = 'Договор',
}: IProps) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const orderId: string = pathname.split('/')[2];
  const [successContractModal, setSuccessContractModal] = useState<boolean>(false);
  const [contractIdentifier, setContractIdentifier] = useState<string>('');
  const [currentSystemOption, setCurrentSystemOption] = useState<string>('');
  const loading = useAppSelector(selectLoadingCurrentOrder);
  const information = useAppSelector(selectInformationEDO);
  const isEmptySelectors = !contractIdentifier || !currentSystemOption;

  const closeAddContract = () => {
    setOpenAddContract(false);
  };

  const sendContract = () => {
    dispatch(
      loadSpecificationEDO({
        id: Number(orderId),
        data: { provider: currentSystemOption, identificator: contractIdentifier },
      }),
    ).then(data => {
      if (!!data?.payload?.id) {
        closeAddContract();
        setSuccessContractModal(true);
      }
    });
  };

  useEffect(() => {
    dispatch(getIdentificator(id));
  }, []);

  return (
    <>
      <ModalNew openModal={openAddContract} setOpenModal={setOpenAddContract} width={'480px'}>
        <div className={styles.container}>
          <div className={styles.topContainer}>
            <h1 className={styles.title} style={{ fontSize: '28px' }}>
              Подписание спецификации
            </h1>
            {loading ? (
              <LoadingSpin />
            ) : (
              <div className={styles.contentContainer}>
                <div className={styles.blockContainer}>
                  <p className={styles.blockTitleText}>Получатель</p>
                  <Input className={styles.inputContainer} value={receiver} disabled={true} />
                </div>
                <div className={styles.border} />
                <div className={styles.blockContainer}>
                  <div className={styles.blockTitleContainer}>
                    <p className={styles.blockTitleText}>Тип документа</p>
                  </div>
                  <Input className={styles.inputContainer} value={typeOfDocument} disabled={true} />
                </div>
                <div className={styles.border} />
                <div className={styles.blockContainer}>
                  <div className={styles.blockTitleContainer}>
                    <p className={styles.blockTitleText}>Система ЭДО</p>
                  </div>
                  <SelectLk
                    error={false}
                    disabled={false}
                    value={currentSystemOption}
                    setValue={value => {
                      setCurrentSystemOption(value);
                      setContractIdentifier(null);
                    }}
                    options={information.map(item => {
                      return { value: item?.provider, label: item?.provider };
                    })}
                    isSearch={true}
                    placeholder={'Выберите систему ЭДО'}
                    size={sizeEnum.SMALL}
                  />
                </div>

                <div className={styles.border} />
                <div className={styles.blockContainer}>
                  <div className={styles.blockTitleContainer}>
                    <p className={styles.blockTitleText}>Идентификатор</p>
                  </div>
                  <SelectLk
                    value={contractIdentifier}
                    error={false}
                    disabled={false}
                    setValue={value => setContractIdentifier(value)}
                    options={information
                      .find(item => item?.provider === currentSystemOption)
                      ?.identificator?.map(itemId => {
                        return { value: itemId, label: itemId };
                      })}
                    placeholder={'Выберите значение'}
                    isSearch={true}
                    size={sizeEnum.SMALL}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <ButtonNotFilled onClick={closeAddContract} disabled={loading} text={'Отмена'} width={'100%'} />
            <ButtonFilled
              disabled={loading || isEmptySelectors}
              onClick={sendContract}
              text={'Отправить'}
              width={'100%'}
            />
          </div>
        </div>
        <div className={styles.closeButton}>
          <FrameModalSvg onClick={closeAddContract} />
        </div>
      </ModalNew>
      <SuccessContractModal
        typeOfDocument="spec"
        successContractModal={successContractModal}
        titlesOfTheCreatedContract={null}
        setSuccessContractModal={setSuccessContractModal}
        getData={getData}
      />
    </>
  );
};
