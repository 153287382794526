import React from 'react';

const DumpTruckOutline = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 4.8C12.2737 3.714 9.45765 3 7.11766 3C4.77766 3 2.22631 3.714 1 4.8V6H13.5V4.8Z"
        fill="var(--color-orange400)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 8H14V14H3L1.57 9H1V7H13L14 6H18V8ZM2.5 8H13V13H4L2.5 8Z"
        fill="var(--color-orange400)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 13L20 9H15V15H2V18H3C3 18.7956 3.31607 19.5587 3.87868 20.1213C4.44129 20.6839 5.20435 21 6 21C6.79565 21 7.55871 20.6839 8.12132 20.1213C8.68393 19.5587 9 18.7956 9 18H15C15 18.7956 15.3161 19.5587 15.8787 20.1213C16.4413 20.6839 17.2044 21 18 21C18.7956 21 19.5587 20.6839 20.1213 20.1213C20.6839 19.5587 21 18.7956 21 18H23V13ZM6 19.5C5.60218 19.5 5.22064 19.342 4.93934 19.0607C4.65804 18.7794 4.5 18.3978 4.5 18C4.5 17.6022 4.65804 17.2206 4.93934 16.9393C5.22064 16.658 5.60218 16.5 6 16.5C6.39782 16.5 6.77936 16.658 7.06066 16.9393C7.34196 17.2206 7.5 17.6022 7.5 18C7.5 18.3978 7.34196 18.7794 7.06066 19.0607C6.77936 19.342 6.39782 19.5 6 19.5ZM18 19.5C17.6022 19.5 17.2206 19.342 16.9393 19.0607C16.658 18.7794 16.5 18.3978 16.5 18C16.5 17.6022 16.658 17.2206 16.9393 16.9393C17.2206 16.658 17.6022 16.5 18 16.5C18.3978 16.5 18.7794 16.658 19.0607 16.9393C19.342 17.2206 19.5 17.6022 19.5 18C19.5 18.3978 19.342 18.7794 19.0607 19.0607C18.7794 19.342 18.3978 19.5 18 19.5ZM17 13V10.5H19.5L21.46 13H17Z"
        fill="var(--color-orange400)"
      />
    </svg>
  );
};

export default DumpTruckOutline;
