import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { fetchGet, fetchPost } from '../../common/proxy-1C-fetch-auth';
import { useMediaQuery } from 'react-responsive';
import { CarsList } from '../../components/CarsList';
import { CarPagePopup } from '../../components/CarPagePopup';
import { toast, ToastContainer } from 'react-toastify';
import { closePopUp, openPopUp } from '../../common/open-close-popup.function';
import { InputSearch } from '../../components/UI/inputs/InputSearch';
import useDebounce from '../../CustomHooks/useDebounce';

// @ts-ignore
import LoadingGif from '../../assets/img/Loader-Solber.gif';
import styles from './CarsPage.module.scss';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import { declinationWord } from '../../common/declinationWord.helper';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import { EmptyList } from '../../components/EmptyList';
import { IOption, IResponseSemitrailers } from './const';
import ButtonPrimeNotFilled from "../../components/newUI/ButtonPrimeNotFilled/ButtonPrimeNotFilled";
import InsuranceSvg from "../../components/UI/svg-icon/insuranceSvg";
import {useAppDispatch, useAppSelector} from "../../CustomHooks/storeHooks";
import {
  getAllImagesBanner,
  postLogImage,
  selectButtonInsurance,
  selectImages
} from "../../components/Banner/services/BannerSlice";
import {IImageBanner, typeBanner} from "../../components/Banner/type";
const InitialCar = {
  valueTypeTC: '',
  valueBrandTC: '',
  stsNumber: '',
  stateNumber: '',
  valueOwnership: '',
  volumeBody: '',
  semitrailerId: '',
};
interface ICar {
  valueOwnership: string | { value: string; label: string };
  valueTypeTC?: string | { value: string; label: string };
  valueBrandTC?: string | { value: string; label: string };
  volumeBody: string;
  stsNumber: string;
  stateNumber: string;
  semitrailerId: string;
}

export const CarsPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useAppDispatch();
  const buttonInsurance: IImageBanner[] = useAppSelector(selectButtonInsurance);
  const [carsList, setCarsList] = useState([]);
  const [semitrailersInfo, setSemitrailersInfo] = useState<IOption[]>([]);
  const [typeList, setTypeList] = useState<{ value: string; label: string }[]>();
  const [brandList, setBrandList] = useState<{ value: string; label: string }[]>();
  const [ownershipList, setOwnershipList] = useState<{ value: string; label: string }[]>();
  const [uploadImagesOwnership, setUploadImagesOwnership] = useState<any>([]);
  const [uploadImagesStsNumber, setUploadImagesStsNumber] = useState<any>([]);
  const [popUpActive, setPopUpActive] = useState(false);
  const [error] = useState(false);
  const [car, setCar] = useState<ICar>(InitialCar);
  const [disabled, setDisabled] = useState(true);
  const [sending, setSending] = useState(false);
  const [updateListCars, setUpdateListCars] = useState(false);
  const [skeleton, setSkeleton] = useState(true);
  const [successfulFormSubmission] = useState(false);
  const [valueBrandTC, setValueBrandTC] = useState<any>('');
  const [valueTypeTC, setValueTypeTC] = useState<any>('');
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchFilter, setSearchFilter] = useState('');
  const debouncedSearchTerm = useDebounce(searchFilter, 500);
  const typeCars: { value: string; label: string }[] = [];
  const brandCars: { value: string; label: string }[] = [];
  const getCars = async (isPagination: Boolean) => {
    setSkeleton(true);
    try {
      const cars = await fetchGet(`/cars/filter`, {
        limit: 10,
        searchString: searchFilter,
        offset: isPagination ? offset : 0,
      });
      const types = await fetchGet(`/cars/types`, {});
      const brands = await fetchGet(`/cars/brands`, {});
      setTotalCount(cars.response.count);
      for (const { id, type } of types.response) {
        typeCars.push({
          value: id,
          label: type,
        });
      }
      for (const { id, brand } of brands.response) {
        brandCars.push({
          value: id,
          label: brand,
        });
      }

      setTypeList(typeCars);
      setBrandList(brandCars);
      setOwnershipList([
        { value: 'ownership', label: 'Собственность' },
        { value: 'leasing', label: 'Лизинг' },
      ]);
      if (isPagination && offset !== 0) {
        setCarsList(prevState => [...prevState, ...cars.response.rows]);
      } else {
        setCarsList(cars.response.rows);
      }

      setSkeleton(false);
    } catch (e) {
      setSkeleton(false);
      console.log(e);
    }
  };

  useEffect(() => {
    dispatch(getAllImagesBanner({
      type: typeBanner.BUTTON_INSURANCE,
      resolution: '0x0',
    }));
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 10);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (totalCount > offset) {
      getCars(true);
    }
  }, [offset]);

  useEffect(() => {
    setOffset(0);
    getCars(false);
  }, [updateListCars, debouncedSearchTerm]);

  useEffect(() => {
    setDisabled(
      (typeof car?.valueOwnership !== 'string' && !car.valueOwnership?.value) ||
        (typeof car?.valueBrandTC !== 'string' && !car.valueBrandTC?.value) ||
        (typeof car?.valueTypeTC !== 'string' && !car.valueTypeTC?.value) ||
        ((car?.valueOwnership === 'Лизинг' ||
          (car?.valueOwnership as { value: string; label: string }).value === 'leasing') &&
          uploadImagesOwnership?.length < 1) ||
        uploadImagesStsNumber?.length < 1 ||
        !car?.stsNumber ||
        !car?.volumeBody ||
        !car?.semitrailerId,
    );
  }, [car, uploadImagesStsNumber, uploadImagesOwnership]);
  const getSemitrailersInfo = async () => {
    try {
      const responseSemitrailers: IResponseSemitrailers = await fetchGet('/cars/semitrailers', {});
      const convertSemitrailersInfo: IOption[] = responseSemitrailers.response.map(semitrailer => {
        return {
          value: String(semitrailer.id),
          label: semitrailer.title,
        };
      });
      setSemitrailersInfo(convertSemitrailersInfo);
    } catch (e) {
      console.log(e);
    }
  };
  const sendDataCars = async (ownershipValue: string, isCheckingAutoCodeSuccessful: Boolean) => {
    setDisabled(true);
    setSending(true);

    const cars = await fetchGet(`/cars/list`, {});

    const currentCar = cars.response.find(item => item.stateNumber === car.stateNumber);

    if (Boolean(currentCar) && !currentCar.active) {
      const patchCar = await fetchPost(`/cars/${currentCar.id}`, 'PATCH', {
        active: true,
      });

      if (patchCar.error) {
        setValueBrandTC(null);
        setValueTypeTC(null);
        patchCar.message.map(el => toast.error(el));
        setSending(false);
        return;
      }

      toast.success('Машина успешно добавлена');
      setSending(false);
      setPopUpActive(closePopUp);
      setCar(prevState => ({ ...prevState, stateNumber: '' }));
      setUpdateListCars(!updateListCars);
      return;
    }

    if (Boolean(currentCar) && currentCar.active) {
      toast.error(`Машина с номером ${car.stateNumber} уже существует`);
      setPopUpActive(closePopUp);
      setCar(prevState => ({ ...prevState, stateNumber: '' }));
      setSending(false);
      return;
    }
    const carsDataHired = {
      stateNumber: car.stateNumber,
      carTypeId: typeof car.valueTypeTC !== 'string' && car.valueTypeTC.value,
      carBrandId: typeof car.valueBrandTC !== 'string' && car.valueBrandTC.value,
      stsSerialNumber: car.stsNumber,
      volumeBody: Number(car.volumeBody),
      semitrailerId: Number(car.semitrailerId),
    };

    const carsDataUnconfirmed = {
      ...carsDataHired,
      legalOwner: typeof car.valueOwnership !== 'string' && car.valueOwnership.value,
    };
    const carsDataConfirmed = {
      stateNumber: car.stateNumber,
      stsSerialNumber: car.stsNumber,
      volumeBody: Number(car.volumeBody),
      semitrailerId: Number(car.semitrailerId),
    };

    let fetchRequest;
    if (ownershipValue !== 'own') {
      fetchRequest = await fetchPost(`/cars/hired`, 'POST', carsDataHired);
    } else if (isCheckingAutoCodeSuccessful) {
      fetchRequest = await fetchPost(`/cars/confirmed`, 'POST', carsDataConfirmed);
    } else {
      fetchRequest = await fetchPost(`/cars/unconfirmed`, 'POST', carsDataUnconfirmed);
    }

    const OwnershipFiles = {
      communicationCategory: 'cars',
      category: 'Договор лизинга',
      fileBelongsId: Number(fetchRequest?.response?.id),
      objectUuid: '',
      files: [...uploadImagesOwnership?.map(file => file.value)],
    };
    const StsNumberFiles = {
      communicationCategory: 'cars',
      category: 'СТС',
      fileBelongsId: Number(fetchRequest?.response?.id),
      objectUuid: '',
      files: [...uploadImagesStsNumber?.map(file => file.value)],
    };
    const requestOption = [StsNumberFiles, OwnershipFiles]?.filter(item => item?.files?.length !== 0);
    const responseFile = await fetchPost(`/files/`, 'POST', requestOption);
    if (ownershipValue === 'own' && !isCheckingAutoCodeSuccessful) {
      await fetch(`https://b24-bnchwk.bitrix24.ru/rest/3250/c2cra3q4lruhrbia/crm.deal.update.json`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          id: fetchRequest.response?.bitrixId,
          fields: {
            UF_CRM_1728984734: responseFile.response.map(itemFile => itemFile.location),
          },
        }),
      });
    }
    if (fetchRequest.error) {
      setPopUpActive(closePopUp);
      setCar(InitialCar);
      fetchRequest.message.map(el => toast.error(el));
      setSending(false);
      return;
    }
    setCar(InitialCar);
    setUploadImagesOwnership([]);
    setUploadImagesStsNumber([]);

    setPopUpActive(closePopUp);
    setCar(prevState => ({ ...prevState, stateNumber: '' }));
    setSending(false);
    setUpdateListCars(!updateListCars);
    toast.success('Машина успешно добавлена');
  };
  const handleOpenNewWindow = (img: IImageBanner) => {
    dispatch(postLogImage({ bannerId: img.id }));
    const isAbsoluteUrl = img.urlRedirect.startsWith('http');
    const url = isAbsoluteUrl ? img.urlRedirect : `${window.location.origin}${img.urlRedirect}`;
    if (url) {
      return window.open(url, '_blank', 'noopener,noreferrer');
    }
  };
  return (
    <>
      <PageWrapper style={{ zIndex: popUpActive ? '100000' : '100' }}>
        <ToastContainer />
        <div className={styles.titleAndSubTitleContainer}>
          <div className={styles.titleAndAddButton}>
            <TitleMain title={'Машины'} />
            <div className={styles.buttonsContainer}>
              {buttonInsurance.length&&!isMobile?<ButtonPrimeNotFilled
                  svg={<InsuranceSvg/>}
                  width={`240px`}
                  sizeText={'small'}
                  onClick={() => handleOpenNewWindow(buttonInsurance[0])}
                  text={'ЗАСТРАХОВАТЬ АВТО'}
              />:<></>}
              <ButtonFilled
                  sizeText={'small'}
                  svg={<NewPlusSvg />}
                  width={isMobile ? '32px' : '140px'}
                  height={isMobile ? '32px' : '40px'}
                  onClick={() => {
                    getSemitrailersInfo();
                    setPopUpActive(openPopUp);
                  }}
                  text={isMobile ? '' : 'ДОБАВИТЬ'}
              />
            </div>

          </div>
          <SubTitleMain
            subTitle={`В настоящий момент у вас ${totalCount} ${declinationWord(totalCount, ['машина', 'машины', 'машин'])}`}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <InputSearch
            placeholder="Поиск"
            onInput={({ currentTarget: { value: search } }) => setSearchFilter(search)}
            value={searchFilter}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          {(buttonInsurance.length && isMobile)?<ButtonPrimeNotFilled
              svg={<InsuranceSvg/>}
              width={`100%`}
              sizeText={'small'}
              onClick={() => handleOpenNewWindow(buttonInsurance[0])}
              text={'ЗАСТРАХОВАТЬ АВТО'}
          />:<></>}
        </div>
        {carsList.length ? (
          <div className="CarsPage__content">
            <div className="CarsPage__titles">
              {!isMobile &&
                ['Номер ТС', 'Марка ТС', 'Вид ТС'].map((item, index) => (
                  <div className="CarsPage__title" key={index}>
                    {item}
                  </div>
                ))}
            </div>

            {skeleton ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '900px ',
                }}
              >
                <div>
                  <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                </div>
              </div>
            ) : (
              <CarsList list={carsList} />
            )}
          </div>
        ) : (
          <EmptyList title={'Машины отсутствуют'} subTitle={`В данный момент у вас нет машин`} />
        )}
        <CarPagePopup
          semitrailersInfo={semitrailersInfo}
          setCar={setCar}
          car={car}
          setUploadImagesOwnership={setUploadImagesOwnership}
          uploadImagesOwnership={uploadImagesOwnership}
          setUploadImagesStsNumber={setUploadImagesStsNumber}
          uploadImagesStsNumber={uploadImagesStsNumber}
          brandCars={brandList}
          typeCars={typeList}
          disabled={disabled}
          ownershipList={ownershipList}
          sendDataCars={sendDataCars}
          popUpActive={popUpActive}
          setPopUpActive={setPopUpActive}
          successfulFormSubmission={successfulFormSubmission}
          error={error}
        />
      </PageWrapper>
    </>
  );
};
