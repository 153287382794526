import React from 'react';

const InsuranceSvg = () => {
    return (

        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.8947 0C0.843141 0 0 0.859053 0 1.90901V17.1811C0 18.2406 0.843141 19.0901 1.8947 19.0901H9.29349C8.95245 18.4983 8.71561 17.8492 8.60193 17.1811H1.8947V1.90901H8.52614V6.68152H13.2629V10.5759C13.5755 10.5282 13.8976 10.4995 14.2102 10.4995C14.5323 10.4995 14.845 10.5282 15.1576 10.5759V5.72702L9.47349 0M3.7894 9.54503V11.454H11.3682V9.54503M3.7894 13.363V15.272H8.52614V13.363H3.7894Z" fill="currentColor"/>
            <path d="M15.3948 12.4095H12.3159V16.7047H11.3686V17.6592H12.3159V18.6137H11.3686V19.5682H12.3159V21H13.2633V19.5682H15.158V18.6137H13.2633V17.6592H15.3948C16.8348 17.6592 18 16.4852 18 15.0344C18 13.5835 16.8348 12.4095 15.3948 12.4095ZM15.3948 16.7047H13.2633V13.364H15.3948C16.309 13.364 17.0527 14.1133 17.0527 15.0344C17.0527 15.9555 16.309 16.7047 15.3948 16.7047Z" fill="currentColor"/>
        </svg>

    );
};

export default InsuranceSvg;