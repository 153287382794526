import React from 'react';
import styles from '../InputFileInOrder.module.scss';
export const ImageContainerInOrder = ({
  children,
  PopUp = true,
  id,
  value,
  name,
  size,
  isSize,
  date,
  isDate,
  type,
  openPopUp,
}) => {
  const openFile = type => {
    const pdfWindow = window.open('PDF');
    const title = 'My PDF';
    const URI = 'PDF/PDF';
    const html = `
      <html>
        <head>
          <title>${title}</title>
        </head>
        <body style="margin:0">
          <embed width="100%" height="100%" src=${value} type=${type}>
        </body>
      </html>
    `;

    pdfWindow.document.write(html);
    pdfWindow.document.close();
    pdfWindow.history.pushState(null, null, URI);
  };

  return (
    <div className={styles.imageContainer}>
      <div onClick={() => openPopUp(value)}>
        <div>
          {type === 'application/pdf' || PopUp === false ? (
            <a target={'_blank'} onClick={() => openFile(type)} rel="noreferrer">
              <div className={styles.nameFileContainer}>{name}</div>
            </a>
          ) : value ? (
            <a target={'_blank'} href={value} rel="noreferrer">
              <div className={styles.nameFileContainer}>{name}</div>
            </a>
          ) : (
            <a>
              <div className={styles.nameFileContainer}>{name}</div>
            </a>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
