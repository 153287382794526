import React from 'react';
import styles from './MobileAdminUsersPage.module.scss';
import { ShowMore } from '../ShowMore/ShowMore';
import cx from 'classnames';
import { validationPhoneNumberFunction } from 'src/common/validation-phone-number.function';
import { IDataUsers } from '../../types';
import { roles, userStatus } from '../../constants';

export const MobileAdminUsersPage = ({
  companies,
  changeBlock,
}: {
  companies: IDataUsers[];
  changeBlock: (id: number) => void;
}) => {
  return (
    <div className={styles.container}>
      {companies?.map(item => (
        <div key={item?.id} className={styles.companyContainer}>
          <div className={styles.nameAndOptionsContainer}>
            <div className={styles.nameAndInnContainer}>
              <p className={styles.text}>{item?.company?.title || 'Нет данных'}</p>
              <p className={styles.subText}>{`ИНН: ${item?.company?.inn || 'Нет данных'}`}</p>
            </div>
            <ShowMore dataUsers={item} changeBlock={changeBlock} />
          </div>
          <div className={styles.line} />
          <p className={styles.text}>{item?.fullName}</p>
          <div className={styles.line} />
          <p className={styles.text}>{validationPhoneNumberFunction(item?.phone)}</p>
          <div className={styles.line} />
          <p className={styles.text}>{roles[item?.type]}</p>
          <div className={styles.line} />
          <div className={styles.statusContainer}>
            <p className={styles.subText}>Статус</p>
            <p className={cx(item?.block ? styles.redStatus : styles.greenStatus, styles.status)}>
              {item?.block ? userStatus.BLOCKED : userStatus.ACTIVE}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
