import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction, Slice, UnknownAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { addEmployee, deleteEmployee, editEmployee, getEmployees } from './apiEmployees';
import {
  IEmployees,
  IRequestAddEmployeeOptions,
  IRequestEditEmployeesOptions,
  IRequestGetEmployeesOptions,
} from './type';
import { toastError } from 'src/common/toastError.helper';
import { TApiResponse } from '../ShowcasesPage/services/types';

export const getAllEmployees = createAsyncThunk(
  'employees/getAllEmployees',
  async (data: IRequestGetEmployeesOptions) => {
    const response = await getEmployees(data);
    return response.data;
  },
);

export const editCurrentEmployee = createAsyncThunk(
  'employees/editCurrentEmployee',
  async (data: IRequestEditEmployeesOptions, { rejectWithValue }) => {
    try {
      const response = await editEmployee(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const addCurrentEmployee = createAsyncThunk(
  'employees/addCurrentEmployee',
  async (data: IRequestAddEmployeeOptions, { rejectWithValue }) => {
    try {
      const response = await addEmployee(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const deleteCurrentEmployee = createAsyncThunk('employees/deleteCurrentEmployee', async (id: number) => {
  const response = await deleteEmployee(id);
  return response.data;
});

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

function isPendingAction(action: UnknownAction): action is PendingAction {
  return typeof action.type === 'string' && action.type.endsWith('/pending');
}
function isRejectedAction(action: UnknownAction): action is RejectedAction {
  return typeof action.type === 'string' && action.type.endsWith('/rejected');
}
function isFulfilledAction(action: UnknownAction): action is FulfilledAction {
  return typeof action.type === 'string' && action.type.endsWith('/fulfilled');
}

export interface employeesState {
  employees: IEmployees[];
  employeesTotalCount: number;
  loading: boolean;
  editEmployee: IEmployees;
  errorMessage: string[];
}

const initialState: employeesState = {
  employees: [],
  loading: false,
  employeesTotalCount: null,
  editEmployee: null,
  errorMessage: [],
};

const initEditEmployee: IEmployees = {
  name: '',
  surname: '',
  patronymic: '',
  phone: '',
  profile: { email: '' },
  type: '',
};

export const employeesSlice: Slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    addEditEmployee: (state, action: PayloadAction<number>) => {
      state.editEmployee = action?.payload
        ? state.employees?.find(employee => employee.id === action?.payload)
        : initEditEmployee;
    },
    editEmployeeParameters: (state, action: PayloadAction<IRequestEditEmployeesOptions>) => {
      const parameters = action.payload?.parameters;
      if (state.editEmployee) {
        Object.assign(state.editEmployee, parameters);
        if (parameters.hasOwnProperty('email')) {
          state.editEmployee.profile.email = parameters.email;
        }
      }
    },
    addEmployeeParameters: (state, action: PayloadAction<IRequestAddEmployeeOptions>) => {
      const parameters = action.payload;
      if (state.editEmployee) {
        Object.assign(state.editEmployee, parameters);
        if (parameters.hasOwnProperty('email')) {
          state.editEmployee.profile.email = parameters.email;
        }
      }
    },
    changeEmployeesAfterAdd: (state, action: PayloadAction<TApiResponse<{ id: number }>>) => {
      const index = action?.payload?.response?.id;
      if (index !== -1) {
        const editEmployee = state.editEmployee;
        state.employees.unshift({
          id: index,
          ...editEmployee,
          fullName: `${editEmployee?.surname} ${editEmployee?.name} ${editEmployee?.patronymic || ''}`,
        });
      }
      state.editEmployee = null;
    },
    changeEmployeesAfterEdit: (state, action: PayloadAction<TApiResponse<{ id: number }>>) => {
      const index = action?.payload?.response?.id;
      const editEmployee = state.editEmployee;
      const newEmployees = state.employees.map(employee => {
        const newEmployee =
          employee.id === index
            ? {
                ...editEmployee,
                fullName: `${editEmployee?.surname} ${editEmployee?.name} ${editEmployee?.patronymic || ''}`,
              }
            : employee;
        return newEmployee;
      });
      state.employees = newEmployees;
      state.editEmployee = null;
    },
    changeAllEmployees: state => {
      const correctedEmployees = state.employees?.map(item => {
        return {
          ...item,
          fullName: `${item?.surname} ${item?.name} ${item?.patronymic || ''}`,
          patronymic: item?.patronymic || undefined,
        };
      });
      state.employees = correctedEmployees;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllEmployees.fulfilled, (state, action) => {
      const newEmployees = action.payload.response?.rows;
      state.employees = action.meta.arg.offset > 0 ? [...state.employees, ...newEmployees] : newEmployees;
      state.employeesTotalCount = action.payload?.response?.count;
    });

    builder.addCase(deleteCurrentEmployee.fulfilled, (state, action) => {
      state.employees = state.employees.filter(employee => employee?.id !== action?.payload?.response?.id);
      state.employeesTotalCount -= 1;
    });

    builder.addMatcher(isPendingAction, state => {
      state.loading = true;
    });

    builder.addMatcher(isFulfilledAction, state => {
      state.loading = false;
    });

    builder.addMatcher(isRejectedAction, state => {
      state.loading = false;
    });
  },
});

export const {
  addEditEmployee,
  editEmployeeParameters,
  addEmployeeParameters,
  changeEmployeesAfterAdd,
  changeEmployeesAfterEdit,
  changeAllEmployees,
} = employeesSlice.actions;

export const selectEmployees = (state: RootState) => state.employees.employees;
export const selectEmployeesTotalCount = (state: RootState) => state.employees.employeesTotalCount;
export const selectEmployeesLoading = (state: RootState) => state.employees.loading;
export const selectEmployeesErrorMessage = (state: RootState) => state.employees.errorMessage;
export const selectEditEmployee = (state: RootState) => state.employees.editEmployee;
