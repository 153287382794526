import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction, Slice, UnknownAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';
import {
  changePublicShowcase,
  changeShowcase,
  createShowcase,
  deleteImage,
  deleteShowcase,
  getQuarries,
  getSelectedQuarry,
  getShowcaseProperties,
  getShowcases,
} from './showcaseApi';
import { dataCardResponseType } from '../../type';
import { regularPhone } from 'src/common/regular-phone';
import {
  IQuarryForRedux,
  IQuarryOption,
  IRequestChangeShowcaseOptions,
  IShowcaseForRedux,
  IShowcaseOptions,
  TApiResponse,
} from '../types';
import { IOption } from 'src/Pages/CarsPage/const';
import { toastError } from 'src/common/toastError.helper';

export const getAllShowcases = createAsyncThunk('showcases/getAllShowcases', async (_, { rejectWithValue }) => {
  try {
    const response = await getShowcases();
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const getCurrentShowcaseProperties = createAsyncThunk(
  'showcases/getCurrentShowcaseProperties',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getShowcaseProperties(id);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const editCurrentShowcase = createAsyncThunk(
  'showcases/editCurrentShowcase',
  async ({ id, options }: { id: number; options: IShowcaseOptions }, { rejectWithValue }) => {
    try {
      const response = await changeShowcase(id, options);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const addNewShowcase = createAsyncThunk(
  'showcases/addNewShowcase',
  async (options: IShowcaseOptions, { rejectWithValue }) => {
    try {
      const response = await createShowcase(options);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getQuarriesOptions = createAsyncThunk('showcases/getQuarriesOptions', async (_, { rejectWithValue }) => {
  try {
    const response = await getQuarries();
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getSelectedQuarryInfo = createAsyncThunk(
  'showcases/getSelectedQuarryInfo',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getSelectedQuarry(id);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const deleteCurrentImage = createAsyncThunk(
  'showcases/deleteCurrentImage',
  async ({ id, name }: { id: number; name: string }, { rejectWithValue }) => {
    try {
      const response = await deleteImage(id, name);
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const deleteCurrentShowcase = createAsyncThunk(
  'showcases/deleteCurrentShowcase',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await deleteShowcase(id);
      return response;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const changeEnableLinkShowcase = createAsyncThunk('', async (id: number, { rejectWithValue }) => {
  try {
    const response = await changePublicShowcase(id);
    return response;
  } catch (e) {
    const errorResponse = JSON.parse(e.response.request.response);
    toastError(errorResponse);
    return rejectWithValue(e);
  }
});

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

function isPendingAction(action: UnknownAction): action is PendingAction {
  return typeof action.type === 'string' && action.type.endsWith('/pending');
}
function isRejectedAction(action: UnknownAction): action is RejectedAction {
  return typeof action.type === 'string' && action.type.endsWith('/rejected');
}
function isFulfilledAction(action: UnknownAction): action is FulfilledAction {
  return typeof action.type === 'string' && action.type.endsWith('/fulfilled');
}

const initialCurrentShowcaseProperties: IShowcaseForRedux = {
  id: null,
  title: '',
  link: '',
  description: '',
  isEnabledLink: false,
  coverImage: null,
  logoImage: null,
  coverImageNew: null,
  logoImageNew: null,
  quarry: {
    address: {
      address: '',
      latitude: null,
      longitude: null,
    },
    id: null,
    name: '',
    quarryType: '',
    workSchedulesGraph: {
      from: '',
      to: '',
      aroundTheClock: false,
    },
    dispatcher: {
      phone: '',
    },
  },
  materialGroups: [],
};

export interface ShowcaseState {
  showcases: dataCardResponseType[];
  currentShowcaseProperties: IShowcaseForRedux;
  quarryInfo: { quarries: IOption[] | IQuarryOption[]; currentQuarry: IOption | IQuarryOption };
  isLoading: boolean;
}

const initialState: ShowcaseState = {
  showcases: [],
  currentShowcaseProperties: initialCurrentShowcaseProperties,
  quarryInfo: { quarries: [], currentQuarry: { value: null, label: '' } },
  isLoading: false,
};

export const showcaseSlice: Slice = createSlice({
  name: 'showcases',
  initialState,
  reducers: {
    addNewShowcaseParameters: state => {
      state.showcases.unshift(state.currentShowcaseProperties);
    },
    editShowcaseParameters: (state, action: PayloadAction<IShowcaseOptions>) => {
      Object.assign(state.currentShowcaseProperties, action.payload);
    },
    clearShowcaseParameters: state => {
      state.currentShowcaseProperties = initialCurrentShowcaseProperties;
      state.quarryInfo.currentQuarry = { value: null, label: '' };
    },
    addQuarryOption: state => {
      state.quarryInfo.quarries.unshift(state.quarryInfo.currentQuarry);
    },
    changeCurrentShowcaseProperties: (state, action: PayloadAction<IRequestChangeShowcaseOptions>) => {
      const showcaseOptions = action?.payload;
      state.currentShowcaseProperties['coverImage'] =
        showcaseOptions?.files?.find(item => item?.category === 'Шапка витрины') || null;
      state.currentShowcaseProperties['logoImage'] =
        showcaseOptions?.files?.find(item => item?.category === 'Лого витрины') || null;
      delete state.currentShowcaseProperties.files;
      state.currentShowcaseProperties.quarry.dispatcher.phone =
        regularPhone(state.currentShowcaseProperties?.quarry?.dispatcher?.phone) || null;
      state.quarryInfo.currentQuarry = {
        value: showcaseOptions?.quarry?.id,
        label: showcaseOptions?.quarry?.name,
      };
    },
    changeQuarriesOptions: state => {
      state.quarryInfo.quarries = state.quarryInfo.quarries.map(quarry => {
        return { value: quarry.id, label: quarry.name };
      });
    },
    changeSelectedQuarry: (state, action: PayloadAction<IQuarryForRedux>) => {
      const currentQuarry = action?.payload;
      state.quarryInfo.currentQuarry = { value: currentQuarry.id, label: currentQuarry.name };
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllShowcases.fulfilled, (state, action: PayloadAction<TApiResponse<dataCardResponseType[]>>) => {
      state.showcases = action.payload.response;
    });
    builder.addCase(
      getCurrentShowcaseProperties.fulfilled,
      (state, action: PayloadAction<TApiResponse<IShowcaseForRedux>>) => {
        state.currentShowcaseProperties = action.payload.response;
      },
    );
    builder.addCase(getQuarriesOptions.fulfilled, (state, action: PayloadAction<TApiResponse<IQuarryOption[]>>) => {
      state.quarryInfo.quarries = action.payload.response;
    });

    builder.addCase(getSelectedQuarryInfo.fulfilled, (state, action: PayloadAction<TApiResponse<IQuarryForRedux>>) => {
      state.currentShowcaseProperties.quarry = action.payload.response;
    });

    builder.addCase(changeEnableLinkShowcase.fulfilled, state => {
      state.currentShowcaseProperties.isEnabledLink = !state.currentShowcaseProperties.isEnabledLink;
    });
    builder.addCase(deleteCurrentShowcase.fulfilled, state => {
      state.showcases = state.showcases.filter(showcase => showcase?.id !== state.currentShowcaseProperties?.id);
      state.currentShowcaseProperties = initialCurrentShowcaseProperties;
    });
    builder.addMatcher(isPendingAction, state => {
      state.isLoading = true;
    });

    builder.addMatcher(isRejectedAction, state => {
      state.isLoading = false;
    });

    builder.addMatcher(isFulfilledAction, state => {
      state.isLoading = false;
    });
  },
});

export const {
  editShowcaseParameters,
  clearShowcaseParameters,
  addQuarryOption,
  changeCurrentShowcaseProperties,
  changeQuarriesOptions,
  changeSelectedQuarry,
} = showcaseSlice.actions;

export const selectShowcases = (state: RootState) => state.showcase.showcases;
export const selectCurrentShowcaseProperties = (state: RootState) => state.showcase.currentShowcaseProperties;
export const selectShowcaseCoverImage = (state: RootState) => state.showcase.currentShowcaseProperties?.coverImage;
export const selectShowcaseCoverImageNew = (state: RootState) =>
  state.showcase.currentShowcaseProperties?.coverImageNew;

export const selectQuarryInfo = (state: RootState) => state.showcase.quarryInfo;

export const selectPublicLink = (state: RootState) => state.showcase.currentShowcaseProperties.link;
export const selectIsEnabledLink = (state: RootState) => state.showcase.currentShowcaseProperties.isEnabledLink;

export const selectShowcasesLoading = (state: RootState) => state.showcase.isLoading;

export const selectMaterialGroups = (state: RootState) => state.showcase.currentShowcaseProperties.materialGroups;
