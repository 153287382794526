import React from 'react';
import styles from './CreateOrderTopInfo.module.scss';
import { CheckBoxCustom } from 'src/components/UI/checkBox/CheckBoxCustom';
import { propertySize } from 'src/Pages/AdminPages/AdminCalculationSupplies/type';
import {useAppDispatch, useAppSelector} from 'src/CustomHooks/storeHooks';
import {
  selectMaterialKindValue,
  selectMaterialKindFields,
  selectMaterialTypeValue,
  selectQuarryValue,
} from 'src/Pages/AdminPages/AdminCalculationSupplies/AdminCalculationSuppliesSlice';
import {changeOrderFiledValue, selectCreateOrderFields} from "../../OrderSlice";
import {orderPropertyName} from "../../type";

export const CreateOrderTopInfo = () => {
  const dispatch = useAppDispatch();
  const materialType = useAppSelector(selectMaterialTypeValue);
  const materialKind = useAppSelector(selectMaterialKindValue);
  const materialFields = useAppSelector(selectMaterialKindFields);
  const quarryName = useAppSelector(selectQuarryValue);
  const createOrderFields = useAppSelector(selectCreateOrderFields);
  const anyQuarryCheckbox=createOrderFields.find(item=>item.name===orderPropertyName.anyQuarryCheckbox)
  // временно пока нет slice
  const isChecked = true;
  const prepareMaterialInfo = () => {
    const materialFieldsStr = materialFields.reduce((acc, item) => {
      acc += `; ${item?.title}:${item?.value}`;
      return acc;
    }, '');
    return `${materialType} ${materialKind}${materialFieldsStr}`;
  };

  return (
    <div className={styles.container}>
      <p className={styles.text}>{prepareMaterialInfo()}</p>
      <div className={styles.quarryContainer}>
        <p className={styles.text}>{`Карьер: ${quarryName || '-'}`}</p>
        <CheckBoxCustom
          error={false}
          children={'Поставка из любого карьера'}
          check={anyQuarryCheckbox.value}
          pick={() => {
            dispatch(
                changeOrderFiledValue({
                  name: anyQuarryCheckbox.name,
                  value: !anyQuarryCheckbox?.value,
                }),
            );
          }}
          name={'option-checkbox'}
          id={'option-checkbox'}
          disabled={false}
          size={propertySize.MEDIUM}
          additionalClassName={styles.text}
        />
      </div>
    </div>
  );
};
