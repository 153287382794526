import cx from 'classnames';
import styles from './AdminOrdersPage/AdminOrderPage.module.scss';
import { formPriceOnlyNumber } from '../../../common/form-price.helper';
import React from 'react';
import { IChangeableData, IMainFilters, IOrderSortBox, ISubFilters } from './type';
import { ISelectValue } from '../AdminTariffsPage/types';
import { regForNumberWithPoint } from '../../../common/number.helper';
import { generateStyleStatus } from './utils';
import MoreInfoMaterialModal from './components/MoreInfoMaterialModal/MoreInfoMaterialModal';
import { Progress } from 'antd';
import ChangeMarginInListMaterial from './components/ChangeMarginInListMaterial/ChangeMarginInListMaterial';
import ShowMoreTable from './components/ShowMoreTable/ShowMoreTable';
export const getImages = (dataRequest, setImages) => {
  dataRequest?.files?.forEach(file => {
    setImages(prevState => {
      const fileMap = new Map(prevState.map(fileKeyValue => [fileKeyValue.name, fileKeyValue]));
      fileMap.set(file.name, file);
      return Array.from(fileMap.values());
    });
  });
};
export const statusesDisableCheckBoxSpecification = ['Новый', 'Ожидает подписания'];
export const initDataInfoTable = [
  {
    name: 'client',
    label: 'Клиент',
    value: '',
  },
  {
    name: 'inn',
    label: 'ИНН',
    value: '',
  },
  {
    name: 'paymentMethod',
    label: 'Способ оплаты',
    value: '',
  },
  {
    name: 'contract',
    label: 'Договор',
    value: '',
  },
  {
    name: 'objectTitle',
    label: 'Объект',
    value: '',
  },
  {
    name: 'acceptance',
    label: 'Вид приемки',
    value: '',
  },
];
export const initChangeableData: IChangeableData = {
  manager: '',
  status: '',
  comment: '',
  specificationReceived: false,
};
export const validationOfVolumeInputs = value =>
  (regForNumberWithPoint.test(value) || value === '') && value.length <= 10;
export const orderSortOptions: IOrderSortBox[] = [
  { value: 'newFirst', label: 'Сначала новые' },
  { value: 'oldFirst', label: 'Сначала старые' },
  { value: 'increasingPrice', label: 'По возрастанию суммы' },
  { value: 'decreasingPrice', label: 'По убыванию суммы' },
  { value: 'increasingVolume', label: 'По возрастанию объема' },
  { value: 'decreasingVolume', label: 'По убыванию объема' },
];
export const statusOptions: ISelectValue[] = [
  { value: 'Активные', label: 'Активные' },
  { value: 'Завершенные', label: 'Завершенные' },
];
export const initStateMainFilters: IMainFilters = {
  searchString: '',
  sort: orderSortOptions[0],
};
export const initStateSubFilters: ISubFilters = {
  dateFrom: '',
  dateTo: '',
  managerId: '',
  materialTypeId: '',
  volumeFrom: null,
  volumeTo: null,
  status: 'Активные',
};
export const columnsTableOrder = [
  {
    title: 'Заказ',
    dataIndex: 'id',
    width: 74,
    key: 'id',
    render: text => (
      <div style={{ marginLeft: '24px' }} className={styles.columnAndBorder}>
        <p className={styles.tableText} style={{ width: '74px' }}>
          № {text}
        </p>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
    width: 120,
    key: 'createdAt',
    render: text => (
      <div className={styles.columnAndBorder}>
        <p className={styles.tableText} style={{ width: '120px' }}>
          {text}
        </p>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    width: 200,
    key: 'client',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '200px' }}>
          <p className={styles.tableText} style={{ width: '200px' }}>
            {text.company}
          </p>
          <p className={cx(styles.tableText, styles.secondaryText)} style={{ width: '200px' }}>
            ИНН: {text.inn}
          </p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Материалы, общий объем',
    dataIndex: 'materialAndVolume',
    width: 195,
    key: 'materialAndVolume',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '195px' }}>
          <div className={styles.materialContainer} style={{ width: '195px' }}>
            <p className={styles.tableText}>{text.material}</p>
            <MoreInfoMaterialModal textMaterial={text.material} />
          </div>
          <p className={cx(styles.tableText, styles.secondaryText)} style={{ width: '195px' }}>
            {text.volume}
          </p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Итого',
    dataIndex: 'totalPrice',
    width: 80,
    key: 'totalPrice',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '80px' }}>
          <p className={styles.tableText}>{text}</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Менеджер',
    dataIndex: 'manager',
    width: 195,
    key: 'manager',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '195px' }}>
          <p className={styles.tableText}>{text ? text : 'Нет данных'}</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    width: 195,
    key: 'status',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '173px' }}>
          <p className={cx(generateStyleStatus(text))}>{text}</p>
        </div>
      </div>
    ),
  },
];
export const dataTableOrderId = [
  {
    title: 'Тип материала',
    dataIndex: 'material',
    width: 489,
    key: 'material',
    render: (text, data) => (
      <div style={{ marginLeft: '24px' }} className={styles.columnAndBorder}>
        <p className={styles.tableTextTypeMaterial} style={{ width: '489px' }}>
          {text}{' '}
          <span className={styles.descText}>{data.materialClientTitle ? `(${data.materialClientTitle})` : ''}</span>
        </p>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Карьер',
    dataIndex: 'quarry',
    width: 100,
    key: 'quarry',
    render: text => (
      <div className={styles.columnAndBorder}>
        <p className={styles.tableText} style={{ width: '100px' }}>
          {text ? text : '-'}
        </p>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Ед. изм',
    dataIndex: 'unit',
    width: 75,
    key: 'unit',
    render: text => (
      <div className={styles.columnAndBorder}>
        <p className={styles.tableText} style={{ width: '75px' }}>
          {text}
        </p>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Плановая маржа',
    dataIndex: 'plannedMarginWithId',
    width: 98,
    key: 'plannedMarginWithId',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div style={{ width: '98px' }} className={styles.marginContainer}>
          <p className={styles.tableText}>{text?.margin} %</p>
          <ChangeMarginInListMaterial id={text.id} />
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Цена за 1 ед.',
    dataIndex: 'pricePerUnit',
    width: 80,
    key: 'pricePerUnit',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '80px' }}>
          <p className={styles.tableText}>{formPriceOnlyNumber(Number(text), 'RUB', 'ru')} ₽</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Объем заказа (факт/план)',
    dataIndex: 'volume',
    width: 175,
    key: 'volume',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.volumeContainer} style={{ width: '175px' }}>
          <div className={styles.progressContent}>
            <p className={styles.orangeText}>{formPriceOnlyNumber(Number(text.fact), 'RUB', 'ru')}</p>
            <p className={styles.grayText}>/</p>
            <p className={styles.grayText}>{formPriceOnlyNumber(Number(text.plan), 'RUB', 'ru')}</p>
          </div>
          <div className={styles.progressContainer}>
            <Progress
              size={['100%', 4]}
              strokeColor={'var(--color-orange400)'}
              percent={(text.fact * 100) / text.plan}
              showInfo={false}
            />
          </div>
          <p className={styles.percentText}>{((text.fact * 100) / text.plan).toFixed(1)}%</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: '',
    dataIndex: 'dataForShowMore',
    width: 40,
    key: 'dataForShowMore',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '40px' }}>
          <ShowMoreTable
            unit={text.unit}
            orderDetailsId={text.id}
            deliveryCalculationId={text.deliveryCalculationId}
            type="orders"
          />
        </div>
      </div>
    ),
  },
];
