import React from 'react';
import { Map, Placemark, TypeSelector, YMaps, Polyline } from '@pbe/react-yandex-maps';
import { useAppSelector } from '../../../../../CustomHooks/storeHooks';
import { selectCheckedCalculationDetailsIndex } from '../../../AdminCalculationSupplies/AdminCalculationSuppliesSlice';
import { ICalculationDetailsItem } from 'src/Pages/AdminPages/AdminCalculationSupplies/type';
import MapLegend from '../MapLegend/MapLegend';

import weightBridgesPng from '../../../../../assets/img/weightBridges.png';

interface IProps {
  coordinates?: {
    latitude: number;
    longitude: number;
    title: string;
    color?: string;
    checkTitle: string;
  }[];
  weightBridgesCoordinates?: {
    latitude: number;
    longitude: number;
  }[];
  detailRouteCoordinate?: {
    latitude: number;
    longitude: number;
  }[];
  calculationDetails?: ICalculationDetailsItem[];
  latitude?: string | number;
  height?: string;
  zoom?: number;
  longitude?: string | number;
  legend?: boolean;
}

const CustomYMapForCalculation = ({
  zoom = 8,
  height = '350px',
  legend = false,
  latitude = 22,
  longitude = 22,
  coordinates = [],
  calculationDetails = [],
  detailRouteCoordinate = [],
  weightBridgesCoordinates = [],
}: IProps) => {
  const checkedDetailRowIndex: number = useAppSelector(selectCheckedCalculationDetailsIndex);
  return (
    <div>
      <YMaps
        query={{
          apikey: '0e34bd35-ff2f-46ac-abbd-6a8143e395c3',
        }}
      >
        <div>
          <Map
            style={{ height: height }}
            state={{
              center: coordinates?.length
                ? [Number(coordinates[0]?.latitude), Number(coordinates[0]?.longitude)]
                : [Number(latitude), Number(longitude)],
              zoom: zoom,
            }}
          >
            {weightBridgesCoordinates?.length &&
              weightBridgesCoordinates?.map((item, index) => (
                <Placemark
                  geometry={[Number(item.latitude), Number(item.longitude)]}
                  key={index}
                  options={{
                    iconImageSize: [24, 24],
                    iconLayout: 'default#image',
                    iconImageHref: weightBridgesPng,
                  }}
                />
              ))}
            {coordinates.length &&
              coordinates.map((item, index) => (
                <Placemark
                  geometry={[Number(item.latitude), Number(item.longitude)]}
                  key={index}
                  options={{
                    iconImageSize: [40, 40],
                    iconColor:
                      calculationDetails[checkedDetailRowIndex]?.quarry?.title == item.checkTitle
                        ? '#f4753a'
                        : item.color,
                    preset: index !== 0 ? 'islands#blueMountainCircleIcon' : 'islands#orangeDeliveryCircleIcon',
                  }}
                  properties={{
                    iconCaption: item.title || '',
                  }}
                />
              ))}

            {detailRouteCoordinate.length > 0 && (
              <Polyline
                geometry={detailRouteCoordinate}
                options={{
                  strokeColor: '#278E63',
                  strokeWidth: 4,
                }}
              />
            )}

            {/*@ts-ignore*/}
            <TypeSelector options={{ float: 'right' }} />
          </Map>
        </div>
      </YMaps>
      {legend && <MapLegend />}
    </div>
  );
};

export default CustomYMapForCalculation;
