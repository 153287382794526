// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageShowContainer_container__Cwuvd {\n  margin-top: 10px;\n  width: 350px;\n}\n\n.ImageShowContainer_fileContainer__JfZRb {\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n}\n\n.ImageShowContainer_fileTitle__vd7CM {\n  color: var(--Gray-900, #1B1B1B);\n  font-family: \"Roboto\";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 150% */\n}", "",{"version":3,"sources":["webpack://./src/Pages/ShowcasesPage/EditAndAddMaterialPage/components/ImageShowContainer/ImageShowContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;AACF;;AACA;EACE,eAAA;EACA,aAAA;EACA,8BAAA;AAEF;;AAAA;EACE,+BAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;AAGF","sourcesContent":[".container{\n  margin-top: 10px;\n  width: 350px;\n}\n.fileContainer{\n  cursor: pointer;\n  display: flex;\n  justify-content: space-between;\n}\n.fileTitle{\n  color: var(--Gray-900, #1B1B1B);\n  font-family: 'Roboto';\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 150% */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ImageShowContainer_container__Cwuvd",
	"fileContainer": "ImageShowContainer_fileContainer__JfZRb",
	"fileTitle": "ImageShowContainer_fileTitle__vd7CM"
};
export default ___CSS_LOADER_EXPORT___;
