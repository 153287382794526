import React, { useState } from 'react';
import styles from './StepsToAddCardGSM.module.scss';
import { CustomSelect } from '../../../../components/UI/select/CustomSelect';
import ButtonNotFilled from '../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import SuccessSvgVer2 from '../../../../components/UI/svg-icon/SuccessSvgVer2';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from '../../../../components/UI/svg-icon/CloseSvg';

interface IProps {
  issueOfFuelCard: () => Promise<void>;
  step: 'selectDriver' | 'success';
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
  driversWithoutCards: { value: number; label: string }[];
  setPickDriver: React.Dispatch<{ value: number; label: string }>;
  pickDriver: { value: number; label: string };
  sending: boolean;
}

const StepsToAddCardGsm = ({
  setOpenModalOfAddGSMCard,
  step,
  driversWithoutCards,
  setPickDriver,
  pickDriver,
  issueOfFuelCard,
  sending,
}: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [heightMobileDrawer, setHeightMobileDrawer] = useState<string>('');

  return (
    <>
      {step === 'selectDriver' && (
        <div className={styles.selectDriverContainer}>
          <div className={styles.selectContainer}>
            <div className={styles.mobileCloseButtonAndTitle}>
              <p className={styles.selectDriverTitle}>Выпуск топливной карты</p>
              {isMobile && (
                <div className={styles.closeButton} onClick={() => setOpenModalOfAddGSMCard(false)}>
                  <CloseSvg />
                </div>
              )}
            </div>
            <div style={{ height: heightMobileDrawer }} className={styles.selectContainer}>
              <p className={styles.toText}>Кому</p>
              <CustomSelect
                value={pickDriver.label ? pickDriver : ''}
                onMenuClose={() => isMobile && setHeightMobileDrawer('auto')}
                onMenuOpen={() => isMobile && setHeightMobileDrawer('230px')}
                maxMenuHeight={144}
                error={false}
                placeholder={'Выберите из списка'}
                noOptionsMessage={''}
                isSearchable={true}
                isClearable={false}
                options={driversWithoutCards}
                setValue={e => setPickDriver(e)}
              />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <ButtonNotFilled onClick={() => setOpenModalOfAddGSMCard(false)} text={'Отмена'} width={'100%'} />
            <ButtonFilled
              disabled={!pickDriver.value || sending}
              onClick={() => {
                issueOfFuelCard();
              }}
              text={'Выпустить'}
              width={'100%'}
            />
          </div>
        </div>
      )}
      {step === 'success' && (
        <div className={styles.successContainer}>
          {isMobile && (
            <div onClick={() => setOpenModalOfAddGSMCard(false)} className={styles.closeSvg}>
              <CloseSvg />
            </div>
          )}
          <div className={styles.successContent}>
            <SuccessSvgVer2 />
            <div className={styles.successContentText}>
              <p className={styles.successThickText}>Карта успешно добавлена</p>
              <p className={styles.successSoftText}>Она появится в общем списке</p>
            </div>
          </div>
          <ButtonFilled width={'100%'} text={'Готово'} onClick={() => setOpenModalOfAddGSMCard(false)} />
        </div>
      )}
    </>
  );
};

export default StepsToAddCardGsm;
