import React from 'react';
import { ButtonClose } from 'src/components/UI/buttons/buttonClose';
import { removeImageFormData } from '../../../../../../common/removeImage';
import { ImageContainerInOrder } from './ImageContainerInOrder';
import styles from '../InputFileInOrder.module.scss';
import { CloseSvg } from '../../../../../../components/UI/svg-icon/CloseSvg';

interface IProps {
  setImages;
  images;
  openPopupFunc;
  setShowImages?;
  showImages?;
}
const UploadImagesContainerInOrder = ({ setImages, images, openPopupFunc, setShowImages, showImages }: IProps) => {
  return (
    <div className={styles.imagesContainer}>
      {showImages.map(({ id, value, name, size, isSize, date, isDate, type }, index) => (
        <div key={id}>
          <ImageContainerInOrder
            id={id}
            value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
            name={name}
            size={size}
            isSize={isSize}
            date={date}
            isDate={isDate}
            type={type}
            openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupFunc(value)}
          >
            <div
              className={styles.svgContainer}
              id={id}
              onClick={({ target }) => removeImageFormData(index, showImages, setShowImages, images, setImages)}
            >
              <CloseSvg width={'15px'} />
            </div>
          </ImageContainerInOrder>
        </div>
      ))}
    </div>
  );
};

export default UploadImagesContainerInOrder;
