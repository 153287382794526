export const InitStateTitlesOfTheCreatedContract = {
  deliveryContract: {
    id: null,
    title: '',
  },
  transportationContractId: {
    id: null,
    title: '',
  },
  usePlatformContract: {
    id: null,
    title: '',
  },
};
