// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalCalculationFAQ_modal__AkXRj {\n  padding: 24px;\n}\n\n.ModalCalculationFAQ_title__Mg-PX {\n  font-family: \"Golos Text\";\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 32px;\n  padding-bottom: 14px;\n}\n\n.ModalCalculationFAQ_list__TSqAr {\n  padding-left: 18px;\n}\n\nli {\n  font-family: \"Golos Text\";\n  font-weight: 500;\n  font-size: 14px;\n  font-style: normal;\n  line-height: 20px;\n  padding-top: 18px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminCalculationSupplies/components/ModalCalculationFAQ/ModalCalculationFAQ.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AACA;EACE,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;AAEF;;AAAA;EACE,kBAAA;AAGF;;AADA;EACE,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;AAIF","sourcesContent":[".modal{\n  padding: 24px;\n}\n.title{\n  font-family: 'Golos Text';\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 32px;\n  padding-bottom: 14px;\n}\n.list{\n  padding-left: 18px;\n}\nli{\n  font-family: 'Golos Text';\n  font-weight: 500;\n  font-size: 14px;\n  font-style: normal;\n  line-height: 20px;\n  padding-top: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "ModalCalculationFAQ_modal__AkXRj",
	"title": "ModalCalculationFAQ_title__Mg-PX",
	"list": "ModalCalculationFAQ_list__TSqAr"
};
export default ___CSS_LOADER_EXPORT___;
