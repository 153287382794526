import { useEffect, useRef } from 'react';

export const useEffectSkipFirstRender = (effect, deps) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    return effect();
  }, deps);
};
