import React, { useEffect, useRef, useState } from 'react';
import styles from './GSMPieChart.module.scss';
import * as d3 from 'd3';
import { useMediaQuery } from 'react-responsive';
import { pieColors } from 'src/Pages/GSMNewPage/GSMconstants';

export const GSMPieChart = ({ labels, data }: { labels: string[]; data: number[] }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const allLiters = data.reduce((item, acc) => {
    return (acc += item);
  }, 0);
  const typesOfFuelAndLiters = labels.map((type, index) => `${type} ${'– ' + data[index] + ' л'}`);
  const pieData = data.map((item, index) => {
    return { name: pieColors[index], value: Math.floor((item / allLiters) * 100) };
  });

  const addActive = (element, labelArc, d) => {
    const index = pieData.indexOf(d.data);
    setActiveIndex(index);

    const [x, y] = labelArc.centroid(d);
    const rectWidth = isMobile ? 44 : 60;
    const rectHeight = isMobile ? 24 : 28;
    d3.select(element)
      .append('rect')
      .attr('class', 'hover-rect')
      .attr('x', x - rectWidth / 2)
      .attr('y', y - rectHeight / 2)
      .attr('width', rectWidth)
      .attr('height', rectHeight)
      .attr('rx', 8)
      .attr('ry', 8)
      .style('fill', 'white')
      .style('stroke', `${d.data.name}`)
      .style('strokeWidth', '1px')
      .style('pointer-events', 'none');

    d3.select(element)
      .append('text')
      .attr('class', 'hover-text')
      .attr('transform', `translate(${x}, ${y})`)
      .attr('dy', '.35em')
      .style('text-anchor', 'middle')
      .style('font-size', isMobile ? '12px' : '14px')
      .style('font-weight', '700')
      .style('font-family', 'LiberationSans')
      .style('fill', '#1B1B1B')
      .text(`${d.data.value} %`)
      .style('pointer-events', 'none');

    d3.select(element)
      .select('path')
      .style('filter', 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.2))')
      .transition()
      .duration(200)
      .attr('transform', 'scale(1.15)');
  };

  const removeActive = element => {
    setActiveIndex(-1);
    d3.select(element).select('.hover-rect').remove();
    d3.select(element).select('.hover-text').remove();
    d3.select(element).select('path').style('filter', null).transition().duration(200).attr('transform', 'scale(1)');
  };

  useEffect(() => {
    if (!svgRef.current) return;

    const width = isMobile ? 180 : 250;
    const height = 200;
    const radius = isMobile ? 66 : 75;

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const color = d3
      .scaleOrdinal()
      .domain(pieData.map(d => d.name))
      .range(pieColors);

    const pie = d3.pie().value(d => d.value);
    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    const arcs = svg.selectAll('.arc').data(pie(pieData)).enter().append('g').attr('class', 'arc');
    const labelArc = d3
      .arc()
      .innerRadius(isMobile ? radius : radius + 10)
      .outerRadius(isMobile ? radius : radius + 10);

    arcs
      .append('path')
      .attr('d', arc)
      .style('fill', d => color(d.data.name))
      .attr('data-index', (d, i) => i);

    arcs
      .on('mouseover', function (event, d, i) {
        if (!isMobile) {
          addActive(event.currentTarget, labelArc, d);
          d3.select(this).raise();
        }
      })
      .on('mousemove', function () {})
      .on('mouseout', function (event, d, i) {
        if (!isMobile) removeActive(event.currentTarget);
      })
      .on('click', function (event, d) {
        if (isMobile) {
          svg.selectAll('.arc').each(function () {
            removeActive(this);
          });
          addActive(event.currentTarget, labelArc, d);
          d3.select(this).raise();
        }
      });
  }, [data]);

  return (
    <div className={styles.container}>
      <svg ref={svgRef}></svg>{' '}
      <div className={styles.legend}>
        <span className={styles.title}>{`Всего ${allLiters} л`}</span>
        <div className={styles.columnsContainer}>
          {typesOfFuelAndLiters.map((type, index) => {
            return (
              <div className={styles.column} key={index} style={{ opacity: activeIndex === index ? '1' : '0.5' }}>
                <div className={styles.columnPoint} style={{ background: pieColors[index] }}></div>
                <span className={styles.columnText}>{type}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
