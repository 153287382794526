import React from 'react';
import styles from '../../GSMCardAdd.module.scss';
import BackGroundGSMCardSvg from '../../../../../../components/UI/svg-icon/BackGroundGSMCardSvg';
import CustomTooltip from '../../../../../../components/newUI/CustomTooltip/CustomTooltip';
import InfoSvg from '../../../../../../components/UI/svg-icon/InfoSvg';
import AddGSMSvg from '../../../../../../components/UI/svg-icon/AddGSMSvg';
import { useMediaQuery } from 'react-responsive';
interface IProps {
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
  driversWithoutCards: { value: number; label: string }[];
}
const GridCardAdd = ({ setOpenModalOfAddGSMCard, driversWithoutCards }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const checkDriversWithoutCards: boolean = !!driversWithoutCards.length;
  return (
    <div className={styles.container} onClick={() => checkDriversWithoutCards && setOpenModalOfAddGSMCard(true)}>
      <div className={styles.tooltipContainer} onClick={event => event.stopPropagation()}>
        <CustomTooltip
          svg={<InfoSvg color={checkDriversWithoutCards ? 'var(--color-gray600)' : 'var(--color-red600)'} />}
          text={'Топливных карт не может быть больше, чем водителей'}
          widthTooltip={isMobile ? '250px' : '400px'}
          widthSvg={'16px'}
          positionTooltipLeft={'-13px'}
          positionTooltipBottom={'23px'}
          MobileSideTooltip={'right'}
        />
      </div>
      <AddGSMSvg />
      <div>
        <p
          style={{
            textDecoration: checkDriversWithoutCards ? 'unset' : 'underline',
          }}
          className={checkDriversWithoutCards ? styles.mainTextCard : styles.redMainTextCard}
        >
          {checkDriversWithoutCards ? 'Добавить топливную' : 'Добавьте водителя,'}
        </p>
        <p className={checkDriversWithoutCards ? styles.mainTextCard : styles.redMainTextCard}>
          {checkDriversWithoutCards ? 'карту' : 'чтобы добавить топливную карту'}
        </p>
      </div>
      <div className={styles.backGround}>
        <BackGroundGSMCardSvg width={isMobile ? '134px' : '165px'} height={isMobile ? '153px' : '188px'} />
      </div>
    </div>
  );
};

export default GridCardAdd;
