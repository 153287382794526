// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminTariffsFilter_filterWithHeader__YKuIz {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.AdminTariffsFilter_filterHeader__t\\+W7A {\n  font-family: \"Golos Text\";\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 24px;\n  color: var(--color-gray800);\n}\n\n.AdminTariffsFilter_filterSubHeader__qT\\+VE {\n  font-family: \"Golos Text\";\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n  color: var(--color-gray800);\n}\n\n.AdminTariffsFilter_filtersContainer__YwDcs {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminTariffsPage/components/AdminTariffsFilter/AdminTariffsFilter.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF","sourcesContent":[".filterWithHeader {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.filterHeader {\n  font-family: 'Golos Text';\n  font-size: 16px;\n  font-weight: 500;\n  line-height: 24px;\n  color: var(--color-gray800);\n}\n\n.filterSubHeader {\n  font-family: 'Golos Text';\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n  color: var(--color-gray800);\n}\n\n.filtersContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterWithHeader": "AdminTariffsFilter_filterWithHeader__YKuIz",
	"filterHeader": "AdminTariffsFilter_filterHeader__t+W7A",
	"filterSubHeader": "AdminTariffsFilter_filterSubHeader__qT+VE",
	"filtersContainer": "AdminTariffsFilter_filtersContainer__YwDcs"
};
export default ___CSS_LOADER_EXPORT___;
