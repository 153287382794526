import dayjs from 'dayjs';
import {
  IValueFieldsCreateBid,
  IValueFieldsCreateAppForBackend,
  IValueFieldsCreateExecutor,
  IValueFieldsCreateExecutorForBack,
} from './types';

export const convertValueFieldsCreateBidForBack = (data: IValueFieldsCreateBid): IValueFieldsCreateAppForBackend => {
  return {
    //Конвертируем для бэкенда тип данных и + если data.time.workingHoursType === 'aroundTheClock', то time.from и time.to - не передаем
    ...data,
    orderDetailsId: Number(data?.orderDetailsId),
    volumePlan: Number(data?.volumePlan),
    semitrailerId: Number(data?.semitrailerId),
    time: data?.time?.workingHoursType === 'aroundTheClock' ? { workingHoursType: 'aroundTheClock' } : data.time,
    dateFrom: dayjs(data.dateFrom).add(3, 'hour').toISOString(), //Прибавляем 3 часа, чтобы не было на один день меньше
    dateTo: dayjs(data.dateTo).add(3, 'hour').toISOString(),
  };
};

export const convertValueFieldsExecutorForBack = (
  data: IValueFieldsCreateExecutor,
  deliveryRequestId: number,
): IValueFieldsCreateExecutorForBack => {
  return {
    carrierId: Number(data.carrierId),
    volume: Number(data.volume),
    distance: Number(data.distance),
    quarryId: Number(data.quarryId),
    deliveryRequestId,
  };
};
