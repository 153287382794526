import React, { Dispatch, SetStateAction, useRef } from 'react';
import styles from './ShowcaseDescription.module.scss';
import CustomTooltip from 'src/components/newUI/CustomTooltip/CustomTooltip';
import NewPlusSvg from 'src/components/UI/svg-icon/NewPlusSvg';
import { useMediaQuery } from 'react-responsive';
import { InputText } from 'src/components/UI/inputs/InputText';
import { InputTextArea } from 'src/components/UI/inputs/InputTextArea';
import defaultLogoImage from '../../images/default-logo.png';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import { editShowcaseParameters, selectCurrentShowcaseProperties } from '../../services/showcase/showcaseSlice';
import { showcaseParameters } from '../../services/types';

interface IShowcaseDescription {
  error: boolean;
  setError: Dispatch<SetStateAction<{ [showcaseParameters.TITLE]: boolean; [showcaseParameters.QUARRYID]: boolean }>>;
  setIsChangedImages?: Dispatch<
    React.SetStateAction<{
      cover: boolean;
      logo: boolean;
    }>
  >;
}

export const ShowcaseDescription = ({ error, setError, setIsChangedImages = () => {} }: IShowcaseDescription) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const dispatch = useAppDispatch();
  const info = useAppSelector(selectCurrentShowcaseProperties);

  const handleContainerClick = () => {
    fileInputRef.current?.click();
  };

  const handleUpload = e => {
    const file = e.target.files[0];
    if (file.size > 5242880) {
      toast.warning('Размер файла превышает 5 МБ.');
      e.target.value = '';
      return;
    }
    const reader = new FileReader();
    reader.onload = e => {
      dispatch(editShowcaseParameters({ logoImageNew: e.target.result }));
    };
    reader.readAsDataURL(file);
    setIsChangedImages(prevState => {
      return { ...prevState, logo: true };
    });
  };

  const changeDescription = (title: showcaseParameters, value: string) => {
    setError(prevState => {
      return { ...prevState, [title]: value ? true : false };
    });
    dispatch(editShowcaseParameters({ [title]: value }));
  };

  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.mobileContainer}>
        <div className={styles.logoContainer} onClick={handleContainerClick}>
          <div
            className={styles.logoImage}
            style={{
              backgroundImage: `url(${info?.logoImageNew || info?.logoImage?.location || info?.logoImage || defaultLogoImage})`,
            }}
          />
          <CustomTooltip
            MobileSideTooltip={'right'}
            SideTooltip={'right'}
            svg={<NewPlusSvg />}
            positionTooltipBottom={'26px'}
            text={
              <p>
                Загрузите логотип вашего карьера. <br /> Разрешение изображения 72 х 72, размер до 5 МБ, форматы: .png,
                .jpg
              </p>
            }
            widthTooltip={isMobile ? '280px' : '498px'}
            positionTooltipLeft={isMobile && '-9px'}
          />
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            ref={fileInputRef}
            onChange={e => handleUpload(e)}
            style={{ display: 'none' }}
          />
        </div>
        {isMobile && (
          <InputText
            clearButton={info?.title !== ''}
            type={''}
            disabled={false}
            isLabel={true}
            value={info?.title}
            setValue={value => changeDescription(showcaseParameters.TITLE, value)}
            margin={''}
            error={error}
            showError={error}
            textError={''}
            width={'100%'}
            placeholder={'Наименование'}
            helpText={
              error ? (
                <p>
                  Данное поле обязательно к заполнению. <br />
                  Введите наименование для вашего карьера
                </p>
              ) : (
                ''
              )
            }
            widthHelp={'210px'}
            widthSvg="20px"
            svgColor="var(--color-red600)"
            onBlur={() => {
              setError(prevState => {
                return { ...prevState, carrierName: false };
              });
            }}
            maxLength="100"
          />
        )}
      </div>
      <div className={styles.inputContainer}>
        {!isMobile && (
          <InputText
            clearButton={info?.title !== ''}
            type={''}
            disabled={false}
            isLabel={true}
            value={info?.title}
            setValue={value => changeDescription(showcaseParameters.TITLE, value)}
            margin={''}
            error={error}
            showError={error}
            helpText={
              error ? (
                <p>
                  Данное поле обязательно к заполнению. <br />
                  Введите наименование для вашего карьера
                </p>
              ) : (
                ''
              )
            }
            widthHelp={'324px'}
            widthSvg="20px"
            svgColor="var(--color-red600)"
            textError={''}
            width={'100%'}
            placeholder={'Наименование'}
            maxLength="100"
            onBlur={() => {
              setError(prevState => {
                return { ...prevState, carrierName: false };
              });
            }}
          />
        )}
        <InputTextArea
          isLabel={true}
          value={info?.description}
          placeholder={'Описание'}
          padding={'24px 16px 8px'}
          height={isMobile ? '160px' : '90px'}
          setValue={value => changeDescription(showcaseParameters.DESCRIPTION, value)}
          margin="0"
          disabled={false}
          maxLength="1000"
          style={{ resize: 'none', height: isMobile ? '128px' : '54px' }}
        />
      </div>
    </div>
  );
};
