import { recordToParams } from 'src/common/proxy-1C-fetch-auth';
import { instanceApi } from '../../../globalConstans';
import { typeBanner } from '../type';

export const getImagesBanner = async (data: { type: typeBanner }) => {
  return await instanceApi.get(`/api/advertisement/banners?${recordToParams(data)}`);
};
export const postLogImageBanner = async (data: { bannerId: number }) => {
  return await instanceApi.post(`/api/advertisement/clicks`, data);
};
