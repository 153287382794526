import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import styles from './GSMNewPageCarrier.module.scss';
import TitleAndSubTitleMain from '../../components/UI/TitleAndSubtitle/TitleAndSubTitleMain';
import CurrentLimit from './components/CurrentLimit/CurrentLimit';
import GSMCardAdd from './components/GSMCardAdd/GSMCardAdd';
import ModalOfAddGsmCard from './components/ModalOfAddGSMCard/ModalOfAddGSMCard';
import Filters from './components/Filters/Filters';
import { IDataCards, IFilters } from './type';
import { initStateFilters } from './constans';
import GSMCard from './components/GSMCard/GSMCard';
import { GSMStatisticsCard } from '../GSMNewPage/GSMStatisticsCard/GSMStatisticsCard';
import { useMediaQuery } from 'react-responsive';
import { fetchGet } from '../../common/proxy-1C-fetch-auth';
import { toastError } from '../../common/toastError.helper';
import useDebounce from '../../CustomHooks/useDebounce';
import { clearObject } from '../../common/ClearObject.helper';
const GSMNewPageCarrier = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [openModalOfAddGSMCard, setOpenModalOfAddGSMCard] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilters>(initStateFilters);
  const [dataCards, setDataCards] = useState<IDataCards[]>([]);
  const [plotData, setPlotData] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState<number>(0);
  const [sort, setSort] = useState<{ label: string; value: string }>({ value: 'По сумме', label: 'По сумме' });
  const [plotDates, setPlotDates] = useState<{ from: Date; to: Date }>({ from: null, to: null });
  const [dateOffset, setDateOffset] = useState<number>(0);
  const [driversWithoutCards, setDriversWithoutCards] = useState<{ value: number; label: string }[]>([]);
  const [limit, setLimit] = useState<{
    distributedLimit: number;
    limit: number;
  }>();
  const debouncedSearch = useDebounce(filters.search, 500);

  const getDataCards = async () => {
    try {
      const responseCards = await fetchGet(
        '/fuel-cards',
        clearObject({
          searchString: filters.search || undefined,
          order: filters.dateAdded || undefined,
        }),
      );
      if (toastError(responseCards)) {
        return;
      }
      setDataCards(responseCards?.response);
    } catch (e) {
      console.log(e);
    }
  };

  const getLimit = async () => {
    try {
      const responseLimit = await fetchGet('/limits/available', {});
      if (toastError(responseLimit)) {
        return;
      }
      setLimit(responseLimit?.response);
    } catch (e) {
      console.log(e);
    }
  };
  const getDriversWithoutCards = async () => {
    try {
      const responseDriversWithoutCards = await fetchGet('/users/without-cards', {});
      if (toastError(responseDriversWithoutCards)) return;
      const convertDataDriversForOptions = responseDriversWithoutCards.response.users.map(item => {
        return {
          value: item.id,
          label: item.fullName,
        };
      });
      setDriversWithoutCards(convertDataDriversForOptions);
    } catch (e) {
      console.log(e);
    }
  };
  const getPlotInfo = async () => {
    if (!plotDates?.from || !plotDates?.to) return;
    const dateFromISO = plotDates?.from?.toISOString();
    const dateToISO = plotDates?.to?.toISOString();

    try {
      const response = await fetchGet('/transactions/report/cards', {
        display: sort?.value === 'По сумме' ? 'money' : 'fuel',
        dateFrom: dateFromISO,
        dateTo: dateToISO,
      });
      if (response?.error) {
        return;
      }
      const plotInfoData = response?.response;
      setPlotData(plotInfoData?.result);
    } catch (e) {
      console.log(e);
    }
  };

  const changeDate = () => {
    setPlotDates({
      from: new Date(new Date(new Date().setDate(new Date().getDate() + 1 + dateOffset - 7)).setHours(3, 0, 0)),
      to: new Date(new Date(new Date().setDate(new Date().getDate() + 1 + dateOffset)).setHours(2, 59, 59)),
    });
  };
  useEffect(() => {
    getDataCards();
    getLimit();
    getDriversWithoutCards();
    setIsFirstRender(prevState => prevState + 1);
  }, []);
  useEffect(() => {
    getDataCards();
  }, [filters.dateAdded, debouncedSearch]);
  useEffect(() => {
    getPlotInfo();
  }, [sort, plotDates]);

  useEffect(() => {
    changeDate();
  }, [dateOffset]);
  useEffect(() => {
    if (openModalOfAddGSMCard === false && isFirstRender > 0) {
      getDataCards();
      getLimit();
      getDriversWithoutCards();
    }
  }, [openModalOfAddGSMCard]);

  return (
    <PageWrapper style={{ backgroundColor: `${isMobile ? 'white' : 'var(--color-gray50)'}` }}>
      <TitleAndSubTitleMain title={'гсм'} subTitle={'Ваши топливные карты и детализация по ним'} />
      <div className={styles.container}>
        <div className={styles.priceAndPlotContainer}>
          <CurrentLimit limit={limit} />
          {plotDates?.from && plotDates?.to && (
            <GSMStatisticsCard
              plotData={plotData === undefined ? [] : plotData}
              sort={sort}
              setSort={setSort}
              plotDates={plotDates}
              offset={dateOffset}
              setOffset={setDateOffset}
            />
          )}
        </div>
        <Filters filters={filters} setFilters={setFilters} />
        <div className={filters.grid === 'Сетка' ? styles.cardsContainer : styles.cardsContainerList}>
          <GSMCardAdd
            filters={filters}
            setOpenModalOfAddGSMCard={setOpenModalOfAddGSMCard}
            driversWithoutCards={driversWithoutCards}
          />
          {dataCards?.map(dataCard => <GSMCard key={dataCard?.id} filters={filters} dataCard={dataCard} />)}
        </div>
      </div>
      <ModalOfAddGsmCard
        driversWithoutCards={driversWithoutCards}
        openModalOfAddGSMCard={openModalOfAddGSMCard}
        setOpenModalOfAddGSMCard={setOpenModalOfAddGSMCard}
      />
    </PageWrapper>
  );
};

export default GSMNewPageCarrier;
