import React from 'react';
interface IProps {
  width?: string;
  height?: string;
}
const AddGsmSvg = ({ width = '52', height = '36' }: IProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 52 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M4.72545 11.1852H47.273M31.2013 22.4448H26.2692M26.2692 22.4448H21.3372M26.2692 22.4448V17.0867M26.2692 22.4448V27.8027M4.17997 34H47.8184C49.0235 34 50.0004 32.9387 50.0004 31.6296V4.37037C50.0004 3.06125 49.0235 2 47.8184 2H4.17997C2.97493 2 1.99805 3.06125 1.99805 4.37037V31.6296C1.99805 32.9387 2.97493 34 4.17997 34Z"
        stroke="var(--color-gray300)"
        strokeWidth="2.90923"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AddGsmSvg;
