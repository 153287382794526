import React, { useRef, useState } from 'react';
import styles from './ModalQuarryBlocking.module.scss';
import cx from 'classnames';
import { ButtonSimple } from '../../../../../components/newUI/ButtonSimple/ButtonSimple';
import { EditSvg } from '../../../../../components/UI/svg-icon/EditSvg';
import { CloseSvg } from '../../../../../components/UI/svg-icon/CloseSvg';
import CustomDatePicker from '../../../../../components/newUI/CustomDatePicker/CustomDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import ButtonFilled from '../../../../../components/newUI/ButtonFilled/ButtonFilled';
import useClickOutside from '../../../../../CustomHooks/useClickOutside';
import { IQuarries } from '../../types';
import { fetchPost } from '../../../../../common/proxy-1C-fetch-auth';
import { toastError } from '../../../../../common/toastError.helper';
import { convertTimeAndDateToBackend } from '../../utils';
import { hoursArray } from '../../constants';
import SelectLk from '../../../../../components/newUI/SelectLk/SelectLk';
import { toast } from 'react-toastify';

const datePlaceholder = dayjs(new Date()).format('DD.MM.YYYY').toString();

interface IProps {
  text: string;
  data: IQuarries;
}

const ModalQuarryBlocking = ({ text, data }: IProps) => {
  const dateFrom = new Date(text);
  const [inputsHours, setInputsHours] = useState<string>(dayjs(data?.blockTo).subtract(3, 'hour').format('HH:00'));
  const [inputDate, setInputsDate] = useState<Dayjs>(dayjs(data?.blockTo).subtract(3, 'hour'));
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [outputDate, setOutputDate] = useState<string>(inputDate.format('DD.MM.YYYY HH:mm') || 'Нет данных');
  const clickRef = useRef();
  useClickOutside(clickRef, setOpenModal);
  const convertTime = (time: Date) => {
    return dayjs(new Date(time.setHours(time.getHours() - 3))).format('DD.MM.YYYY HH:mm');
  };

  const changeBlockDateQuarries = async () => {
    try {
      const formatDate: string = inputDate?.format('DD.MM.YYYY').toString();
      const convertTime: string = convertTimeAndDateToBackend(formatDate, inputsHours);
      const response = await fetchPost(`/admin/quarries/blocked/${data?.id}`, 'PATCH', {
        blockTo: convertTime,
      });
      if (toastError(response)) return;
      toast.success(`Вы успешно изменили дату`);
      setOutputDate(`${formatDate} ${inputsHours}`);
    } catch (e) {
      console.log(e);
    } finally {
      setOpenModal(false);
    }
  };

  return (
    <div>
      <div className={styles.columnAndBorder}>
        <div className={styles.datesContainer} style={{ width: '192px' }}>
          <div className={cx(styles.columnContainer, styles.twoRows)}>
            <p className={styles.tableText}>{convertTime(dateFrom) || 'Нет данных'}</p>
            <p className={styles.tableText}>{outputDate || 'Нет данных'}</p>
          </div>
          <ButtonSimple
            svg={<EditSvg />}
            onClick={e => {
              e.stopPropagation();
              setOpenModal(true);
            }}
          />
        </div>
        <div className={styles.border}></div>
      </div>
      {openModal && (
        <div ref={clickRef} onClick={event => event.stopPropagation()} className={styles.modalContainer}>
          <div className={styles.headerModal}>
            <p className={styles.modalTitle}>Конец блокировки (Дата/время) *</p>
            <div style={{ cursor: 'pointer' }} onClick={() => setOpenModal(false)}>
              <CloseSvg />
            </div>
          </div>
          <div className={styles.datetime}>
            <CustomDatePicker
              value={inputDate}
              setValue={date => setInputsDate(date)}
              width="150px"
              height="48px"
              paddingRight="16px"
              paddingLeft="4px"
              placeholder={datePlaceholder}
            />
            <SelectLk
              heightSelectWindow={'195px'}
              value={inputsHours}
              setValue={value => setInputsHours(value)}
              placeholder={'00:00'}
              width={'100%'}
              options={hoursArray()}
            />
          </div>
          <div className={styles.btnContainer}>
            <ButtonFilled
              text={'Сохранить'}
              width={'100%'}
              onClick={() => changeBlockDateQuarries()}
              disabled={!inputsHours || !inputDate}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalQuarryBlocking;
