import React from 'react';
import { PageWrapper } from '../../components/PageWrapper';
import { HelpItem } from '../../components/HelpItem';
import { helpItemsMapping } from '../../common/help-items/help-items.maping';
import styles from './HelpPage.module.scss';
import TitleAndSubTitleMain from '../../components/UI/TitleAndSubtitle/TitleAndSubTitleMain';

export const HelpPage = () => {
  const userLs = JSON.parse(localStorage.getItem('user'));

  return (
    <PageWrapper>
      <div className={styles.HelpPage}>
        <TitleAndSubTitleMain title={'Помощь'} subTitle={'Часто задаваемые вопросы и ответы на них'} />
        <div>
          {helpItemsMapping[userLs?.type === 'driver' ? 'driver' : userLs?.companyType]?.map(
            ({ title, description, link, isLink, hrefLink }, index) => (
              <HelpItem
                key={index}
                props={''}
                title={title}
                description={description}
                isLink={isLink}
                link={link}
                hrefLink={hrefLink}
              />
            ),
          )}
        </div>
        <div className={styles.HelpPageFooter}>
          <div>
            <div className={styles.titleFooter}>Не нашли ответ на свой вопрос?</div>
            <div className={styles.subTitleFooter}>
              Напишите нам: <a>help@solber.ru</a>
            </div>
          </div>
          <div className={styles.svgFooter}>
            <svg width="100%" height="100%" viewBox="0 0 130 120" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M108.68 77.94C110.955 72.415 112.19 66.5 112.19 60C112.19 55.32 111.475 50.835 110.24 46.675C106.015 47.65 101.595 48.17 96.98 48.17C77.09 48.17 59.475 38.355 48.75 23.21C42.965 37.25 31.915 48.69 18.005 54.93C17.745 56.555 17.745 58.31 17.745 60C17.745 72.5328 22.7236 84.5523 31.5857 93.4143C40.4477 102.276 52.4672 107.255 65 107.255C71.825 107.255 78.39 105.76 84.305 103.095C88.01 110.18 89.7 113.69 89.57 113.69C78.91 117.265 70.655 119.02 65 119.02C49.27 119.02 34.255 112.845 23.205 101.73C16.445 94.97 11.44 86.715 8.645 77.745H0V48.17H7.085C12.545 21.26 36.4 0.97998 65 0.97998C80.6 0.97998 95.615 7.15498 106.73 18.27C114.985 26.46 120.51 36.925 122.785 48.17H130V77.355V77.485V77.745H129.61L106.47 99L72.02 95.1V84.245H103.415L108.68 77.94ZM47.255 58.505C49.205 58.505 51.09 59.285 52.455 60.715C53.82 62.08 54.6 63.965 54.6 65.915C54.6 67.865 53.82 69.75 52.455 71.115C51.09 72.48 49.205 73.26 47.255 73.26C43.16 73.26 39.845 70.01 39.845 65.915C39.845 61.82 43.16 58.505 47.255 58.505ZM82.68 58.505C86.775 58.505 90.025 61.82 90.025 65.915C90.025 70.01 86.775 73.26 82.68 73.26C78.585 73.26 75.27 70.01 75.27 65.915C75.27 63.9497 76.0507 62.065 77.4404 60.6753C78.83 59.2857 80.7148 58.505 82.68 58.505Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
