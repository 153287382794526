import React, { useEffect, useState } from 'react';
import styles from './IndividualMaterialPage.module.scss';
import { PageWrapper } from '../../../components/PageWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../../components/UI/svg-icon/NewPlusSvg';
import { useMediaQuery } from 'react-responsive';
import { MaterialItem } from '../components/MaterialCatalog/MaterialItem/MaterialItem';
import { declinationWord } from 'src/common/declinationWord.helper';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import {
  getCurrentMaterialsGroup,
  handleChangeCurrentMaterialKindOption,
  handleChangeMaterialGroupOptions,
  selectCurrentMaterialsGroup,
  selectIsLoadingMaterialsInfo,
} from '../services/material/materialSlice';
import { IMaterialsGroup } from '../services/types';
import SelectLk from 'src/components/newUI/SelectLk/SelectLk';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import { clearObject } from 'src/common/ClearObject.helper';
const IndividualMaterialPage = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const currentMaterialId = pathname.split('/')[pathname.split('/').length - 2];
  const currentQuarryId = localStorage.getItem('currentQuarryId');
  const currentShowcaseId = localStorage.getItem('currentShowcaseId');
  const [isFirstRender, setIsFirstRender] = useState<number>(0);
  const isLoading = useAppSelector(selectIsLoadingMaterialsInfo);
  const materialsGroup: IMaterialsGroup = useAppSelector(selectCurrentMaterialsGroup);
  const materialKindOptions = materialsGroup.materialsOptions;
  const currentMaterialKindOption = materialsGroup?.currentMaterialOption;
  const kind = localStorage.getItem('currentKindMaterialForSelect');

  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const getMaterials = async () => {
    const requestOption = {
      materialTypeId: Number(currentMaterialId),
      quarryId: Number(currentQuarryId),
      kind:
        isFirstRender < 2 && kind && kind !== 'Все'
          ? kind
          : currentMaterialKindOption?.label !== 'Все'
            ? currentMaterialKindOption?.label
            : undefined,
    };
    dispatch(getCurrentMaterialsGroup(clearObject(requestOption))).then(() => {
      if (isFirstRender < 2 && kind) dispatch(handleChangeCurrentMaterialKindOption(kind));
    });
    setIsFirstRender(2);
  };

  useEffect(() => {
    getMaterials();
  }, [currentMaterialKindOption]);

  useEffect(() => {
    if (materialsGroup?.materials?.length) {
      dispatch(handleChangeMaterialGroupOptions(''));
    }
  }, [materialsGroup.materials]);

  return (
    <PageWrapper>
      <div className={styles.container}>
        <div className="button-back" onClick={() => navigate(`/showcases/${currentShowcaseId}`)}>
          <div className="button-back__svg">
            <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                fill="currentColor"
              />
            </svg>
          </div>
          Вернуться к витрине
        </div>
        {isLoading ? (
          <LoadingSpin />
        ) : (
          <>
            <div className={styles.topTitleContainer}>
              <div className={styles.titleAndButton}>
                <p className={styles.titleText}>{materialsGroup?.title}</p>
                {!isMobile && (
                  <ButtonFilled
                    svg={<NewPlusSvg />}
                    text={'Добавить материал'}
                    width={'195px'}
                    sizeText={'small'}
                    onClick={() => navigate(`/showcases/create/materials/${currentShowcaseId}`)}
                  />
                )}
              </div>
              <div className={styles.countProductContainer}>
                Всего {materialsGroup?.materials?.length}{' '}
                {declinationWord(materialsGroup?.materials?.length, ['товар', 'товара', 'товаров'])}
              </div>
            </div>
            {isMobile && (
              <ButtonFilled
                svg={<NewPlusSvg />}
                text={'Добавить материал'}
                width={'100%'}
                sizeText={'small'}
                onClick={() => navigate(`/showcases/create/materials/${currentShowcaseId}`)}
              />
            )}
            <div className={styles.contentContainer}>
              <div className={styles.selectAndTextContainer}>
                <p className={styles.textSelect}>Выберите вид</p>
                <div className={styles.selectContainer}>
                  <SelectLk
                    options={materialKindOptions}
                    setValue={value => {
                      dispatch(handleChangeCurrentMaterialKindOption(value));
                      localStorage.setItem('currentKindMaterialForSelect', value);
                    }}
                    placeholder={''}
                    error={false}
                    value={currentMaterialKindOption?.value}
                  />
                </div>
              </div>
            </div>
            <div className={styles.cardsContainer}>
              {materialsGroup?.materials?.length &&
                materialsGroup?.materials?.map((material, index) => (
                  <MaterialItem
                    key={material?.id + '_' + index}
                    material={material}
                    link={`/showcases/materials/edit/${material?.id}/${currentShowcaseId}`}
                  />
                ))}
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default IndividualMaterialPage;
