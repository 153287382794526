import React, { Dispatch, useState } from 'react';
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata';
import './CustomAddressInput.css';
import { CloseCircleNewSvg } from 'src/components/UI/svg-icon/CloseCircleNewSvg';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import { HelpNewSvg } from 'src/components/UI/svg-icon/HelpNewSvg';

interface IProps {
  placeholder?: string;
  valueAddress?: DaDataSuggestion<DaDataAddress> | undefined;
  setValueAddress: Dispatch<any>;
  clearButton?: boolean;
  error?: boolean;
  disabled?: boolean;
  showError?: boolean;
  helpText?: string;
  widthHelp?: string;
  svgColor?: string;
  widthSvg?: string;
}

const CustomAddressInput: React.FC<IProps> = ({
  valueAddress,
  setValueAddress,
  placeholder,
  clearButton = false,
  error = false,
  disabled = false,
  helpText = '',
  widthHelp = '',
  svgColor = '',
  widthSvg = '20px',
}) => {
  const [isOnFocus, setIsOnFocus] = useState(false);
  return (
    <>
      <div className={`form-item ${error && valueAddress === undefined ? 'error' : ''}`} style={{ width: '100%' }}>
        <AddressSuggestions
          delay={300}
          selectOnBlur={false}
          containerClassName={`customAddressInput`}
          token="acd0b65bca29ba39870a3ced6049625e0e15405e"
          value={valueAddress}
          onChange={value => {
            setValueAddress(value);
          }}
          inputProps={{
            disabled: disabled,
            onFocus: () => setIsOnFocus(true),
            onBlur: () => {
              setIsOnFocus(false);
            },
          }}
        />
        {clearButton && valueAddress && (
          <div
            onClick={() => setValueAddress(undefined)}
            style={{
              marginRight: '16px',
              cursor: 'pointer',
              position: 'absolute',
              zIndex: '10',
              right: '0',
              bottom: '50%',
              paddingTop: '4px',
              transform: 'translateY(+50%)',
            }}
          >
            <CloseCircleNewSvg width={widthSvg} />
          </div>
        )}
        {helpText && (
          <CustomTooltip
            positionTooltipBottom={'28px'}
            positionTooltipRight={'-11px'}
            SideTooltip={'left'}
            MobileSideTooltip={'left'}
            style={{
              marginRight: '16px',
              cursor: 'pointer',
              position: 'absolute',
              zIndex: '10',
              right: '0',
              bottom: '50%',
              transform: 'translateY(+50%)',
            }}
            svg={<HelpNewSvg color={svgColor} />}
            text={helpText}
            widthSvg={widthSvg}
            widthTooltip={widthHelp ? widthHelp : '311px'}
          />
        )}
        {!valueAddress && !isOnFocus && (
          <label className={valueAddress ? 'InputText__label form-label valid' : 'InputText__label form-label'}>
            {placeholder}
          </label>
        )}
      </div>
    </>
  );
};

export default CustomAddressInput;
