// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminUsersPage_container__8Iydm {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.AdminUsersPage_titleAndSubtitleContainer__Xoa2N {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.AdminUsersPage_inputsAndSelectorsContainer__TpBec {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n}\n\n.AdminUsersPage_customTableContainer__\\+UTEl {\n  padding: 8px;\n  border-radius: 12px;\n  background: var(--color-blue50);\n}\n\n@media (max-width: 768px) {\n  .AdminUsersPage_container__8Iydm {\n    gap: 24px;\n  }\n  .AdminUsersPage_titleAndSubtitleContainer__Xoa2N {\n    gap: 4px;\n  }\n  .AdminUsersPage_inputsAndSelectorsContainer__TpBec {\n    flex-direction: column;\n    gap: 8px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminUsersPage/AdminUsersPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,YAAA;EACA,mBAAA;EACA,+BAAA;AACF;;AAEA;EACE;IACE,SAAA;EACF;EAEA;IACE,QAAA;EAAF;EAGA;IACE,sBAAA;IACA,QAAA;EADF;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 40px;\n}\n\n.titleAndSubtitleContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.inputsAndSelectorsContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n}\n\n.customTableContainer {\n  padding: 8px;\n  border-radius: 12px;\n  background: var(--color-blue50);\n}\n\n@media (max-width: 768px) {\n  .container {\n    gap: 24px;\n  }\n\n  .titleAndSubtitleContainer {\n    gap: 4px;\n  }\n\n  .inputsAndSelectorsContainer {\n    flex-direction: column;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AdminUsersPage_container__8Iydm",
	"titleAndSubtitleContainer": "AdminUsersPage_titleAndSubtitleContainer__Xoa2N",
	"inputsAndSelectorsContainer": "AdminUsersPage_inputsAndSelectorsContainer__TpBec",
	"customTableContainer": "AdminUsersPage_customTableContainer__+UTEl"
};
export default ___CSS_LOADER_EXPORT___;
