import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  addPropertyName,
  ICalculationAddProperties,
  createdPropertyName,
  IDataGetListUsersInAdmin,
} from '../Pages/AdminPages/AdminCalculationSupplies/type';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './storeHooks';
import {
  addInfoInCreatedCalculationFields,
  addMaterialKindsCreateCalculations,
  addMaterialTypesCreateCalculations,
  changeCalculationDetailsInfo,
  changeDisabled,
  clearCalculationMaterialFields,
  clearCalculationOptionsFields,
  clearDetails,
  getAddressFromCoordinates,
  getAllMaterialsInAdmin,
  getAllUsersInAdmin,
  getBuyers,
  getCalculation,
  getContracts,
  getDetails,
  getMaterialInfoInAdmin,
  getMaxBodySize,
  getObject,
  getObjects,
  getQuarries,
  handleChangeFieldsForObject,
  handleChangeOptions,
  handleChangeValue,
  selectCalculationOptions,
} from 'src/Pages/AdminPages/AdminCalculationSupplies/AdminCalculationSuppliesSlice';
import { shallowEqual } from 'react-redux';

interface IProps {
  isDisabledButton: boolean;
  setIsDisabledButton: Dispatch<SetStateAction<boolean>>;
}

export const useOptionsChangeCalculation = (options: IProps) => {
  const { isDisabledButton, setIsDisabledButton } = options;
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isAddCalculationPath = pathname.includes('/calculation_supplies/create');
  const isCreateOrderPath = pathname.includes('/orders/create');
  const calculationId = pathname.split('/')[2];
  const properties: ICalculationAddProperties[] = useAppSelector(selectCalculationOptions, shallowEqual);
  const materialTypesValue = properties?.find(item => item?.name === addPropertyName.materialTypeId)?.value;
  const materialKindsValue = properties?.find(item => item?.name === addPropertyName.materialKindId)?.value;
  const buyersValue = properties?.find(item => item?.name === addPropertyName.clientId)?.value;
  const objectValue = properties?.find(item => item?.name === addPropertyName.objectId)?.value;
  const [isCanceled, setIsCanceled] = useState(false);
  let getDetailsInterval;

  const requestOptionsForGetAllManagers: IDataGetListUsersInAdmin = {
    limit: 1000,
    offset: 0,
    type: 'commercialManager',
  };
  const getDetailsData = async () => {
    try {
      await dispatch(getDetails(Number(calculationId))).then(data => {
        dispatch(changeCalculationDetailsInfo());
        if (data?.payload.isCanceled) {
          setIsCanceled(true);
          clearInterval(getDetailsInterval);
        }
      });
      await dispatch(getAllUsersInAdmin(requestOptionsForGetAllManagers))
        .unwrap()
        .then(data => {
          dispatch(handleChangeOptions({ name: createdPropertyName.managerId, options: data.data?.rows }));
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const isSomeRequiredNone = properties.some(item => {
      const isScheduleNone = item.name === addPropertyName.workSchedule && !item?.value?.value;
      const isMaterialKindNone = materialTypesValue === '6' ? false : !materialKindsValue;
      [];
      const isValueNone =
        (item.isRequired &&
          !item.value &&
          item.name !== addPropertyName.managerId &&
          item.name !== addPropertyName.materialKindId) ||
        isScheduleNone ||
        isMaterialKindNone;

      return isValueNone;
    });
    if (isDisabledButton !== isSomeRequiredNone) {
      setIsDisabledButton(isSomeRequiredNone);
    }
  }, [properties, isDisabledButton]);

  useEffect(() => {
    if (isAddCalculationPath) return;
    dispatch(getCalculation(Number(calculationId))).then(data => {
      dispatch(addInfoInCreatedCalculationFields(data?.payload));
    });
    getDetailsData();
    if (!isCanceled) {
      getDetailsInterval = setInterval(() => {
        getDetailsData();
      }, 2000);
    }
    if (!isCreateOrderPath) {
      dispatch(clearDetails());
      dispatch(clearCalculationOptionsFields());
      dispatch(clearCalculationMaterialFields());
    }
    return () => {
      clearInterval(getDetailsInterval);
    };
  }, [pathname]);

  useEffect(() => {
    if (isAddCalculationPath) {
      dispatch(
        getAllMaterialsInAdmin({
          withKinds: true,
        }),
      ).then(() => {
        dispatch(addMaterialTypesCreateCalculations());
        dispatch(getMaxBodySize()).then(data => {
          dispatch(handleChangeOptions({ name: addPropertyName.semitrailerId, options: data?.payload }));
        });
        dispatch(getBuyers({ type: 'buyer' })).then(data => {
          dispatch(handleChangeOptions({ name: addPropertyName.clientId, options: data?.payload }));
        });
      });
      return;
    }
  }, []);

  useEffect(() => {
    if (isAddCalculationPath) {
      dispatch(clearCalculationMaterialFields());
      if (materialTypesValue) {
        if (materialTypesValue === '6') {
          dispatch(getMaterialInfoInAdmin({ id: materialTypesValue, params: { excludeKind: true } }));
          dispatch(handleChangeOptions({ name: addPropertyName.materialKindId, options: [] }));
          dispatch(handleChangeValue({ name: addPropertyName.materialKindId, value: '' }));
          dispatch(changeDisabled({ name: addPropertyName.quarryId, disabled: false }));
          dispatch(getQuarries({ materialTypeId: materialTypesValue })).then(data => {
            dispatch(handleChangeOptions({ name: addPropertyName.quarryId, options: data?.payload }));
          });
          return;
        }
        dispatch(changeDisabled({ name: addPropertyName.materialKindId, disabled: false }));
        dispatch(addMaterialKindsCreateCalculations());
        return;
      }
      dispatch(changeDisabled({ name: addPropertyName.materialKindId, disabled: true }));
      dispatch(changeDisabled({ name: addPropertyName.quarryId, disabled: true }));
    }
  }, [materialTypesValue]);

  useEffect(() => {
    if (!isAddCalculationPath) return;
    if (materialTypesValue === '6') return;
    if (materialKindsValue) {
      dispatch(changeDisabled({ name: addPropertyName.quarryId, disabled: false }));
      dispatch(getQuarries({ materialTypeId: materialTypesValue })).then(data => {
        dispatch(handleChangeOptions({ name: addPropertyName.quarryId, options: data?.payload }));
      });
      dispatch(getMaterialInfoInAdmin({ id: materialTypesValue, params: { excludeKind: true } }));
      return;
    }
    dispatch(changeDisabled({ name: addPropertyName.quarryId, disabled: true }));
  }, [materialKindsValue]);

  useEffect(() => {
    if (!isAddCalculationPath) return;
    if (buyersValue) {
      dispatch(changeDisabled({ name: addPropertyName.clientContractId, disabled: false }));
      dispatch(changeDisabled({ name: addPropertyName.objectId, disabled: false }));
      dispatch(getContracts({ companyId: buyersValue })).then(data => {
        dispatch(handleChangeOptions({ name: addPropertyName.clientContractId, options: data?.payload }));
      });
      dispatch(getObjects({ companyId: buyersValue })).then(data => {
        dispatch(handleChangeOptions({ name: addPropertyName.objectId, options: data?.payload }));
      });
      return;
    }
    dispatch(changeDisabled({ name: addPropertyName.clientContractId, disabled: true }));
    dispatch(changeDisabled({ name: addPropertyName.objectId, disabled: true }));
  }, [buyersValue]);

  useEffect(() => {
    if (!objectValue) return;
    dispatch(getObject(Number(objectValue))).then(data => {
      const address = data?.payload?.address;
      dispatch(handleChangeFieldsForObject());
      dispatch(getAddressFromCoordinates({ lat: address?.latitude, lon: address?.longitude }));
    });
  }, [objectValue]);
};
