import React, { useRef, useState } from 'react';
import styles from './SelectStatus.module.scss';
import cx from 'classnames';
import useClickOutsideNode from '../../../CustomHooks/useClickOutsideNode';
import { generateStyleSelectStatus } from './utils';
import { selectStatusEnum } from './type';
import ChevronDownSvg from '../SelectLk/assets/ChevronDownSvg';
interface IProps {
  value: string;
  setValue: React.Dispatch<string>;
}
const options = [
  { value: 'Новый', label: selectStatusEnum.NEW },
  { value: 'Ожидает подписания', label: selectStatusEnum.SIGNATURE },
  { value: 'Ожидает оплаты', label: selectStatusEnum.PAYMENT },
  { value: 'Активный', label: selectStatusEnum.ACTIVE },
  { value: 'Отменен', label: selectStatusEnum.CANCELLED },
  { value: 'Выполнен', label: selectStatusEnum.COMPLETED },
];
const SelectStatus = ({ value, setValue }: IProps) => {
  const [openWindow, setOpenWindow] = useState<boolean>(false);
  const currentLabel = options?.find(item => item.value === value)?.label;
  const containerRef = useRef(null);
  useClickOutsideNode(containerRef, setOpenWindow);
  const handleSelect = option => {
    setValue(option?.value);
    setOpenWindow(false);
  };
  return (
    <div ref={containerRef} className={styles.container}>
      <button className={styles.buttonContainer} onClick={() => setOpenWindow(!openWindow)}>
        <p className={cx(generateStyleSelectStatus(currentLabel))}>{currentLabel}</p>
        <div className={styles.svgContainer}>
          <ChevronDownSvg />
        </div>
      </button>

      {openWindow && (
        <div className={styles.windowContainer}>
          {options.map(option => (
            <div className={styles.optionContainer} key={option.value} onClick={() => handleSelect(option)}>
              <div className={cx(generateStyleSelectStatus(option.label))}>{option.label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectStatus;
