import React from 'react';
import styles from './ShowcaseMainInfo.module.scss';
import { useMediaQuery } from 'react-responsive';
import { ShowcaseMainInfoField } from './ShowcaseMainInfoField/ShowcaseMainInfoField';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'src/CustomHooks/storeHooks';
import { selectCurrentShowcaseProperties } from '../../services/showcase/showcaseSlice';
import { regularPhone } from 'src/common/regular-phone';

export const ShowcaseMainInfo = () => {
  const location = useLocation();
  const isCatalogPath = location.pathname.startsWith('/catalog');
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const info = useAppSelector(selectCurrentShowcaseProperties)?.quarry;
  const fields = [
    { name: 'Телефон', value: info?.dispatcher?.phone ? regularPhone(info?.dispatcher?.phone) : 'Нет данных' },
    { name: 'Адрес', value: info?.address?.address || 'Нет данных' },
    { name: 'Тип', value: info?.quarryType || 'Нет данных' },
    {
      name: 'Часы работы',
      value:
        info?.workSchedulesGraph?.aroundTheClock === true
          ? 'Круглосуточно'
          : info.workSchedulesGraph.from + '–' + info.workSchedulesGraph.to,
    },
  ];
  const newFields = isCatalogPath ? fields.slice(1, 4) : fields;

  return (
    <div className={styles.mainInfoContainer}>
      <div className={styles.columnContainer}>
        <div className={styles.list}>
          {newFields.slice(0, 2).map(field => {
            return <ShowcaseMainInfoField key={field.name} name={field.name} value={field.value} />;
          })}
        </div>
      </div>

      {!isMobile && <div className={styles.line} />}

      <div className={styles.columnContainer}>
        <div className={styles.list}>
          {newFields.slice(2, fields.length).map(field => {
            return <ShowcaseMainInfoField key={field.name} name={field.name} value={field.value} />;
          })}
        </div>
      </div>
    </div>
  );
};
