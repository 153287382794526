import React, { useState } from 'react';

interface IProps {
  children?: string;
  error?;
  check?;
  pick?;
  name?;
  id?;
  disabled?: boolean;
  size?: string;
  additionalClassName?: string;
}
export const CheckBoxCustom = ({
  children = '',
  error,
  check,
  pick,
  name,
  id,
  disabled = false,
  size = 'large',
  additionalClassName = '',
}: IProps) => {
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const sizeClass = { large: '24px', medium: '20px', small: '16px' };
  const handleOnChange = () => {
    pick(id);
    if (id === 'all') {
      setCheckAll(!checkAll);
    }
  };
  const selectCheckBoxFill = (): string => {
    if (!disabled) return 'white';
    return check ? 'var(--color-gray600)' : 'var(--color-gray200)';
  };

  return (
    <div className={`check-box-custom ${error ? 'error' : ''}`}>
      <input
        disabled={disabled}
        onChange={handleOnChange}
        className={'check-box-custom__input'}
        type="checkbox"
        name={name}
        id={id}
      />
      <label
        className={`check-box-custom__label ${check || checkAll ? 'active' : ''} ${disabled && 'disabled'} ${additionalClassName}`}
        htmlFor={id}
      >
        <div className="check-box-custom__check" style={{ width: sizeClass[size], height: sizeClass[size] }}>
          <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.0002 8.66656L10.0002 16.6666L6.3335 12.9999L7.2735
                            12.0599L10.0002 14.7799L17.0602 7.72656L18.0002 8.66656Z"
              fill={selectCheckBoxFill()}
            />
          </svg>
        </div>
        {children}
      </label>
    </div>
  );
};
