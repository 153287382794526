import React, { Dispatch, SetStateAction } from 'react';
import styles from './DeleteShowcasePopup.module.scss';
import FrameModalSvg from 'src/components/UI/svg-icon/FrameModalSvg';
import { Modal } from 'antd';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from 'src/components/newUI/ButtonFilled/ButtonFilled';
import { useMediaQuery } from 'react-responsive';
import { CloseSvg } from 'src/components/UI/svg-icon/CloseSvg';
import { IShowcaseForRedux } from '../../services/types';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import { deleteCurrentShowcase, selectCurrentShowcaseProperties } from '../../services/showcase/showcaseSlice';
import { useNavigate } from 'react-router-dom';

type TDeleteShowcasePopup = {
  popUpActive: boolean;
  setPopUpActive: Dispatch<SetStateAction<boolean>>;
};

export const DeleteShowcasePopup = ({ popUpActive, setPopUpActive }: TDeleteShowcasePopup) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const showcase: IShowcaseForRedux = useAppSelector(selectCurrentShowcaseProperties);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const deleteShowcase = async () => {
    dispatch(deleteCurrentShowcase(showcase?.id));
    setPopUpActive(false);
    navigate('/showcases');
  };

  return (
    <>
      <Modal
        zIndex={9500}
        width={isMobile ? '100%' : '544px'}
        footer={null}
        open={popUpActive}
        onCancel={() => setPopUpActive(false)}
        closeIcon={false}
        centered={true}
        style={isMobile ? { padding: '12px' } : { padding: '40px' }}
      >
        <div className={styles.container}>
          {isMobile && (
            <div className={styles.mobileCloseButtonContainer} onClick={() => setPopUpActive(false)}>
              <div style={{ cursor: 'pointer' }}>
                <CloseSvg />
              </div>
            </div>
          )}
          <div className={styles.topContainer}>
            <div className={styles.topContainerTitle}>Подтвердите удаление</div>
            <div className={styles.text}>
              Вы уверены, что хотите удалить витрину <b style={{ color: 'var(--color-gray800)' }}>{showcase?.title}</b>?
              <br /> Это действие нельзя будет отменить.
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <ButtonNotFilled onClick={() => setPopUpActive(false)} disabled={false} text={'Отмена'} width={'100%'} />
            <ButtonFilled disabled={false} onClick={() => deleteShowcase()} text={'Удалить'} width={'100%'} />
          </div>
        </div>

        {!isMobile && (
          <div className={styles.closeButton}>
            <FrameModalSvg onClick={() => setPopUpActive(false)} />
          </div>
        )}
      </Modal>
    </>
  );
};
