import React from 'react';
import img from '../assets/img/EmptyPng.png';

type EmptyType = {
  title: string;
  subTitle: string | any;
  svg?: any;
};

export const EmptyList = ({ title, subTitle, svg = <img width={300} src={img} alt="" /> }: EmptyType) => {
  return (
    <div className="EmptyList">
      <div style={{ marginTop: '-10px' }} className="EmptyList__box">
        {svg}
        <div style={{ marginTop: '-80px' }}>
          <div className="EmptyList__title">{title}</div>
          <div className="EmptyList__subTitle">{subTitle}</div>
        </div>
      </div>
    </div>
  );
};
