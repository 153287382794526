import React from 'react';

export const ShowcasesIconSvgActive = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_25769_86511)">
        <path
          d="M10.5 16V15H3.51L3.5 19C3.5 20.11 4.39 21 5.5 21H19.5C20.61 21 21.5 20.11 21.5 19V15H14.5V16H10.5ZM20.5 7H16.49V5L14.49 3H10.49L8.49 5V7H4.5C3.4 7 2.5 7.9 2.5 9V12C2.5 13.11 3.39 14 4.5 14H10.5V12H14.5V14H20.5C21.6 14 22.5 13.1 22.5 12V9C22.5 7.9 21.6 7 20.5 7ZM14.5 7H10.5V5H14.5V7Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_25769_86511">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
