import React from 'react';

const VkLogoSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.394 17.3564H12.5416C12.5416 17.3564 12.8882 17.3201 13.0654 17.1391C13.2283 16.9727 13.2231 16.6605 13.2231 16.6605C13.2231 16.6605 13.2006 15.1984 13.9152 14.9831C14.6199 14.7709 15.5245 16.3961 16.4834 17.0211C17.2085 17.4939 17.7595 17.3904 17.7595 17.3904L20.3236 17.3564C20.3236 17.3564 21.6648 17.2778 21.0288 16.2765C20.9768 16.1947 20.6583 15.5359 19.1223 14.1822C17.5144 12.7653 17.73 12.9945 19.6666 10.5437C20.8461 9.05115 21.3175 8.13999 21.1702 7.74976C21.0299 7.37796 20.1623 7.47617 20.1623 7.47617L17.2754 7.49312C17.2754 7.49312 17.0612 7.46545 16.9026 7.55558C16.7474 7.64372 16.6478 7.84965 16.6478 7.84965C16.6478 7.84965 16.1907 9.00455 15.5815 9.98689C14.296 12.0594 13.7819 12.1691 13.5718 12.0402C13.0829 11.7403 13.2051 10.8354 13.2051 10.1924C13.2051 8.18377 13.5259 7.34631 12.5802 7.12953C12.2664 7.05764 12.0353 7.01007 11.2327 7.0023C10.2025 6.99235 9.33084 7.00532 8.83715 7.23494C8.50871 7.38765 8.25531 7.72788 8.40973 7.74745C8.60059 7.77159 9.03261 7.85819 9.26167 8.15412C9.5576 8.53639 9.54726 9.39452 9.54726 9.39452C9.54726 9.39452 9.71729 11.7589 9.15025 12.0526C8.76116 12.254 8.22732 11.8428 7.0812 9.96244C6.49408 8.99928 6.05063 7.93451 6.05063 7.93451C6.05063 7.93451 5.96524 7.73558 5.81271 7.62908C5.62773 7.50005 5.36926 7.45916 5.36926 7.45916L2.6258 7.47617C2.6258 7.47617 2.21406 7.48709 2.06275 7.65713C1.92814 7.8085 2.052 8.12119 2.052 8.12119C2.052 8.12119 4.19968 12.8921 6.63173 15.2964C8.86197 17.5011 11.394 17.3564 11.394 17.3564Z"
        fill="#9BA1B4"
      />
    </svg>
  );
};

export default VkLogoSvg;
