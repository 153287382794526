// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MapLegend_container__JNaP9 {\n  display: flex;\n  gap: 32px;\n  margin-top: 8px;\n}\n\n.MapLegend_contentContainer__8rh2d {\n  display: flex;\n  gap: 16px;\n  align-items: center;\n}\n\n.MapLegend_contentText__uG1xI {\n  color: #000;\n  font-family: \"Golos Text\";\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 28px;\n}\n\n.MapLegend_iconContainer__fbU5G {\n  display: flex;\n  border-radius: 100px;\n  width: 35px;\n  height: 35px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminCalculationAddPage/components/MapLegend/MapLegend.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,eAAA;AACJ;;AACA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;AAEJ;;AAAA;EACI,WAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAGJ;;AADA;EACI,aAAA;EACA,oBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAIJ","sourcesContent":[".container{\n    display: flex;\n    gap: 32px;\n    margin-top: 8px;\n}\n.contentContainer{\n    display: flex;\n    gap: 16px;\n    align-items: center;\n}\n.contentText{\n    color: #000;\n    font-family: \"Golos Text\";\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 28px;\n}\n.iconContainer{\n    display: flex;\n    border-radius: 100px;\n    width: 35px;\n    height: 35px;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MapLegend_container__JNaP9",
	"contentContainer": "MapLegend_contentContainer__8rh2d",
	"contentText": "MapLegend_contentText__uG1xI",
	"iconContainer": "MapLegend_iconContainer__fbU5G"
};
export default ___CSS_LOADER_EXPORT___;
