import React from 'react';

type TCloseCircleNewSvg = {
  width?: string;
  color?: string;
};

export const CloseCircleNewSvg = ({ width = '16px', color = 'var(--color-blue900)' }: TCloseCircleNewSvg) => {
  return (
    <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0303 8.96967C9.73742 8.67678 9.26254 8.67678 8.96965 8.96967C8.67676 9.26256 8.67676 9.73744 8.96965 10.0303L10.9393 12L8.96967 13.9697C8.67678 14.2626 8.67678 14.7374 8.96967 15.0303C9.26256 15.3232 9.73744 15.3232 10.0303 15.0303L12 13.0607L13.9696 15.0303C14.2625 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2625 15.0303 13.9697L13.0606 12L15.0303 10.0304C15.3232 9.73746 15.3232 9.26258 15.0303 8.96969C14.7374 8.6768 14.2625 8.6768 13.9696 8.96969L12 10.9394L10.0303 8.96967Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
        fill={color}
      />
    </svg>
  );
};
