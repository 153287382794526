import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './SelectLk.module.scss';
import ChevronUpSvg from './assets/ChevronUpSvg';
import ChevronDownSvg from './assets/ChevronDownSvg';
import cx from 'classnames';
import { IOption, IPropsSelectLk } from './type';
import { generateStyleButtonContainer, generateStyleButtonText, generateStyleOptionContent, sizeEnum } from './utils';
import useClickOutsideNode from 'src/CustomHooks/useClickOutsideNode';

const SelectLk = ({
  placeholder,
  width = '100%',
  options,
  error,
  errorText,
  disabled,
  value,
  setValue,
  heightSelectWindow = 'fit-content',
  size = sizeEnum.BIG,
  isSearch = false,
  searchFields = ['label'],
  isClearable = false,
}: IPropsSelectLk) => {
  const [openSelectWindow, setOpenSelectWindow] = useState<boolean>(false);
  const [filteredOptions, setFilteredOptions] = useState<IOption[]>([{ value: '', label: '' }]);
  const [inputValue, setInputValue] = useState<string>('');
  const selectContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const validOptions = useMemo(() => {
    const optionsWithClearChoice = isClearable ? [{ value: '', label: 'Не выбрано' }, ...options] : options;
    return options
      ? optionsWithClearChoice.filter(item => item.label?.trim() !== '' && item.label)
      : [
          {
            value: '',
            label: '',
          },
        ];
  }, [options]);
  const checkOptions: boolean = validOptions.length > 0;
  const currentLabel = useMemo(() => {
    return value && checkOptions ? validOptions.find(item => item.value === value)?.label : '';
  }, [value, validOptions]);
  useClickOutsideNode(selectContainerRef, setOpenSelectWindow);
  const changingChevronSvg = (openState: boolean) => {
    if (openState && checkOptions) {
      return <ChevronUpSvg />;
    }
    return <ChevronDownSvg />;
  };
  const handleFocusInput = () => {
    if (inputRef.current) {
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
    }
  };
  const handleSelect = (option: IOption) => {
    setOpenSelectWindow(false);
    setValue(option?.value);
    if (option?.value !== '') {
      setInputValue(option?.label);
    }
  };
  const handleOpenWindow = () => {
    if (!disabled && checkOptions && inputRef.current && !openSelectWindow) {
      setFilteredOptions([...validOptions]);
      setOpenSelectWindow(true);
      inputRef.current.focus();
      handleFocusInput();
    }
    if (openSelectWindow) {
      setOpenSelectWindow(false);
      inputRef.current.blur();
    }
  };
  const isFilterOptionsByInputValue = (e, searchFields) => {
    const valueFromInput: string = e?.target?.value?.toLowerCase();
    const newFilteredArrayOptions = valueFromInput
      ? validOptions.filter(item => searchFields.some(field => item[field]?.toLowerCase().includes(valueFromInput)))
      : validOptions;
    setFilteredOptions(newFilteredArrayOptions);
  };
  const handleInput = e => {
    setInputValue(e.target.value);
    isFilterOptionsByInputValue(e, searchFields);
  };
  useEffect(() => {
    setInputValue(currentLabel);
  }, [value, options, openSelectWindow]);
  return (
    <div
      ref={selectContainerRef}
      style={{ width: width, userSelect: checkOptions ? 'none' : 'initial' }}
      className={styles.container}
    >
      <div
        onClick={handleOpenWindow}
        className={cx(...generateStyleButtonContainer(size, openSelectWindow, error, disabled))}
      >
        <div className={styles.textAndSvgContainer}>
          <input
            disabled={disabled}
            placeholder={placeholder}
            value={inputValue}
            onChange={e => handleInput(e)}
            readOnly={!checkOptions || !isSearch}
            ref={inputRef}
            className={cx(...generateStyleButtonText(size, value))}
          />
          <div className={styles.svgContainer}>{changingChevronSvg(openSelectWindow)}</div>
        </div>
      </div>
      {error && errorText && <p className={styles.errorText}>{errorText}</p>}
      {openSelectWindow && (
        <div style={{ width: width, maxHeight: heightSelectWindow }} className={styles.optionsContainer}>
          {filteredOptions.map(option => (
            <div
              key={option.value}
              className={styles.optionContainer}
              onClick={() => option.label !== inputValue && handleSelect(option)}
            >
              <div className={cx(...generateStyleOptionContent(inputValue, option, size))}>{option.label}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectLk;
