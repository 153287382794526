import styles from './AdminOrdersPage/AdminOrderPage.module.scss';
import {
  IChangeableData,
  IDataInfoTable,
  IDataOrderId,
  IDataOrderIdInnConvertForDetail,
  IRowsGetListTableOrders,
} from './type';
import { selectStatusEnum } from '../../../components/newUI/SelectStatus/type';
import { formatISOToDDMMYYYY } from '../../../common/date-format.helper';
import { formNumber, formPriceOnlyNumber } from '../../../common/form-price.helper';
import { unitFormatBackToFront } from '../../../common/unit-format.helper';
import { clearObject } from '../../../common/ClearObject.helper';
import React from 'react';

export const generateStyleStatus = (text: string) => {
  const activeStyle = text === selectStatusEnum.ACTIVE && styles.activeStatusContainer;
  const newStyle = text === selectStatusEnum.NEW && styles.activeStatusContainer;
  const signatureStyle = text === selectStatusEnum.SIGNATURE && styles.signatureStatusContainer;
  const paymentStyle = text === selectStatusEnum.PAYMENT && styles.paymentStatusContainer;
  const completedStyle = text === selectStatusEnum.COMPLETED && styles.completedStatusContainer;
  const cancelledStyle = text === selectStatusEnum.CANCELLED && styles.cancelledStatusContainer;
  return [styles.statusContainer, activeStyle, signatureStyle, paymentStyle, completedStyle, cancelledStyle, newStyle];
};
const calcVolume = (data: { volume: number; unit: string }[]) => {
  //функция чтобы посчитать массив из {volume:1000,unit:'тонн'} и преобразовать в строку такого формата "{число} тн, {число} м³"

  //Группируем и складываем наши значение через reduce
  const grouped = data.reduce<{ [key: string]: number }>((acc, { volume, unit }) => {
    acc[unit] = (acc[unit] || 0) + volume;
    return acc;
  }, {}); //задаем нашему аккумулятору начальное значение, как {}. Итог {м³:число, тонн:число}

  return Object.entries(grouped)
    .map(([unit, volume]) => `${formNumber(volume)} ${unitFormatBackToFront(unit)}`)
    .join(', '); // "число тн, число м³"
};
export const convertGetListTableOrders = (data: IRowsGetListTableOrders) => {
  return {
    id: data?.id,
    createdAt: formatISOToDDMMYYYY(data?.createdAt),
    client: {
      company: data?.client?.title,
      inn: data?.client?.inn,
    },
    materialAndVolume: {
      material: data?.materials?.map(material => material.title).join(', '),
      volume: calcVolume(data?.volume),
    },
    totalPrice: `${formPriceOnlyNumber(data.totalPrice, 'RUB', 'ru')} ₽`,
    manager: data.manager?.title || 'Нет данных',
    status: data.status.title,
  };
};
export const prepareOrderDateToCreate = (fields, calculationId) => {
  const orderFields = fields.reduce(
    (acc, item) => {
      acc[item.name] = item.value;
      return acc;
    },
    {} as Record<string, any>,
  );

  const clientId = fields[4]?.properties.find(item => item?.label.includes(orderFields?.clientTitle))?.value;
  const contractId = fields[5]?.properties.find(item => item?.label === orderFields?.clientContract)?.value;
  const requestObj = {
    clientId: Number(clientId) || Number(orderFields?.clientTitle),
    acceptance: orderFields?.typeSchedule?.value,
    contractId: Number(contractId) || Number(orderFields?.clientContract),
    deliveryCalculationId: Number(calculationId),
    title: orderFields?.materialTitle|| undefined,
    comment: orderFields?.comment,
    orderId: orderFields?.addToOrder || undefined,
    paymentMethod: orderFields?.paymentProcess,
    pricePerUnit: Number(orderFields?.clientPricePerUnit.replaceAll(/\s/g, '')),
    volume: Number(orderFields?.volume),
    plannedMargin: Number(orderFields?.margin),
    nomenclatureCanImproved: orderFields?.nomenclatureCheckbox || false,
    volumeMustNotExceeded: orderFields?.volumeCheckbox || false,
    specificationClientForm: orderFields?.specificationCheckbox || false,
    anyQuarry: orderFields?.anyQuarryCheckbox || false,
    contactPerson: {
      person: orderFields?.clientName,
      phone: orderFields?.clientPhone,
    },
    time: {
      workingHoursType: orderFields?.workSchedule.value === 'Круглосуточная' ? 'aroundTheClock' : 'daytime',
      from: orderFields?.workScheduleTimeLeft || undefined,
      to: orderFields?.workScheduleTimeRight || undefined,
    },
    objectTitle: orderFields?.object,
  };
  return clearObject(requestObj);
};

const getValueInObjectChangeableData = (key: string, value: any) => {
  // Проверка, что value — это объект (не null)
  if (typeof value === 'object' && value !== null) {
    // Если ключ — status, возвращаем title, иначе id
    return key === 'status' ? value?.['title'] : String(value?.['id']);
  }
  // Если value не объект, просто возвращаем его (или приводим к строке)
  return value;
};

export const updateInputStatesInOrderId = (
  payload: IDataOrderId,
  changeableData: IChangeableData,
  setChangeableData: React.Dispatch<IChangeableData>,
  dataInfoTable: IDataInfoTable[],
  setDataInfoTable: React.Dispatch<IDataInfoTable[]>,
) => {
  const convertPayload: IDataOrderIdInnConvertForDetail = { ...payload, inn: payload.client.inn }; //Достаем из client inn наверх
  const keysChangeableData = Object.keys(changeableData); // достаем ключи из changeableData

  //Обновляем dataInfoTable новыми данными, которые пришли с бэка
  const updatedDataInfoTable = dataInfoTable.map(item => {
    const payloadValue =
      //если объект, то изымаем title в value, иначе просто значение ключа
      typeof convertPayload[item.name] === 'object' && convertPayload[item.name] !== null
        ? convertPayload?.[item.name]?.['title']
        : convertPayload?.[item.name];
    return {
      ...item,
      value: payloadValue ? String(payloadValue) : 'Нет данных', //подставляем value в зависимости от payloadValue
    };
  });
  //Обновляем changeableData новыми данными, которые пришли с бэка
  const updatedChangeableData = Object.entries(convertPayload)
    .filter(([key]) => keysChangeableData.includes(key)) //Оставляем ключи, только которые в changeableData
    .reduce((acc, [key, value]) => {
      const valueInObj = getValueInObjectChangeableData(key, value);
      acc[key] = valueInObj ?? (typeof value === 'boolean' ? value : String(value ?? '')); //Если ключ-объект, то значение берем из title(если key==='status') или id, иначе просто значение ключа, иначе ''
      return acc;
    }, {} as IChangeableData);
  //Сетаем обновленные значения в наши состояния
  setChangeableData(updatedChangeableData);
  setDataInfoTable(updatedDataInfoTable);
};

export const formatDateLocal = (isoString?: string) => {
  if (!isoString) return; // Если пусто — возвращаем сообщение
  const date = new Date(isoString);

  if (isNaN(date.getTime())) return; // Проверяем, является ли дата валидной

  return new Intl.DateTimeFormat('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
    .format(date)
    .replace(',', ' в');
};

export const downloadWord = (base64: string, orderId: string) => {
  try {
    // Создаем ссылку и скачиваем файл
    const link = document.createElement('a');
    link.href = base64;
    link.download = `templateOrder${orderId}.docx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    console.error('Ошибка при скачивании PDF:', e);
  }
};
