import React from 'react';
import styles from '../../GSMCardAdd.module.scss';
import AddGSMSvg from '../../../../../../components/UI/svg-icon/AddGSMSvg';
import CustomTooltip from '../../../../../../components/newUI/CustomTooltip/CustomTooltip';
import InfoSvg from '../../../../../../components/UI/svg-icon/InfoSvg';
import BackGroundGSMCardSvg from '../../../../../../components/UI/svg-icon/BackGroundGSMCardSvg';
import { useMediaQuery } from 'react-responsive';
interface IProps {
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
  driversWithoutCards: { value: number; label: string }[];
}
const ListCardAdd = ({ setOpenModalOfAddGSMCard, driversWithoutCards }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const checkDriversWithoutCards: boolean = !!driversWithoutCards.length;
  return (
    <div onClick={() => checkDriversWithoutCards && setOpenModalOfAddGSMCard(true)} className={styles.listContainer}>
      <div className={styles.svgAndTextContainer}>
        <AddGSMSvg width={isMobile ? '18' : '52'} height={isMobile ? '12' : '36'} />
        <div className={styles.listRedContainer}>
          <p
            style={{
              textDecoration: checkDriversWithoutCards ? 'unset' : 'underline',
            }}
            className={checkDriversWithoutCards ? styles.listText : styles.listRedText}
          >
            {checkDriversWithoutCards ? 'Добавить топливную карту' : 'Добавьте водителя,'}
          </p>
          {!checkDriversWithoutCards && (
            <>
              <span
                style={{
                  color: checkDriversWithoutCards ? 'var(--color-gray600)' : 'var(--color-red600)',
                }}
                className={checkDriversWithoutCards ? styles.listText : styles.listRedText}
              >
                &nbsp;чтобы добавить топливную карту
              </span>
            </>
          )}
        </div>
      </div>
      <div onClick={e => e.stopPropagation()}>
        <CustomTooltip
          svg={<InfoSvg color={checkDriversWithoutCards ? 'var(--color-gray600)' : 'var(--color-red600)'} />}
          text={'Топливных карт не может быть больше, чем водителей'}
          widthTooltip={isMobile ? '250px' : '400px'}
          widthSvg={'16px'}
          positionTooltipRight={isMobile && '-13px'}
          positionTooltipBottom={'23px'}
          MobileSideTooltip={'left'}
          SideTooltip={'up'}
        />
      </div>
      <div className={styles.backGroundList}>
        {!isMobile && (
          <p style={{ marginBottom: '-23px' }}>
            <BackGroundGSMCardSvg width={'165px'} height={'188px'} />
          </p>
        )}
      </div>
    </div>
  );
};

export default ListCardAdd;
