import React from 'react';
import styles from './ProgressLineSum.module.scss';
import { IDataOrderId } from '../../type';
import { formPrice } from 'src/common/form-price.helper';
import { Progress } from 'antd';
interface IProps {
  dataOrder: IDataOrderId;
}
export const ProgressLineSum = ({ dataOrder }: IProps) => {
  const percentOfProgress: number = (dataOrder?.requestSum * 100) / dataOrder?.orderSum;
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <p className={styles.titleText}>Сумма всех заявок</p>
        <p className={styles.titleText}>Сумма заказа</p>
      </div>
      <div className={styles.sumContainer}>
        <p className={styles.sumAll}>{formPrice(dataOrder?.requestSum, 'RUB', 'ru')}</p>
        <p className={styles.dividingLine}>/</p>
        <p className={styles.sumOrder}>{formPrice(dataOrder?.orderSum, 'RUB', 'ru')}</p>
      </div>
      <div className={styles.progressContainer}>
        <Progress
          size={['100%', 6]}
          strokeColor={'var(--color-orange50)'}
          percent={percentOfProgress}
          showInfo={false}
        />
      </div>
    </div>
  );
};
