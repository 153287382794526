import React from 'react';

const EmployeeDelete = () => {
  return (
    <div>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.077 10C16.6555 10 14.6924 11.8794 14.6924 14.1977C14.6924 16.516 16.6555 18.3953 19.077 18.3953C21.4985 18.3953 23.4616 16.516 23.4616 14.1977C23.4616 11.8794 21.4985 10 19.077 10ZM16.077 14.1977C16.077 12.6115 17.4201 11.3256 19.077 11.3256C20.7338 11.3256 22.077 12.6115 22.077 14.1977C22.077 15.7839 20.7338 17.0698 19.077 17.0698C17.4201 17.0698 16.077 15.7839 16.077 14.1977Z"
          fill="var(--color-blue900)"
        />
        <path
          d="M23.8736 21.3874C23.6032 21.1285 23.1649 21.1285 22.8945 21.3874C22.6242 21.6462 22.6242 22.0658 22.8945 22.3247L23.6359 23.0345L22.8948 23.7439C22.6245 24.0028 22.6245 24.4224 22.8948 24.6813C23.1652 24.9401 23.6035 24.9401 23.8739 24.6813L24.615 23.9718L25.356 24.6813C25.6264 24.9401 26.0647 24.9401 26.3351 24.6813C26.6055 24.4224 26.6055 24.0028 26.3351 23.7439L25.594 23.0345L26.3354 22.3247C26.6058 22.0658 26.6058 21.6462 26.3354 21.3874C26.065 21.1285 25.6267 21.1285 25.3563 21.3874L24.615 22.0971L23.8736 21.3874Z"
          fill="var(--color-blue900)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.077 19.7209C19.9709 19.7209 20.8326 19.8023 21.6382 19.9533C22.4201 19.2606 23.4661 18.8372 24.6154 18.8372C27.037 18.8372 29 20.7166 29 23.0349C29 24.5903 28.1163 25.9481 26.8034 26.6734C26.459 27.3886 25.814 27.9569 24.7258 28.3447C23.5169 28.7756 21.7245 29 19.077 29C17.2071 29 15.7717 28.8877 14.6704 28.6743C13.5722 28.4615 12.7594 28.1394 12.1783 27.6864C10.9977 26.7659 10.999 25.4773 11.0001 24.4506L11.0001 24.3605C11.0001 22.9165 12.0796 21.7443 13.5159 20.9708C14.9739 20.1857 16.9414 19.7209 19.077 19.7209ZM12.3847 24.3605C12.3847 23.6081 12.9583 22.792 14.1947 22.1262C15.4094 21.472 17.1342 21.0465 19.077 21.0465C19.6364 21.0465 20.1791 21.0818 20.6976 21.1481C20.399 21.7154 20.2308 22.3563 20.2308 23.0349C20.2308 25.1463 21.8592 26.8937 23.9793 27.1887C22.9909 27.4858 21.4566 27.6744 19.077 27.6744C17.2546 27.6744 15.9208 27.5641 14.9451 27.3751C13.9664 27.1854 13.3947 26.9252 13.0527 26.6585C12.4219 26.1668 12.3847 25.5162 12.3847 24.3605ZM24.6154 20.1628C22.9586 20.1628 21.6154 21.4487 21.6154 23.0349C21.6154 24.6211 22.9586 25.907 24.6154 25.907C26.2723 25.907 27.6154 24.6211 27.6154 23.0349C27.6154 21.4487 26.2723 20.1628 24.6154 20.1628Z"
          fill="var(--color-blue900)"
        />
      </svg>
    </div>
  );
};

export default EmployeeDelete;
