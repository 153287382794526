import React from 'react';
import { ConfigProvider, Table } from 'antd';
import './CustomTable.css';
import { useNavigate } from 'react-router-dom';

// @ts-ignore
import LoadingGif from '../../../assets/img/Loader-Solber.gif';
import img from '../../../assets/img/EmptyPng.png';
interface IProps {
  setOpenModal?: any;
  isModal?: boolean;
  getRowData?: any;
  columns?: any;
  data?: any;
  link?: boolean;
  loading?: boolean;
  isGetRowData?: boolean;
  isRowRedData?: string;
  emptyTitle?: string;
  emptyFilterTitle?: string;
  emptyDescription?: string;
  activityFilter?: boolean;
  linkTextBeforeId?: string;
  isDisabledOnClick?: boolean;
  additionalOnClick?: (id: string) => void;
  dispatchFunction?: (index: number) => void;
  setShowId?: React.Dispatch<number>; //id выбранной (hover) cтроки
}
const CustomTable: React.FC<IProps> = ({
  columns,
  emptyDescription = 'По выбранным фильтрам нет документов.',
  emptyTitle = 'Документов не найдено.',
  emptyFilterTitle = 'Сформируйте историю расчетов',
  data,
  link,
  linkTextBeforeId,
  loading = false,
  isGetRowData = false,
  setOpenModal,
  isModal,
  getRowData,
  isRowRedData,
  activityFilter = true,
  isDisabledOnClick = false,
  additionalOnClick = () => {},
  dispatchFunction = () => {},
  setShowId = null,
}) => {
  const navigate = useNavigate();
  const onMouseEnterTooltip = (record: any) => {
    setShowId(record.id); //Записываем в состояние извне айдишник выбранной строки, чтобы в const отображать тултип, если showId === id строки
  };
  const onMouseLeaveTooltip = () => {
    setShowId(null);
  };
  const isRowRed = rowData => {
    if (rowData[isRowRedData]) {
      return 'table-row-red';
    } else {
      return 'table-row-light';
    }
  };
  const Spin = () => {
    return (
      <div style={{ marginTop: '160px' }}>
        <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
      </div>
    );
  };

  return (
    <ConfigProvider
      renderEmpty={() => (
        <div>
          {loading ? (
            <div style={{ height: '330px' }} />
          ) : (
            <div className={'emptyContentContainer'}>
              <div
                style={{
                  marginTop: '-100px',
                }}
              >
                <img src={img} alt="" />
              </div>
              <div
                style={{
                  marginTop: '-120px',
                }}
              >
                {activityFilter ? (
                  <>
                    <h4 className={'EmptyList__title'} style={{ marginBottom: '8px' }}>
                      {emptyTitle}
                    </h4>
                    <p className={'EmptyList__subTitle'}>{emptyDescription}</p>
                    <p className={'EmptyList__subTitle'}>Попробуйте изменить параметры</p>
                  </>
                ) : (
                  <>
                    <h4 className={'emptyTitle'} style={{ marginBottom: '8px' }}>
                      {emptyFilterTitle}
                    </h4>
                    <p className={'EmptyList__subTitle'}>Нажмите на нужный вам параметр и/или выберите дату</p>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    >
      <Table
        className={'tariffs-table'}
        rowClassName={(record, index) => {
          return `${record ? isRowRed(record) : ''} table-clickable table-documents`;
        }}
        loading={{ indicator: <></>, spinning: loading }}
        style={{
          background: 'var(--color-blue50)',
        }}
        pagination={false}
        onRow={(record, rowIndex) => {
          if (!isDisabledOnClick)
            return {
              onClick: () => {
                link && navigate(`${linkTextBeforeId ? linkTextBeforeId + '/' : ''}${record.id}`);
                isModal && setOpenModal(true);
                isModal || (isGetRowData && getRowData(record));
                isModal && additionalOnClick(record?.id);
                dispatchFunction(rowIndex);
              },
              onMouseEnter: () => {
                setShowId && onMouseEnterTooltip(record);
              },
              onMouseLeave: () => {
                setShowId && onMouseLeaveTooltip();
              },
            };
        }}
        columns={columns}
        dataSource={data}
      />
    </ConfigProvider>
  );
};

export default CustomTable;
