import styles from '../constants.module.scss';
import React from 'react';
import cx from 'classnames';
import { validationPhoneNumberFunction } from 'src/common/validation-phone-number.function';
import { IDataUsers } from './types';
import { ShowMore } from './components/ShowMore/ShowMore';

export enum roles {
  all = 'Все пользователи',
  carrier = 'Перевозчик',
  supplier = 'Поставщик',
  buyer = 'Покупатель',
  driver = 'Водитель',
}

export enum userStatus {
  ACTIVE = 'Активный',
  BLOCKED = 'Заблокированный',
}

export enum menuOptions {
  AUTH = 'Войти под пользователем',
  BLOCK = 'Заблокировать пользователя',
  UNBLOCK = 'Разблокировать пользователя',
}

export const roleOptions = [
  { value: 'all', label: 'Все пользователи' },
  { value: 'carrier', label: 'Перевозчик' },
  { value: 'supplier', label: 'Поставщик' },
  { value: 'buyer', label: 'Покупатель' },
  { value: 'driver', label: 'Водитель' },
];

export const columnsForTableOfCompanies = (changeBlock: (id: number) => void) => [
  {
    title: 'Компания',
    dataIndex: 'company',
    width: 200,
    key: 'company',
    render: text => {
      return (
        <div className={styles.columnAndBorder}>
          <div
            className={cx(styles.columnContainer, styles.twoRows, styles.firstColumnContainer)}
            style={{ width: '224px' }}
          >
            <p className={styles.tableText} style={{ maxWidth: '200px' }}>
              {text?.title || 'Нет данных'}
            </p>
            <p className={cx(styles.tableText, styles.secondaryText)}>{`ИНН: ${text?.inn || 'Нет данных'}`}</p>
          </div>
          <div className={styles.border}></div>
        </div>
      );
    },
  },
  {
    title: 'Фамилия Имя Отчество',
    dataIndex: 'fullName',
    width: 200,
    key: 'fullName',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '234px' }}>
          <p className={styles.tableText} style={{ maxWidth: '234px' }}>
            {text || 'Нет данных'}
          </p>
        </div>
        <div className={styles.border}></div>
      </div>
    ),
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone',
    width: 200,
    key: 'phone',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '140px' }}>
          <p className={styles.tableText} style={{ maxWidth: '200px' }}>
            {validationPhoneNumberFunction(text) || 'Нет данных'}
          </p>
        </div>
        <div className={styles.border}></div>
      </div>
    ),
  },
  {
    title: 'Роль',
    dataIndex: 'type',
    width: 200,
    key: 'type',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '140px' }}>
          <p className={styles.tableText}>{roles[text] || 'Нет данных'}</p>
        </div>
        <div className={styles.border}></div>
      </div>
    ),
  },
  {
    title: 'Статус',
    dataIndex: 'block',
    width: 200,
    key: 'block',
    render: text => {
      return (
        <div className={styles.columnAndBorder}>
          <div className={styles.columnContainer} style={{ width: '200px' }}>
            <p className={cx(text ? styles.redStatus : styles.greenStatus, styles.status)}>
              {text ? userStatus.BLOCKED : userStatus.ACTIVE}
            </p>
          </div>
          <div className={styles.border}></div>
        </div>
      );
    },
  },
  {
    title: '',
    dataIndex: 'showMore',
    width: 200,
    key: 'showMore',
    render: (text: string, data: IDataUsers) => {
      return (
        <div className={styles.columnContainer}>
          <ShowMore dataUsers={data} changeBlock={changeBlock} />
        </div>
      );
    },
  },
];
