import React, { SetStateAction, useEffect, useState } from 'react';
import { InputSearch } from '../../../../../components/UI/inputs/InputSearch';
import FilterBox from '../../../../../components/newUI/FilterBox/FilterBox';
import SelectLk from '../../../../../components/newUI/SelectLk/SelectLk';
import { sizeEnum } from '../../../../../components/newUI/SelectLk/utils';
import { CheckBoxCustom } from '../../../../../components/UI/checkBox/CheckBoxCustom';
import styles from './MainFilters.module.scss';
import { IAdminCalculationFilters, IDataGetListMaterialsInAdmin, IDataGetListUsersInAdmin } from '../../type';
import { initStateAdminCalculationFilters } from '../../const';
import { useAppDispatch, useAppSelector } from '../../../../../CustomHooks/storeHooks';
import {
  convertDataFromMaterialsAdminForOptions,
  convertDataFromUsersAdminForOptions,
  getAllMaterialsInAdmin,
  getAllUsersInAdmin,
  selectOptionsManagers,
  selectOptionsMaterials,
} from '../../AdminCalculationSuppliesSlice';
interface IProps {
  filters: IAdminCalculationFilters;
  setFilters: React.Dispatch<SetStateAction<IAdminCalculationFilters>>;
  search: string;
  setSearch: React.Dispatch<SetStateAction<string>>;
}
const MainFilters = ({ filters, setFilters, search, setSearch }: IProps) => {
  const [openFilterBox, setOpenFilterBox] = useState<boolean>(false);
  const [disableFilterBox, setDisableFilterBox] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const optionsManagers = useAppSelector(selectOptionsManagers);
  const optionsMaterials = useAppSelector(selectOptionsMaterials);
  const materialOptions = optionsMaterials.map(material => ({
    value: material.value,
    label: material.label,
  }));

  const requestOptionsForGetAllManagers: IDataGetListUsersInAdmin = {
    limit: 1000,
    offset: 0,
    type: 'manager',
  };
  const requestOptionsForGetAllMaterials: IDataGetListMaterialsInAdmin = {
    withKinds: false,
  };
  const pickCheckCallback = (): void => {
    setFilters(prevState => ({ ...prevState, checkCallback: !prevState.checkCallback }));
  };
  const checkValuesInFilters = () => {
    const findTrueValue: boolean = Object.values(filters).some(item => Boolean(item) === true);
    setDisableFilterBox(findTrueValue);
  };
  const clearFilters = () => {
    setFilters(initStateAdminCalculationFilters);
  };
  useEffect(() => {
    checkValuesInFilters();
  }, [filters]);
  useEffect(() => {
    dispatch(getAllUsersInAdmin(requestOptionsForGetAllManagers))
      .unwrap()
      .then(data => {
        dispatch(convertDataFromUsersAdminForOptions(data.data));
      });
    dispatch(getAllMaterialsInAdmin(requestOptionsForGetAllMaterials))
      .unwrap()
      .then(data => {
        dispatch(convertDataFromMaterialsAdminForOptions(data));
      });
  }, []);

  return (
    <>
      <InputSearch
        placeholder={'Поиск по id, клиенту, инн, объекту, адресу'}
        onInput={({ currentTarget: { value: search } }) => {
          setSearch(search);
        }}
        value={search}
      />
      <FilterBox
        disabled={!disableFilterBox}
        clearFilters={clearFilters}
        openFilter={openFilterBox}
        setOpenFilter={setOpenFilterBox}
      >
        <div className={styles.containerFilterBox}>
          <p className={styles.titleText}>Фильтры</p>
          <div className={styles.contentFilterBox}>
            <div className={styles.doubleCellFilterBox}>
              <p>Менеджер</p>
              <SelectLk
                isSearch={true}
                isClearable={true}
                setValue={value => setFilters(prevState => ({ ...prevState, manager: value }))}
                value={filters.manager}
                placeholder={'Выберите из списка'}
                options={optionsManagers}
                size={sizeEnum.SMALL}
                heightSelectWindow={'181px'}
              />
            </div>
            <div className={styles.doubleCellFilterBox}>
              <p>Материал</p>
              <SelectLk
                isSearch={true}
                isClearable={true}
                setValue={value => setFilters(prevState => ({ ...prevState, material: value }))}
                value={filters.material}
                placeholder={'Выберите из списка'}
                options={materialOptions}
                size={sizeEnum.SMALL}
                heightSelectWindow={'181px'}
              />
            </div>
            <div>
              <CheckBoxCustom
                children={'Заказан обратный звонок'}
                error={false}
                check={filters.checkCallback}
                pick={pickCheckCallback}
                name={'checkCallback'}
                id={'checkCallback'}
              />
            </div>
          </div>
        </div>
      </FilterBox>
    </>
  );
};

export default MainFilters;
