import { instanceApi } from '../../globalConstans';
import { recordToParams } from '../../common/proxy-1C-fetch-auth';
import { IRequestAddEmployeeOptions, IRequestEditEmployeesOptions, IRequestGetEmployeesOptions } from './type';

export const getEmployees = async (data: IRequestGetEmployeesOptions) => {
  return await instanceApi.get(`api/users/operators/?${recordToParams(data)}`);
};
export const deleteEmployee = async (id: number) => {
  return await instanceApi.delete(`api/users/delete-operator/${id}`);
};

export const editEmployee = async (data: IRequestEditEmployeesOptions) => {
  data.parameters['role'] = data.parameters.type;
  delete data.parameters.type;
  return await instanceApi.patch(`api/users/update-operator/${data?.id}/`, data.parameters);
};

export const addEmployee = async (data: IRequestAddEmployeeOptions) => {
  data['role'] = data.type;
  delete data.type;
  return await instanceApi.post(`api/users/create-operator`, data);
};
