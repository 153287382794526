import React, { useEffect, useState } from 'react';
import styles from './EmployeesPage.module.scss';
import { PageWrapper } from '../../components/PageWrapper';
import { InputSearch } from '../../components/UI/inputs/InputSearch';
import CustomTable from '../../components/newUI/CustomTable/CustomTable';
import { columnsEmployees } from './Constans';
import { clearObject } from '../../common/ClearObject.helper';
import {
  IEmployees,
  IRequestAddEmployeeOptions,
  IRequestEditEmployeesOptions,
  IRequestGetEmployeesOptions,
} from './type';
import ButtonFilled from '../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../components/UI/svg-icon/NewPlusSvg';
import { declinationWord } from '../../common/declinationWord.helper';
import MobileCardEmployee from './Components/MobileCardEmployee/MobileCardEmployee';
import { useMediaQuery } from 'react-responsive';
import TitleMain from '../../components/UI/TitleAndSubtitle/TitleMain';
import SubTitleMain from '../../components/UI/TitleAndSubtitle/SubTitleMain';
import { EmptyList } from '../../components/EmptyList';
import {
  addCurrentEmployee,
  addEditEmployee,
  changeAllEmployees,
  changeEmployeesAfterAdd,
  changeEmployeesAfterEdit,
  deleteCurrentEmployee,
  editCurrentEmployee,
  getAllEmployees,
  selectEmployees,
  selectEmployeesLoading,
  selectEmployeesTotalCount,
} from './employeesSlice';
import { useAppDispatch, useAppSelector } from '../../CustomHooks/storeHooks';
import { usePageScroll } from '../../CustomHooks/usePageScroll';
import FormEmployees from './Components/FormAdditionsEmployees/FormEmployees';

const EmployeesPage = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [filterSearch, setFilterSearch] = useState<string>('');
  const [openFormAddition, setOpenFormAddition] = useState<boolean>(false);
  const [openFormEdit, setOpenFormEdit] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const employees = useAppSelector(selectEmployees);
  const totalCount = useAppSelector(selectEmployeesTotalCount);
  const loading = useAppSelector(selectEmployeesLoading);
  const dispatch = useAppDispatch();

  const requestOptionGetAllEmployees: IRequestGetEmployeesOptions = {
    searchString: filterSearch ? filterSearch.trim() : undefined,
    limit: 10,
    offset: offset,
  };

  const addCurrentEditEmployee = async (data: IRequestEditEmployeesOptions) => {
    dispatch(editCurrentEmployee(clearObject(data))).then(async res => {
      if (!res?.payload?.message) {
        dispatch(changeEmployeesAfterEdit(res?.payload));
        setOpenFormEdit(false);
      }
    });
  };
  const addEmployee = async (data: IRequestAddEmployeeOptions) => {
    dispatch(addCurrentEmployee(clearObject(data))).then(async res => {
      dispatch(changeEmployeesAfterAdd(res?.payload));
      if (!res?.payload?.message) {
        setOpenFormAddition(false);
      }
    });
  };
  const deleteEmployee = async (id: number) => {
    dispatch(deleteCurrentEmployee(id));
  };

  usePageScroll(setOffset, 10);

  useEffect(() => {
    if (totalCount > offset) {
      dispatch(getAllEmployees(clearObject(requestOptionGetAllEmployees))).then(() => dispatch(changeAllEmployees('')));
    }
  }, [offset]);

  useEffect(() => {
    setOffset(0);
    dispatch(getAllEmployees(clearObject(requestOptionGetAllEmployees))).then(() => dispatch(changeAllEmployees('')));
  }, [filterSearch]);

  return (
    <>
      <PageWrapper style={{ zIndex: '100' }}>
        <div className={styles.topContainer}>
          <div className={styles.titleAndSubTitleContainer}>
            <div className={styles.titleAndAddButton}>
              <TitleMain title={'Сотрудники'} />
              <ButtonFilled
                sizeText={'small'}
                svg={<NewPlusSvg />}
                width={isMobile ? '32px' : '140px'}
                onClick={() => {
                  setOpenFormAddition(true);
                  dispatch(addEditEmployee(''));
                }}
                height={isMobile ? '32px' : '40px'}
                text={isMobile ? '' : 'Добавить'}
              />
            </div>
            <SubTitleMain
              subTitle={`В настоящий момент у вас ${totalCount} ${declinationWord(totalCount, ['сотрудник', 'сотрудника', 'сотрудников'])}`}
            />
          </div>
        </div>
        <div className={styles.Container}>
          <div style={{ width: '100%' }}>
            <InputSearch
              placeholder={'Поиск'}
              onInput={({ currentTarget: { value: search } }) => setFilterSearch(search)}
              value={filterSearch}
            />
          </div>
          {isMobile ? (
            employees?.length ? (
              employees?.map((employee: IEmployees) => {
                return (
                  <div
                    key={employee?.id}
                    onClick={() => {
                      setOpenFormEdit(true);
                      dispatch(addEditEmployee(employee?.id));
                    }}
                  >
                    <MobileCardEmployee employee={employee} />
                  </div>
                );
              })
            ) : (
              <EmptyList
                title={'Сотрудники отсутствуют'}
                subTitle={'В данный момент у вас нет сотрудников. Добавьте сотрудника чтобы увидеть его в общем списке'}
              />
            )
          ) : (
            <CustomTable
              emptyTitle={'Сотрудники отсутствуют'}
              emptyDescription={
                'В данный момент у вас нет сотрудников. Добавьте сотрудника чтобы увидеть его в общем списке.'
              }
              getRowData={() => {}}
              isModal={true}
              setOpenModal={setOpenFormEdit}
              loading={loading}
              link={false}
              columns={columnsEmployees}
              data={employees}
              additionalOnClick={id => {
                dispatch(addEditEmployee(id));
              }}
            />
          )}

          <FormEmployees
            title={'Добавление сотрудника'}
            sendFunction={addEmployee}
            setOpenFormAddition={setOpenFormAddition}
            openFormModal={openFormAddition}
          />
          <FormEmployees
            title={'Редактирование сотрудника'}
            refusalFunction={deleteEmployee}
            sendFunction={addCurrentEditEmployee}
            setOpenFormAddition={setOpenFormEdit}
            openFormModal={openFormEdit}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export default EmployeesPage;
