import React from 'react';

const EyeScanSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        opacity="0.5"
        d="M10.5 22C6.72876 22 4.84315 22 3.67157 20.8284C2.5 19.6569 2.5 18.7712 2.5 15"
        stroke="var(--color-orange400)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        opacity="0.5"
        d="M22.5 15C22.5 18.7712 22.5 19.6569 21.3284 20.8284C20.1569 22 18.2712 22 14.5 22"
        stroke="var(--color-orange400)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        opacity="0.5"
        d="M14.5 2C18.2712 2 20.1569 2 21.3284 3.17157C22.5 4.34315 22.5 5.22876 22.5 9"
        stroke="var(--color-orange400)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        opacity="0.5"
        d="M10.5 2C6.72876 2 4.84315 2 3.67157 3.17157C2.5 4.34315 2.5 5.22876 2.5 9"
        stroke="var(--color-orange400)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.39243 14.0598C5.79748 13.3697 5.5 13.0246 5.5 12C5.5 10.9754 5.79747 10.6303 6.39242 9.94021C7.58037 8.56222 9.57268 7 12.5 7C15.4273 7 17.4196 8.56222 18.6076 9.94021C19.2025 10.6303 19.5 10.9754 19.5 12C19.5 13.0246 19.2025 13.3697 18.6076 14.0598C17.4196 15.4378 15.4273 17 12.5 17C9.57268 17 7.58038 15.4378 6.39243 14.0598Z"
        stroke="var(--color-orange400)"
        strokeWidth="1.5"
      />
      <circle cx="12.5" cy="12" r="2" stroke="var(--color-orange400)" strokeWidth="1.5" />
    </svg>
  );
};

export default EyeScanSvg;
