import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../context/context';
import styles from './FiltersButtons.module.scss';
import CustomTooltip from '../../../../components/newUI/CustomTooltip/CustomTooltip';
import { WarningEmptySvg } from '../../../../components/UI/svg-icon/WarningEmptySvg';
import { arrayFilterButtonsPaymentProcess, initFundsInfo, mainTextOfFilterButtons } from '../../constans';
import { IButton, ICommissionList, IFilterButtons, IFundsInfo, ISection } from '../../type';
import { fetchGet } from '../../../../common/proxy-1C-fetch-auth';
import { formPrice } from '../../../../common/form-price.helper';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { ButtonSimple } from 'src/components/newUI/ButtonSimple/ButtonSimple';
import { PaymentsClockSvg } from 'src/components/UI/svg-icon/PaymentsClockSvg';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import { FundsSvg } from 'src/components/UI/svg-icon/FundsSvg';
import { ModalNew } from 'src/components/ModalNew/ModalNew';
import { SmsVerification } from '../SmsVerification/SmsVerification';
import { toastError } from '../../../../common/toastError.helper';
interface IProps {
  getDataTable?: () => Promise<void>;
  tab: string;
}

export enum steps {
  TRANSFER = 'transfer',
  SMSAUTH = 'smsAuth',
  SUCCESS = 'success',
  ERROR = 'error',
}

const FiltersProcessingOfPaymentProcess = ({ getDataTable, tab }: IProps) => {
  const [filterButtons, setFilterButtons] = useState<IFilterButtons>(arrayFilterButtonsPaymentProcess);
  const { user } = useContext(Context);
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [step, setStep] = useState<steps>(steps.TRANSFER);
  const [codeValue, setCodeValue] = useState<string>('');
  const [inputsFormReplenishment, setInputsFormReplenishment] = useState<string>('');
  const [fundsInfo, setFundsInfo] = useState<IFundsInfo>(initFundsInfo);
  const [commissionList, setCommissionList] = useState<ICommissionList[]>([]);
  const balance = filterButtons[user.companyType][1]?.buttons[0]?.sum;
  const isCheap = Number(balance) <= fundsInfo?.minimumAmount;

  const CorrectUrlGeneralCalculations: string = user.companyType === 'carrier' ? 'finance' : 'reports';
  const getGeneralCalculations = async () => {
    try {
      const response = await fetchGet(`/${CorrectUrlGeneralCalculations}/general-calculations/${user.companyType}`, {});
      const newDataButtons = { ...filterButtons };
      Object.keys(newDataButtons).forEach(key => {
        if (key === user.companyType) {
          newDataButtons[key as keyof IFilterButtons].forEach(section => {
            section.buttons.forEach(button => {
              button.sum = response.response[button.value];
            });
          });
        }
      });
      setFundsInfo(prevState => {
        return {
          ...prevState,
          commission: response?.response?.commission,
          minimumAmount: response?.response?.minimumAmount,
        };
      });
      await setFilterButtons(newDataButtons);
    } catch (e) {
      console.log(e);
    }
  };
  const getListCommission = async () => {
    try {
      const response = await fetchGet(`/finance/commissions`, { type: 'Срочный вывод средств' });
      toastError(response);
      setCommissionList(response.response);
    } catch (e) {
      console.log(e);
    }
  };

  const handleFilter = () => {
    getDataTable();
  };

  useEffect(() => {
    handleFilter();
    if (tab === '1') {
      getGeneralCalculations();
      getListCommission();
    }
  }, [tab]);

  const closeModal = () => {
    setStep(steps.TRANSFER);
    setInputsFormReplenishment('');
    setCodeValue('');
    setOpenModal(false);
  };

  const handleFundsClick = () => {
    setOpenModal(true);
  };

  const exceptionInn = [];
  const handleOperationsClick = () => navigate('/paymentProcess/operationHistory');

  return (
    <div className={styles.container}>
      {/*Функционал открыт по задаче YGLOB-327 */}
      {exceptionInn.includes(user.inn) && (
        <div className={styles.filterDateContainer}>
          {!isMobile ? (
            <>
              <p className={styles.filterDateText}>{mainTextOfFilterButtons[user.companyType]}</p>

              <ButtonSimple
                svg={<PaymentsClockSvg />}
                text={'Операции по балансу'}
                disabled={false}
                onClick={handleOperationsClick}
              />
            </>
          ) : (
            <>
              <ButtonNotFilled
                svg={
                  <div style={{ color: 'var(--color-blue900)' }}>
                    <PaymentsClockSvg />
                  </div>
                }
                width="100%"
                text={'Операции по балансу'}
                disabled={false}
                onClick={handleOperationsClick}
              />
            </>
          )}
        </div>
      )}
      {/*Фильтры в зависимости от выбранного личного кабинета*/}
      <div className={styles.filterButtonsAndTitleContainer}>
        {filterButtons[user.companyType].map((container: ISection, index: number) => {
          if (index > 0 && user.companyType === 'carrier') return;
          return (
            <div
              key={index}
              style={{
                width: isMobile ? '100%' : container.widthContainer,
                flexShrink: `${container?.mainTitle === 'Баланс' ? 0 : 1}`,
              }}
              className={styles.filterButtonsContent}
            >
              {/*Функционал открыт по задаче YGLOB-327 */}
              {exceptionInn.includes(user.inn) && (
                <div className={styles.balanceContainer}>
                  <h2 className={styles.filterButtonsTitleText}>{container?.mainTitle}</h2>
                  <ButtonSimple
                    svg={<FundsSvg />}
                    text={'Вывести средства'}
                    disabled={isCheap}
                    isAnotherDisabledColor={isCheap && true}
                    onClick={handleFundsClick}
                  />
                </div>
              )}
              <div className={styles.filterButtonsContainer}>
                {container.buttons.map((button: IButton) => (
                  <div
                    key={button?.value}
                    style={{
                      borderColor: 'var(--color-gray400)',
                    }}
                    className={styles.filterButtonContainer}
                  >
                    <div className={styles.filterButtonContent}>
                      <div className={styles.filterButtonSubtitleAndTooltip}>
                        <p className={styles.filterSubtitleText}>{button?.title}</p>
                        <div onClick={e => e.stopPropagation()}>
                          <CustomTooltip
                            SideTooltip={'up'}
                            MobileSideTooltip={'left'}
                            svg={<WarningEmptySvg />}
                            text={button.tooltipText}
                            widthTooltip={'250px'}
                          />
                        </div>
                      </div>
                      <p style={{ color: 'var(--color-gray800)' }} className={styles.valueText}>
                        {formPrice(Number(button.sum), 'RUB')}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <ModalNew
        openModal={openModal}
        setOpenModal={setOpenModal}
        afterClose={closeModal}
        isDisabledClose={step === steps.ERROR}
      >
        <SmsVerification
          commissionList={commissionList}
          codeValue={codeValue}
          setCodeValue={setCodeValue}
          step={step}
          setStep={setStep}
          inputsFormReplenishment={inputsFormReplenishment}
          setInputsFormReplenishment={setInputsFormReplenishment}
          currentBalance={(Math.floor(balance * 100) / 100)?.toString()}
          fundsInfo={fundsInfo}
          closeModal={closeModal}
        />
      </ModalNew>
    </div>
  );
};

export default FiltersProcessingOfPaymentProcess;
