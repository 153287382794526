import React from 'react';

export const MapSvg = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_35553_61148)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5835 5.67659C2.5835 2.8139 5.06029 0.583496 8.00016 0.583496C10.94 0.583496 13.4168 2.8139 13.4168 5.67659C13.4168 8.29782 11.7987 11.3836 9.14827 12.5162C8.41829 12.8282 7.58204 12.8282 6.85206 12.5162C4.20166 11.3836 2.5835 8.29782 2.5835 5.67659ZM8.00016 2.0835C5.78538 2.0835 4.0835 3.74202 4.0835 5.67659C4.0835 7.81501 5.44421 10.2833 7.44151 11.1369C7.79499 11.2879 8.20534 11.2879 8.55882 11.1369C10.5561 10.2833 11.9168 7.81501 11.9168 5.67659C11.9168 3.74202 10.2149 2.0835 8.00016 2.0835ZM8.00016 5.41683C7.678 5.41683 7.41683 5.678 7.41683 6.00016C7.41683 6.32233 7.678 6.5835 8.00016 6.5835C8.32233 6.5835 8.5835 6.32233 8.5835 6.00016C8.5835 5.678 8.32233 5.41683 8.00016 5.41683ZM5.91683 6.00016C5.91683 4.84957 6.84957 3.91683 8.00016 3.91683C9.15076 3.91683 10.0835 4.84957 10.0835 6.00016C10.0835 7.15076 9.15076 8.0835 8.00016 8.0835C6.84957 8.0835 5.91683 7.15076 5.91683 6.00016ZM2.52989 10.8894C2.83693 10.6114 2.86045 10.1371 2.58242 9.83008C2.30439 9.52304 1.8301 9.49952 1.52306 9.77755C0.981838 10.2676 0.583496 10.9074 0.583496 11.6668C0.583496 12.9742 1.72497 13.9081 2.97834 14.4721C4.30212 15.0678 6.07828 15.4168 8.00016 15.4168C9.92205 15.4168 11.6982 15.0678 13.022 14.4721C14.2754 13.9081 15.4168 12.9742 15.4168 11.6668C15.4168 10.9074 15.0185 10.2676 14.4773 9.77755C14.1702 9.49952 13.6959 9.52304 13.4179 9.83008C13.1399 10.1371 13.1634 10.6114 13.4704 10.8894C13.8163 11.2027 13.9168 11.4684 13.9168 11.6668C13.9168 12.0163 13.5659 12.5824 12.4064 13.1042C11.3174 13.5943 9.76017 13.9168 8.00016 13.9168C6.24015 13.9168 4.68297 13.5943 3.59389 13.1042C2.43441 12.5824 2.0835 12.0163 2.0835 11.6668C2.0835 11.4684 2.18398 11.2027 2.52989 10.8894Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_35553_61148">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
