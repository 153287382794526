import React from 'react';
import { CloseSvg } from '../svg-icon/CloseSvg';

export const ButtonClose = ({ hidden, ...props }) => {
  return (
    <div {...props} className={`ButtonClose ${hidden}`}>
      <CloseSvg />
    </div>
  );
};
