import React from 'react';
import styles from './constans.module.scss';
import cx from 'classnames';
import { formPrice } from '../../common/form-price.helper';
import { PaymentStatus1Svg } from '../../components/UI/svg-icon/paymentStatus1Svg';
import { PaymentStatus2Svg } from '../../components/UI/svg-icon/PaymentStatus2Svg';
import { PaymentStatus3Svg } from '../../components/UI/svg-icon/PaymentStatus3Svg';
import { CloseCircleSvg } from '../../components/UI/svg-icon/CloseCircleSvg';
export const statusContentColor = {
  'По сканам': styles.statusContentOrange,
  'По оригиналам': styles.statusContentGreen,
  'Частично оплачен': styles.statusContentBlue,
  'Не оплачено': styles.statusContentRed,
};
export const mainTextOfFilterButtons = {
  carrier: 'Выберите дату, чтобы посмотреть историю заработанных денежных средств',
  supplier: 'Выберите дату, чтобы посмотреть задолженность',
  buyer: 'Выберите дату, чтобы посмотреть историю дебиторской задолженности',
};

export const arrayFilterButtonsPaymentProcess = {
  carrier: [
    {
      mainTitle: 'Расчеты',
      widthContainer: '',
      buttons: [
        {
          title: 'По сканам',
          value: 'byScan',
          sum: 0,
          tooltipText: 'Выплата 50% от стоимости перевозки по факту принятия скана ТН',
        },
        {
          title: 'По оригиналам',
          value: 'byOriginal',
          sum: 0,
          tooltipText:
            'Выплата 50% от стоимости по факту принятия оригиналов ТН и закрывающей документации (УПД и реестр)',
        },
        {
          title: 'ГСМ',
          sum: 0,
          value: 'fuel',
          tooltipText: 'Пролив ГСМ взаиморасчётом из денежных средств',
        },
      ],
    },
    {
      mainTitle: 'Итог',
      widthContainer: '366px',
      buttons: [
        {
          title: 'Итоговая сумма',
          value: 'result',
          sum: 0,
          tooltipText: 'Общая задолженность перед контрагентом',
        },
      ],
    },
  ],
  supplier: [
    {
      mainTitle: 'Расчеты',
      widthContainer: '',
      buttons: [
        {
          title: 'Не оплачено',
          value: 'notPaid',
          sum: 0,
          tooltipText: 'Задолженность покупателя за полученный материал.',
        },
        {
          title: 'Предоплата',
          value: 'prepayment',
          sum: 0,
          tooltipText: 'Остаток предоплаты покупателя за материал.',
        },
      ],
    },
  ],
  buyer: [
    {
      mainTitle: 'Дебиторская задолженность',
      widthContainer: '',
      buttons: [
        {
          title: 'Предоплата',
          value: 'prepayment',
          sum: 0,
          tooltipText: 'Сумма, которая была заранее оплачена за еще не поставленные товары.',
        },
        {
          title: 'Не оплачено',
          value: 'notPaid',
          sum: 0,
          tooltipText:
            'Сумма, которую необходимо оплатить за полученные товары.' +
            ' ' +
            'Пожалуйста, произведите оплату в ближайшее время,' +
            ' ' +
            'чтобы избежать возможных штрафов или прерывания поставки.',
        },
      ],
    },
    {
      mainTitle: 'К оплате на сегодня ',
      widthContainer: '',
      buttons: [
        {
          title: 'Итоговая сумма',
          value: 'result',
          sum: 0,
          tooltipText:
            'Общая сумма всех задолженностей, подлежащих оплате на сегодняшнюю дату.' +
            ' ' +
            'Пожалуйста, произведите оплату своевременно, чтобы избежать возможных' +
            ' ' +
            'штрафов или приостановки поставки.',
        },
        {
          title: 'Из них просрочено',
          value: 'overdue',
          sum: 0,
          tooltipText:
            'Задолженности, не оплаченные в установленный срок, считаются просроченными.' +
            ' ' +
            'Пожалуйста, произведите оплату в ближайшее время, чтобы избежать' +
            ' ' +
            'дополнительных штрафов и неудобств.',
        },
      ],
    },
  ],
};

export const columnsForTableOfPaymentPageProgress = {
  carrier: [
    {
      title: 'Дата',
      dataIndex: 'flightDate',
      width: 104,
      key: 'flightDate',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(text ? styles.holdRightPositionTable : styles.holdCenterPositionTable, styles.thickText)}>
            {text ? text?.slice(0, 10).split('-').reverse().join('.') : '—'}
          </p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Номер рейса',
      dataIndex: 'flightNumber',
      width: 139,
      key: 'flightNumber',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Номер ТН',
      dataIndex: 'numberTn',
      width: 148,
      key: 'numberTn',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Транспортное средство',
      dataIndex: 'car',
      width: 156,
      key: 'car',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <div className={styles.inlineTwoStringTable}>
              {text?.stateNumber ? (
                <>
                  <p className={styles.thickText}>{text?.carModel}</p>&nbsp;
                  <p className={styles.thickText}>{text?.stateNumber}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Водитель',
      dataIndex: 'driver',
      width: 196,
      key: 'driver',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Объем и груз',
      dataIndex: 'volumeAndUnit',
      width: 259,
      key: 'volumeAndUnit',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <div className={styles.inlineTwoStringTable}>
              {text?.volume || text?.volume === 0 ? (
                <>
                  <p className={styles.thickText}>{text?.volume}</p>&nbsp;
                  <p className={styles.thickText}>{text?.unit}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
            <p className={styles.softText}>{text?.nomenclature ? text?.nomenclature : '—'}</p>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'По сканам',
      dataIndex: 'sumByScan',
      width: 116,
      key: 'sumByScan',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{formPrice(text ? text : '0', 'RUB')}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'По оригиналам',
      dataIndex: 'sumByOriginal',
      width: 228,
      key: 'sumByOriginal',
      render: text => (
        <div className={styles.statusContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{formPrice(text ? text : '0', 'RUB')}</p>
        </div>
      ),
    },
  ],

  supplier: [
    {
      title: 'Дата',
      dataIndex: 'flightDate',
      width: 120,
      key: 'flightDate',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(text ? styles.holdRightPositionTable : styles.holdCenterPositionTable, styles.thickText)}>
            {text ? text?.slice(0, 10).split('-').reverse().join('.') : '—'}
          </p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Номер рейса',
      dataIndex: 'flightNumber',
      width: 139,
      key: 'flightNumber',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Загрузка',
      dataIndex: 'loading',
      width: 228,
      key: 'loading',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <p className={styles.thickText}>{text?.name ? text?.name : '—'}</p>
            <p className={styles.softText}>{text?.address ? text?.address : '—'}</p>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Транспортное средство',
      dataIndex: 'car',
      width: 228,
      key: 'car',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <div className={styles.inlineTwoStringTable}>
              {text?.stateNumber ? (
                <>
                  <p className={styles.thickText}>{text?.carModel}</p>&nbsp;
                  <p className={styles.thickText}>{text?.stateNumber}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
            <p className={styles.softText}>{text?.driver ? text?.driver : '—'}</p>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Фактическая погрузка с ед. изм',
      dataIndex: 'volumeAndUnit',
      width: 228,
      key: 'volumeAndUnit',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <div className={styles.inlineTwoStringTable}>
              {text?.volume || text?.volume === 0 ? (
                <>
                  <p className={styles.thickText}>{text?.volume}</p>&nbsp;
                  <p className={styles.thickText}>{text?.unit}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
            <p className={styles.softText}>{text?.nomenclature ? text?.nomenclature : '—'}</p>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Стоимость',
      dataIndex: 'sum',
      width: 116,
      key: 'sum',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{formPrice(text ? text : '0', 'RUB')}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Статус оплаты',
      dataIndex: 'status',
      width: 228,
      key: 'status',
      render: text => (
        <div className={styles.statusContainer}>
          <p className={cx(styles.statusContent, statusContentColor[text])}>{text ? text : '—'}</p>
        </div>
      ),
    },
  ],

  buyer: [
    {
      title: 'Дата',
      dataIndex: 'flightDate',
      width: 104,
      key: 'flightDate',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(text ? styles.holdRightPositionTable : styles.holdCenterPositionTable, styles.thickText)}>
            {text ? text?.slice(0, 10).split('-').reverse().join('.') : '—'}
          </p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Номер рейса',
      dataIndex: 'flightNumber',
      width: 139,
      key: 'flightNumber',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Номер ТН',
      dataIndex: 'numberTn',
      width: 148,
      key: 'numberTn',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Транспортное средство',
      dataIndex: 'car',
      width: 156,
      key: 'car',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <div className={styles.inlineTwoStringTable}>
              {text?.stateNumber ? (
                <>
                  <p className={styles.thickText}>{text?.carModel}</p>&nbsp;
                  <p className={styles.thickText}>{text?.stateNumber}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Водитель',
      dataIndex: 'driver',
      width: 196,
      key: 'driver',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Объем и груз',
      dataIndex: 'volumeAndUnit',
      width: 259,
      key: 'volumeAndUnit',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <div className={styles.inlineTwoStringTable}>
              {text?.volume || text?.volume === 0 ? (
                <>
                  <p className={styles.thickText}>{text?.volume}</p>&nbsp;
                  <p className={styles.thickText}>{text?.unit}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
            <p className={styles.softText}>{text?.nomenclature ? text?.nomenclature : '—'}</p>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      width: 275,
      key: 'sum',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{formPrice(text ? text : '0', 'RUB')}</p>
        </div>
      ),
    },
  ],
};
export const columnsForTableOfPaymentPagePaid = {
  carrierAndBuyer: [
    {
      title: 'Дата',
      dataIndex: 'dateTn',
      width: 104,
      key: 'dateTn',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(text ? styles.holdRightPositionTable : styles.holdCenterPositionTable, styles.thickText)}>
            {text ? text?.slice(0, 10).split('-').reverse().join('.') : '—'}
          </p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Номер рейса',
      dataIndex: 'flightName',
      width: 148,
      key: 'flightName',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Номер ТН',
      dataIndex: 'numberTn',
      width: 148,
      key: 'numberTn',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Транспортное средство',
      dataIndex: 'car',
      width: 156,
      key: 'car',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <div className={styles.inlineTwoStringTable}>
              {text?.stateNumber ? (
                <>
                  <p className={styles.thickText}>{text?.carModel}</p>&nbsp;
                  <p className={styles.thickText}>{text?.stateNumber}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Водитель',
      dataIndex: 'driver',
      width: 196,
      key: 'driver',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Объем',
      dataIndex: 'volumeAndUnit',
      width: 176,
      key: 'volumeAndUnit',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <div className={styles.inlineTwoStringTable}>
              {text?.volume || text?.volume === 0 ? (
                <>
                  <p className={styles.thickText}>{text?.volume}</p>&nbsp;
                  <p className={styles.thickText}>{text?.unit}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Груз',
      dataIndex: 'volumeAndUnit',
      width: 196,
      key: 'volumeAndUnit',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.holdCenterPositionTable}>
            <p className={styles.thickText}>{text?.nomenclature ? text?.nomenclature : '—'}</p>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      width: 162,
      key: 'sum',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{formPrice(text ? text : '0', 'RUB')}</p>
        </div>
      ),
    },
  ],
  supplier: [
    {
      title: 'Дата',
      dataIndex: 'dateTn',
      width: 104,
      key: 'dateTn',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(text ? styles.holdRightPositionTable : styles.holdCenterPositionTable, styles.thickText)}>
            {text ? text?.slice(0, 10).split('-').reverse().join('.') : '—'}
          </p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Номер рейса',
      dataIndex: 'flightName',
      width: 136,
      key: 'flightName',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Загрузка',
      dataIndex: 'loading',
      width: 250,
      key: 'loading',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <p className={styles.thickText}>{text?.name ? text?.name : '—'}</p>
            <p className={styles.softText}>{text?.address ? text?.address : '—'}</p>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Транспортное средство',
      dataIndex: 'car',
      width: 156,
      key: 'car',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <div className={styles.inlineTwoStringTable}>
              {text?.stateNumber ? (
                <>
                  <p className={styles.thickText}>{text?.carModel}</p>&nbsp;
                  <p className={styles.thickText}>{text?.stateNumber}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Водитель',
      dataIndex: 'driver',
      width: 196,
      key: 'driver',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{text ? text : '—'}</p>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Объем',
      dataIndex: 'volumeAndUnit',
      width: 96,
      key: 'volumeAndUnit',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.twoStringCentreTable}>
            <div className={styles.inlineTwoStringTable}>
              {text?.volume || text?.volume === 0 ? (
                <>
                  <p className={styles.thickText}>{text?.volume}</p>&nbsp;
                  <p className={styles.thickText}>{text?.unit}</p>
                </>
              ) : (
                <p className={styles.thickText}> — </p>
              )}
            </div>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Груз',
      dataIndex: 'volumeAndUnit',
      width: 196,
      key: 'volumeAndUnit',
      render: text => (
        <div className={styles.columnContainer}>
          <div className={styles.holdCenterPositionTable}>
            <p className={styles.thickText}>{text?.nomenclature ? text?.nomenclature : '—'}</p>
          </div>
          <div className={styles.border}></div>
        </div>
      ),
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      width: 152,
      key: 'sum',
      render: text => (
        <div className={styles.columnContainer}>
          <p className={cx(styles.holdCenterPositionTable, styles.thickText)}>{formPrice(text ? text : '0', 'RUB')}</p>
        </div>
      ),
    },
  ],
};
