// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CreateOrderTopInfo_container__GLwLb {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.CreateOrderTopInfo_quarryContainer__Wpmdh {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n}\n\n.CreateOrderTopInfo_text__Meav2 {\n  overflow: hidden;\n  color: var(--color-gray900);\n  text-overflow: ellipsis;\n  font-family: \"Golos Text\";\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminOrdersPage/components/CreateOrderTopInfo/CreateOrderTopInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;EACA,2BAAA;EACA,uBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.quarryContainer {\n  display: flex;\n  flex-direction: row;\n  gap: 16px;\n}\n\n.text {\n  overflow: hidden;\n  color: var(--color-gray900);\n  text-overflow: ellipsis;\n  font-family: 'Golos Text';\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CreateOrderTopInfo_container__GLwLb",
	"quarryContainer": "CreateOrderTopInfo_quarryContainer__Wpmdh",
	"text": "CreateOrderTopInfo_text__Meav2"
};
export default ___CSS_LOADER_EXPORT___;
