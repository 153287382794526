import React from 'react';
import { ModalNew } from '../../../../../components/ModalNew/ModalNew';
import styles from '../ModalCalculationFAQ/ModalCalculationFAQ.module.scss';

interface IProps {
  openModalFAQ: boolean;
  setOpenModalFAQ: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalCalculationFAQ = ({ openModalFAQ, setOpenModalFAQ }: IProps) => {
  return (
    <ModalNew openModal={openModalFAQ} setOpenModal={setOpenModalFAQ} width={'1302px'}>
      <div className={styles.title}>Порядок работы расчетов стоимости поставки в платформе Solber</div>
      <div>
        <ol className={styles.list}>
          <li>
            В платформу необходимо ввести все карьеры, все материалы, все перевозчики должны зарегистрироваться, завести
            все свои машины и т.д.
          </li>
          <li>
            Снабжение обязано вводить тарифы поставщиков - стоимость материалов на карьерах, в ЦИС это называется
            спецификация поставщика. Цены имеют ограниченный срок действия, по окончании снабжения должно запросить
            новую актуальную цену.
            <br />
            Задача отдела снабжения - ввести цены по всем материалам всех поставщиков, которые мы хотим "выставить" на
            продажу.
          </li>
          <li>
            Логисты вводят тариф на перевозку. В формате руб/тн(м3)-км. Задача максимум - ввести тарифы всех
            перевозчиков. Минимум необходимо ввести коммерческие цены для своего парка.
            <br /> Если актуальных тарифов в платформе не будет, то посчитать ничего не возможно.
          </li>

          <li>
            В разделе "Расчеты" пользователь (пока только наши сотрудники) могут задать "что" "сколько" "куда" "когда"
            надо отвезти.
            <br />
            1) Система рассчитает расстояние от всех карьеров, с которых имеет смысл просчитывать доставку нужного
            материала, до адреса поставки с учетом объезда весовых рамок, адреса которых мы берем на сайте Неруда
            <br />
            2) Система определит себестоимость материала каждого карьера, по которому завели тариф
            <br />
            3) Система определит себестоимость перевозки нужного материала для каждого перевозчика, по которому завели
            тариф
            <br />
            4) Система конвертирует стоимость за м3 или тн в ту единицу измерения, которая была выбрана в расчете,
            согласно насыпному коэффициенту материала каждого карьера.
            <br />
            5) Система рассчитает себестоимость для каждой пары карьер-перевозчик = [себестоимость материала за м3] +
            [себестоимость перевозки за м3-км] * [кол-во км], выведет в таблицу по порядке возрастания цены.
            <br />
            6) Все карьеры, адрес доставки, точки с весовыми рамками будут отражены на карте
            <br />
            7) Далее сотрудник может изменить "плановую маржу" (по умолчанию 13%) и увидеть получить стоимость всего
            заказа и за м3/тн для клиента.
          </li>
          <li>
            Т.о. задача расчета поставки определить себестоимость поставки. Решение о продажной цене принимает
            коммерсант.
          </li>
          <li>
            Каждый расчет сохраняется в системе, удалить его или исправить невозможно. Мы будем просить связывать лиды в
            битриксе с расчетами, чтобы анализировать по проигранным лидам стоимость из КП и рассчитанную маржу по
            материалам.
          </li>
        </ol>
      </div>
    </ModalNew>
  );
};
export default ModalCalculationFAQ;
