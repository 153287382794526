import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AuthPage } from '../Pages/AuthPage/AuthPage';
import { Context } from '../context/context';
import { switchRoutes } from '../common/route-role';
import { fetchGet } from '../common/proxy-1C-fetch-auth';
import { localStorageWrite } from '../common/user/local-storage.helper';
import { toast, ToastContainer } from 'react-toastify';
import { logout } from '../common/logout.mapping';
import Agreements from '../Pages/UserAgreement/Agreements';
import NewAuthRole from '../Pages/AuthPage/NewAuthRole';
import NewRegisterPhone from '../Pages/AuthPage/NewRegisterPhone';
import NewAuthSms from '../Pages/AuthPage/NewAuthSms';
import ThanksPage from '../Pages/AuthPage/thanksPage/ThanksPage';
import { ShowcasePage } from 'src/Pages/ShowcasesPage/ShowcasePage/ShowcasePage';
import SelectedMaterialPage from 'src/Pages/Catalog/SelectedMaterialPage/SelectedMaterialPage';
import SuggestAnIdea from '../Pages/SuggestAnIdea/SuggestAnIdea';
import PublicTn from '../Pages/PublicTN/PublicTN';
import BlockPage from '../Pages/BlockPage/BlockPage';

const AppRoutes = () => {
  const exceptionUrl = [
    'register_carrier',
    'register_supplier',
    'register_buyer',
    'register_driver',
    'register_carrier',
    'register_seller',
    'register_buyer',
    'register_driver',
    'privacyPolicy',
    'store',
    'flight',
    'thanks',
    'userAgreement',
    'blockPage',
  ];
  const { user, setUser, setStartTour } = useContext(Context);
  const [active, setActive] = useState(Boolean(localStorage.getItem('apiKey')));
  const navigate = useNavigate();
  const location = useLocation();
  const getUser = async (user, setUser) => {
    try {
      const response = await fetchGet(`/users`, {
        phone: `+${user.phone}`,
      });
      if (response?.statusCode === 401) {
        toast.error('Истекло время сессии.');
        return logout(user, setUser);
      }

      if (response?.statusCode === 410) {
        toast.error('ИНН не найден. Проверьте введенный ИНН и попробуйте снова.');
        return logout(user, setUser);
      }
      if (response?.response?.phone) {
        Object.assign(user, {
          block: response?.response?.block,
          blockDate: response?.response?.blockDate,
          timeToUnblock: response?.response?.timeToUnblock,
          blockNumber: response?.response?.blockNumber,
          root: response?.response?.root,
          active: true,
          activeProfile: true,
          inn: response?.response?.company?.inn,
          companyId: response?.response?.companyId,
          companyType: response?.response?.company?.type,
          type: response?.response?.type,
          preLoaded: false,
        });

        setUser({
          ...user,
          block: response?.response?.block,
          blockDate: response?.response?.blockDate,
          blockNumber: response?.response?.blockNumber,
          timeToUnblock: response?.response?.timeToUnblock,
          inn: response?.response?.company?.inn,
          companyId: response?.response?.companyId,
          root: response?.response?.root,
          companyType: response?.response?.company?.type,
          type: response?.response?.type,
        });
        if (response?.response?.company?.verifications[0].status.title === 'Проверен') {
          setUser({ ...user, verified: true });
        } else {
          setUser({ ...user, verified: false });
        }
        if (response?.response?.company?.verifications[0]?.status?.title === 'Первичная регистрация') {
          setStartTour(true);
        }
        localStorageWrite(user);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };
  const validateExceptionUrl = () => {
    let validExceptionUrl = exceptionUrl.filter(item => item.startsWith(location.pathname.split('/')[1]));
    return !!validExceptionUrl.length;
  };
  useEffect(() => {
    if (user.block) {
      navigate('/blockPage');
    }
  }, [user.block]);
  useEffect(() => {
    user.phone && getUser(user, setUser).catch(console.error);
  }, [user.apiKey, user.type, user.isLogin]);

  useEffect(() => {
    setActive(Boolean(user.activeProfile && user.type));
    if (!active && user.activeProfile && user.type && user.isFirstLogin) {
      navigate('/thanks');
      return;
    }
    if (!active && user.activeProfile && user.type) {
      navigate('');
    }
  }, [user.activeProfile, user.type, user.companyType]);

  useEffect(() => {
    if (!active && !validateExceptionUrl()) {
      navigate('');
    }
    if (active && user?.block) {
      navigate('/blockPage');
    }
  }, [active]);

  return (
    <>
      <ToastContainer />
      {active ? (
        <Routes>
          {switchRoutes(user)}
          <Route path="/userAgreement" element={<Agreements agreementType={'userAgreement'} />} />
          <Route path="/privacyPolicy" element={<Agreements agreementType={'privacyPolicy'} />} />
          <Route path="/thanks" element={<ThanksPage />} />
          <Route path="/store/:id" element={<ShowcasePage />} />
          <Route path="/store/product/:id" element={<SelectedMaterialPage />} />
          <Route path="/suggestAnIdea" element={<SuggestAnIdea />} />
          <Route path="/flight/bill-lading/:id" element={<PublicTn />} />
          <Route path="/blockPage" element={<BlockPage />} />
        </Routes>
      ) : (
        <Routes>
          <Route index element={<Navigate to="auth" replace />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/flight/bill-lading/:id" element={<PublicTn />} />
          <Route path="/store/:id" element={<ShowcasePage />} />
          <Route path="/store/product/:id" element={<SelectedMaterialPage />} />
          <Route path="/userAgreement" element={<Agreements agreementType={'userAgreement'} />} />
          <Route path="/privacyPolicy" element={<Agreements agreementType={'privacyPolicy'} />} />
          <Route path="/registerRole" element={<NewAuthRole />} />
          <Route path="/register_carrier" element={<NewRegisterPhone typeRole={'carrier'} />} />
          <Route path="/register_seller" element={<NewRegisterPhone typeRole={'supplier'} />} />
          <Route path="/register_buyer" element={<NewRegisterPhone typeRole={'buyer'} />} />
          <Route path="/register_driver" element={<NewRegisterPhone typeRole={'driver'} />} />
          <Route path="/authSms" element={<NewAuthSms type={'auth'} />} />
          <Route path="/registerSms" element={<NewAuthSms type={'register'} />} />
          <Route path="/thanks" element={<ThanksPage />} />
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
