import { IOption } from 'src/Pages/CarsPage/const';
import { dataCardResponseType, IFileInfo, IMaterials, IQuarryInfo } from '../type';

export interface IQuarryOption {
  id: number;
  name: string;
}
export interface IDeleteFileReqObj {
  communicationCategory: string;
  name: string;
  objectUuid: string;
}
export interface IQuarryForRedux extends IQuarryInfo {
  workSchedulesGraph: {
    from: string;
    to: string;
    aroundTheClock: boolean;
  };
  dispatcher: {
    phone: string;
  };
}

export interface IShowcaseForRedux extends dataCardResponseType {
  coverImage?: IFileInfo;
  logoImage?: IFileInfo;
  coverImageNew?: IFileInfo;
  logoImageNew?: IFileInfo;
  quarry: IQuarryForRedux;
}

export interface IShowcaseOptions {
  title?: string;
  description?: string;
  quarryId?: number;
  header?: IFileInfo | string;
  logo?: IFileInfo | string;
}

export interface IRequestChangeShowcaseOptions {
  files: IFileInfo[];
  quarry: IQuarryForRedux;
}

export interface IMaterialGroupData {
  quarryId: number;
  materialTypeId: number;
  kind?: string;
}

export interface IMaterialsGroup {
  title: string;
  materialsOptions: IOption[];
  currentMaterialOption: IOption;
  materials: IMaterials[];
}

export enum showcaseParameters {
  TITLE = 'title',
  DESCRIPTION = 'description',
  QUARRYID = 'quarryId',
  HEADER = 'header',
  LOGO = 'logo',
}

export type TApiResponse<T> = {
  error: string;
  response: T;
};
