import React from 'react';
import FooterLogoSvg from '../../../components/UI/svg-icon/FooterLogoSvg';
import styles from './FooterAuth.module.scss';
import VkLogoSvg from '../../../components/UI/svg-icon/VkLogoSvg';
import { useNavigate } from 'react-router-dom';
import FooterLogoTextSvg from '../../../components/UI/svg-icon/FooterLogoTextSvg';
import TelegramLogoSvg from 'src/components/UI/svg-icon/TelegramLogoSvg';

const FooterAuth = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContentContainer}>
        <div className={styles.logoContainer}>
          <FooterLogoSvg />
          <FooterLogoTextSvg />
        </div>
        <div className={styles.linksContainer}>
          <div className={styles.linkContainer}>
            <a
              className={styles.linkContainer}
              style={{ textDecoration: 'underline' }}
              target={'_blank'}
              href="https://solber.ru/"
              rel="noreferrer"
            >
              solber.ru
            </a>
          </div>
          <div className={styles.logosContainer}>
            <a className={styles.linkContainer} target={'_blank'} href="https://t.me/solberru" rel="noreferrer">
              <TelegramLogoSvg />
            </a>
            <a className={styles.linkContainer} target={'_blank'} href="https://vk.com/solberru" rel="noreferrer">
              <VkLogoSvg />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.documentsAndCopyrightContainer}>
        <div className={styles.documentsAndTitleContainer}>
          <a target={'_blank'} href="https://solber.ru/docs" rel="noreferrer">
            <div className={styles.documentsTitle}>Правовые документы</div>
          </a>
          <div className={styles.documentsContainer}>
            <a target={'_blank'} href="https://solber.ru/docs/privacy" rel="noreferrer">
              <p className={styles.document}>Политика конфиденциальности</p>
            </a>
            <a target={'_blank'} href="https://solber.ru/docs/personal-data" rel="noreferrer">
              <p className={styles.document}>Политика обработки персональных данных</p>
            </a>
            <a target={'_blank'} href="https://solber.ru/docs/consent" rel="noreferrer">
              <p className={styles.document}>Согласие на обработку персональных данных</p>
            </a>
            <a target={'_blank'} href="https://solber.ru/docs/user-agreement" rel="noreferrer">
              <p className={styles.document}>Пользовательское соглашение</p>
            </a>
            <a target={'_blank'} href="https://solber.ru/docs/recommendation-technologies" rel="noreferrer">
              <p className={styles.document}>Рекомендательные технологии</p>
            </a>
          </div>
        </div>
        <div className={styles.thirdStringFirstContainer}>© 2025 ООО НТК «СОЛБЕР».Все права защищены.</div>
      </div>
    </div>
  );
};

export default FooterAuth;
