import React, { useEffect, useState } from 'react';
import { InputSearch } from '../../../../../components/UI/inputs/InputSearch';
import FilterBox from '../../../../../components/newUI/FilterBox/FilterBox';
import styles from './OrderFilters.module.scss';
import SelectLk from '../../../../../components/newUI/SelectLk/SelectLk';
import { sizeEnum } from '../../../../../components/newUI/SelectLk/utils';
import { SortBox } from '../../../../../components/SortBox/SortBox';
import { initStateSubFilters, orderSortOptions, statusOptions, validationOfVolumeInputs } from '../../const';
import { IMainFilters, IOrderSortBox, ISubFilters } from '../../type';
import CustomInput from '../../../../../components/newUI/CustomInput/CustomInput';
import CustomRangePicker from '../../../../../components/newUI/CustomRangePicker/CustomRangePicker';
import {
  convertDataFromMaterialsAdminForOptions,
  convertDataFromUsersAdminForOptions,
  getAllMaterialsInAdmin,
  getAllUsersInAdmin,
  selectOptionsManagers,
  selectOptionsMaterials,
} from '../../../AdminCalculationSupplies/AdminCalculationSuppliesSlice';
import { IDataGetListMaterialsInAdmin, IDataGetListUsersInAdmin } from '../../../AdminCalculationSupplies/type';
import { useAppDispatch, useAppSelector } from '../../../../../CustomHooks/storeHooks';
interface IProps {
  mainFilters: IMainFilters;
  setMainFilters: React.Dispatch<React.SetStateAction<IMainFilters>>;
  subFilters: ISubFilters;
  setSubFilters: React.Dispatch<React.SetStateAction<ISubFilters>>;
}
const OrderFilters = ({ mainFilters, setMainFilters, subFilters, setSubFilters }: IProps) => {
  const [openFilterBox, setOpenFilterBox] = useState<boolean>(false);
  const [disableFilterBox, setDisableFilterBox] = useState<boolean>(false);
  const [openSort, setOpenSort] = useState<boolean>(false);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const optionsManagers = useAppSelector(selectOptionsManagers);
  const optionsMaterials = useAppSelector(selectOptionsMaterials);
  const materialOptions = optionsMaterials.map(material => ({
    value: material.value,
    label: material.label,
  }));
  const requestOptionsForGetAllManagers: IDataGetListUsersInAdmin = {
    limit: 1000,
    offset: 0,
    type: 'commercialManager',
  };
  const requestOptionsForGetAllMaterials: IDataGetListMaterialsInAdmin = {
    withKinds: false,
  };
  const checkValuesInFilters = () => {
    const findTrueValue: boolean = Object.entries(subFilters).some(([key, value]) => {
      if (key === 'status' && value === 'Активные') {
        return false;
      }
      if (Array.isArray(value)) {
        return value.some(date => Boolean(date) === true); //Проверяем в наших фильтрах на true у dateRange (это string[])
      }
      return Boolean(value) === true;
    });
    setDisableFilterBox(findTrueValue);
  };
  const clearSubFilters = () => {
    setSubFilters(initStateSubFilters);
  };
  useEffect(() => {
    checkValuesInFilters();
  }, [subFilters]);
  useEffect(() => {
    dispatch(getAllUsersInAdmin(requestOptionsForGetAllManagers))
      .unwrap()
      .then(data => {
        dispatch(convertDataFromUsersAdminForOptions(data.data));
      });
    dispatch(getAllMaterialsInAdmin(requestOptionsForGetAllMaterials))
      .unwrap()
      .then(data => {
        dispatch(convertDataFromMaterialsAdminForOptions(data));
      });
  }, []);
  return (
    <>
      <InputSearch
        placeholder={'Поиск'}
        onInput={({ currentTarget: { value: search } }) => {
          if (search.length <= 300) {
            setMainFilters(prevState => ({ ...prevState, searchString: search }));
          }
        }}
        value={mainFilters.searchString}
      />
      <SortBox
        sortOptions={orderSortOptions}
        openSort={openSort}
        setOpenSort={setOpenSort}
        sort={mainFilters.sort}
        setSort={value => setMainFilters(prevState => ({ ...prevState, sort: value as IOrderSortBox }))}
      />
      <FilterBox
        disabled={!disableFilterBox}
        clearFilters={clearSubFilters}
        openFilter={openFilterBox}
        setOpenFilter={setOpenFilterBox}
      >
        <div className={styles.containerFilterBox}>
          <p className={styles.titleText}>Фильтры</p>
          <div className={styles.contentFilterBox}>
            <div className={styles.doubleCellFilterBox}>
              <p>Период</p>
              <CustomRangePicker
                width={'100%'}
                isPresets={true}
                open={openCalendar}
                dateRange={[subFilters.dateFrom, subFilters.dateTo]}
                setDateRange={value =>
                  setSubFilters(prevState => ({
                    ...prevState,
                    dateFrom: value[0],
                    dateTo: value[1],
                  }))
                }
                setOpen={setOpenCalendar}
                placeholder={['Начало', 'Конец']}
                height="40px"
              />
            </div>
            <div className={styles.doubleCellFilterBox}>
              <p>Менеджер</p>
              <SelectLk
                isSearch={true}
                isClearable={true}
                setValue={value => setSubFilters(prevState => ({ ...prevState, managerId: value }))}
                value={subFilters.managerId}
                placeholder={'Выберите из списка'}
                options={optionsManagers}
                size={sizeEnum.SMALL}
                heightSelectWindow={'181px'}
              />
            </div>
            <div className={styles.doubleCellFilterBox}>
              <p>Группа материала</p>
              <SelectLk
                isSearch={true}
                isClearable={true}
                setValue={value => setSubFilters(prevState => ({ ...prevState, materialTypeId: value }))}
                value={subFilters.materialTypeId}
                placeholder={'Выберите из списка'}
                options={materialOptions}
                size={sizeEnum.SMALL}
                heightSelectWindow={'181px'}
              />
            </div>
            <div className={styles.doubleCellFilterBox}>
              <p>Объем материала</p>
              <div className={styles.inputsVolumeContainer}>
                <CustomInput
                  height={'40px'}
                  clearButton={true}
                  setValue={value => {
                    if (validationOfVolumeInputs(value)) {
                      setSubFilters(prevState => ({ ...prevState, volumeFrom: Number(value) }));
                    }
                  }}
                  value={subFilters.volumeFrom}
                  placeholder={''}
                />
                <p className={styles.textVolume}>до</p>
                <CustomInput
                  height={'40px'}
                  clearButton={true}
                  setValue={value => {
                    if (validationOfVolumeInputs(value)) {
                      setSubFilters(prevState => ({ ...prevState, volumeTo: Number(value) }));
                    }
                  }}
                  value={subFilters.volumeTo}
                  placeholder={''}
                />
              </div>
              <div className={styles.doubleCellFilterBox}>
                <p>Статус заказа</p>
                <SelectLk
                  setValue={value => setSubFilters(prevState => ({ ...prevState, status: value }))}
                  value={subFilters.status}
                  placeholder={'Выберите из списка'}
                  options={statusOptions}
                  size={sizeEnum.SMALL}
                  heightSelectWindow={'181px'}
                />
              </div>
            </div>
          </div>
        </div>
      </FilterBox>
    </>
  );
};

export default OrderFilters;
