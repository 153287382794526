// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormReplenishmentsCardPage_backButtonAndTitleContainer__Ea22h {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-bottom: 40px;\n}\n\n.FormReplenishmentsCardPage_title__KXcCE {\n  font-family: \"Golos Text\";\n  color: var(--color-gray900);\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n}\n\n@media (max-width: 768px) {\n  .FormReplenishmentsCardPage_backButtonAndTitleContainer__Ea22h {\n    margin-bottom: 24px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/Pages/GSMNewPageCarrier/FormReplenishmentsCardPage/FormReplenishmentsCardPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AACF;;AACA;EACE,yBAAA;EACA,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAEF;;AACA;EACE;IACE,mBAAA;EAEF;AACF","sourcesContent":[".backButtonAndTitleContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  margin-bottom: 40px;\n}\n.title {\n  font-family: 'Golos Text';\n  color: var(--color-gray900);\n  font-size: 24px;\n  font-weight: 500;\n  line-height: 32px;\n}\n\n@media (max-width: 768px) {\n  .backButtonAndTitleContainer {\n    margin-bottom: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButtonAndTitleContainer": "FormReplenishmentsCardPage_backButtonAndTitleContainer__Ea22h",
	"title": "FormReplenishmentsCardPage_title__KXcCE"
};
export default ___CSS_LOADER_EXPORT___;
