import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction, Slice, UnknownAction } from '@reduxjs/toolkit';
import { getImagesBanner, postLogImageBanner } from './BannerApi';
import { IImageBanner, IRequestImagesBanner, IStateBanner } from '../type';
import { RootState } from '../../../store';

export const getAllImagesBanner = createAsyncThunk(
  'banner/getAllImagesBanner',
  async (data: IRequestImagesBanner, { rejectWithValue }) => {
    try {
      const response = await getImagesBanner(data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
export const postLogImage = createAsyncThunk(
  'banner/postLogImage',
  async (data: { bannerId: number }, { rejectWithValue }) => {
    try {
      const response = await postLogImageBanner(data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

function isPendingAction(action: UnknownAction): action is PendingAction {
  return typeof action.type === 'string' && action.type.endsWith('/pending');
}
function isRejectedAction(action: UnknownAction): action is RejectedAction {
  return typeof action.type === 'string' && action.type.endsWith('/rejected');
}
function isFulfilledAction(action: UnknownAction): action is FulfilledAction {
  return typeof action.type === 'string' && action.type.endsWith('/fulfilled');
}
const initialState: IStateBanner = {
  images: [],
  buttonInsurance:[],
  isLoading: false,
};
export const bannerSlice: Slice = createSlice({
  name: 'banner',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAllImagesBanner.fulfilled, (state, action: PayloadAction<{ response: IImageBanner[] }>) => {
      action.payload.response.forEach((item:IImageBanner)=>{
        if(item.type==='button_insurance'){
          state.buttonInsurance.push(item)
          return
        }
        state.images.push(item)
      })
    });
    builder.addMatcher(isPendingAction, state => {
      state.isLoading = true;
    });

    builder.addMatcher(isRejectedAction, state => {
      state.isLoading = false;
    });

    builder.addMatcher(isFulfilledAction, state => {
      state.isLoading = false;
    });
  },
});

export const selectImages = (state: RootState) => state.banner.images;
export const selectButtonInsurance = (state: RootState) => state.banner.buttonInsurance;

