import React, { useEffect, useRef, useState } from 'react';
import styles from './Banner.module.scss';
import { FastAverageColor } from 'fast-average-color';
import { IImageBanner, IRequestImagesBanner, typeBanner } from './type';
import { getAllImagesBanner, postLogImage, selectImages } from './services/BannerSlice';
import { useAppDispatch, useAppSelector } from '../../CustomHooks/storeHooks';
import { useNavigate } from 'react-router-dom';

interface IProps {
  type: typeBanner;
}
const Banner = ({ type }: IProps) => {
  const [indexImg, setIndexImg] = useState<number>(0);
  const [screenSize, setScreenSize] = useState(`${window.innerWidth}x${window.innerHeight}`);
  const [borderColor, setBorderColor] = useState<string>('orange');
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const dispatch = useAppDispatch();
  const imgRef = useRef<HTMLImageElement>();
  const images: IImageBanner[] = useAppSelector(selectImages);
  const checkImages: boolean = !!images.length;
  const navigate = useNavigate();
  const requestImagesBanner: IRequestImagesBanner = {
    type: type,
    resolution: screenSize,
  };
  const resetAutoSlide = () => {
    if (images.length > 1) {
      intervalRef.current && clearInterval(intervalRef.current);
      intervalRef.current = setInterval(nextSlide, 8000);
    }
  };
  const nextSlide = () => {
    setIndexImg(prev => (prev + 1) % images.length);
    resetAutoSlide();
  };
  const prevSlide = () => {
    setIndexImg(prev => (prev - 1 + images.length) % images.length);
    resetAutoSlide();
  };
  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX; /*начальное положение пальца на экране*/
  };
  const handleTouchEnd = (e: React.TouchEvent) => {
    touchEndX.current = e.changedTouches[0].clientX; /*последнее положение пальца на экране*/
    const swipeDistance = touchStartX.current - touchEndX.current; /*расстояние от начального до конечного положения*/
    if (swipeDistance > 50) nextSlide();
    if (swipeDistance < -50) prevSlide();
  };

  useEffect(() => {
    dispatch(getAllImagesBanner(requestImagesBanner));

    const updateSize = () => {
      setScreenSize(`${window.innerWidth}x${window.innerHeight}`);
    };

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    resetAutoSlide();
    const fac = new FastAverageColor();
    const img = imgRef.current;
    if (!img) return;
    fac
      .getColorAsync(img, { algorithm: 'dominant' })
      .then(color => {
        setBorderColor(color.hex);
      })
      .catch(console.error);
  }, [images, indexImg]);
  const handleOpenNewWindow = (img: IImageBanner) => {
    dispatch(postLogImage({ bannerId: img.id }));
    const isAbsoluteUrl = img.urlRedirect.startsWith('http');
    const url = isAbsoluteUrl ? img.urlRedirect : `${window.location.origin}${img.urlRedirect}`;
    if (url) {
      return window.open(url, '_blank', 'noopener,noreferrer');
    }
  };
  return (
    <>
      {checkImages && (
        <div id={'Banner'} className={styles.container}>
          <div className={styles.content}>
            <div className={styles.slider} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
              {images.length > 1 &&<button
                  className={styles.buttonLeft}
                  onClick={e => {
                    e.stopPropagation();
                    prevSlide();
                  }}
              >
                <p className={styles.chevron}>‹</p>
              </button>}
              {images.map((img, index) => (
                <img
                  onClick={() => handleOpenNewWindow(img)}
                  style={{
                    border: `1px solid ${borderColor}`,
                    opacity: index === indexImg ? 1 : 0,
                    zIndex: index === indexImg ? 1 : 0,
                  }}
                  className={styles.image}
                  key={index}
                  ref={index === indexImg ? imgRef : null}
                  src={img.pictureUrl}
                  alt="img"
                  crossOrigin="anonymous"
                />
              ))}{images.length > 1 && <button
                  className={styles.buttonRight}
                  onClick={e => {
                    e.stopPropagation();
                    nextSlide();
                  }}
              >
                <p className={styles.chevron}>›</p>
              </button>}
              {images.length > 1 && (
                <div className={styles.sliderPointContainer}>
                  {images.map((element, index) => {
                    return (
                      <div
                        style={
                          index === indexImg
                            ? {
                                borderColor: '#F4753A',
                                backgroundColor: '#F4753A',
                              }
                            : {}
                        }
                        key={index}
                        className={styles.circleContainer}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
