import React from 'react';
import cx from 'classnames';
import styles from './AdminBidsPage.module.scss';
import dayjs from 'dayjs';
import {
  IBid,
  IMainFiltersBids,
  ISubFiltersBids,
  tabs,
  bidTabs,
  flightsStatuses,
  performersStatuses,
  IValueFieldsCreateExecutor,
  IInfoDetail,
  infoDetailName,
  infoDetailType,
  IParametersRerouting,
} from './types';
import ShowMoreTable from '../AdminOrdersPage/components/ShowMoreTable/ShowMoreTable';
import { StopRedSvg } from 'src/components/UI/svg-icon/StopRedSvg';
import { SuccessGreenSvgNew } from 'src/components/UI/svg-icon/SuccessGreenSvgNew';
import ClockTimeThreeOutlineSvg from 'src/components/UI/svg-icon/ClockTimeThreeOutlineSvg';
import { declinationWord } from 'src/common/declinationWord.helper';
import { ArrowRightSvg } from 'src/components/UI/svg-icon/ArrowRightSvg';
import { Progress } from 'antd';
import { IOrderSortBox } from '../AdminOrdersPage/type';
import { ISelectValue } from '../AdminTariffsPage/types';
import CustomTooltip from '../../../components/newUI/CustomTooltip/CustomTooltip';
import InfoSvg from '../../../components/UI/svg-icon/InfoSvg';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';

export const colorMessageExecutor = {
  true: 'var(--color-green700)',
  false: 'var(--color-red600)',
  null: 'var(--color-gray900)',
};
export const keysForDisableSaveButtonExecutor = [`carrierId`, `quarryId`, `distance`, `volume`];
export const initStateValueFieldsCreateExecutor: IValueFieldsCreateExecutor = {
  material: '',
  quarryId: '',
  remainBid: '',
  carrierId: '',
  percent: '',
  volume: '',
  distance: '',
  cost: '',
  freeCars: '',
  message: '',
  pass: null,
  unit: '',
};
export const orderSortBidsOptions: IOrderSortBox[] = [
  { value: 'newFirst', label: 'Сначала новые' },
  { value: 'oldFirst', label: 'Сначала старые' },
  { value: 'increasingPrice', label: 'По возрастанию суммы' },
  { value: 'decreasingPrice', label: 'По убыванию суммы' },
];
export const statusBidsOptions: ISelectValue[] = [
  { value: 'Активные', label: 'Активные' },
  { value: 'Завершенные', label: 'Завершенные' },
];
export const initStateMainFiltersBids: IMainFiltersBids = {
  searchString: '',
  sort: null,
};
export const initStateSubFiltersBids: ISubFiltersBids = {
  dateFrom: '',
  dateTo: '',
  orderId: '',
  managerId: '',
  materialTypeId: '',
  status: statusBidsOptions[0]?.value,
};

export enum bidsStatuses {
  active = 'Активная',
  new = 'Новая',
  rejected = 'Отменена',
  await = 'Ожидает',
  done = 'Выполнена',
}
export const mainBidsTabs: tabs[] = Object.values(tabs);
export const currentBidTabs: bidTabs[] = Object.values(bidTabs);

export const bidsStatusesSvg = (data: IBid) => {
  return {
    [bidsStatuses.active]: <ShowMoreTable type="bids" bidId={data?.id} />,
    [bidsStatuses.new]: <ShowMoreTable type="bids" bidId={data?.id} />,
    [bidsStatuses.rejected]: <StopRedSvg />,
    [bidsStatuses.await]: <ClockTimeThreeOutlineSvg color="var(--color-orange400)" size="20px" />,
    [bidsStatuses.done]: <SuccessGreenSvgNew />,
  };
};

export const bidsFlightStatusColors = {
  [flightsStatuses.new]: 'var(--color-green600)',
  [flightsStatuses.approved]: 'var(--color-gray600)',
  [flightsStatuses.active]: 'var(--color-green600)',
  [flightsStatuses.rejected]: 'var(--color-red600)',
  [flightsStatuses.cancelled]: 'var(--color-gray600)',
  [flightsStatuses.await]: 'var(--color-orange400)',
};

export const bidsPerformersStatusColors = {
  [performersStatuses.new]: '',
  [performersStatuses.approved]: 'var(--color-red600)',
  [performersStatuses.active]: 'var(--color-red600)',
  [performersStatuses.rejected]: 'var(--color-gray600)',
  [performersStatuses.cancelled]: 'var(--color-gray600)',
  [performersStatuses.await]: 'var(--color-green600)',
};

export const dataTableBids = [
  {
    title: 'Номер заявки',
    dataIndex: 'id',
    width: 80,
    key: 'id',
    render: text => (
      <div style={{ marginLeft: '24px' }} className={styles.columnAndBorder}>
        <p className={styles.tableTextTypeMaterial} style={{ width: '80px' }}>
          {text}
        </p>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Дата доставки',
    dataIndex: 'deliveryDate',
    width: 80,
    key: 'deliveryDate',
    render: text => (
      <div className={styles.columnAndBorder}>
        <p className={styles.tableText} style={{ width: '80px' }}>
          {text ? dayjs(text).format('DD.MM.YYYY') : 'Нет данных'}
        </p>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Материал',
    dataIndex: 'material',
    width: 300,
    key: 'material',
    render: text => (
      <div className={styles.columnAndBorder}>
        <p className={cx(styles.tableText, styles.materialText)} style={{ width: '300px' }}>
          {text}
        </p>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Карьер',
    dataIndex: 'quarry',
    width: 160,
    key: 'quarry',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div style={{ width: '160px' }}>
          <p className={styles.tableText} style={{ textWrap: 'wrap' }}>
            {text || '-'}
          </p>
        </div>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Расстояние',
    dataIndex: 'distance',
    width: 80,
    key: 'distance',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '80px' }}>
          <p className={styles.tableText}>{text || 0} км</p>
        </div>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Перевозка',
    dataIndex: 'carrierCount',
    width: 144,
    key: 'carrierCount',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '144px' }}>
          <div className={styles.shippingContainer}>
            <p className={cx(styles.tableText, styles.shippingText)}>
              {`${text || 0} ${declinationWord(text || 0, ['перевозчик', 'перевозчика', 'перевозчиков'])}`}
            </p>
            <ArrowRightSvg size="24px" />
          </div>
        </div>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Объем заказа (факт/план)',
    dataIndex: 'volume',
    width: 200,
    key: 'volume',
    render: (text, data) => (
      <div className={styles.columnAndBorder}>
        <div className={styles.volumeContainer} style={{ width: '126px' }}>
          <div className={styles.progressContent}>
            <p className={styles.orangeText}>{`${text?.factVolume} ${text?.unit}`}</p>
            <p className={styles.grayText}>/</p>
            <p className={styles.grayText}>{`${text?.planVolume} ${text?.unit}`}</p>
          </div>
          <div className={styles.progressContainer}>
            <Progress
              size={['100%', 4]}
              strokeColor={'var(--color-orange400)'}
              percent={(text?.factVolume * 100) / text?.planVolume}
              showInfo={false}
            />
          </div>
          <p className={styles.percentText}>{((text?.factVolume * 100) / text?.planVolume).toFixed(0)}%</p>
        </div>
        <div className={styles.mediumBorder} />
        <div className={styles.columnContainer} style={{ width: '24px', marginRight: '24px' }}>
          {data?.status?.title && bidsStatusesSvg(data)[data?.status?.title]}
        </div>
      </div>
    ),
  },
];

export const dataTableFlights = [
  {
    title: 'Рейс',
    dataIndex: 'id',
    width: 80,
    key: 'id',
    render: (text, data) => (
      <div
        style={{
          marginLeft: '24px',
          opacity: (data?.status === flightsStatuses.cancelled || data?.status === flightsStatuses.rejected) && 0.6,
        }}
        className={styles.columnAndBorder}
      >
        <div className={styles.columnContainer} style={{ width: '80px' }}>
          <p className={styles.tableText}>{`№ ${text}`}</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Карьер',
    dataIndex: 'quarry',
    width: 250,
    key: 'quarry',
    render: (text, data) => (
      <div
        className={styles.columnAndBorder}
        style={{
          opacity: (data?.status === flightsStatuses.cancelled || data?.status === flightsStatuses.rejected) && 0.6,
        }}
      >
        <div className={cx(styles.columnContainer, styles.twoRows)}>
          <p className={styles.tableText} style={{ width: '250px' }}>
            {text?.title || 'Нет данных'}
          </p>
          <p className={cx(styles.tableText, styles.secondaryText)} style={{ width: '250px' }}>
            {`ИНН: ${data?.supplier?.inn}` || 'Нет данных'}
          </p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Перевозчик',
    dataIndex: 'carrier',
    width: 250,
    key: 'carrier',
    render: (text, data) => (
      <div
        className={styles.columnAndBorder}
        style={{
          opacity: (data?.status === flightsStatuses.cancelled || data?.status === flightsStatuses.rejected) && 0.6,
        }}
      >
        <div className={cx(styles.columnContainer, styles.twoRows)}>
          <p className={styles.tableText} style={{ width: '250px' }}>
            {text?.title || 'Нет данных'}
          </p>
          <p className={cx(styles.tableText, styles.secondaryText)} style={{ width: '250px' }}>
            {`ИНН: ${text?.inn}` || 'Нет данных'}
          </p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Водитель и ТС',
    dataIndex: 'driver',
    width: 250,
    key: 'driver',
    render: (text, data) => (
      <div
        className={styles.columnAndBorder}
        style={{
          opacity: (data?.status === flightsStatuses.cancelled || data?.status === flightsStatuses.rejected) && 0.6,
        }}
      >
        <div className={cx(styles.columnContainer, styles.twoRows)}>
          <p className={styles.tableText} style={{ width: '250px' }}>
            {data?.car?.stateNumber || 'Нет данных'}
          </p>
          <p className={styles.tableText} style={{ width: '250px' }}>
            {`${text?.fullName}` || 'Нет данных'}
          </p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Объем',
    dataIndex: 'volume',
    width: 180,
    key: 'volume',
    render: (text, data) => (
      <div
        className={styles.columnAndBorder}
        style={{
          opacity: (data?.status === flightsStatuses.cancelled || data?.status === flightsStatuses.rejected) && 0.6,
        }}
      >
        <div className={styles.columnContainer} style={{ width: '180px' }}>
          {`${text} ${data?.unit}`}
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Статус рейса',
    dataIndex: 'status',
    width: 144,
    key: 'status',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '144px', marginRight: '24px' }}>
          <p
            className={styles.statusContainer}
            style={{
              color: bidsFlightStatusColors[text] || bidsFlightStatusColors[flightsStatuses.new],
              border: `1px solid ${bidsFlightStatusColors[text] || bidsFlightStatusColors[flightsStatuses.new]}`,
            }}
          >
            {text}
          </p>
        </div>
      </div>
    ),
  },
];

export const customTimePickerButton = (
  isLeft: boolean,
  setOpenLeftTimer: React.Dispatch<boolean>,
  setOpenRightTimer: React.Dispatch<boolean>,
) => {
  const closeTimer = () => {
    if (isLeft) {
      setOpenLeftTimer(false);
    } else {
      setOpenRightTimer(false);
    }
  };

  return (
    <button
      onClick={e => {
        e.stopPropagation();
        closeTimer();
      }}
      className={styles.customRangePickerBtnChoose}
    >
      Готово
    </button>
  );
};
export const mockTableData = [
  {
    bid: 12309,
    dateDelivery: '09.10.2024',
    client: {
      client: 'ООО НТК "СОЛБЕР"',
      inn: 7713461582,
    },
    material: {
      material: 'Щебень гравийный',
      quarry: 'ВИТА карьер',
    },
    volume: '1000 тн',
    manager: 'Иванов Петр Сергеевич',
    status: 'Ожидает',
    cancelled: true,
  },
  {
    bid: 12309,
    dateDelivery: '09.10.2024',
    client: {
      client: 'ООО НТК "СОЛБЕР"',
      inn: 7713461582,
    },
    material: {
      material: 'Щебень гравийный',
      quarry: 'ВИТА карьер',
    },
    volume: '1000 тн',
    manager: 'Иванов Петр Сергеевич',
    status: 'Новая',
    cancelled: false,
  },
  {
    bid: 12309,
    dateDelivery: '09.10.2024',
    client: {
      client: 'ООО НТК "СОЛБЕР"',
      inn: 7713461582,
    },
    material: {
      material: 'Щебень гравийный',
      quarry: 'ВИТА карьер',
    },
    volume: '1000 тн',
    manager: 'Иванов Петр Сергеевич',
    status: 'Отменена',
    cancelled: true,
  },
  {
    bid: 12309,
    dateDelivery: '09.10.2024',
    client: {
      client: 'ООО НТК "СОЛБЕР"',
      inn: 7713461582,
    },
    material: {
      material: 'Щебень гравийный',
      quarry: 'ВИТА карьер',
    },
    volume: '1000 тн',
    manager: 'Иванов Петр Сергеевич',
    status: 'Активная',
    cancelled: false,
  },
];
const colorStatusTableBids = {
  Ожидает: 'var(--color-orange400)',
  Новая: 'var(--color-green600)',
  Активная: 'var(--color-green600)',
  Отменена: 'var(--color-gray600)',
};
export const columnsTableBids = [
  {
    title: 'Заявка',
    dataIndex: 'id',
    width: 74,
    key: 'id',
    render: (text, data) => (
      <div
        style={{ marginLeft: '24px', opacity: data?.status?.title === 'Отменена' && '0.6' }}
        className={styles.columnAndBorder}
      >
        <p className={styles.tableText} style={{ width: '74px' }}>
          № {text}
        </p>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Дата доставки',
    dataIndex: 'dateFrom',
    width: 120,
    key: 'dateFrom',
    render: (text, data) => (
      <div style={{ opacity: data?.status?.title === 'Отменена' && '0.6' }} className={styles.columnAndBorder}>
        <p className={styles.tableText} style={{ width: '120px' }}>
          {text ? dayjs(text).format('DD.MM.YYYY') : 'Нет данных'}
        </p>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    width: 200,
    key: 'client',
    render: (text, data) => (
      <div style={{ opacity: data?.status?.title === 'Отменена' && '0.6' }} className={styles.columnAndBorder}>
        <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '200px' }}>
          <p className={styles.tableText} style={{ width: '200px' }}>
            {text?.title || 'Нет данных'}
          </p>
          <p className={cx(styles.tableText, styles.secondaryText)} style={{ width: '200px' }}>
            ИНН: {text?.inn || 'Нет данных'}
          </p>
        </div>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Материал',
    dataIndex: 'material',
    width: 195,
    key: 'material',
    render: (text, data) => (
      <div style={{ opacity: data?.status?.title === 'Отменена' && '0.6' }} className={styles.columnAndBorder}>
        <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '195px' }}>
          <p className={styles.tableText}>{text || 'Нет данных'}</p>
          <p className={cx(styles.tableText, styles.secondaryText)} style={{ width: '195px' }}>
            {data?.quarry?.title || 'Нет данных'}
          </p>
        </div>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Объем',
    dataIndex: 'volume',
    width: 80,
    key: 'volume',
    render: (text, data) => (
      <div style={{ opacity: data?.status?.title === 'Отменена' && '0.6' }} className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '80px' }}>
          <p className={styles.tableText}>{`${text} ${data?.unit}`}</p>
        </div>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Менеджер',
    dataIndex: 'manager',
    width: 195,
    key: 'manager',
    render: (text, data) => (
      <div style={{ opacity: data?.status?.title === 'Отменена' && '0.6' }} className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '195px' }}>
          <p className={styles.tableText}>{text?.title || 'Нет данных'}</p>
        </div>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    width: 190,
    key: 'status',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '173px' }}>
          <div style={{ color: colorStatusTableBids[text?.title] }} className={styles.status}>
            {text?.title}
            {text?.title === 'Ожидает' && (
              <CustomTooltip
                widthSvg={'16px'}
                SideTooltip={'left'}
                svg={<InfoSvg color={colorStatusTableBids[text?.title]} />}
                text={'По данной заявке есть нераспределенные рейсы'}
                widthTooltip={'212px'}
                positionTooltipRight={'-13px'}
                positionTooltipBottom={'23px'}
              />
            )}
          </div>
        </div>
      </div>
    ),
  },
];
export const mockDataFlightRerouting = [
  {
    flight: 'Рейс 7810',
    client: {
      client: 'ООО "РУССКИЙ ЩЕБЕНЬ"33 333 333333 3333333',
      quarry: 'ВИТА Карьер',
    },
    carrier: 'ООО “Ибрагимович”',
    driver: {
      fullName: 'Мухин Сергей Николаевич',
      vehicle: 'Вольво К128УМ750',
    },
    volume: '45 тн',
    material: 'Щебень гравийный; гр:мытый/сеяный; фр:5-20; марка дроб.:М 800-1000; насып. пл.:1,35-1,45',
  },
];
export const columnsTableRerouting = [
  {
    title: 'Рейс',
    dataIndex: 'flight',
    width: 70,
    key: 'flight',
    render: text => (
      <div className={styles.columnAndBorder}>
        <p className={styles.tableTextUnfilled} style={{ width: '70px', marginLeft: '24px' }}>
          {text}
        </p>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Карьер',
    dataIndex: 'client',
    width: 256,
    key: 'client',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={cx(styles.columnUnfilledContainer, styles.twoRows)} style={{ width: '256px' }}>
          <p className={styles.tableTextUnfilled}>{text.client}</p>
          <p className={styles.tableTextUnfilled}>{text.quarry}</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Перевозчик',
    dataIndex: 'carrier',
    width: 256,
    key: 'carrier',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnUnfilledContainer} style={{ width: '256px' }}>
          <p className={styles.tableTextUnfilled}>{text}</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Водитель и ТС',
    dataIndex: 'driver',
    width: 256,
    key: 'driver',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={cx(styles.columnUnfilledContainer, styles.twoRows)} style={{ width: '256px' }}>
          <p className={styles.tableTextUnfilled}>{text.fullName}</p>
          <p className={styles.tableTextUnfilled}>{text.vehicle}</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Объем',
    dataIndex: 'volume',
    width: 80,
    key: 'volume',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnUnfilledContainer} style={{ width: '80px' }}>
          <p className={styles.tableTextUnfilled}>{text}</p>
        </div>
        <div className={styles.smallBorder} />
      </div>
    ),
  },
  {
    title: 'Материал',
    dataIndex: 'material',
    width: 256,
    key: 'material',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnUnfilledContainer} style={{ width: '256px' }}>
          <p className={styles.tableTextUnfilledTwoString}>{text ? text : 'Нет данных'}</p>
        </div>
      </div>
    ),
  },
];

export const initStateInfoDetail: IInfoDetail[] = [
  {
    name: infoDetailName.ORDER,
    label: 'Заказ',
    value: '123098',
    type: infoDetailType.ORDER_BID,
  },
  {
    name: infoDetailName.BID,
    label: 'Заявка',
    value: '123098',
    type: infoDetailType.ORDER_BID,
  },
  {
    name: infoDetailName.DATE_ORDER,
    label: 'Дата заказа',
    value: '17.02.2025',
    type: infoDetailType.NORMAL,
  },
  {
    name: infoDetailName.SHIPPER,
    label: 'Грузоотправитель',
    value: 'ООО "Неруд Центр"',
    type: infoDetailType.NORMAL,
  },
  {
    name: infoDetailName.QUARRY,
    label: 'Карьер',
    value: 'ООО "РУССКИЙ ЩЕБЕНЬ"',
    type: infoDetailType.NORMAL,
  },
  {
    name: infoDetailName.SHIPMENT,
    label: 'Погрузка',
    value: 'Юрьев-Польский',
    type: infoDetailType.NORMAL,
  },
  {
    name: infoDetailName.WORK_SCHEDULE,
    label: 'График работы',
    value: 'Круглосуточный',
    type: infoDetailType.NORMAL,
  },
  {
    name: infoDetailName.DISPATCHER,
    label: 'Диспетчер',
    value: '79206209856',
    type: infoDetailType.NUMBER,
  },
];

export const textCancelOrderAndBid = {
  order: 'Отменен',
  bid: 'Отменена',
};

export const initStateParametersRouting: IParametersRerouting = {
  client: '',
  material: '',
  kindMaterial: '',
};
export const mockDataBidsListRerouting = [
  {
    id: '12309',
    dateDelivery: '01.10.2024',
    client: {
      client: 'ООО «Кубис Транс»',
      inn: '7713461582',
    },
    material: {
      material: 'Щебень гравийный',
      quarry: 'ВИТА карьер',
    },
    manager: 'Иванов Петр Сергеевич',
  },
];
export const columnsDataBidsListRerouting = [
  {
    title: 'Заявка',
    dataIndex: 'id',
    width: 80,
    key: 'id',
    render: text => (
      <div style={{ marginLeft: '24px' }} className={styles.columnAndBorder}>
        <p className={styles.tableText} style={{ width: '80px' }}>
          № {text}
        </p>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Дата доставки',
    dataIndex: 'dateDelivery',
    width: 120,
    key: 'dateDelivery',
    render: text => (
      <div className={styles.columnAndBorder}>
        <p className={styles.tableText} style={{ width: '120px' }}>
          {text}
        </p>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Клиент',
    dataIndex: 'client',
    width: 262,
    key: 'client',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '262px' }}>
          <p className={styles.tableText}>{text?.client}</p>
          <p className={cx(styles.tableText, styles.secondaryText)}>ИНН: {text?.inn}</p>
        </div>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Материал',
    dataIndex: 'material',
    width: 262,
    key: 'material',
    render: text => (
      <div style={{ width: '262px' }} className={styles.columnAndBorder}>
        <div className={cx(styles.columnContainer, styles.twoRows)}>
          <p className={styles.tableText}>{text?.material}</p>
          <p className={cx(styles.tableText, styles.secondaryText)}>{text?.quarry}</p>
        </div>
        <div className={styles.mediumBorder} />
      </div>
    ),
  },
  {
    title: 'Менеджер',
    dataIndex: 'manager',
    width: 384,
    key: 'manager',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '384px' }}>
          <div className={styles.oneLineTable}>
            <p style={{ width: '262px' }} className={styles.tableText}>
              {text ? text : 'Нет данных'}
            </p>
            <div onClick={e => e.stopPropagation()}>
              <ButtonFilled sizeText={'small'} text={'Выбрать'} width={'106px'} />
            </div>
          </div>
        </div>
      </div>
    ),
  },
];
