import React from 'react';

export const RadioBoxCircle = ({ id, value, name, check, isSmall = false, disabled = false, ...props }) => {
  return (
    <div className={'radioBoxCircle'}>
      <label
        className={`radioBoxCircle__label ${isSmall && 'radioBoxCircle__smallLabel'} ${check ? 'active' : ''}`}
        htmlFor={id}
      >
        <input className={'radioBoxCircle__input'} {...props} id={id} type={'radio'} value={value} name={name} />
        <div
          className={`radioBoxCircle__circle ${isSmall && 'radioBoxCircle__small'} ${disabled && 'radioBoxCircle__circle__disabled'} ${check ? 'active' : ''}`}
        >
          <span></span>
        </div>
        {value}
      </label>
    </div>
  );
};
