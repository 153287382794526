import { instanceApi } from '../../../../globalConstans';
import { IRequestCreateMaterialObject } from '../../type';
import { IMaterialGroupData } from '../types';

export const getMaterials = async () => {
  return await instanceApi.get(`/api/public/materials/types`);
};
export const getMaterialProperties = async (id: number) => {
  return await instanceApi.get(`/api/public/materials/properties/${id}`);
};
export const postCreateMaterial = async (data: IRequestCreateMaterialObject) => {
  return await instanceApi.post(`/api/supplier/materials`, data);
};
export const patchEditMaterial = async (reqObj: { id: string; data: IRequestCreateMaterialObject }) => {
  return await instanceApi.patch(`/api/supplier/materials/${reqObj.id}`, reqObj.data);
};
export const getMaterialsGroupProperties = async (data: IMaterialGroupData) => {
  return await instanceApi.get(`/api/supplier/materials`, { params: data });
};
export const getMaterialsForEdit = async (id: number) => {
  return await instanceApi.get(`/api/supplier/materials/${id}`);
};
export const deleteMaterial = async (id: number) => {
  return await instanceApi.delete(`/api/supplier/materials/${id}`);
};
