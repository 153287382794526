import React from 'react';

export const FlightSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="road-outline" clipPath="url(#clip0_19673_34570)">
        <g id="Vector">
          <path d="M20 4H18V20H20V4Z" fill="currentColor" />
          <path d="M6 4H4V20H6V4Z" fill="currentColor" />
          <path d="M13 4H11V8H13V4Z" fill="currentColor" />
          <path d="M13 10H11V14H13V10Z" fill="currentColor" />
          <path d="M13 16H11V20H13V16Z" fill="currentColor" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_19673_34570">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

/*
<svg width="100%" height="100%" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0H14V20H16V0Z" fill="currentColor"/>
    <path d="M2 0H0V20H2V0Z" fill="currentColor"/>
    <path d="M9 0H7V5H9V0Z" fill="currentColor"/>
    <path d="M9 7.5H7V12.5H9V7.5Z" fill="currentColor"/>
    <path d="M9 15H7V20H9V15Z" fill="currentColor"/>
</svg>*/
