import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header/Header';
import { Container } from '../components/Container';
import { useMediaQuery } from 'react-responsive';
import { ButtonClose } from '../components/UI/buttons/buttonClose';
import { Context } from '../context/context';
import NewAsideNavDriverMobile from '../components/asideNav/NewAsideNavDriverMobile';
import NewAsideNavDriver from '../components/asideNav/NewAsideNavDriver';
import FooterLk from '../components/newUI/FooterLk/FooterLk';
import MobileTour from '../components/asideNav/components/MobileTour/MobileTour';
import Banner from '../components/Banner/Banner';
import { typeBanner } from '../components/Banner/type';

export const LayoutDriver = () => {
  const { openDescription, setOpenDescription, helpTitle, helpDescription, startTour } = useContext(Context);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <>
      {!isMobile && <Header />}
      <Banner type={typeBanner.MAIN} />
      {isMobile ? (
        startTour ? (
          <div>
            <MobileTour />
          </div>
        ) : (
          <div id="Page" className="Page">
            <div className={openDescription ? 'Page__wrapper blur' : 'Page__wrapper'}>
              <Outlet />
            </div>
            <NewAsideNavDriverMobile />
            <div
              onClick={() => setOpenDescription(false)}
              className={openDescription ? 'PopUpHelp active' : 'PopUpHelp'}
            ></div>
            <div className={openDescription ? 'PopUpHelp__content active' : 'PopUpHelp__content'}>
              <ButtonClose
                onClick={() => setOpenDescription(false)}
                hidden={openDescription ? 'active' : 'hidden'}
                style={{ zIndex: '1200', position: 'absolute', top: '6px', right: '20px' }}
              />
              <div className="PopUpHelp__title">{helpTitle}</div>
              <div className="PopUpHelp__description">{helpDescription}</div>
            </div>

            <FooterLk />
          </div>
        )
      ) : (
        <div className="Page">
          <Container>
            <div className={openDescription ? 'Page__wrapper blur' : 'Page__wrapper'}>
              <div className="Aside">
                <NewAsideNavDriver />
              </div>
              <div className="content">
                <Outlet />
              </div>
            </div>
          </Container>
          <div
            onClick={() => setOpenDescription(false)}
            className={openDescription ? 'PopUpHelp active' : 'PopUpHelp'}
          ></div>
          <div className={openDescription ? 'PopUpHelp__content active' : 'PopUpHelp__content'}>
            <ButtonClose
              onClick={() => setOpenDescription(false)}
              hidden={openDescription ? 'active' : 'hidden'}
              style={{ zIndex: '1200', position: 'absolute', top: '64px', left: '-112px' }}
            />
            <div className="PopUpHelp__title">{helpTitle}</div>
            <div className="PopUpHelp__description">{helpDescription}</div>
          </div>

          <FooterLk />
        </div>
      )}
    </>
  );
};
