import React, { useCallback, useState } from 'react';
import styles from './OptionFieldOrder.module.scss';
import SelectLk from '../../../../../components/newUI/SelectLk/SelectLk';
import { InputText } from '../../../../../components/UI/inputs/InputText';
import TextArea from 'antd/es/input/TextArea';
import { ICreateOrderProperties, orderPropertyName } from '../../type';
import cx from 'classnames';
import { RadioBoxCircle } from '../../../../../components/UI/checkBox/RadioBoxCircle';
import { TimePicker } from 'antd';
import { selectCreateOrderFields } from '../../OrderSlice';
import { useAppSelector } from '../../../../../CustomHooks/storeHooks';
import { MyPhoneInput } from '../../../../../components/UI/inputs/MyPhoneInput';
import dayjs from 'dayjs';

export const OptionFieldOrder = ({
  properties,
  changeValue,
}: {
  properties: ICreateOrderProperties[];
  changeValue: ({ name, value }: { name: string; value: any }) => void;
  calculationId: string;
}) => {
  const [openLeftTimer, setOpenLeftTimer] = useState(false);
  const [openRightTimer, setOpenRightTimer] = useState(false);

  const [phoneError, setPhoneError] = useState<boolean>(false);
  const createOrderFields = useAppSelector(selectCreateOrderFields);
  const isDisableTimer = createOrderFields.find(item => item.name === 'workSchedule')?.value.value === 'Круглосуточная';
  const handleValidateAndChangeInputField = useCallback(
    (value: string, name: string, fieldMask: string) => {
      if (fieldMask) {
        (new RegExp(fieldMask).test(value) || !value) && changeValue({ name, value });
        return;
      }
      changeValue({ name, value });
    },
    [changeValue],
  );
  const handleChangeValue = useCallback(
    (value: any, name: string) => {
      changeValue({ name, value });
    },
    [changeValue],
  );
  const customTimePickerButton = (isLeft: boolean) => {
    const closeTimer = () => {
      if (isLeft) {
        setOpenLeftTimer(false);
      } else {
        setOpenRightTimer(false);
      }
    };

    return (
      <button onClick={closeTimer} className={styles.customRangePickerBtnСhoose}>
        Готово
      </button>
    );
  };
  return (
    <div>
      <div className={styles.materialFieldsContainer}>
        {properties?.map((infoField: ICreateOrderProperties) => {
          return (
            <div key={infoField?.id} className={styles.fieldContainer}>
              {infoField.fieldType === 'select' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <p className={styles.materialTypeTitle}>
                    {infoField.title}
                    {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                  </p>
                  <SelectLk
                    disabled={infoField?.isDisabled}
                    isSearch={infoField.isSearch}
                    value={infoField.value}
                    heightSelectWindow={'195px'}
                    options={infoField?.properties || []}
                    placeholder={infoField?.placeholder || ''}
                    setValue={value => {
                      handleChangeValue(value, infoField?.name);
                    }}
                    isClearable={!infoField?.isRequired}
                  />
                </div>
              )}
              {infoField.fieldType === 'time' && (
                <div className={styles.optionTimeContainer}>
                  <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                    <p className={styles.materialTypeTitle}>
                      {infoField.title}
                      {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                    </p>
                    <div className={'orders-page-popup-box__time'} style={{ width: infoField.width }}>
                      <TimePicker
                        value={infoField?.value ? dayjs(infoField?.value, 'HH:mm') : null}
                        showNow={false}
                        needConfirm={false}
                        placeholder={'Период времени'}
                        format={'HH:mm'}
                        onChange={(time, timeString) => {
                          handleChangeValue(timeString, infoField?.name);
                        }}
                        disabled={isDisableTimer}
                        open={
                          infoField?.name === orderPropertyName?.workScheduleTimeRight ? openRightTimer : openLeftTimer
                        }
                        onClick={() => {
                          infoField?.name === orderPropertyName?.workScheduleTimeRight
                            ? setOpenRightTimer(true)
                            : setOpenLeftTimer(true);
                        }}
                        renderExtraFooter={() =>
                          customTimePickerButton(infoField?.name !== orderPropertyName?.workScheduleTimeRight)
                        }
                      />
                    </div>
                  </div>
                  {infoField?.name === orderPropertyName?.workScheduleTimeLeft && (
                    <p className={styles.timeFormWord}>до</p>
                  )}
                </div>
              )}
              {infoField.fieldType === 'radio' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <p className={styles.materialTypeTitle}>
                    {infoField.title}
                    {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                  </p>
                  <div className={styles.option}>
                    {infoField?.properties?.length &&
                      infoField?.properties.map((radioItem, index) => {
                        return (
                          <RadioBoxCircle
                            key={infoField?.id + '_' + index}
                            id={infoField?.id + '_' + index}
                            value={radioItem.label}
                            name={'optionFieldRadio'}
                            check={infoField?.value?.label === radioItem?.label}
                            onInput={event => {
                              if (infoField.isDisabled) return;
                              const value = event.target.value;
                              handleChangeValue({ value, label: value }, infoField?.name);
                            }}
                            isSmall={true}
                            disabled={infoField.isDisabled}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
              {infoField.fieldType === 'phone' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <p className={styles.materialTypeTitle}>
                    {infoField.title}
                    {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                  </p>
                  <MyPhoneInput
                    value={infoField.value}
                    placeholder={infoField?.placeholder}
                    disabled={infoField?.isDisabled}
                    onChange={value => {
                      handleChangeValue(value, infoField?.name);
                    }}
                    isActive={undefined}
                    btnHelp={undefined}
                    setIsActive={undefined}
                    isError={false}
                    isFunction={undefined}
                    isLabel={false}
                    setIsError={setPhoneError}
                  />
                </div>
              )}
              {infoField.fieldType === 'input' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <div className={styles.addressTitle}>
                    <p className={styles.materialTypeTitle}>
                      {infoField.title}
                      {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                    </p>
                  </div>
                  <InputText
                    isLabel={true}
                    error={false}
                    type={''}
                    value={infoField.value}
                    textError={''}
                    placeholder={infoField?.placeholder || ''}
                    setValue={value => {
                      handleValidateAndChangeInputField(value, infoField?.name, infoField.fieldMask);
                    }}
                    disabled={infoField.isDisabled}
                    helpText={''}
                  />
                </div>
              )}
              {infoField.fieldType === 'textArea' && (
                <div className={styles.option} style={{ width: `${infoField?.width}` }}>
                  <p className={styles.materialTypeTitle}>
                    {infoField.title}
                    {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                  </p>
                  <TextArea
                    value={infoField.value as string}
                    className={styles.descriptionMaterialField}
                    maxLength={1000}
                    placeholder={infoField?.placeholder || ''}
                    onChange={e => {
                      handleChangeValue(e.target.value, infoField?.name);
                    }}
                    style={{ resize: 'none' }}
                    disabled={infoField.isDisabled}
                    rows={3}
                  />
                </div>
              )}
              {infoField.fieldType === 'text' && (
                <div className={cx(styles.option, styles.textOption)} style={{ width: `${infoField?.width}` }}>
                  <p className={styles.materialTypeTitle}>
                    {infoField.title}
                    {infoField.isRequired && <span style={{ color: 'var(--color-red600)' }}> *</span>}
                  </p>
                  <div className={styles.fieldText}>{infoField?.value || 'Нет данных'}</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
