import React, { Dispatch, SetStateAction, useState } from 'react';
import styles from './MaterialTypes.module.scss';
import { IMaterialType } from '../../../type';
import { useAppDispatch, useAppSelector } from '../../../../../CustomHooks/storeHooks';
import { getCurrentMaterialProperties, selectMaterialTypes } from '../../../services/material/materialSlice';
import { useMediaQuery } from 'react-responsive';
import SelectLk from '../../../../../components/newUI/SelectLk/SelectLk';
interface IProps {
  activeMaterialType: number;
  edit: boolean;
  setActiveMaterialType: Dispatch<SetStateAction<number>>;
}
const MaterialTypes = ({ activeMaterialType, setActiveMaterialType, edit = false }: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const dispatch = useAppDispatch();
  const materialTypes = useAppSelector(selectMaterialTypes);
  const materialTypesOptions = materialTypes.map(type => {
    return {
      value: type.id,
      label: type.title,
    };
  });
  const handelSelectMaterialType = materialTypeId => {
    if (!edit) {
      dispatch(getCurrentMaterialProperties(materialTypeId));
      setActiveMaterialType(materialTypeId);
    }
  };
  return (
    <div>
      {!isMobile && <p className={styles.materialTypeTitleGroup}>Выберите породу материала</p>}
      {isMobile ? (
        <SelectLk
          disabled={edit}
          isSearch={false}
          heightSelectWindow={'195px'}
          options={materialTypesOptions}
          value={materialTypesOptions.find(item => Number(activeMaterialType) === item?.value)?.value}
          placeholder={'Выберите из списка'}
          setValue={value => {
            handelSelectMaterialType(Number(value));
          }}
        />
      ) : (
        <div className={styles.materialTypesContainer}>
          {materialTypes.map((materialType: IMaterialType) => (
            <div
              onClick={() => {
                handelSelectMaterialType(materialType.id);
              }}
              className={
                edit && activeMaterialType !== materialType.id
                  ? styles.materialTypeContainerDisable
                  : styles.materialTypeContainer
              }
              key={materialType.id}
            >
              <div
                className={
                  activeMaterialType === materialType.id ? styles.materialTypeImgActive : styles.materialTypeImgBorder
                }
              >
                <img src={materialType.imageUrl} alt="фото типа материала" />
              </div>
              <span
                className={
                  activeMaterialType === materialType.id ? styles.materialTypeTitleActive : styles.materialTypeTitle
                }
              >
                {materialType.title}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MaterialTypes;
