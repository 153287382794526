import React, { useEffect, useState } from 'react';
import { ButtonTransparent } from './UI/buttons/ButtonTransparent';
import { CustomSelect } from './UI/select/CustomSelect';
import { InputText } from './UI/inputs/InputText';
import { useMediaQuery } from 'react-responsive';
import { closePopUp, openPopUp } from '../common/open-close-popup.function';
import { ProfilePageUploading } from './ProfilePageUploading';
import { ImageContainer } from './ImageContainer';
import { ButtonClose } from './UI/buttons/buttonClose';
import { removeImage } from '../common/removeImage';
import { carNumber } from '../common/regular-car-number';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import { OwnershipOwnSvg } from './UI/svg-icon/OwnershipOwnSvg';
import { OwnershipLeasingSvg } from './UI/svg-icon/OwnershipLeasingSvg';
import { OwnershipButton } from './OwnershipButton';
import LoadingGif from '../assets/img/Loader-Solber.gif';
import ButtonFilled from './newUI/ButtonFilled/ButtonFilled';
import { CloseCircleNewSvg } from './UI/svg-icon/CloseCircleNewSvg';
import { CheckCircleSvg } from './UI/svg-icon/CheckCircleSvg';
import { toast } from 'react-toastify';
export const CarPagePopup = ({
  setCar,
  car,
  error,
  typeCars,
  brandCars,
  disabled,
  sendDataCars,
  popUpActive,
  setPopUpActive,
  setUploadImagesStsNumber,
  uploadImagesStsNumber,
  successfulFormSubmission,
  setUploadImagesOwnership,
  uploadImagesOwnership,
  ownershipList,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const InitialCar = {
    valueTypeTC: '',
    valueBrandTC: '',
    stsNumber: '',
    stateNumber: '',
    valueOwnership: '',
  };
  const [image, setImage] = useState('');
  const [popUpActiveImg, setPopUpActiveImg] = useState(false);
  const [isCarNumberChecked, setIsCarNumberChecked] = useState<Boolean>(false); //Проверка автокода выполнена
  const [isLoadingCarInfo, setIsLoadingCarInfo] = useState<Boolean>(false);
  const [isCarInfoPageActive, setisCarInfoPageActive] = useState<Boolean>(false); //Флаг страницы ввода информации об авто
  const [isCheckingAutoCodeSuccessful, setisCheckingAutoCodeSuccessful] = useState<Boolean>(true); //Успешность проверки автокода
  const [checkedOwnershipValue, setCheckedOwnershipValue] = useState<string>(''); //Значение типа владения ТС (собственный или наемный)
  useEffect(() => {
    if (!popUpActive) {
      clearFields();
      setisCarInfoPageActive(false);
      setCheckedOwnershipValue('');
    }
  }, [popUpActive]);

  useEffect(() => {
    clearFields();
  }, [isCarInfoPageActive]);

  const getValidSTSNumber = value => {
    const regex = /^([0-9]|[А-Я])*$/;
    if (regex.test(value) && value.length < 11) {
      setCar(prevState => ({ ...prevState, stsNumber: value }));
    }
  };
  const getValidVolumeBody = value => {
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      setCar(prevState => ({ ...prevState, volumeBody: Number(value) <= 70 ? value : 70 }));
    }
  };
  const openPopupWithImg = src => {
    setImage(src);
    setPopUpActiveImg(openPopUp);
  };
  const getCarInfo = async () => {
    setIsLoadingCarInfo(true);
    try {
      const carInfo = await fetchGet('/cars/get-info', { stateNumber: car.stateNumber });
      if (carInfo.error) {
        carInfo.message.map(el => toast.error(el));
        setisCheckingAutoCodeSuccessful(false);
      }
      if (carInfo?.response) {
        setCar(prevState => ({
          ...prevState,
          valueBrandTC: carInfo.response.brand.brand,
          valueTypeTC: carInfo.response.type.type,
          valueOwnership: carInfo.response.isLeasing ? 'Лизинг' : 'Собственность',
        }));
        toast.success(`Номер ТС ${car.stateNumber} успешно найден в базе данных.`);
      }
    } catch (e) {
      console.log(e);
    }
    setIsCarNumberChecked(true);
    setIsLoadingCarInfo(false);
  };

  const clearFields = () => {
    setUploadImagesOwnership([]);
    setUploadImagesStsNumber([]);
    setCar(InitialCar);
    setisCheckingAutoCodeSuccessful(true);
    setIsCarNumberChecked(false);
  };
  const checkCarNumber = () => {
    getCarInfo();
  };

  const changeOwnership = (e, value: string) => {
    e.preventDefault();
    setCheckedOwnershipValue(value);
  };

  const changeCheckedOwnership = () => {
    setisCarInfoPageActive(!isCarInfoPageActive);
  };

  return (
    popUpActive && (
      <div className={popUpActive ? 'CarPagePopup active' : 'CarPagePopup'}>
        <div onClick={() => setPopUpActive(closePopUp)} className="CarPagePopup__white"></div>
        {isMobile && <div className="CarPagePopup__line"></div>}
        {successfulFormSubmission ? (
          <div className="CarPagePopup__wrapper">
            <div className="CarPagePopup-successful">
              <div className="CarPagePopup__title">
                Добавление ТС
                <div onClick={() => setPopUpActive(closePopUp)} className="CarPagePopup__close"></div>
              </div>
              <div className="CarPagePopup-successful__content">
                {error ? (
                  <div className="CarPagePopup-successful__svgBox CarPagePopup-successful__svgBox_red">
                    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11 7H9V5H11M11 15H9V9H11M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="CarPagePopup-successful__svgBox">
                    <div className="CarPagePopup-successful__svg">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 2.00009L6 14.0001L0.5 8.50009L1.91 7.09009L6 11.1701L16.59 0.590088L18 2.00009Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                {error ? (
                  <div className="CarPagePopup-successful__title">При добавлении ТС произошла ошибка</div>
                ) : (
                  <div className="CarPagePopup-successful__title">ТС успешно добавлено</div>
                )}
                {!error && <div className="CarPagePopup-successful__subTitle">Оно появится в общем списке ТС</div>}
              </div>
            </div>
            <ButtonTransparent
              modifier={''}
              onClick={() => setPopUpActive(closePopUp)}
              children={'Закрыть окно'}
              color={'gray'}
              svg={null}
              disabled={false}
              isSvg={false}
            />
          </div>
        ) : (
          <div className="CarPagePopup__wrapper">
            <div>
              <div style={{ marginBottom: '40px' }}>
                <div className="CarPagePopup__title">
                  {isMobile ? 'Добавление ТС' : 'Добавление транспортного средства'}
                  <div onClick={() => setPopUpActive(closePopUp)} className="CarPagePopup__close"></div>
                </div>
                <div className="CarPagePopup__content">
                  {isCarInfoPageActive ? (
                    /* Основная страница */
                    <div className="CarPagePopup__box">
                      {!isCheckingAutoCodeSuccessful && (
                        <div className="CarPagePopup__infoRequest">
                          К сожалению, автомобиль не прошел проверку. Введите данные вручную. После отправки, автомобиль
                          проверит служба безопасности
                        </div>
                      )}
                      <div className="CarPagePopup__inputItem">
                        <div className="CarPagePopup__check-container">
                          <InputText
                            type={''}
                            style={{ textTransform: 'uppercase' }}
                            placeholder={'Номер ТС'}
                            textError={'Поле обязательное для заполнения'}
                            helpText={''}
                            widthHelp={0}
                            value={car.stateNumber}
                            setValue={value =>
                              carNumber(value, value => setCar(prevState => ({ ...prevState, stateNumber: value })))
                            }
                            isLabel={true}
                            error={false}
                            secondError={false}
                            fill={false}
                            margin={''}
                            disabled={false}
                            width={'100%'}
                            additionalSvg={
                              isCarNumberChecked &&
                              (isCheckingAutoCodeSuccessful ? (
                                <CheckCircleSvg width="24px" color="#31986D" />
                              ) : (
                                <CloseCircleNewSvg width="24px" color="#DC2626" />
                              ))
                            }
                          />
                          {checkedOwnershipValue === 'own' && (
                            <ButtonTransparent
                              children={'Проверить'}
                              disabled={isLoadingCarInfo || isCarNumberChecked || car.stateNumber.length < 8}
                              isSvg={false}
                              svg={null}
                              color={'blue'}
                              modifier={isMobile ? 'check-mobile' : 'check'}
                              onClick={checkCarNumber}
                            />
                          )}
                        </div>
                      </div>
                      {!isLoadingCarInfo ? (
                        <>
                          {checkedOwnershipValue === 'own' && isCheckingAutoCodeSuccessful ? (
                            <>
                              <div className="CarPagePopup__inputItem">
                                <InputText
                                  type={''}
                                  defaultValue={''}
                                  error={false}
                                  placeholder={'Марка ТС'}
                                  value={car.valueBrandTC}
                                  setValue={value => value}
                                  disabled={checkedOwnershipValue === 'own'}
                                  isLabel={true}
                                  secondError={false}
                                  fill={false}
                                  textError={''}
                                  margin={''}
                                  helpText={''}
                                  widthHelp={0}
                                />
                              </div>

                              <div className="CarPagePopup__inputItem">
                                <InputText
                                  type={''}
                                  defaultValue={''}
                                  error={false}
                                  placeholder={'Вид ТС'}
                                  value={car.valueTypeTC}
                                  setValue={value => value}
                                  disabled={checkedOwnershipValue === 'own'}
                                  isLabel={true}
                                  secondError={false}
                                  fill={false}
                                  textError={''}
                                  margin={''}
                                  helpText={''}
                                  widthHelp={0}
                                />
                              </div>
                              <div className="CarPagePopup__inputItem">
                                <InputText
                                  type={''}
                                  defaultValue={''}
                                  placeholder={'Владение ТС'}
                                  value={car.valueOwnership}
                                  error={false}
                                  setValue={value => value}
                                  disabled={true}
                                  isLabel={true}
                                  secondError={false}
                                  fill={false}
                                  textError={''}
                                  margin={''}
                                  helpText={''}
                                  widthHelp={0}
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="CarPagePopup__inputItem">
                                <CustomSelect
                                  defaultValue={car.valueBrandTC?.value && car.valueBrandTC}
                                  error={false}
                                  options={brandCars}
                                  placeholder={'Марка ТС'}
                                  noOptionsMessage={'Нет вариантов'}
                                  setValue={value => setCar(prevState => ({ ...prevState, valueBrandTC: value }))}
                                  isSearchable={true}
                                  isClearable={true}
                                />
                              </div>

                              <div className="CarPagePopup__inputItem">
                                <CustomSelect
                                  defaultValue={car.valueTypeTC?.value && car.valueTypeTC}
                                  error={false}
                                  options={typeCars}
                                  placeholder={'Вид ТС'}
                                  noOptionsMessage={'Нет вариантов'}
                                  setValue={value => setCar(prevState => ({ ...prevState, valueTypeTC: value }))}
                                  isSearchable={false}
                                  isClearable={true}
                                />
                              </div>
                              {checkedOwnershipValue === 'hired' ? (
                                <div className="CarPagePopup__inputItem">
                                  <InputText
                                    type={''}
                                    defaultValue={''}
                                    error={false}
                                    placeholder={'Владение ТС'}
                                    value={'Наемный'}
                                    setValue={value => value}
                                    disabled={true}
                                    isLabel={true}
                                    secondError={false}
                                    fill={false}
                                    textError={''}
                                    margin={''}
                                    helpText={''}
                                    widthHelp={0}
                                  />
                                </div>
                              ) : (
                                <div className="CarPagePopup__inputItem">
                                  <CustomSelect
                                    defaultValue={car.valueOwnership?.value && car.valueOwnership}
                                    error={false}
                                    options={ownershipList}
                                    placeholder={'Владение ТС'}
                                    noOptionsMessage={'Нет вариантов'}
                                    setValue={value => setCar(prevState => ({ ...prevState, valueOwnership: value }))}
                                    isSearchable={false}
                                    isClearable={true}
                                  />
                                </div>
                              )}
                            </>
                          )}
                          {(car?.valueOwnership === 'Лизинг' || car?.valueOwnership?.label === 'Лизинг') && (
                            <div className="CarPagePopup__inputItem">
                              <ProfilePageUploading
                                name={'Договор лизинга'}
                                desc={'Файл договора лизинга'}
                                setImages={setUploadImagesOwnership}
                                images={uploadImagesOwnership}
                                count={1}
                              />
                              {uploadImagesOwnership.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                                <div className="image-container" key={id}>
                                  <ImageContainer
                                    id={id}
                                    value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                                    name={name}
                                    size={size}
                                    isSize={isSize}
                                    date={date}
                                    isDate={isDate}
                                    type={type}
                                    openPopUp={() =>
                                      (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)
                                    }
                                  >
                                    <ButtonClose
                                      id={id}
                                      onClick={({ target }) => {
                                        removeImage(target, uploadImagesOwnership, setUploadImagesOwnership);
                                      }}
                                      hidden=""
                                    />
                                  </ImageContainer>
                                </div>
                              ))}
                            </div>
                          )}
                          <div className="CarPagePopup__inputItem">
                            <InputText
                              bgError={true}
                              fill={false}
                              placeholder={'Объем кузова, м³'}
                              margin={''}
                              textError={''}
                              error={false}
                              value={car.volumeBody}
                              type={'text'}
                              helpText={'Максимальное значение до 70 м³'}
                              widthHelp={'260px'}
                              setValue={value => getValidVolumeBody(value)}
                              isLabel={true}
                              disabled={false}
                            />
                          </div>
                          <div className="CarPagePopup__inputItem">
                            <InputText
                              maxLength={10}
                              type={'text'}
                              disabled={false}
                              isLabel={true}
                              value={car.stsNumber}
                              setValue={value => getValidSTSNumber(value.toUpperCase())}
                              margin=""
                              error={false}
                              helpText={''}
                              widthHelp={0}
                              textError={''}
                              placeholder="Серия и номер CТС"
                            />
                          </div>
                          <div>
                            <ProfilePageUploading
                              name={'СТС транспортного средства'}
                              desc={'Лицевая и оборотная сторона'}
                              setImages={setUploadImagesStsNumber}
                              images={uploadImagesStsNumber}
                              count={2}
                            />
                            {uploadImagesStsNumber.map(({ id, value, name, size, isSize, date, isDate, type }) => (
                              <div className="image-container" key={id}>
                                <ImageContainer
                                  id={id}
                                  value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
                                  name={name}
                                  size={size}
                                  isSize={isSize}
                                  date={date}
                                  isDate={isDate}
                                  type={type}
                                  openPopUp={() =>
                                    (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)
                                  }
                                >
                                  <ButtonClose
                                    id={id}
                                    onClick={({ target }) => {
                                      removeImage(target, uploadImagesStsNumber, setUploadImagesStsNumber);
                                    }}
                                    hidden=""
                                  />
                                </ImageContainer>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="CarPagePopup__content">
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '485px ',
                              }}
                            >
                              <div>
                                <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
                              </div>
                              <div className="CarPagePopup__loader">Подождите завершения проверки номера ТС</div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    /* Выбор типа собственности */
                    <div className="CarPagePopup__box">
                      <div
                        className="CarPagePopup__subTitle"
                        style={isMobile ? { margin: '0 0 32px' } : { margin: '0 0 40px' }}
                      >
                        Выберите тип владения ТС
                      </div>
                      <div
                        className={`CarPagePopup__check-container ${isMobile ? 'CarPagePopup__check-container__mobile' : ''}`}
                        style={isMobile ? {} : { gap: '24px' }}
                      >
                        <OwnershipButton
                          left={true}
                          right={false}
                          isSvg={true}
                          svg={<OwnershipOwnSvg color={checkedOwnershipValue === 'own' ? '#FF641D' : '#6C779C'} />}
                          children="Собственный"
                          disabled={false}
                          sending={false}
                          color={checkedOwnershipValue === 'own' ? 'orange' : 'gray'}
                          modifier={''}
                          svgModifier={'svg-own'}
                          fontColor={checkedOwnershipValue === 'own' ? '#FF641D' : '#373D50'}
                          onClick={e => changeOwnership(e, 'own')}
                        />
                        <OwnershipButton
                          left={true}
                          right={false}
                          isSvg={true}
                          svg={
                            <OwnershipLeasingSvg color={checkedOwnershipValue === 'hired' ? '#FF641D' : '#6C779C'} />
                          }
                          children="Наемный"
                          disabled={false}
                          sending={false}
                          color={checkedOwnershipValue === 'hired' ? 'orange' : 'gray'}
                          modifier={''}
                          svgModifier={'svg-leasing'}
                          fontColor={checkedOwnershipValue === 'hired' ? '#FF641D' : '#373D50'}
                          onClick={e => changeOwnership(e, 'hired')}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* Кнопки */}
              {isCarInfoPageActive ? (
                !isLoadingCarInfo && (
                  <div className={`CarPagePopup__check-container`} style={isMobile ? {} : { gap: '24px' }}>
                    <ButtonTransparent
                      children={'Назад'}
                      disabled={false}
                      isSvg={false}
                      svg={null}
                      color={'blue'}
                      onClick={changeCheckedOwnership}
                      modifier={isMobile ? 'small' : ''}
                    />
                    <ButtonFilled
                      onClick={() => sendDataCars(checkedOwnershipValue, isCheckingAutoCodeSuccessful)}
                      text={'Сохранить'}
                      svg={null}
                      disabled={disabled}
                      width="100%"
                      height="48px"
                    />
                  </div>
                )
              ) : (
                <ButtonFilled
                  onClick={changeCheckedOwnership}
                  svg={null}
                  disabled={checkedOwnershipValue === ''}
                  text="Далее"
                  width="100%"
                  height="48px"
                  sizeText="big"
                />
              )}
            </div>
            <div className={popUpActiveImg ? 'Popup active' : 'Popup'}>
              <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__white"></div>
              <div className="Popup__wrapper">
                <div>
                  <div>
                    <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__close"></div>
                    <div className="Popup__content">
                      <div className="Popup__box">
                        <img src={image} alt={'Транспортная накладная'} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};
