import React, { useContext } from 'react';
import { IDataResponsePaymentProgress } from '../../type';
import styles from './MobileCard.module.scss';
import cx from 'classnames';
import { statusContentColor } from '../../constans';
import { formPrice } from '../../../../common/form-price.helper';
import NewEmptySvg from '../../../../components/UI/svg-icon/NewEmptySvg';
// @ts-ignore
import LoadingGif from '../../../../assets/img/Loader-Solber.gif';
import { Context } from '../../../../context/context';
interface IProps {
  data: IDataResponsePaymentProgress[];
  loading: boolean;
}
const MobileCardProgress = ({ data, loading }: IProps) => {
  const { user } = useContext(Context);
  const Spin = () => {
    return (
      <div style={{ marginTop: '40px' }}>
        <img style={{ width: '100px' }} src={LoadingGif} alt="Loading..." />
      </div>
    );
  };

  return (
    <>
      {data.length ? (
        <>
          {data.map(item => (
            <div className={styles.container}>
              {user.companyType !== 'buyer' && (
                <div className={styles.statusContainer}>
                  <p className={cx(styles.statusContent, statusContentColor[item.status])}>
                    {item.status ? item.status : '—'}
                  </p>
                </div>
              )}
              <div className={styles.inlineContainer}>
                <p className={styles.thickText}>№ Рейса: {item.flightNumber ? item.flightNumber : '—'}</p>
                <p className={styles.normalText}>
                  {item.flightDate ? item.flightDate?.slice(0, 10).split('-').reverse().join('.') : '—'}
                </p>
              </div>
              <div className={styles.border} />
              {user.companyType !== 'supplier' && (
                <>
                  <div className={styles.soloElemContainer}>
                    <p className={styles.thickText}>№ТН: {item.numberTn ? item.numberTn : '—'}</p>
                  </div>
                  <div className={styles.border} />
                </>
              )}
              <div className={styles.columnToLeftSideContainer}>
                <div className={styles.inlineContainer} style={{ justifyContent: 'normal' }}>
                  {item.stateNumber ? (
                    <>
                      <p className={styles.thickText}>{item.carModel}</p>&nbsp;
                      <p className={styles.thickText}>{item.stateNumber}</p>
                    </>
                  ) : (
                    <p className={styles.thickText}> — </p>
                  )}
                </div>
                <p className={styles.softText}>{item.driver ? item.driver : '—'}</p>
              </div>
              <div className={styles.border} />
              {user.companyType === 'supplier' && (
                <>
                  <div className={styles.columnToLeftSideContainer}>
                    <p className={styles.thickText}>{item.supplier ? item.supplier : '—'}</p>
                    <p className={styles.softText}>{item.quarry ? item.quarry : '—'}</p>
                  </div>
                  <div className={styles.border} />
                </>
              )}
              <div className={styles.columnToLeftSideContainer}>
                <div className={styles.inlineContainer} style={{ justifyContent: 'normal' }}>
                  {item.stateNumber ? (
                    <>
                      <p className={styles.thickText}>{item.volume}</p>&nbsp;
                      <p className={styles.thickText}>{item.unit}</p>
                    </>
                  ) : (
                    <p className={styles.thickText}> — </p>
                  )}
                </div>
                <p className={styles.softText}>{item.nomenclature ? item.nomenclature : '—'}</p>
              </div>
              <div className={styles.border} />
              <div className={styles.inlineContainer}>
                <p className={styles.thickText}>Сумма по сканам:</p>
                <p className={styles.thickText}>{formPrice(item.sumByScan ? Number(item.sumByScan) : 0, 'RUB')}</p>
              </div>
              <div className={styles.inlineContainer}>
                <p className={styles.thickText}>Сумма по оригиналам:</p>
                <p className={styles.thickText}>
                  {formPrice(item.sumByOriginal ? Number(item.sumByOriginal) : 0, 'RUB')}
                </p>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {loading ? (
            <div className={styles.loadingContainer}>
              <div className={styles.loadingContent}>
                <Spin />
              </div>
            </div>
          ) : (
            <>
              <div className={styles.emptySvgContainer}>
                <NewEmptySvg />
              </div>
              <div style={{ marginTop: '-80px' }}>
                <h4 className={'emptyTitle'}>Сформируйте историю расчетов</h4>
                <p className={'emptyDescription'}>Нажмите на нужный вам параметр и/или выберите дату</p>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MobileCardProgress;
