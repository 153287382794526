import React, { useState } from 'react';
import { IFilters } from '../../type';
import GridCardAdd from './components/GridCardAdd/GridCardAdd';
import ListCardAdd from './components/ListCardAdd/ListCardAdd';

interface IProps {
  setOpenModalOfAddGSMCard: React.Dispatch<boolean>;
  filters: IFilters;
  driversWithoutCards: { value: number; label: string }[];
}
const GSMCardAdd = ({ setOpenModalOfAddGSMCard, filters, driversWithoutCards }: IProps) => {
  return (
    <>
      {filters.grid === 'Сетка' ? (
        <GridCardAdd driversWithoutCards={driversWithoutCards} setOpenModalOfAddGSMCard={setOpenModalOfAddGSMCard} />
      ) : (
        <ListCardAdd driversWithoutCards={driversWithoutCards} setOpenModalOfAddGSMCard={setOpenModalOfAddGSMCard} />
      )}
    </>
  );
};

export default GSMCardAdd;
