import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './CancelBid.module.scss';
import SelectLk from '../../../../../components/newUI/SelectLk/SelectLk';
import ButtonNotFilled from '../../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import ButtonFilled from '../../../../../components/newUI/ButtonFilled/ButtonFilled';
import FrameModalSvg from '../../../../../components/UI/svg-icon/FrameModalSvg';
import { useAppDispatch, useAppSelector } from '../../../../../CustomHooks/storeHooks';
import {
  deleteCurrentBid,
  getBids,
  getBidsCancelReasons,
  selectActiveTab,
  selectMaterialInfo,
} from '../../AdminBidsSlice';
import { IOptions } from '../../../AdminTariffsAddPage/type';
import SuccessSvgVer2 from '../../../../../components/UI/svg-icon/SuccessSvgVer2';
import { useLocation } from 'react-router-dom';

interface IProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<boolean>;
  bidId?: number;
}
const CancelBid = ({ openModal, setOpenModal, bidId }: IProps) => {
  const [step, setStep] = useState<'create' | 'success'>('create');
  const [optionsReasons, setOptionsReasons] = useState<IOptions[]>();
  const [reason, setReason] = useState<string>('');

  const { pathname } = useLocation();
  const orderId = pathname.split('/')[2];
  const activeTab = useAppSelector(selectActiveTab);
  const materialInfo = useAppSelector(selectMaterialInfo);
  const dispatch = useAppDispatch();
  const closeModal = () => {
    setOpenModal(false);
  };
  const afterClose = () => {
    setReason('');
    setOptionsReasons(null);
    setStep('create');
    dispatch(
      getBids({
        id: Number(orderId),
        data: {
          status: activeTab,
          materialType: materialInfo?.currentMaterial, // обновляем наши заявки после закрытия модалки
          materialKind: materialInfo.options.find(option => option?.type === materialInfo?.currentMaterial)?.kind,
        },
      }),
    );
  };
  useEffect(() => {
    if (openModal) {
      dispatch(getBidsCancelReasons()).then(data => {
        setOptionsReasons(
          data?.payload?.map(item => {
            return {
              value: item?.id,
              label: item?.title,
            };
          }),
        );
      });
    }
  }, [openModal]);

  return (
    <Modal
      destroyOnClose={true}
      afterClose={afterClose}
      width={464}
      zIndex={9500}
      footer={null}
      closeIcon={false}
      open={openModal}
      onCancel={() => setOpenModal(false)}
      centered
    >
      {step === 'create' && (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.mainBlock}>
              <p className={styles.titleText}>Отменить заявку № {bidId}</p>
              <p className={styles.softText}>
                Выберите причину, по которой вы хотите отменить заявку № <span className={styles.idText}>{bidId}</span>{' '}
                ?
              </p>
              <SelectLk
                placeholder={'Выберите из списка'}
                options={optionsReasons}
                value={reason}
                setValue={setReason}
              />
            </div>
            <div className={styles.buttons}>
              <ButtonNotFilled text={'Нет, вернуться'} onClick={closeModal} />
              <ButtonFilled
                disabled={!reason}
                text={'Да, хочу отказаться'}
                onClick={() => {
                  dispatch(deleteCurrentBid({ id: bidId, data: { deliveryRequestsCancelReasonId: Number(reason) } }))
                    .unwrap()
                    .then(() => {
                      setStep('success');
                    });
                }}
              />
            </div>
          </div>
        </div>
      )}
      {step === 'success' && (
        <div className={styles.successContainer}>
          <div className={styles.successContent}>
            <div className={styles.successSvgContainer}>
              <SuccessSvgVer2 />
            </div>
            <p className={styles.successThickText}>Вы успешно отменили заявку {bidId}</p>
            <p className={styles.successSoftText}>Она исчезнет из списка заявок в течение n-времени</p>
          </div>
          <ButtonFilled width={'100%'} text={'Готово'} onClick={closeModal} />
        </div>
      )}
      <div className={styles.closeButton}>
        <FrameModalSvg onClick={closeModal} />
      </div>
    </Modal>
  );
};

export default CancelBid;
