import React, { useEffect, useState } from 'react';
import styles from './CatalogMaterialsPage.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TFilter, TFilterItem } from '../../catalogTypes';
import { defaultFilter, materialsFractionValues, materialsKindValues } from '../../catalogConstants';
import { formatNumber } from '../../utils';
import { PageWrapper } from 'src/components/PageWrapper';
import TitleMain from 'src/components/UI/TitleAndSubtitle/TitleMain';
import { CatalogFilter } from '../../CatalogFilter/CatalogFilter';
import { SelectCustom } from 'src/components/UI/select/SelectCustom';
import { PriceFilterInput } from '../../components/PriceFilterInput/PriceFilterInput';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import { MaterialItem } from 'src/Pages/ShowcasesPage/components/MaterialCatalog/MaterialItem/MaterialItem';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import { convertNameFromValueMaterial} from '../../../ShowcasesPage/constans';
import { IMaterials } from 'src/Pages/ShowcasesPage/type';
import { clearObject } from 'src/common/ClearObject.helper';
import useDebounce from 'src/CustomHooks/useDebounce';
import { regForNumberWithComma } from 'src/common/number.helper';

export const CatalogMaterialsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { pathname } = useLocation();
  const nameOfMaterial = convertNameFromValueMaterial[pathname.split('/').pop()] || '';
  const [filter, setFilter] = useState<TFilter>(defaultFilter);
  const [sort, setSort] = useState<TFilterItem | ''>({ value: 'new', label: 'Новые' });
  const [isDisabledFilterButton, setIsDisabledFilterButton] = useState<boolean>(true);
  const [combinedFilter, setCombinedFilter] = useState<TFilterItem[]>([]);
  const [materials, setMaterials] = useState<IMaterials[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [materialsKind, setMaterialsKind] = useState<TFilterItem[]>([]);
  const [materialsFraction, setMaterialsFraction] = useState<TFilterItem[]>([]);
  const [isClearedFilters, setIsClearedFilters] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(filter.search, 500);
  const limitForInput = e => (regForNumberWithComma.test(e) || e === '') && Number(e.replace(',', '.')) <= 9999999.99;
  const isEmptyFilters =
    !filter.objectPlace &&
    !filter.materialType.length &&
    !filter.materialFraction.length &&
    !filter.priceRange.to &&
    !filter.priceRange.from;

  useEffect(() => {
    setMaterialsKind(prevState => [...prevState, ...materialsKindValues[nameOfMaterial]]);
    getMaterials(false);
    if (nameOfMaterial === 'Гравий') setMaterialsFraction(materialsFractionValues['Гравий']);
  }, []);

  useEffect(() => {
    setOffset(0);
    setTotalCount(0);
    if (!isEmptyFilters || (sort !== '' && sort?.value !== 'new') || filter.search) {
      setIsClearedFilters(true);
      setMaterials([]);
      getMaterials(false);
      return;
    }
    if (isClearedFilters) {
      setMaterials([]);
      getMaterials(false);
      setIsClearedFilters(false);
    }
  }, [filter.materialFraction, filter.materialType, filter.objectPlace, filter.priceRange, sort, debouncedSearchTerm]);

  useEffect(() => {
    setIsDisabledFilterButton(isEmptyFilters);
    setCombinedFilter([
      ...(!filter.objectPlace ? [] : [filter.objectPlace]),
      ...filter.materialType,
      ...filter.materialFraction,
      ...(filter.priceRange.from || filter.priceRange.to
        ? [
            {
              value: 'price',
              label: `От ${formatNumber(filter.priceRange.from) || '0'} до ${formatNumber(filter.priceRange.to) || '999 999'}`,
            },
          ]
        : []),
    ]);
  }, [filter]);

  useEffect(() => {
    if (nameOfMaterial === 'Гравий') return;
    if (!filter.materialType.length) {
      setMaterialsFraction([]);
      setFilter(prevState => ({ ...prevState, materialFraction: [] }));
      return;
    }
    setMaterialsFraction([]);
    filter.materialType.forEach(kind => {
      if (kind.label === 'Отсев дробления' || kind.label === 'Бетонный' || kind.label === 'Кирпичный бой') {
        setMaterialsFraction(prevState =>
          [...prevState, ...materialsFractionValues[kind.label]].filter(
            (item, index, self) => index === self.findIndex(i => i.value === item.value),
          ),
        );
        return;
      }

      for (const [key, values] of Object.entries(materialsKindValues)) {
        if (values.some(item => item.value === kind.value)) {
          if (key === 'Песок' || key === 'ЩПС') return;
          setMaterialsFraction(prevState =>
            [...prevState, ...materialsFractionValues[key]].filter(
              (item, index, self) => index === self.findIndex(i => i.value === item.value),
            ),
          );
        }
      }
    });
  }, [filter.materialType]);

  const clearFilters = () => {
    setIsClearedFilters(true);
    setOffset(0);
    setTotalCount(0);
    setMaterialsFraction([]);
    setFilter(prevState => ({
      ...prevState,
      objectPlace: null,
      materialType: [],
      materialFraction: [],
      priceRange: { from: '', to: '' },
    }));
  };

  const deleteFilter = (filterLabel: string, filterValue: string) => {
    setFilter(prevFilter => {
      const updatedFilter: TFilter = {
        ...prevFilter,
        objectPlace:
          !prevFilter.objectPlace || prevFilter.objectPlace.label === filterLabel ? null : prevFilter.objectPlace,
        materialType: prevFilter.materialType.filter(item => item.label !== filterLabel),
        materialFraction: prevFilter.materialFraction.filter(item => item.label !== filterLabel),
        priceRange: {
          from: filterValue === 'price' ? '' : prevFilter.priceRange.from,
          to: filterValue === 'price' ? '' : prevFilter.priceRange.to,
        },
      };
      return updatedFilter;
    });

    setCombinedFilter(prevCombinedValues => prevCombinedValues.filter(item => item.label !== filterLabel));
  };

  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 100 &&
        isScroll
      ) {
        setOffset(prevState => prevState + 8);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 100) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (totalCount > offset) {
      getMaterials(true);
    }
  }, [offset]);

  const getMaterials = async (isPagination: boolean) => {
    try {
      const response = await fetchGet(
        '/buyer/catalog',
        clearObject({
          limit: 8,
          offset: isPagination ? offset : 0,
          breed: nameOfMaterial,
          sort: !sort || sort.value === 'new' ? undefined : sort.value,
          fraction: filter.materialFraction.length
            ? filter.materialFraction.reduce((acc, item) => {
                return acc.concat(item.value);
              }, [])
            : undefined,
          kind: filter.materialType.length
            ? filter.materialType.reduce((acc, item) => {
                return acc.concat(item.value);
              }, [])
            : undefined,
          minPrice: !filter.priceRange.from ? undefined : Number(filter.priceRange.from.replace(',', '.')),
          maxPrice: !filter.priceRange.to ? undefined : Number(filter.priceRange.to.replace(',', '.')),
          searchString: filter.search || undefined,
        }),
      );
      setTotalCount(offset + 9);
      if (response?.response?.rows) {
        setMaterials(prevState => prevState.concat(response?.response?.rows));
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageWrapper>
      <Link
        className="button-back"
        to="/catalog"
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="button-back__svg">
          <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
              fill="currentColor"
            />
          </svg>
        </div>
        Назад
      </Link>
      <div className={styles.mainContainer}>
        <div className={styles.container}>
          <div className={styles.titleAndButtonContainer}>
            <TitleMain title={nameOfMaterial} />
          </div>
          <CatalogFilter
            filter={filter}
            setFilter={setFilter}
            sort={sort}
            setSort={setSort}
            clearFilters={clearFilters}
            isDisabledFilterButton={isDisabledFilterButton}
            combinedFilter={combinedFilter}
            deleteFilter={deleteFilter}
          >
            <div className={styles.filtersContainer}>
              <h1 className={styles.filterHeader}>Фильтры</h1>
              <div className={styles.filtersContainer}>
                <div className={styles.filterWithHeader}>
                  <h2 className={styles.filterSubHeader}>Куда доставить</h2>
                  <SelectCustom
                    options={[]}
                    maxTagCount={1}
                    maxCount={1}
                    placeholder={'Выберите объект из списка'}
                    mode={null}
                    value={filter.objectPlace}
                    setValue={value => setFilter(prevState => ({ ...prevState, objectPlace: value }))}
                    disabled={false}
                    status={''}
                    errorText={''}
                  />
                </div>
                <div className={styles.filterWithHeader}>
                  <h2 className={styles.filterSubHeader}>Тип материала</h2>
                  <SelectCustom
                    showSearch={false}
                    options={materialsKind}
                    maxTagCount={1}
                    maxCount={materialsKind.length}
                    placeholder={'Выберите из списка'}
                    mode={'multiple'}
                    value={filter.materialType}
                    setValue={value => setFilter(prevState => ({ ...prevState, materialType: value }))}
                    disabled={nameOfMaterial === 'Гравий' || nameOfMaterial === 'ЩПС'}
                    status={''}
                    errorText={''}
                  />
                </div>
                <div className={styles.filterWithHeader}>
                  <h2 className={styles.filterSubHeader}>Фракция материала</h2>
                  <SelectCustom
                    showSearch={false}
                    options={materialsFraction}
                    maxTagCount={3}
                    maxCount={materialsFraction.length}
                    placeholder={'Выберите из списка'}
                    mode={'multiple'}
                    value={filter.materialFraction}
                    setValue={value => setFilter(prevState => ({ ...prevState, materialFraction: value }))}
                    disabled={
                      nameOfMaterial === 'Гравий' ? false : !filter.materialType.length || !materialsFraction.length
                    }
                    status={''}
                    errorText={''}
                  />
                </div>
                <div className={styles.filterWithHeader}>
                  <h2 className={styles.filterSubHeader}>Диапазон цены, ₽</h2>
                  <div className={styles.priceFilter}>
                    <p>От</p>
                    <PriceFilterInput
                      value={filter.priceRange.from}
                      placeholder={'0'}
                      setValue={value => {
                        if (limitForInput(value)) {
                          setFilter(prevState => ({
                            ...prevState,
                            priceRange: {
                              ...prevState.priceRange,
                              from: value,
                            },
                          }));
                        }
                      }}
                    />
                    <p>до</p>
                    <PriceFilterInput
                      value={filter.priceRange.to}
                      placeholder={'9 999 999,99'}
                      setValue={value => {
                        if (limitForInput(value)) {
                          setFilter(prevState => ({
                            ...prevState,
                            priceRange: {
                              ...prevState.priceRange,
                              to: value,
                            },
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </CatalogFilter>
        </div>
        {loading ? (
          <LoadingSpin />
        ) : (
          <>
            <div className={styles.materialsContainer}>
              {materials?.map(material => (
                <div key={material?.id}>
                  <MaterialItem
                    material={material}
                    link={`/catalog/materials/show/${material?.materialsId[0]}`}
                    sellUnitProps={'тн'}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
};
