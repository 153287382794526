import React, { useEffect, useState } from 'react';
import styles from './ShowcaseCreatePage.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { PageWrapper } from 'src/components/PageWrapper';
import { CreateEditButton } from '../components/CreateEditButton';
import { ShowcaseCover } from '../components/ShowcaseCover/ShowcaseCover';
import { ShowcaseDescription } from '../components/ShowcaseDescription/ShowcaseDescription';
import { ShowcaseMainInfo } from '../components/ShowcaseMainInfo/ShowcaseMainInfo';
import { IShowcaseForRedux, showcaseParameters } from '../services/types';
import { useAppDispatch, useAppSelector } from 'src/CustomHooks/storeHooks';
import {
  addNewShowcase,
  changeQuarriesOptions,
  changeSelectedQuarry,
  clearShowcaseParameters,
  getAllShowcases,
  getQuarriesOptions,
  getSelectedQuarryInfo,
  selectCurrentShowcaseProperties,
  selectQuarryInfo,
} from '../services/showcase/showcaseSlice';
import { IOption } from 'src/Pages/CarsPage/const';
import SelectLk from 'src/components/newUI/SelectLk/SelectLk';

export const ShowcaseCreatePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const showcase: IShowcaseForRedux = useAppSelector(selectCurrentShowcaseProperties);
  const editQuarryInfo: { quarries: IOption[]; currentQuarry: IOption } = useAppSelector(selectQuarryInfo);
  const [errors, setErrors] = useState<{ [showcaseParameters.TITLE]: boolean; [showcaseParameters.QUARRYID]: boolean }>(
    {
      [showcaseParameters.TITLE]: false,
      [showcaseParameters.QUARRYID]: false,
    },
  );
  const quarryType = showcase?.quarry?.quarryType;

  useEffect(() => {
    getQuarriesNames();
    clearShowcase();
  }, []);

  useEffect(() => {
    if (errors[showcaseParameters.TITLE] && showcase?.title)
      setErrors({ ...errors, [showcaseParameters.TITLE]: false });
    if (errors[showcaseParameters.QUARRYID] && showcase?.quarry?.id)
      setErrors({ ...errors, [showcaseParameters.QUARRYID]: false });
  }, [showcase.title, showcase?.quarry?.id]);

  const clearShowcase = () => {
    dispatch(clearShowcaseParameters(''));
  };

  const getQuarriesNames = async () => {
    dispatch(getQuarriesOptions()).then(() => {
      dispatch(changeQuarriesOptions(''));
    });
  };

  const changeCurrentQuarry = async (id: string) => {
    dispatch(getSelectedQuarryInfo(Number(id))).then(data => {
      dispatch(changeSelectedQuarry(data.payload.response));
    });
  };

  const addShowcase = async () => {
    const options = {
      title: showcase?.title,
      description: showcase?.description,
      quarryId: showcase?.quarry?.id,
      header: showcase?.coverImageNew,
      logo: showcase?.logoImageNew,
    };
    dispatch(addNewShowcase(options)).then(res => {
      dispatch(getAllShowcases());
      if (!res?.payload?.message) navigate('/showcases');
    });
  };

  const saveChanges = () => {
    if (!quarryType || !showcase?.title) {
      setErrors({
        [showcaseParameters.QUARRYID]: !quarryType,
        [showcaseParameters.TITLE]: !showcase?.title,
      });
      return;
    }
    addShowcase();
  };

  return (
    <>
      <PageWrapper>
        <Link className="button-back" to="/showcases">
          <div className="button-back__svg">
            <svg width="100%" height="100%" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.3333 5.3333V6.66664H3.33332L6.99998 10.3333L6.05332 11.28L0.773315 5.99997L6.05332 0.719971L6.99998 1.66664L3.33332 5.3333H11.3333Z"
                fill="currentColor"
              />
            </svg>
          </div>
          Вернуться к списку витрин
        </Link>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <div className={styles.titleText}>Создание новой витрины</div>
            <CreateEditButton isCreatePage={true} disabled={false} createShowcase={() => saveChanges()} />
          </div>
          <ShowcaseCover isEdit={true} />
          <ShowcaseDescription error={errors[showcaseParameters.TITLE]} setError={setErrors} />

          <div className={styles.mainInfoContainer}>
            <SelectLk
              options={editQuarryInfo.quarries}
              setValue={value => {
                changeCurrentQuarry(value);
              }}
              placeholder={'Выберите карьер из списка'}
              error={!editQuarryInfo?.currentQuarry?.value}
              value={editQuarryInfo?.currentQuarry?.value}
              heightSelectWindow={'246px'}
            />
            {showcase?.quarry?.id && (
              <div className={styles.mainInfoSubContainer}>
                <div className={styles.mainInfoSubText}>Основная информация</div>
                <ShowcaseMainInfo />
              </div>
            )}
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
