import styles from '../constants.module.scss';
import React from 'react';
import cx from 'classnames';
import { contractorRoles, ISelectValue, ITariff, period } from './types';
import dayjs from 'dayjs';
import { textColorForRemainingDays, isDataExpires, leftDaysToExpire } from './utils';
import { IOptions } from '../AdminTariffsAddPage/type';
import { formPriceOnlyNumber } from '../../../common/form-price.helper';

export const columnsForTableOfTariffs = (showId: number) => {
  return [
    {
      title: 'id',
      dataIndex: 'id',
      width: 100,
      key: 'id',
      render: (text, data: ITariff) => {
        return (
          <div className={styles.columnAndBorder}>
            <div className={cx(styles.columnContainer, styles.firstColumnContainer)} style={{ width: '100px' }}>
              <p className={styles.tableText}>{text || 'Нет данных'}</p>
            </div>
            <div className={styles.smallBorder}></div>
            {text === showId && (
              <div className={styles.tooltipContainer}>
                <div className={styles.idTextTooltip}>id {data?.id}</div>
                {Array.isArray(data?.tariffDetailsInfo) && data.tariffDetailsInfo.length > 0 ? (
                  data.tariffDetailsInfo.map((detail, index, array) => {
                    return (
                      <React.Fragment key={`${index + detail.material}`}>
                        <div className={styles.tooltipContent}>
                          <div className={styles.materialAndQuarryContainerTooltip}>
                            <p className={styles.normalTextTooltip}>
                              {detail.material ? detail.material : 'Нет данных'}
                            </p>
                            {Boolean(detail?.quarryTitle) && (
                              <p className={styles.softTextTooltip}>{detail.quarryTitle}</p>
                            )}
                          </div>
                          <div className={styles.dividerStringTooltip} />
                          <div className={styles.normalTextTooltip}>
                            {detail.cost ? formPriceOnlyNumber(detail.cost, 'RUB', 'ru') + ' ₽' : 'Нет данных'}&nbsp;
                            <span>/ {detail.unit}</span>
                          </div>
                        </div>
                        {array.length > 1 && index !== array.length - 1 && (
                          <div className={styles.dividerMaterialTooltip} />
                        )}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div className={styles.normalTextTooltip}>Нет данных</div>
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Организатор',
      dataIndex: 'organization',
      width: 160,
      key: 'organization',
      render: text => {
        return (
          <div className={styles.columnAndBorder}>
            <div className={styles.columnContainer} style={{ width: '160px' }}>
              <p className={styles.tableText} style={{ maxWidth: '160px' }}>
                {text?.title || 'Нет данных'}
              </p>
            </div>
            <div className={styles.smallBorder}></div>
          </div>
        );
      },
    },
    {
      title: 'Роль',
      dataIndex: 'company',
      width: 100,
      key: 'company',
      render: text => (
        <div className={styles.columnAndBorder}>
          <div className={styles.columnContainer} style={{ width: '100px' }}>
            <p className={styles.tableText} style={{ maxWidth: '100px' }}>
              {contractorRoles[text?.type.toUpperCase()] || 'Нет данных'}
            </p>
          </div>
          <div className={styles.smallBorder}></div>
        </div>
      ),
    },
    {
      title: 'Контрагент',
      dataIndex: 'company',
      width: 227,
      key: 'company',
      render: text => {
        return (
          <div className={styles.columnAndBorder}>
            <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '227px' }}>
              <p className={styles.tableText} style={{ maxWidth: '227px' }}>
                {text?.title || 'Нет данных'}
              </p>
              <p className={cx(styles.tableText, styles.secondaryText)} style={{ maxWidth: '227px' }}>
                {`ИНН: ${text?.inn || 'Нет данных'}`}
              </p>
            </div>
            <div className={styles.smallBorder}></div>
          </div>
        );
      },
    },

    {
      title: 'Период действия',
      dataIndex: 'dateFrom',
      width: 168,
      key: 'dateFrom',
      render: (text, data: ITariff) => (
        <div className={styles.columnAndBorder}>
          <div
            className={cx(
              styles.columnContainer,
              isDataExpires(data?.dateTo, expirationWarningDays).isExpires ? styles.twoRows : '',
            )}
            style={{ width: '168px' }}
          >
            <p
              className={cx(styles.tableText)}
              style={{
                maxWidth: '168px',
                color: textColorForRemainingDays(data?.dateTo, expirationWarningDays),
              }}
            >
              {data?.dateTo
                ? `${convertTime(new Date(text), false)} - ${convertTime(new Date(data?.dateTo), false)}`
                : `${convertTime(new Date(text), false)}`}
            </p>
            {isDataExpires(data?.dateTo, expirationWarningDays)?.isExpires && (
              <p className={cx(styles.tableText, styles.secondaryText)} style={{ maxWidth: '168px' }}>
                {leftDaysToExpire(isDataExpires(data?.dateTo, expirationWarningDays)?.days)}
              </p>
            )}
          </div>
          <div className={styles.smallBorder}></div>
        </div>
      ),
    },
    {
      title: 'Наценка, %',
      dataIndex: 'margin',
      width: 64,
      key: 'margin',
      render: text => (
        <div className={styles.columnAndBorder}>
          <div className={styles.columnContainer} style={{ width: '64px' }}>
            <p className={styles.tableText} style={{ maxWidth: '64px' }}>
              {text || 0}
            </p>
          </div>
          <div className={styles.smallBorder}></div>
        </div>
      ),
    },
    {
      title: 'Последнее изменение',
      dataIndex: 'user',
      width: 227,
      key: 'user',
      render: text => {
        return (
          <div className={styles.columnAndBorder}>
            <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '227px' }}>
              <p className={styles.tableText}>{convertTime(new Date(text?.updatedAt), true) || 'Нет данных'}</p>
              <p className={cx(styles.tableText, styles.secondaryText)} style={{ maxWidth: '227px' }}>
                {text?.fullName || 'Нет данных'}
              </p>
            </div>
          </div>
        );
      },
    },
  ];
};

const convertTime = (time: Date, isWithTime: boolean) => {
  return dayjs(new Date(time.setHours(time.getHours() - 3))).format(`DD.MM.YYYY ${isWithTime ? 'HH:mm' : ''}`);
};

export const tariffsSortOptions: ISelectValue[] = [
  { value: 'new', label: 'Новые' },
  { value: 'old', label: 'Старые' },
];

export const contractorRoleOptions: ISelectValue[] = [
  { value: contractorRoles.CARRIER, label: contractorRoles.CARRIER },
  { value: contractorRoles.SUPPLIER, label: contractorRoles.SUPPLIER },
  { value: contractorRoles.SHIPMENT, label: contractorRoles.SHIPMENT },
];

export const periodOptions: IOptions[] = [
  { value: '0', label: period.ACTIVE },
  { value: '3', label: period.THREEDAYS },
  { value: '10', label: period.TENDAYS },
  { value: '-1', label: period.UNACTIVE },
];

export const initFilterTariffs = {
  organizator: null,
  typeOfContractor: null,
  validityPeriod: null,
  material: null,
  materialType: null,
};

export const initFirstRenderFields = {
  sort: true,
  search: true,
  filter: true,
};

const expirationWarningDays: number = 10;
