import React, { useEffect, useState } from 'react';
import { PageWrapper } from '../../../../components/PageWrapper';
import BackButton from '../../../../components/newUI/BackButton/BackButton';
import { useLocation, useNavigate } from 'react-router-dom';
import TitleMain from '../../../../components/UI/TitleAndSubtitle/TitleMain';
import styles from './AdminOrderIdPage.module.scss';
import SelectStatus from '../../../../components/newUI/SelectStatus/SelectStatus';
import SelectLk from '../../../../components/newUI/SelectLk/SelectLk';
import { ICalculationDetailsInfo, IDataGetListUsersInAdmin } from '../../AdminCalculationSupplies/type';
import {
  dataTableOrderId,
  getImages,
  initChangeableData,
  initDataInfoTable,
  statusesDisableCheckBoxSpecification,
} from '../const';
import TextArea from 'antd/es/input/TextArea';
import { ButtonSimple } from '../../../../components/newUI/ButtonSimple/ButtonSimple';
import FileSendSvg from '../../../../components/UI/svg-icon/FileSendSvg';
import { CheckBoxCustom } from '../../../../components/UI/checkBox/CheckBoxCustom';
import InputFileInOrder from '../components/InputFileInOrder/InputFileInOrder';
import CheckCircleSvgVer2 from '../../../../components/UI/svg-icon/CheckCircleSvgVer2';
import ButtonFilled from '../../../../components/newUI/ButtonFilled/ButtonFilled';
import FileDocumentSvg from '../../../../components/UI/svg-icon/FileDocumentSvg';
import CustomTable from '../../../../components/newUI/CustomTable/CustomTable';
import {
  getCurrentOrder,
  getSpecTemplate,
  loadSpecificationEDO,
  patchCurrentOrder,
  selectCurrentOrder,
  sendFilesFormDataCurrentOrder,
} from '../OrderSlice';
import { useAppDispatch, useAppSelector } from '../../../../CustomHooks/storeHooks';
import { IChangeableData, IDataInfoTable, IDataOrderId } from '../type';
import {
  convertDataFromUsersAdminForOptions,
  getAllUsersInAdmin,
  selectOptionsManagers,
} from '../../AdminCalculationSupplies/AdminCalculationSuppliesSlice';
import { downloadWord, formatDateLocal, updateInputStatesInOrderId } from '../utils';
import LoadingSpin from '../../../../components/newUI/LoadingSpin/LoadingSpin';
import ButtonNotFilled from 'src/components/newUI/ButtonNotFilled/ButtonNotFilled';
import { ProgressLineSum } from '../components/ProgressLineSum/ProgressLineSum';
import { AddSpecification } from '../components/AddSpecification/AddSpecification';
import { toast } from 'react-toastify';

const AdminOrderIdPage = () => {
  const { pathname } = useLocation();
  const orderId: string = pathname.split('/')[2];
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dataOrder: IDataOrderId = useAppSelector(selectCurrentOrder); //вся data заказа
  const [sendDataLoader, setSendDataLoader] = useState<boolean>(false);
  const optionsManagers = useAppSelector(selectOptionsManagers);
  const [dataInfoTable, setDataInfoTable] = useState<IDataInfoTable[]>(initDataInfoTable); // состояние нашей информации в табличке
  const [changeableData, setChangeableData] = useState<IChangeableData>(initChangeableData); //состояние изменяемых полей
  const [loadingPage, setLoadingPage] = useState<boolean>(true); //Загрузка страницы
  const [specificationReceivedFromBack, setSpecificationReceivedFromBack] = useState<boolean>(null);
  const [images, setImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [showUploadImages, setShowUploadImages] = useState([]);
  const [openAddContract, setOpenAddContract] = useState<boolean>(false);
  const checkQuantityImages: boolean = Boolean(images.length + showUploadImages.length);
  const requestOptionsForGetAllManagers: IDataGetListUsersInAdmin = {
    limit: 1000,
    offset: 0,
    type: 'commercialManager',
  };
  const disabledCheckBoxSpecification =
    !statusesDisableCheckBoxSpecification.includes(dataOrder?.status?.title) ||
    sendDataLoader ||
    dataOrder?.specificationReceived;
  const disabledSendEDO =
    Boolean(dataOrder?.specificationPublishDate) || dataOrder?.specificationClientForm || sendDataLoader;
  const visibleInputFileCurrentOrder =
    (dataOrder?.specificationClientForm && specificationReceivedFromBack) || // true - если форма НЕ НАША и спецификая ПОЛУЧЕНА
    (!dataOrder?.specificationClientForm && !dataOrder?.specificationSignedDate) || //true - если форма НАША и спецификация ПОДПИСАНА
    changeableData.specificationReceived;
  const getDataCurrentOrder = async () => {
    try {
      await dispatch(getAllUsersInAdmin(requestOptionsForGetAllManagers)) //Get запрос на менеджеров
        .unwrap()
        .then(data => {
          dispatch(convertDataFromUsersAdminForOptions(data.data)); //Функция конвертации данных с бэка на фронт
        });
      // Get запрос на получение всего заказа
      await dispatch(getCurrentOrder(Number(orderId))).then(({ payload }) => {
        setSpecificationReceivedFromBack(payload.specificationReceived);
        //Функция обновления данных в states (changeableData, dataInfoTable)
        updateInputStatesInOrderId(payload, changeableData, setChangeableData, dataInfoTable, setDataInfoTable);
        getImages(payload, setImages);
      });
    } catch (e) {
      console.error(e);
    } finally {
      setSendDataLoader(false);
      setLoadingPage(false);
    }
  };
  const patchDataCurrentOrder = async () => {
    setSendDataLoader(true); // Дизейблим кнопку сохранить
    try {
      if (changeableData.specificationReceived && !showUploadImages.length && !specificationReceivedFromBack) {
        toast.error('Добавьте файлы');
        return;
      } // Условие неотправки файлов
      await dispatch(sendFilesFormDataCurrentOrder({ uploadImages, id: orderId })); //Функция отправки файлов
      await dispatch(
        //Patch запрос на изменение managerId(manager), status, comment (Данные берем из changeableData)
        patchCurrentOrder({
          data: {
            status: changeableData.status,
            comment: changeableData.comment,
            managerId: Number(changeableData.manager),
            //По нашей форме мы не отправляем на бэк specificationReceived
            ...(dataOrder.specificationClientForm && { specificationReceived: changeableData.specificationReceived }),
          },
          id: Number(orderId),
        }),
      );
      setUploadImages([]); //Зануляем state с images
      setShowUploadImages([]);
      await getDataCurrentOrder();
    } catch (e) {
      console.log(e);
    } finally {
      setSendDataLoader(false);
    }
  };
  const pickCheckSpecificationReceived = (): void => {
    setUploadImages([]); //Зануляем state с images
    setShowUploadImages([]);

    setChangeableData(prevState => ({ ...prevState, specificationReceived: !prevState.specificationReceived }));
  };
  const handleSaveButton = () => {
    patchDataCurrentOrder();
  };
  const handleSendSpecification = () => {
    setSendDataLoader(true); // Дизейблим кнопку сохранить (getDataCurrentOrder - тут раздизейбл)
    if (!dataOrder?.client?.hasIdentificatorEdo) {
      setOpenAddContract(true);
      return;
    }
    dispatch(loadSpecificationEDO({ id: Number(orderId) })).then(() => getDataCurrentOrder());
  };

  useEffect(() => {
    setLoadingPage(true);
    getDataCurrentOrder(); //Получение данных для заказа
  }, []);
  return (
    <PageWrapper>
      {loadingPage ? (
        <LoadingSpin />
      ) : (
        <div className={styles.container}>
          <div className={styles.topContainer}>
            <BackButton textButton={'Вернуться назад'} onClick={() => navigate(-1)} />
            <div className={styles.titleAndStatusContainer}>
              <TitleMain title={`Заказ № ${orderId} от 21.01.2025`} fontSize="24px" />
              <div className={styles.statusAndButtonContainer}>
                <SelectStatus
                  value={changeableData.status}
                  setValue={value => setChangeableData(prevState => ({ ...prevState, status: value }))}
                />
               {/* <ButtonNotFilled
                  width="163px"
                  text="Заявки по заказу"
                  sizeText="small"
                  onClick={() => navigate(`bids`)}
                />*/}
              </div>
            </div>
          </div>
          <div style={{ width: '308px' }} className={styles.titleAndSelectContainer}>
            <p className={styles.normalText}>
              Менеджер <span style={{ color: 'var(--color-red600)' }}>*</span>
            </p>
            <SelectLk
              options={optionsManagers}
              value={changeableData.manager}
              setValue={value => setChangeableData(prevState => ({ ...prevState, manager: value }))}
            />
          </div>
          <div className={styles.infoContainer}>
            {dataInfoTable.map((item: ICalculationDetailsInfo) => (
              <div key={item.name} className={styles.stringContainer}>
                <span className={styles.labelText}>{item.label}</span>
                <div className={styles.borderBetween} />
                <span className={styles.valueText}>{item.value}</span>
              </div>
            ))}
            <ProgressLineSum dataOrder={dataOrder} />
          </div>
          <div className={styles.commentAndFiles}>
            <div className={styles.titleAndSelectContainer}>
              <p className={styles.normalText}>Примечания к заказу</p>
              <TextArea
                value={changeableData.comment}
                className={styles.descriptionMaterialField}
                maxLength={300}
                placeholder={''}
                onChange={e => setChangeableData(prevState => ({ ...prevState, comment: e.target.value }))}
                style={{ resize: 'none' }}
                disabled={false}
                rows={3}
              />
            </div>
            <div className={styles.filesContainer}>
              <div className={styles.buttonAndCheckBoxContainer}>
                <ButtonSimple
                  disabled={disabledSendEDO}
                  svg={<FileSendSvg />}
                  text={'Отправить спецификацию по ЭДО'}
                  onClick={handleSendSpecification}
                />
                <CheckBoxCustom
                  children={'Спецификация по форме клиента'}
                  check={dataOrder?.specificationClientForm}
                  name={'specificationClientForm'}
                  id={'specificationClientForm'}
                  disabled={true}
                />
              </div>
              <div className={styles.infoSendContainer}>
                <p className={styles.textInfoSend}>
                  {dataOrder?.specificationPublishDate === null
                    ? 'Спецификация не отправлена'
                    : `Отправлено ${formatDateLocal(dataOrder?.specificationPublishDate)}`}
                </p>
                <p className={styles.textInfoSend}>
                  {dataOrder?.specificationSignedDate === null
                    ? 'Спецификация не подписана'
                    : `Подписано ${formatDateLocal(dataOrder?.specificationSignedDate)}`}
                </p>
              </div>
              {dataOrder?.specificationClientForm && (
                <div className={styles.getSpecContainer}>
                  <CheckBoxCustom
                    children={'Спецификация получена'}
                    check={changeableData.specificationReceived}
                    pick={pickCheckSpecificationReceived}
                    name={'specificationReceived'}
                    id={'specificationReceived'}
                    disabled={disabledCheckBoxSpecification}
                  />
                </div>
              )}
              {visibleInputFileCurrentOrder && (
                <div className={styles.filesBlockContainer}>
                  <InputFileInOrder
                    dataOrder={dataOrder}
                    titleText={'Прикрепить файл'}
                    subTitleText={'До 3 файлов'}
                    images={images}
                    setImages={setImages}
                    uploadImages={uploadImages}
                    setUploadImages={setUploadImages}
                    showUploadImages={showUploadImages}
                    setShowUploadImages={setShowUploadImages}
                  />
                  {checkQuantityImages && <CheckCircleSvgVer2 />}
                </div>
              )}
            </div>
            <div className={styles.saveButtonContainer}>
              <ButtonFilled
                disabled={sendDataLoader && !changeableData?.manager}
                width={'166px'}
                sizeText={'xs'}
                text={'Сохранить'}
                onClick={handleSaveButton}
              />
            </div>
          </div>
          <div className={styles.tableContainer}>
            <div className={styles.tableTitleContainer}>
              <p className={styles.titleTableText}>Список материалов</p>
              <ButtonSimple
                onClick={() =>
                  dispatch(getSpecTemplate(Number(orderId))).then(data => {
                    downloadWord(data.payload.file, orderId);
                  })
                }
                smallDesktopText={true}
                svg={<FileDocumentSvg width={'16px'} />}
                text={'Шаблон спецификции'}
              />
            </div>
            <div className={styles.customTableContainer}>
              <CustomTable columns={dataTableOrderId} data={dataOrder?.materials} />
            </div>
          </div>
          <AddSpecification
            receiver="Клиент"
            typeOfDocument="Спецификация"
            openAddContract={openAddContract}
            setOpenAddContract={setOpenAddContract}
            getData={getDataCurrentOrder}
            id={Number(dataOrder?.client?.id)}
          />
        </div>
      )}
    </PageWrapper>
  );
};

export default AdminOrderIdPage;
