import { configureStore } from '@reduxjs/toolkit';
import { employeesSlice } from './Pages/EmployeesPage/employeesSlice';
import { materialSlice } from './Pages/ShowcasesPage/services/material/materialSlice';
import { showcaseSlice } from './Pages/ShowcasesPage/services/showcase/showcaseSlice';
import { adminCalculationSlice } from './Pages/AdminPages/AdminCalculationSupplies/AdminCalculationSuppliesSlice';
import { bannerSlice } from './components/Banner/services/BannerSlice';
import { adminOrderSlice } from './Pages/AdminPages/AdminOrdersPage/OrderSlice';
import { adminBidsSlice } from './Pages/AdminPages/AdminBidsPage/AdminBidsSlice';

export const store = configureStore({
  reducer: {
    employees: employeesSlice.reducer,
    adminOrder: adminOrderSlice.reducer,
    adminBids: adminBidsSlice.reducer,
    material: materialSlice.reducer,
    showcase: showcaseSlice.reducer,
    adminCalculation: adminCalculationSlice.reducer,
    banner: bannerSlice.reducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
