import React from 'react';

const TruckV2Svg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
      <path
        d="M10.0393 11.8893L6.9193 11.8693C6.79482 11.3185 6.48758 10.8262 6.0476 10.4723C5.60762 10.1184 5.06082 9.92394 4.49621 9.92047C3.9316 9.91699 3.38244 10.1047 2.93814 10.4531C2.49383 10.8016 2.18055 11.2901 2.0493 11.8393H0.479297C0.359039 11.8366 0.244589 11.787 0.160473 11.701C0.0763572 11.6151 0.0292685 11.4995 0.0292969 11.3793V1.43926C0.0292969 1.18926 0.229297 0.989258 0.479297 0.989258H8.4693C8.7193 0.989258 8.9693 1.18926 8.9693 1.43926V6.93926L9.9793 6.95926L10.0593 4.40926C10.0593 4.14926 10.2593 3.94926 10.5093 3.94926H12.7393C13.2893 3.94926 14.0093 4.30926 14.3393 4.74926L15.3193 6.10926C15.6493 6.55926 15.9193 7.37926 15.9193 7.93926L15.9693 11.4593C15.9693 11.7193 15.7993 11.9193 15.5493 11.9193H14.9193C14.808 11.3534 14.5046 10.8434 14.0603 10.4756C13.616 10.1079 13.0583 9.90509 12.4816 9.90156C11.9049 9.89803 11.3447 10.094 10.8959 10.4563C10.4472 10.8185 10.1375 11.3248 10.0193 11.8893H10.0393ZM12.4793 13.9193C12.0815 13.9193 11.6999 13.7612 11.4186 13.4799C11.1373 13.1986 10.9793 12.8171 10.9793 12.4193C10.9793 12.0214 11.1373 11.6399 11.4186 11.3586C11.6999 11.0773 12.0815 10.9193 12.4793 10.9193C12.8771 10.9193 13.2587 11.0773 13.54 11.3586C13.8213 11.6399 13.9793 12.0214 13.9793 12.4193C13.9793 12.8171 13.8213 13.1986 13.54 13.4799C13.2587 13.7612 12.8771 13.9193 12.4793 13.9193ZM4.4793 13.9193C4.08147 13.9193 3.69994 13.7612 3.41864 13.4799C3.13733 13.1986 2.9793 12.8171 2.9793 12.4193C2.9793 12.0214 3.13733 11.6399 3.41864 11.3586C3.69994 11.0773 4.08147 10.9193 4.4793 10.9193C4.87712 10.9193 5.25865 11.0773 5.53996 11.3586C5.82126 11.6399 5.9793 12.0214 5.9793 12.4193C5.9793 12.8171 5.82126 13.1986 5.53996 13.4799C5.25865 13.7612 4.87712 13.9193 4.4793 13.9193Z"
        fill="#878DA0"
      />
    </svg>
  );
};

export default TruckV2Svg;
