import React, { Dispatch, SetStateAction, useState } from 'react';
import { ButtonClose } from '../../../../../components/UI/buttons/buttonClose';
import styles from './ImageShowContainer.module.scss';
import { closePopUp, openPopUp } from '../../../../../common/open-close-popup.function';
import { SUBSERVER } from '../../../../../common/sub-server.constant';
import { toastError } from '../../../../../common/toastError.helper';
interface IProps {
  files: File[] | any;
  setFiles: any;
  deleteFile: (id: string, name: string, communicationCategory: string) => Promise<void>;
}
const ImageShowContainer = ({ files, setFiles, deleteFile }: IProps) => {
  const [showImg, setShowImg] = useState();
  const [popUpActiveImg, setPopUpActiveImg] = useState(false);
  const openPopupWithImg = (src: any, type: string) => {
    if (type === 'application/pdf') {
      window.open(src, '_blank');
      return;
    }
    setShowImg(src);
    setPopUpActiveImg(openPopUp);
  };

  const removeImage = (indexItem, images, setImages) => {
    setImages(images.filter((_, index) => index !== indexItem));
  };
  return (
    <div>
      <div className={styles.container}>
        {files?.map((file, index) => (
          <div className={styles.fileContainer}>
            <p
              className={styles.fileTitle}
              onClick={() => {
                if (file.location) {
                  openPopupWithImg(file.location, file?.mimeType);
                  return;
                }
                openPopupWithImg(URL.createObjectURL(file), file?.type);
              }}
            >
              {file?.name}
            </p>
            <ButtonClose
              onClick={() => {
                if (file.location) {
                  deleteFile(file.id, file.name, file.communicationCategory);
                }
                removeImage(index, files, setFiles);
              }}
              hidden=""
            />
          </div>
        ))}
      </div>
      <div className={popUpActiveImg ? 'Popup active' : 'Popup'}>
        <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__white"></div>
        <div className="Popup__wrapper">
          <div>
            <div>
              <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__close"></div>
              <div className="Popup__content">
                <div className="Popup__box">
                  <img src={showImg} alt={'Транспортная накладная'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageShowContainer;
