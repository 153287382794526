import dayjs from 'dayjs';
import { declinationWord } from '../../../common/declinationWord.helper';

export const isDataExpires = (dateTime: string, days: number): { isExpires: boolean; days: number } => {
  const date = dayjs(dateTime);
  if (date.isValid()) {
    const currentDate = dayjs().startOf('day').add(3, 'hour');
    const differenceInDays = date.diff(currentDate, 'day') + 1;
    return { isExpires: differenceInDays < days + 1, days: differenceInDays };
  }
  return { isExpires: false, days: undefined };
};

export const leftDaysToExpire = (days: number): string => {
  if (days === 1) return 'Осталось менее суток';
  if (days <= 0) return 'Тариф истек';
  return (
    declinationWord(days, [`Остался `, 'Осталось ', 'Осталось ']) +
    days +
    declinationWord(days, [` день`, ' дня', ' дней'])
  );
};

export const textColorForRemainingDays = (date: string, days: number): string => {
  const leftDays: number = isDataExpires(date, days).days;
  switch (true) {
    case leftDays < 1:
      return 'var(--color-gray600)';
    case leftDays < days + 1:
      return 'var(--color-red600)';
    default:
      return 'var(--color-green700)';
  }
};
