import React, { Dispatch, SetStateAction } from 'react';
import styles from './AdminTariffsFilter.module.scss';
import SelectLk from 'src/components/newUI/SelectLk/SelectLk';
import { contractorRoleOptions } from '../../constants';
import { IFilterTariffs, IMaterialOptions } from '../../types';
import { sizeEnum } from '../../../../../components/newUI/SelectLk/utils';
import { IOptions } from '../../../AdminTariffsAddPage/type';

interface IProps {
  filter: IFilterTariffs;
  setFilter: Dispatch<SetStateAction<IFilterTariffs>>;
  organizationOptions: IOptions[];
  materialOptions: IMaterialOptions[];
  periodOptions: IOptions[];
  materialTypeOptions: IOptions[];
  setMaterialTypeOptions: Dispatch<SetStateAction<IOptions[]>>;
}

export const AdminTariffsFilter = ({
  filter,
  setFilter,
  organizationOptions,
  materialTypeOptions,
  setMaterialTypeOptions,
  materialOptions,
  periodOptions,
}: IProps) => {
  const setType = (value: string): IOptions[] => {
    const materialType: IOptions[] = materialOptions.find(item => item.value === value)?.materialKind;
    return materialType ? materialType : [];
  };

  return (
    <div className={styles.filtersContainer}>
      <h1 className={styles.filterHeader}>Фильтры</h1>
      <div className={styles.filtersContainer}>
        <div className={styles.filterWithHeader}>
          <h2 className={styles.filterSubHeader}>Роль контрагента</h2>
          <SelectLk
            size={sizeEnum.SMALL}
            options={contractorRoleOptions}
            placeholder="Выберите из списка"
            value={filter.typeOfContractor}
            setValue={value => setFilter(prevState => ({ ...prevState, typeOfContractor: value }))}
          />
        </div>
        <div className={styles.filterWithHeader}>
          <h2 className={styles.filterSubHeader}>Срок действия тарифа</h2>
          <SelectLk
            size={sizeEnum.SMALL}
            options={periodOptions}
            placeholder="Выберите из списка"
            value={filter?.validityPeriod}
            setValue={value =>
              setFilter(prevState => ({
                ...prevState,
                validityPeriod: value,
              }))
            }
          />
        </div>
        <div className={styles.filterWithHeader}>
          <h2 className={styles.filterSubHeader}>Тип материала</h2>
          <SelectLk
            size={sizeEnum.SMALL}
            options={materialOptions}
            placeholder="Выберите материал из списка"
            value={filter?.material}
            setValue={value => {
              setFilter(prevState => ({
                ...prevState,
                material: value,
                materialType: '',
              }));
              setMaterialTypeOptions(setType(value));
            }}
          />
        </div>
        <div className={styles.filterWithHeader}>
          <h2 className={styles.filterSubHeader}>Вид материала</h2>
          <SelectLk
            disabled={!filter.material || materialTypeOptions.length <= 0}
            size={sizeEnum.SMALL}
            options={materialTypeOptions}
            placeholder="Выберите материал из списка"
            value={filter?.materialType}
            setValue={value =>
              setFilter(prevState => ({
                ...prevState,
                materialType: value,
              }))
            }
          />
        </div>
        <div className={styles.filterWithHeader}>
          <h2 className={styles.filterSubHeader}>Организатор перевозки</h2>
          <SelectLk
            size={sizeEnum.SMALL}
            options={organizationOptions}
            placeholder="Выберите объект из списка"
            value={filter?.organizator}
            setValue={value =>
              setFilter(prevState => ({
                ...prevState,
                organizator: value,
              }))
            }
          />
        </div>
      </div>
    </div>
  );
};
