import React, { useContext, useRef, useState } from 'react';
import styles from './ShowMore.module.scss';
import { ShowMoreSVG } from 'src/components/UI/svg-icon/ShowMoreSvg';
import useClickOutside from 'src/CustomHooks/useClickOutside';
import { IDataUsers } from '../../types';
import { Context } from 'src/context/context';
import { fetchPost } from 'src/common/proxy-1C-fetch-auth';
import { getMe } from 'src/common/user/getMe';
import { localStorageWrite } from 'src/common/user/local-storage.helper';
import { toastError } from 'src/common/toastError.helper';
import { menuOptions } from '../../constants';

interface TProps {
  dataUsers: IDataUsers;
  changeBlock: (id: number) => void;
}

export const ShowMore = ({ dataUsers, changeBlock }: TProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const { setUser, user } = useContext(Context);
  const enterToUserFromRoot = async () => {
    try {
      const responseEnterToUserFromRoot = await fetchPost('/auth/login/root-by-user', 'POST', {
        userId: dataUsers?.solberId,
      });
      localStorage.setItem('apiKey', responseEnterToUserFromRoot.token);
      await getMe(user, setUser);
      localStorageWrite({
        ...user,
        activeProfile: false,
        phone: dataUsers.phone.replaceAll('+', ''),
        apiKey: responseEnterToUserFromRoot.token,
      });
      setUser({
        ...user,
        phone: dataUsers.phone.replaceAll('+', ''),
        apiKey: responseEnterToUserFromRoot.token,
        activeProfile: false,
      });
      if (toastError(responseEnterToUserFromRoot)) return;
    } catch (e) {
      console.log(e);
    }
  };
  useClickOutside(containerRef, setOpenMenu);

  const blockUser = async () => {
    try {
      const response = await fetchPost('/admin/users/block-status', 'PATCH', {
        userId: dataUsers?.id,
        isBlock: !dataUsers?.block,
      });
      if (toastError(response)) return;
      changeBlock(dataUsers?.id);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.svgContainer} onClick={() => setOpenMenu(!openMenu)}>
        <ShowMoreSVG />
      </div>
      {openMenu && (
        <div className={styles.menu}>
          <div
            className={styles.option}
            onClick={() => {
              enterToUserFromRoot();
            }}
          >
            {menuOptions.AUTH}
          </div>
          <div className={styles.option} onClick={() => blockUser()}>
            {dataUsers?.block ? menuOptions.UNBLOCK : menuOptions.BLOCK}
          </div>
        </div>
      )}
    </div>
  );
};
