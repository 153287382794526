import React from 'react';

export const ShipmentsIconSvgActive = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="file-check-outline">
        <path
          id="Union"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 19C13 20.0929 13.2922 21.1175 13.8027 22H6C4.89 22 4 21.11 4 20V4C4 2.9 4.89 2 6 2H14L20 8V13.083C19.6748 13.0284 19.3407 13 19 13C15.6863 13 13 15.6863 13 19ZM14 8V4L18 8H14ZM18.5 22L23.5 17L22 15.5L18.5 19L16.5 17L15 18.5L18.5 22Z"
          fill="var(--color-orange400)"
        />
      </g>
    </svg>
  );
};

/*
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13 19C13 20.0929 13.2922 21.1175 13.8027 22H6C4.89 22 4 21.11 4 20V4C4 2.9 4.89 2 6 2H14L20 8V13.083C19.6748 13.0284 19.3407 13 19 13C15.6863 13 13 15.6863 13 19ZM14 8V4L18 8H14ZM18.5 22L23.5 17L22 15.5L18.5 19L16.5 17L15 18.5L18.5 22Z" fill="var(--color-orange400)"/>
</svg>*/
