import dayjs from 'dayjs';

export const formatISOToDDMMYYYY = (isoString: string): string => {
  //из "2025-03-05T07:41:23.168Z" в 05.03.2025
  return dayjs(isoString).format('DD.MM.YYYY');
};

export const getTomorrowLocalDate = (): string => {
  //Получаем завтрашний день
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow.toLocaleDateString(); // Установка формата в зависимости от местоположения
};

export const getLocalDate = (plusDay: number): string => {
  //Получаем нужный день
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + plusDay);
  return tomorrow.toLocaleDateString(); // Установка формата в зависимости от местоположения
};
