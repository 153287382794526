import { AsyncThunk, createAsyncThunk, createSlice, UnknownAction, PayloadAction } from '@reduxjs/toolkit';
import {
  IActionCalculation,
  IActionConvertDataFromMaterialsAdminForOptions,
  IDataGetListCalculationInAdmin,
  IBuyersRequest,
  IContractsRequest,
  IDataGetListMaterialsInAdmin,
  IDataGetListUsersInAdmin,
  IInitStateSlicer,
  IMaterialsOptionsForAdmin,
  IMaterialTypeRequest,
  IQuarriesRequest,
  IObjectsRequest,
  IPayloadActionCalculationRows,
  IRowsUsersAdmin,
  INewCalculationRequest,
  ICalculationAddProperties,
  addPropertyName,
  ICreatedCalculationRequest,
  createdPropertyName,
  ICalculationDetails,
  calculationDetailsInfoTitles,
  IPatchDetailsOfCalculation,
  IActionConvertDataFromUsersAdminForOptions,
  IObject,
  ICoordinate,
  IDataGetListOrdersInAdmin,
  IActionConvertDataFromOrdersAdminForOptions,
} from './type';
import {
  getAddress,
  getListCalculationInAdmin,
  getBuyersCompaniesFields,
  getContractsFields,
  getListMaterialsInAdmin,
  getListUsersInAdmin,
  getMaterialTypeFields,
  getMaxBodySizeFields,
  getObjectsFields,
  getQuarriesFields,
  postNewCalculation,
  getCreatedCalculation,
  getDetailsOfCalculation,
  patchDetailsOfCalculation,
  getObjectInfo,
  getClosestWeightBridges,
  getDetailRoute,
  getListOrdersInAdmin,
} from './apiAdminCalculation';
import { toastError } from '../../../common/toastError.helper';
import { RootState } from '../../../store';
import {
  initCalculationAddFields,
  initCalculationCreatedFields,
  initCalculationDetailsInfoFields,
  scheduleProperties,
} from './const';
import { convertDataForCalculationTableByMap } from './utils';
import { validationPhoneNumberFunction } from 'src/common/validation-phone-number.function';
import { declinationWord } from 'src/common/declinationWord.helper';
import { formPriceOnlyNumber } from 'src/common/form-price.helper';
import { clearObject } from 'src/common/ClearObject.helper';
import { formatISOToDDMMYYYY } from '../../../common/date-format.helper';
import { unitFormatBackToFront } from '../../../common/unit-format.helper';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

function isPendingAction(action: UnknownAction): action is PendingAction {
  return typeof action.type === 'string' && action.type.endsWith('/pending');
}
function isRejectedAction(action: UnknownAction): action is RejectedAction {
  return typeof action.type === 'string' && action.type.endsWith('/rejected');
}
function isFulfilledAction(action: UnknownAction): action is FulfilledAction {
  return typeof action.type === 'string' && action.type.endsWith('/fulfilled');
}
export const getAllUsersInAdmin = createAsyncThunk(
  'adminCalculation/getAllUsersInAdmin',
  async (data: IDataGetListUsersInAdmin, { rejectWithValue }) => {
    try {
      const response = await getListUsersInAdmin(data);
      return response;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const getAllMaterialsInAdmin = createAsyncThunk(
  'adminCalculation/getAllMaterialsInAdmin',
  async (data: IDataGetListMaterialsInAdmin, { rejectWithValue }) => {
    try {
      const response = await getListMaterialsInAdmin(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const getAllOrdersInAdmin = createAsyncThunk(
  'adminCalculation/getAllOrdersInAdmin',
  async (data: IDataGetListOrdersInAdmin, { rejectWithValue }) => {
    try {
      const response = await getListOrdersInAdmin(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const getAllCalculationInAdmin = createAsyncThunk(
  'adminCalculation/getAllCalculationInAdmin',
  async (data: IDataGetListCalculationInAdmin, { rejectWithValue }) => {
    try {
      const response = await getListCalculationInAdmin(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getAddressFromCoordinates = createAsyncThunk(
  'calculation/getAddressFromCoordinates',
  async (data: { lat: string; lon: string }, { rejectWithValue }) => {
    const { lat, lon } = data;
    try {
      const response = (await getAddress(lat, lon)).json();
      return response;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getMaterialInfoInAdmin = createAsyncThunk(
  'adminCalculation/getMaterialInfoInAdmin',
  async (data: IMaterialTypeRequest, { rejectWithValue }) => {
    try {
      const response = await getMaterialTypeFields(data.id, data.params);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getMaxBodySize = createAsyncThunk('adminCalculation/getMaxBodySize', async (_, { rejectWithValue }) => {
  try {
    const response = await getMaxBodySizeFields();
    return response.data;
  } catch (e) {
    const errorResponse = JSON.parse(e.response.request.response);
    toastError(errorResponse);
    return rejectWithValue(e);
  }
});

export const getBuyers = createAsyncThunk(
  'adminCalculation/getBuyers',
  async (data: IBuyersRequest, { rejectWithValue }) => {
    try {
      const response = await getBuyersCompaniesFields(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getQuarries = createAsyncThunk(
  'adminCalculation/getQuarries',
  async (data: IQuarriesRequest, { rejectWithValue }) => {
    try {
      const response = await getQuarriesFields(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getContracts = createAsyncThunk(
  'adminCalculation/getContracts',
  async (data: IContractsRequest, { rejectWithValue }) => {
    try {
      const response = await getContractsFields(data);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const getObjects = createAsyncThunk(
  'adminCalculation/getObjects',
  async (data: IObjectsRequest, { rejectWithValue }) => {
    try {
      const response = await getObjectsFields(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getObject = createAsyncThunk('adminCalculation/getObject', async (data: number, { rejectWithValue }) => {
  try {
    const response = await getObjectInfo(data);
    return response.data;
  } catch (e) {
    const errorResponse = JSON.parse(e.response.request.response);
    toastError(errorResponse);
    return rejectWithValue(e);
  }
});

export const createNewCalculation = createAsyncThunk(
  'adminCalculation/createNewCalculation',
  async (data: INewCalculationRequest, { rejectWithValue }) => {
    try {
      const response = await postNewCalculation(data);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getCalculation = createAsyncThunk(
  'adminCalculation/getCalculation',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getCreatedCalculation(id);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const getClosestWeightBridgesCoordinates = createAsyncThunk(
  'adminCalculation/getClosestWeightBridgesCoordinates',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getClosestWeightBridges(id);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);
export const getDetailRouteCoordinate = createAsyncThunk(
  'adminCalculation/getDetailRouteCoordinate',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getDetailRoute(id);
      return response.data;
    } catch (e) {
      const errorResponse = JSON.parse(e.response.request.response);
      toastError(errorResponse);
      return rejectWithValue(e);
    }
  },
);

export const getDetails = createAsyncThunk('adminCalculation/getDetails', async (id: number, { rejectWithValue }) => {
  try {
    const response = await getDetailsOfCalculation(id);
    return response.data;
  } catch (e) {
    const errorResponse = JSON.parse(e.response.request.response);
    toastError(errorResponse);
    return rejectWithValue(e);
  }
});

export const patchDetail = createAsyncThunk(
  'adminCalculation/patchDetail',
  async (
    { data, id }: { data: IPatchDetailsOfCalculation; id: number },
    { rejectWithValue, getState }, // Добавляем getState из thunkAPI
  ) => {
    const state: any = getState(); // Получаем текущее состояние
    const manager = state.adminCalculation.addCalculationOptions[0];
    const callback = state.adminCalculation.addCalculationOptions[1];
    const managerId =
      Number(manager.properties.find(item => item.label === manager.value)?.value) || Number(manager.value);
    const requestData = clearObject({
      managerId: data?.managerId || managerId || undefined, //Ищем id в properties если нет берём из value(из-за бэка)
      callback: data?.callback ? data?.callback : callback.value,
      ...data,
    });
    try {
      const response = await patchDetailsOfCalculation(requestData, id);
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
const initialState: IInitStateSlicer = {
  optionsManager: [],
  optionsMaterials: [],
  optionsOrders: [],
  addCalculationOptions: initCalculationAddFields,
  addCalculationMaterialFields: [],
  calculationDetails: null,
  calculationDetailsInfo: initCalculationDetailsInfoFields,
  checkedCalculationDetailsIndex: 0,
  isChangedCoordinates: false,
  dataTableCalculation: [],
  closestWeightBridgesCoordinates: [],
  detailRouteCoordinate: [],
  loading: false,
  totalCount: 0,
  currentObjectInfo: null,
};
export const adminCalculationSlice = createSlice({
  name: 'adminCalculation',
  initialState,
  reducers: {
    convertDataFromUsersAdminForOptions: (state, action: PayloadAction<IActionConvertDataFromUsersAdminForOptions>) => {
      state.optionsManager = action?.payload?.rows.map((item: IRowsUsersAdmin) => {
        return {
          value: String(item.id),
          label: String(item.fullName),
        };
      });
    },
    convertDataFromMaterialsAdminForOptions: (
      state,
      action: PayloadAction<IActionConvertDataFromMaterialsAdminForOptions[]>,
    ) => {
      state.optionsMaterials = action.payload.map(item => {
        return {
          value: String(item.id),
          label: item.title,
        };
      });
    },
    convertDataFromOrdersAdminForOptions: (
      state,
      action: PayloadAction<IActionConvertDataFromOrdersAdminForOptions[]>,
    ) => {
      state.optionsOrders = action?.payload?.map(item => {
        return {
          value: String(item?.id),
          label: `${item?.id} ${item?.client?.title}`,
        };
      });
    },
    addMaterialTypesCreateCalculations: state => {
      const materialTypeField = state.addCalculationOptions.find(item => item.name === addPropertyName.materialTypeId);
      if (materialTypeField) {
        materialTypeField.properties = state.optionsMaterials.map(item => {
          return { value: item?.id.toString(), label: item?.title };
        });
      }
    },
    addMaterialKindsCreateCalculations: state => {
      const materialTypeValue = state.addCalculationOptions.find(
        item => item.name === addPropertyName.materialTypeId,
      )?.value;
      const materialKindField = state.addCalculationOptions.find(item => item.name === addPropertyName.materialKindId);
      if (materialKindField) {
        materialKindField.properties = state.optionsMaterials
          .find(material => material?.id === Number(materialTypeValue))
          ?.materialKind?.properties?.map(kind => {
            return { value: String(kind?.id), label: kind?.value };
          });
      }
    },
    handleChangeFieldsForObject: state => {
      const latitudeField = state.addCalculationOptions.find(item => item.name === addPropertyName.latitude);
      const longitudeField = state.addCalculationOptions.find(item => item.name === addPropertyName.longitude);
      const semitrailer = state.addCalculationOptions.find(item => item.name === addPropertyName.semitrailerId);
      const workSchedule = state.addCalculationOptions.find(item => item.name === addPropertyName.workSchedule);
      const currentObject = state.currentObjectInfo;

      latitudeField.value = currentObject?.address?.latitude;
      longitudeField.value = currentObject?.address?.longitude;
      semitrailer.value = currentObject?.semitrailerId.toString();
      workSchedule.value = currentObject?.workSchedulesGraph.aroundTheClock
        ? scheduleProperties[0]
        : scheduleProperties[1];
    },
    handleChangeValue: (
      state,
      action: PayloadAction<{
        name: string;
        value: any;
      }>,
    ) => {
      const name = action.payload.name;
      const value = action.payload.value;
      const field = state.addCalculationOptions.find(item => item.name === name);

      if (field) {
        field.value = value;
      }

      if (name === addPropertyName.address) {
        state.isChangedCoordinates = false;
        const latitudeField = state.addCalculationOptions.find(item => item.name === addPropertyName.latitude);
        const longitudeField = state.addCalculationOptions.find(item => item.name === addPropertyName.longitude);
        if (latitudeField && longitudeField) {
          latitudeField.value = (value?.data?.geo_lat && Number(value?.data?.geo_lat).toFixed(5).toString()) || '';
          longitudeField.value = (value?.data?.geo_lon && Number(value?.data?.geo_lon).toFixed(5).toString()) || '';
        }
      }

      if (name === addPropertyName.latitude || name === addPropertyName.longitude) {
        state.isChangedCoordinates = true;
      }

      // очищаем объект, если меняются данные адрес/широта/долгота/размер кузова/график приемки
      const isClearObject =
        name === addPropertyName.address ||
        name === addPropertyName.latitude ||
        name === addPropertyName.longitude ||
        name === addPropertyName.semitrailerId ||
        name === addPropertyName.workSchedule;

      if (isClearObject) {
        state.addCalculationOptions.find(item => item.name === addPropertyName.objectId).value = '';
        state.currentObjectInfo = null;
      }
    },
    handleChangeDetailInfoValue: (
      state,
      action: PayloadAction<{
        name: string;
        value: any;
      }>,
    ) => {
      const name = action.payload.name;
      const value = action.payload.value;
      const field = state.calculationDetailsInfo.find(item => item.name === name);
      if (field) {
        field.value = value;
      }
    },
    handleChangeMaterialValue: (
      state,
      action: PayloadAction<{
        name: string;
        value: any;
      }>,
    ) => {
      const name = action.payload.name;
      const value = action.payload.value;
      const field = state.addCalculationMaterialFields.find(item => item.title === name);
      if (field) {
        field.value = value;
      }
    },
    handleChangeOptions: (state, action: PayloadAction<{ name: string; options: IMaterialsOptionsForAdmin[] }>) => {
      const field = state.addCalculationOptions.find(item => item.name === action.payload.name);
      if (field && field.properties) {
        field.properties = action.payload.options.map(property => {
          return { value: String(property?.id), label: property?.fullName || property?.title };
        });
      }
    },
    changeDisabled: (
      state,
      action: PayloadAction<{
        name: string;
        disabled: boolean;
      }>,
    ) => {
      const field = state.addCalculationOptions.find(item => item.name === action.payload.name);
      const disabled = action.payload.disabled;
      if (field) {
        field.value = '';
        field.isDisabled = disabled;
        if (disabled && field?.properties) {
          field.properties = [];
        }
      }
    },
    clearCalculationOptionsFields: state => {
      state.addCalculationOptions = initCalculationAddFields;
    },
    clearCalculationMaterialFields: state => {
      state.addCalculationMaterialFields = [];
    },
    changeCheckedIndex: (state, action: PayloadAction<number>) => {
      state.checkedCalculationDetailsIndex = action?.payload;
    },
    addInfoInCreatedCalculationFields: (state, action: PayloadAction<ICreatedCalculationRequest>) => {
      const data = action.payload;
      state.addCalculationOptions = state.addCalculationOptions.map(item => {
        const value = data[item.name];
        if (value === null) return item;
        if (item.name === createdPropertyName.workSchedule) {
          item.value = { value: value, label: value };
          return item;
        }
        if (item.name === createdPropertyName.clientPhone) {
          item.value = value ? validationPhoneNumberFunction(value) : '';
          return item;
        }
        if (item.name === createdPropertyName.unit) {
          item.value = unitFormatBackToFront(value);
          return item;
        }

        if (item.name === createdPropertyName.startDelivery || item.name === createdPropertyName.endDelivery) {
          item.value = value ? formatISOToDDMMYYYY(value) : '';
          return item;
        }
        item.value = value;
        return item;
      });
    },
    changeCalculationDetailsInfo: state => {
      if (!state.calculationDetails?.calculationDetails?.length) {
        state.calculationDetailsInfo = initCalculationDetailsInfoFields;
        return;
      }
      const currentCalculationDetails =
        state.calculationDetails?.calculationDetails[state.checkedCalculationDetailsIndex];
      const results = currentCalculationDetails?.results;
      state.calculationDetailsInfo = state.calculationDetailsInfo.map(item => {
        let value = results[item?.name];
        if (value === null) return item;
        if (item.name === calculationDetailsInfoTitles.createdAt)
          value = new Date(results[item.name])
            .toLocaleDateString('ru-RU', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })
            .replace(',', '');
        if (item.name === calculationDetailsInfoTitles.distance)
          value = `${formPriceOnlyNumber(Number(currentCalculationDetails[item.name]), 'RUB', 'ru')} км`;
        if (item.name === calculationDetailsInfoTitles.deliveryDays)
          value = `${formPriceOnlyNumber(Number(results[item.name]), 'RUB', 'ru')} ${declinationWord(results[item.name], ['день', 'дня', 'дней'])}`;
        if (item.name === calculationDetailsInfoTitles.volume)
          value = `${formPriceOnlyNumber(Number(results[item.name]), 'RUB', 'ru')} ${results.unit}`;
        if (
          item.name === calculationDetailsInfoTitles.totalCostPerUnit ||
          item.name === calculationDetailsInfoTitles.clientPricePerUnit
        )
          value = `${formPriceOnlyNumber(Number(results[item.name]), 'RUB', 'ru')} ₽/${results.unit}`;
        if (
          item.name === calculationDetailsInfoTitles.totalCostWithMargin ||
          item.name === calculationDetailsInfoTitles.totalMargin
        )
          value = `${formPriceOnlyNumber(Number(results[item.name]), 'RUB', 'ru')} ₽`;
        item.value = value;
        return item;
      });
    },
    clearDetails: state => {
      state.calculationDetails = null;
      state.calculationDetailsInfo = initCalculationDetailsInfoFields;
      state.checkedCalculationDetailsIndex = 0;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAddressFromCoordinates.fulfilled, (state, action: PayloadAction<any>) => {
      const addressField = state.addCalculationOptions.find(item => item.name === addPropertyName.address);
      if (addressField) {
        addressField.value = action.payload.suggestions[0];
      }
    });
    builder.addCase(getObject.fulfilled, (state, action: PayloadAction<IObject>) => {
      state.currentObjectInfo = action.payload;
    });
    builder.addCase(getClosestWeightBridgesCoordinates.fulfilled, (state, action: PayloadAction<ICoordinate[]>) => {
      state.closestWeightBridgesCoordinates = action.payload;
    });
    builder.addCase(getDetailRouteCoordinate.fulfilled, (state, action: PayloadAction<{ points: ICoordinate[] }>) => {
      state.detailRouteCoordinate = action.payload.points;
    });
    builder.addCase(getAllMaterialsInAdmin.fulfilled, (state, action: PayloadAction<IMaterialsOptionsForAdmin[]>) => {
      state.optionsMaterials = action.payload;
    });
    builder.addCase(getMaterialInfoInAdmin.fulfilled, (state, action: PayloadAction<ICalculationAddProperties[]>) => {
      const fields = action.payload;
      fields.pop();
      state.addCalculationMaterialFields = fields.map(field => ({
        ...field,
        width: '412px',
        placeholder: 'Выберите из списка',
        isRequired: false,
      }));
    });
    builder.addCase(getAllCalculationInAdmin.fulfilled, (state, action: IActionCalculation) => {
      const correctedDataTable = action.payload.rows.map((item: IPayloadActionCalculationRows) =>
        convertDataForCalculationTableByMap(item),
      );
      state.dataTableCalculation =
        action.meta.arg.offset > 0 ? [...state.dataTableCalculation, ...correctedDataTable] : correctedDataTable;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getCalculation.fulfilled, (state, action: PayloadAction<ICreatedCalculationRequest>) => {
      const calculationData = action?.payload;
      const materialData = calculationData.materialProperties;
      state.addCalculationOptions = initCalculationCreatedFields;
      state.addCalculationMaterialFields = materialData.map((field, index) => ({
        id: index,
        title: field.title,
        fieldType: 'input',
        value: field.value,
        width: '412px',
        placeholder: 'Выберите из списка',
        isRequired: false,
        isDisabled: true,
      }));
    });
    builder.addCase(getDetails.fulfilled, (state, action: PayloadAction<ICalculationDetails>) => {
      state.calculationDetails = action?.payload;
    });
    builder.addMatcher(isPendingAction, state => {
      state.loading = true;
    });

    builder.addMatcher(isRejectedAction, state => {
      state.loading = false;
    });

    builder.addMatcher(isFulfilledAction, state => {
      state.loading = false;
    });
  },
});

export const {
  convertDataFromUsersAdminForOptions,
  convertDataFromMaterialsAdminForOptions,
  convertDataFromOrdersAdminForOptions,
  addMaterialTypesCreateCalculations,
  addMaterialKindsCreateCalculations,
  handleChangeValue,
  handleChangeMaterialValue,
  handleChangeDetailInfoValue,
  handleChangeOptions,
  clearCalculationOptionsFields,
  clearCalculationMaterialFields,
  changeDisabled,
  addInfoInCreatedCalculationFields,
  changeCheckedIndex,
  changeCalculationDetailsInfo,
  handleChangeFieldsForObject,
  clearDetails,
} = adminCalculationSlice.actions;
export const selectOptionsManagers = (state: RootState) => state?.adminCalculation.optionsManager;
export const selectClosestWeightBridgesCoordinates = (state: RootState) =>
  state?.adminCalculation.closestWeightBridgesCoordinates;
export const selectOptionsMaterials = (state: RootState) => state?.adminCalculation.optionsMaterials;
export const selectMaterialKindFields = (state: RootState) => state.adminCalculation.addCalculationMaterialFields;
export const selectCalculationOptions = (state: RootState) => state?.adminCalculation.addCalculationOptions;
export const selectChangedCoordinates = (state: RootState) => state.adminCalculation.isChangedCoordinates;

export const selectLoading = (state: RootState) => state.adminCalculation.loading;
export const selectTableCalculation = (state: RootState) => state?.adminCalculation.dataTableCalculation;
export const selectDetailRouteCoordinate = (state: RootState) => state?.adminCalculation.detailRouteCoordinate;
export const selectTotalCountCalculation = (state: RootState) => state?.adminCalculation.totalCount;
export const selectLoadingCalculation = (state: RootState) => state?.adminCalculation.loading;

export const selectCalculationDetails = (state: RootState) => state?.adminCalculation?.calculationDetails;
export const selectCheckedCalculationDetailsIndex = (state: RootState) =>
  state?.adminCalculation?.checkedCalculationDetailsIndex;
export const selectCalculationDetailsInfo = (state: RootState) => state?.adminCalculation?.calculationDetailsInfo;

export const selectMaterialTypeValue = (state: RootState) =>
  state?.adminCalculation?.addCalculationOptions?.find(item => item?.name === createdPropertyName.materialTypeId)
    ?.value;
export const selectMaterialKindValue = (state: RootState) =>
  state?.adminCalculation?.addCalculationOptions?.find(item => item?.name === createdPropertyName.materialKindId)
    ?.value;
export const selectQuarryValue = (state: RootState) =>
  state?.adminCalculation?.addCalculationOptions?.find(item => item?.name === createdPropertyName.quarryId)?.value;

export const selectOptionsOrders = (state: RootState) => state?.adminCalculation.optionsOrders;
